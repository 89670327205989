import {
  // SET_NOTIFICATIONS,
  SET_NOTIFICATIONLOADER,
  SET_CLOSENOTIFICATIONLOADER,
  SET_CHAT_NOTIFICATION,
  RESET_CHAT_NOTIFICATION,
} from "../types/NotificationTypes";
import { path } from "./../../path";
import axios from "axios";

export const getNotifications = (id) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token, },
    } = getState();
    // validating a user using token
    const config = {
      host: `${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": `${path}`,
      },
      token,
    };
    dispatch({ type: SET_NOTIFICATIONLOADER });
    try {
      const response = await axios.get(`${path}/getNotification/${id}`, config);
      dispatch({ type: SET_NOTIFICATIONLOADER });
      console.log(response.data.data);
      dispatch({ type: "UPDATE_NOTIFICATION", payload: response.data.data });
      dispatch({ type: SET_CLOSENOTIFICATIONLOADER });
    } catch (e) {
      dispatch({ type: SET_CLOSENOTIFICATIONLOADER });
      console.log(e);
    }
  };
};

export const setChatNotification = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CHAT_NOTIFICATION, payload: data });
  };
};

export const resetChatNotification = (message) => {
  return (dispatch) => {
    // console.log("Message text:", message.text);
    dispatch({ type: RESET_CHAT_NOTIFICATION, payload: message.text });
  };
};
