/* eslint-disable no-warning-comments */
/* eslint-disable react/no-array-index-key */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { path } from "../../../path";
import PhotoPost from "./Posts/PhotoPost";
import { Close } from "@mui/icons-material";

function ReportedPosts() {
  const { token } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState(false);
  const [reportedPosts, setReportedPosts] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [Postfallback, setpostfallback] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [content, setContent] = useState("");
  const [showDeleteDialogue, setShowDeleteDialogue] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(-1);

  const deleteReportPostHandler = async (reportedPostId) => {
    try {
      const res = await axios.delete(
        `${path}/admin/deletereportedpost/${reportedPostId}`
      );
      if (res.data.success) {
        alert("Deleted successfully");
        getAllReportedPosts();
        return;
      } else {
        alert("Error in deleting post");
        return;
      }
    } catch (error) {
      console.log(error);
      alert("Error in deleting post.");
    }
  };

  const removeReportHandler = async (reportId) => {
    try {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await axios.delete(
        `${path}/admin/removeReportedPostRequest/${reportId}`,
        config
      );
      if (res.data.success) {
        getAllReportedPosts();
        return;
      } else {
        alert("Error in deleting post");
        return;
      }
    } catch (error) {
      console.log(error);
      alert("Error in deleting post.");
    }
  };

  const getAllReportedPosts = async () => {
    try {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      const res = await axios.get(
        `${path}/admin/fetchallreportedposts`,
        config
      );

      const data = res?.data;
      ////  Separated valid data(having createdAt date) and invalid data(not having createdAt date)
      ////  this is because invalid data will be appended at the end of final list
      const validData = data?.filter((item) => item?.postId?.createdAt);
      const invalidData = data?.filter((item) => !item?.postId?.createdAt);

      setReportedPosts(
        validData
          .sort(
            // Sorting in descending order
            (item1, item2) =>
              Date.parse(item2.postId.createdAt) -
              Date.parse(item1.postId.createdAt)
          )
          .concat(invalidData)
      );
      setpostfallback(res?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert("Error in fetching reported posts.");
    }
  };

  useEffect(() => {
    getAllReportedPosts();
  }, []);

  const searchPost = (e) => {
    if (e.trim() === "") {
      setReportedPosts(Postfallback);
    } else {
      const filterdPost = reportedPosts?.filter((post) => {
        return post?.postId?._id.includes(e);
      });
      setReportedPosts(filterdPost);
    }
  };

  useEffect(() => {
    searchPost(searchtext);
  }, [searchtext]);

  const deleteHandler = () => {
    deleteReportPostHandler(postIdToDelete);
    setShowDeleteDialogue(false);
  };

  return (
    <div className="text-primary bg-white min-h-[100vh] px-2  py-[3rem] overflow-x-none ">
      {/* THIS SHOWS DELETE CONFIRMATION DIALOGUE BOX */}
      {showDeleteDialogue ? (
        <div className="fixed top-0 left-0 flex justify-center items-center bg-[#000000b3] w-[100%] h-[100%] z-30">
          <div className="flex flex-col p-4 w-[25%] bg-white rounded-lg">
            <p className="text-[1.05rem]">
              Are you sure want to delete reported post?
            </p>
            <div className="ml-auto mt-6">
              <button
                className="px-6 py-1 rounded-lg text-white bg-[#008080]"
                onClick={() => deleteHandler()}>
                Yes
              </button>
              <button
                className="px-6 py-1 ml-2 rounded-lg"
                onClick={() => setShowDeleteDialogue(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* THIS SHOWS CONTENT OF POST LIKE IMAGE, VIDEO, TEXT */}
      {showContent ? (
        <div className="fixed flex flex-col h-[100%] w-[100%] items-center top-0 left-0 z-30 bg-[rgba(0,0,0,0.7)] text-white">
          <button
            className="ml-auto mr-2 mt-2"
            onClick={() => {
              setContent(""); //  Setting content as blank so that it won't be showed for next report's content
              setShowContent(false);
            }}>
            <Close fontSize="large" />
          </button>
          <div className="flex justify-center items-center mt-4 w-[90%] h-[85%]">
            {content}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="flex justify-center">
        <input
          type="text"
          value={searchtext}
          onChange={(e) => setsearchtext(e.target.value)}
          placeholder="Search By Post id"
          className="text-grayish search-tab-event p-4 rounded-3xl border m-1 border-viewMore w-[31vw] h-8 mb-2 bg-primary outline-none
              focus:ring-2 ring-[#008080] Searchbox"
        />
      </div>
      <div className="flex flex-col items-center justify-between w-[80vw] px-2 overflow-y-auto">
        {loading ? (
          <p className="text-primary">Loading...</p>
        ) : reportedPosts.length > 0 ? (
          reportedPosts.map((rp, index) => {
            return (
              <PhotoPost
                key={index}
                post={rp}
                setShowContent={setShowContent}
                setContent={setContent}
                setShowDeleteDialogue={setShowDeleteDialogue}
                setPostIdToDelete={setPostIdToDelete}
                removeReportHandler={removeReportHandler}
              />
            );
          })
        ) : (
          <p>No reported posts found.</p>
        )}
      </div>
    </div>
  );
}

export default ReportedPosts;
