import React from 'react'

function D({ fields }) {
    return (
        <>
            {fields?.map((field) => {
                return <div key={field._id} className="flex flex-col text-primary p-3 gap-2">
                    <h1 className="font-gilroy-medium text-compPrimary text-base">{field.title}</h1>
                    <p className="font-gilroy-regular text-xs text-compSec leading-4" name="" id="" cols="30" rows="10">
                        {field.response}
                    </p>
                </div>
            })}
        </>

    )
}

export default D