import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals.js";
import { HashRouter } from "react-router-dom";
import Store, { persistor } from "./store/index.js";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { CookiesProvider } from "react-cookie";
import { WebSocketContext, socket } from "./WebSocket.js";
import { GoogleOAuthProvider } from "@react-oauth/google";

// eslint-disable-next-line no-warning-comments
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  // Setting up global Store
  <CookiesProvider>
    <React.StrictMode>
      <WebSocketContext.Provider value={socket}>
        <Provider store={Store}>
          <PersistGate persistor={persistor}>
            <HashRouter>
              <GoogleOAuthProvider clientId="749240237355-5sluo8i7vd5m3e1vk0chvgpqn0b62hol.apps.googleusercontent.com">
                <App />
              </GoogleOAuthProvider>
            </HashRouter>
          </PersistGate>
        </Provider>
      </WebSocketContext.Provider>
    </React.StrictMode>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
