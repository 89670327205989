/* eslint-disable no-warning-comments */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useContext } from "react";
import { path } from "../../path";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PasswordIcon from "@mui/icons-material/Password";
import Alert from "../Alert/Alert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Header/Header";
// Import the SVG file

import BarChartIcon from "@mui/icons-material/BarChart";
import ChangePassword from "../ChangePassword/ChangePassword";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ChangeEmail from "../ChangeEmail/ChangeEmail";
import FeedbackForm from "../FeedbackForm/FeedbackForm";
import CloseAccount from "../CloseAccount/CloseAccount";
import LockIcon from "@mui/icons-material/Lock";
import EditIcon from "@mui/icons-material/Edit";
import MailLockIcon from "@mui/icons-material/MailLock";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import axios from "axios";
import MobileFooter from "../footer/MobileFooter";
import { UPDATE_ACCOUNT } from "../../store/types/AuthTypes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { WebSocketContext } from "../../WebSocket";
import MyStatisticsDesktop from "../MyStatistics/my-statistics-desktop";
import PaymentDetailModal from "../Modals/PaymentDetailModal";

const AccountInfo = () => {
  // const params = useParams();
  const socket = useContext(WebSocketContext);
 
  const history = useHistory();
  const lctn = history.location.pathname.split("/")[1];
  const [location, setLocation] = useState(lctn);
  const handleLocation = (e, path) => {
    setLocation(path);
  };
  const [UpdateOperation, setUpdateOperation] = useState(false);
  const [val, setVal] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const { user, token } = useSelector((state) => state.AuthReducer);
  const [fullName, setFullName] = useState(
    `${user.firstName} ${user.lastName}`
  );
  // const [Countries, setCountries] = useState([]);
  const [Countries2, setCountries2] = useState([]);
  const [userDetails, setUserDetails] = useState(user);
  { console.log("userDetails",userDetails);}
  const dispatch = useDispatch();
  // const [rerender, setRerender] = useState(false);
  const [custom, setCustom] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
  });
  const [isUpiModalVisible, setIsUpiModalVisible] = useState(false);
  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000,
    pauseOnHover: true,
    draggable: true,
    theme: user.theme === "dark" ? "colored" : "light",
  };

  const handleCustom = (e) => {
    // Allows on alphabetical values 
    e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '');

    setCustom({ ...custom, [e.target.name]: e.target.value });
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleCountry = (e) => {
    console.log("e", e.target.name, e.target.value);
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    console.log("showing user", user);
  };

  const handleGender = (e) => {
    console.log("e", e.target.name, e.target.value);
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handlePhone = (e) => {
    console.log("e", e.target.name, e.target.value);
    e.target.value = e.target.value.replace(/[^0-9]/g, '')
    console.log("e", e.target.name, e.target.value);
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  }

  const [selectedDate, setSelectedDate] = React.useState(
    userDetails.birthdate ? new Date(userDetails.birthdate) : null
  );

  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 10);

  // Set maximum date to 13 years ago
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 13);

  const handleDateOfBirthChange = (date) => {
    setSelectedDate(date);
    console.log("showing date", date);
    const formattedDate = date.toISOString();
    setUserDetails({ ...userDetails, birthdate: formattedDate });
    console.log("Formatteddate", formattedDate);
  };

   //address Changes fields
   const handleAddress1=(e)=>{
     setUserDetails({ ...userDetails, addressLine1: e.target.value });
   }
   const handleAddress2=(e)=>{
     setUserDetails({ ...userDetails, addressLine2: e.target.value }); 
   }

  const [show, setShow] = useState(false);
  const [editPhoneNumber,setEditPhoneNumber] = useState(false);

  // to change theme of userdetails according to user in redux
  useEffect(() => {
    setUserDetails({ ...userDetails, theme: user.theme });
    console.log(val);
  }, [user]);
  
  const getNotification = async () => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(
        `${path}/getNotification/${user._id}`,
        config
      );
      const filteredNoti = response.data.data.filter(
        (noti) =>
          noti.fromUserId !== null &&
          noti.type !== "Not Improve" &&
          noti.type !== "Unappreciate" &&
          noti.postId !== null
      );
      dispatch({ type: "UPDATE_NOTIFICATION", payload: filteredNoti });
    } catch (error) {
      console.log(error);
    }
  };
  const updateAccount = async (updatedDetails) => {
    // //console.log("Action: "+updatedDetails.firstName);
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    console.log(updatedDetails);
    try {
      const response = await axios.post(
        `${path}/updateAccount/${updatedDetails._id}`,
        { updatedDetails, flag: true },
        config
      );
      socket.emit("detailsUpdated", user._id);
      console.log(response);
      dispatch({ type: UPDATE_ACCOUNT, payload: response.data });
      getNotification();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setUserDetails((prev) => ({ ...prev, theme: user.theme }));
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(custom.firstName);
    if (custom.firstName.trim() === "" || custom.lastName.trim() === "") {
      toast.info("Name can't be empty", toastOption);
      return;
    }

    if(userDetails.phone !== '' && userDetails.phone?.length !== 10){
      toast.info("Enter a vaild Number", toastOption)
      return
    }

    // const arr = fullName.toString().split(" ");

    setFullName(`${custom.firstName} ${custom.lastName}`);
    updateAccount(userDetails);
    setUpdateOperation(true);

    // Timer for custom alert box
    setTimeout(() => {
      setUpdateOperation(false);
      // toggle(false);
    }, 2000);

    setShow(false);
    setEditPhoneNumber(false)
    // setRerender((prev) => !prev);
  };

  const handleName = (e) => {
    setFullName(e.target.value);
  };
  const handleResize = () => {
    if (window.innerWidth < 770) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(async () => {
    handleResize();
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `*`,
      },
    };
    const ArrayCountries = await axios.get(
      "https://countriesnow.space/api/v0.1/countries/capital",
      config
    );
    // setCountries(ArrayCountries.data.data);
    setCountries2([
      ...[{ name: "Select Country" }],
      ...ArrayCountries.data.data,
    ]);
  }, []);

  // const handleMystatistics = () => {
  //   // console.log(params)
  //   history.push(`/mystatistics/${params.userId}`);
  // };

  return (
    <>
      <Helmet>
        <title>Settings</title>
        <meta name="description" content="Upload Video" />
      </Helmet>
      <Header />
      <Toaster position="top-right" reverseOrder={false} />
      {isUpiModalVisible && (
        <PaymentDetailModal
          setIsVisible={setIsUpiModalVisible}
          userId={user._id}
          toast={toast}
          displayText={"Your UPI ID"}
        />
      )}
      <div className="bg-page min-h-[100vh] overflow-y-auto">
        <div className="max-w-[1240px] mx-auto md:py-[4rem] px-[1rem]">
          <div className="grid grid-cols-1 md:grid-cols-4 md:gap-2">
            {!isMobile ? (
              <div className="gap-y-3 border-none rounded-lg text-primary border-1 h-70 flex flex-col items-start justify-start gap-2 border-viewMore  text-xl  mt-6 font-gilroy-medium">
                <div className="flex rounded-lg flex-col items-start justify-start gap-2 border-viewMore  text-xl bg-card font-gilroy-medium">
                <div className="mt-4 ">
                  <h4
                    className={
                      location === "accountInfo"
                        ? "cursor-pointer  px-9 py-2 rounded-t-lg text-[#008080]"
                        : "text-primary  px-9 py-2 cursor-pointer  rounded-t-lg hover:text-teal-600 transition duration-200"
                    }
                    onClick={(e) => handleLocation(e, "accountInfo")}>
                    {/* <ManageAccountsIcon
                      className="mr-2 md:mx-auto"
                      sx={{
                        color:
                          location === "accountsInfo" ? "white" : "#637381",
                      }}
                    />{" "} */}
                    <div className="flex items-center gap-1">
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-2 md:mx-auto">
                          <g clipPath="url(#clip0_759_5769)">
                            <path
                              d="M8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346629 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87104 0 6.41775 0 8C0.00229405 10.121 0.845886 12.1545 2.34568 13.6543C3.84547 15.1541 5.87897 15.9977 8 16ZM8 3.33334C8.19778 3.33334 8.39112 3.39199 8.55557 3.50187C8.72002 3.61175 8.84819 3.76793 8.92388 3.95065C8.99957 4.13338 9.01937 4.33445 8.98079 4.52843C8.9422 4.72241 8.84696 4.90059 8.70711 5.04044C8.56726 5.1803 8.38907 5.27554 8.19509 5.31412C8.00111 5.35271 7.80004 5.3329 7.61732 5.25722C7.43459 5.18153 7.27841 5.05336 7.16853 4.88891C7.05865 4.72446 7 4.53112 7 4.33334C7 4.06812 7.10536 3.81377 7.29289 3.62623C7.48043 3.43869 7.73478 3.33334 8 3.33334ZM7.33333 6.66667H8C8.35362 6.66667 8.69276 6.80715 8.94281 7.0572C9.19286 7.30724 9.33333 7.64638 9.33333 8V12C9.33333 12.1768 9.2631 12.3464 9.13807 12.4714C9.01305 12.5964 8.84348 12.6667 8.66667 12.6667C8.48986 12.6667 8.32029 12.5964 8.19526 12.4714C8.07024 12.3464 8 12.1768 8 12V8H7.33333C7.15652 8 6.98695 7.92977 6.86193 7.80474C6.73691 7.67972 6.66667 7.51015 6.66667 7.33334C6.66667 7.15653 6.73691 6.98696 6.86193 6.86193C6.98695 6.73691 7.15652 6.66667 7.33333 6.66667Z"
                              fill={
                                location === "accountInfo"
                                  ? "#008080"
                                  : "#637381"
                              }
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_759_5769">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span> Account Info</span>
                    </div>
                  </h4>
                </div>
                {/* <hr className="border-viewMore"></hr> */}
                <div>
                  <h4
                    className={
                      location === "changeEmail"
                        ? " cursor-pointer   px-9 py-2 text-[#008080]"
                        : "text-primary px-9 py-2 cursor-pointer hover:text-teal-600 transition duration-200"
                    }
                    onClick={(e) => handleLocation(e, "changeEmail")}>
                    {/* <EmailIcon
                      className="mr-2 md:mx-auto"
                      sx={{
                        color:
                          location === "changesEmail" ? "white" : "#637381",
                      }}
                    />{" "}
                    Alternate Email */}

                    <div className="flex items-center gap-1">
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-2 md:mx-auto">
                          <g clipPath="url(#clip0_759_5773)">
                            <path
                              d="M15.9693 3.69482L10.3573 9.30682C9.73158 9.93099 8.88383 10.2815 8 10.2815C7.11617 10.2815 6.26842 9.93099 5.64267 9.30682L0.0306667 3.69482C0.0213333 3.80016 0 3.89549 0 4.00016V12.0002C0.00105857 12.8839 0.352588 13.7311 0.97748 14.356C1.60237 14.9809 2.4496 15.3324 3.33333 15.3335H12.6667C13.5504 15.3324 14.3976 14.9809 15.0225 14.356C15.6474 13.7311 15.9989 12.8839 16 12.0002V4.00016C16 3.89549 15.9787 3.80016 15.9693 3.69482Z"
                              fill={
                                location === "changeEmail"
                                  ? "#008080"
                                  : "#637381"
                              }
                            />
                            <path
                              d="M9.41476 8.36384L15.5041 2.27384C15.2091 1.78472 14.7931 1.37986 14.2961 1.09832C13.7991 0.816786 13.2379 0.668064 12.6668 0.666504H3.33343C2.76224 0.668064 2.20109 0.816786 1.70411 1.09832C1.20713 1.37986 0.791079 1.78472 0.496094 2.27384L6.58543 8.36384C6.96114 8.73805 7.46982 8.94815 8.00009 8.94815C8.53037 8.94815 9.03905 8.73805 9.41476 8.36384Z"
                              fill={
                                location === "changeEmail"
                                  ? "#008080"
                                  : "#637381"
                              }
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_759_5773">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span> Alternate Email</span>
                    </div>
                  </h4>
                </div>
                {/* <hr className="border-viewMore"></hr> */}
                <div>
                  <h4
                    className={
                      location === "changePassword"
                        ? " cursor-pointer   px-9 py-2  text-[#008080]"
                        : "text-primary px-9 py-2 cursor-pointer hover:text-teal-600 transition duration-200 "
                    }
                    onClick={(e) => handleLocation(e, "changePassword")}>
                    {/* <PasswordIcon
                      className="mr-2 md:mx-auto"
                      sx={{
                        color:
                          location === "changesPassword" ? "white" : "#637381",
                      }}
                    />{" "}
                     */}

                    <div className="flex items-center gap-1">
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-2 md:mx-auto">
                          <g clipPath="url(#clip0_759_5778)">
                            <path
                              d="M14.6667 8.05299e-06H13.7094C13.4467 -0.000740267 13.1864 0.0506715 12.9437 0.151263C12.7009 0.251855 12.4806 0.399625 12.2954 0.586008L6.61075 6.26667C5.5393 5.90066 4.37548 5.90821 3.30887 6.2881C2.24226 6.668 1.33571 7.39785 0.736882 8.35878C0.138051 9.3197 -0.11777 10.4551 0.0110869 11.58C0.139944 12.7049 0.645887 13.753 1.4465 14.5536C2.24712 15.3542 3.29524 15.8601 4.42012 15.989C5.54501 16.1179 6.68039 15.862 7.64131 15.2632C8.60224 14.6644 9.33209 13.7578 9.71199 12.6912C10.0919 11.6246 10.0994 10.4608 9.73342 9.38934L11.3334 7.78934V5.33334H13.3334V3.33334H15.7007C15.8954 3.02031 15.999 2.65928 16.0001 2.29067V1.33334C16.0001 0.979719 15.8596 0.640581 15.6096 0.390532C15.3595 0.140484 15.0204 8.05299e-06 14.6667 8.05299e-06ZM3.66675 13.3333C3.46897 13.3333 3.27563 13.2747 3.11118 13.1648C2.94673 13.0549 2.81856 12.8988 2.74287 12.716C2.66718 12.5333 2.64738 12.3322 2.68596 12.1383C2.72455 11.9443 2.81979 11.7661 2.95964 11.6262C3.09949 11.4864 3.27768 11.3911 3.47166 11.3526C3.66564 11.314 3.86671 11.3338 4.04943 11.4095C4.23216 11.4852 4.38834 11.6133 4.49822 11.7778C4.6081 11.9422 4.66675 12.1356 4.66675 12.3333C4.66675 12.5986 4.56139 12.8529
                             4.37385 13.0404C4.18632 13.228 3.93196 13.3333 3.66675 13.3333Z"
                              fill={
                                location === "changePassword"
                                  ? "#008080"
                                  : "#637381"
                              }
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_759_5778">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span> Change Password</span>
                    </div>
                  </h4>
                </div>
                {/* <hr className="border-viewMore"></hr> */}
                <div>
                  <h4
                    className={
                      location === "feedback"
                        ? " cursor-pointer py-2  px-9 text-[#008080]"
                        : "text-primary px-9 py-2 cursor-pointer hover:text-teal-600 transition duration-200 "
                    }
                    onClick={(e) => handleLocation(e, "feedback")}>
                    <FeedbackIcon
                      className="mr-2 md:mx-auto"
                      sx={{
                        color: location === "feedsback" ? "#008080" : "#637381",
                      }}
                    />{" "}
                    Feedback
                  </h4>
                </div>
                {/* <hr className="border-viewMore"></hr> */}
                <div>
                  <h4
                    className={
                      location === "closeAccount"
                        ? " cursor-pointer py-2  px-9  text-[#008080]"
                        : "text-primary px-9 py-2 cursor-pointer hover:text-teal-600 transition duration-200 rounded-b-lg"
                    }
                    onClick={(e) => handleLocation(e, "closeAccount")}>
                    {/* <PersonRemoveIcon
                      className="mr-2 md:mx-auto"
                      sx={{
                        color:
                          location === "closesAccount" ? "white" : "#637381",
                      }}
                    />{" "}
                    Close Account */}

                    <div className="flex items-center gap-1">
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-2 md:mx-auto">
                          <g clipPath="url(#clip0_759_5782)">
                            <path
                              d="M15.758 7.03348L12.9233 1.72548C12.5753 1.07281 11.8993 0.66748 11.1593 0.66748H4.85468C4.11601 0.66748 3.44001 1.07148 3.09201 1.72215L0.244012 7.03281C-0.0819883 7.64015 -0.0819883 8.36615 0.244012 8.97348L3.09135 14.2788C3.44001 14.9288 4.11535 15.3328 4.85401 15.3328H11.1587C11.8987 15.3328 12.5747 14.9275 12.9227 14.2748L15.7573 8.96681C16.0807 8.36148 16.0807 7.63815 15.7573 7.03281L15.758 7.03348ZM10.828 9.88615L9.88535 10.8288L8.00001 8.94348L6.11468 10.8288L5.17201 9.88615L7.05735 8.00015L5.17201 6.11415L6.11468 5.17148L8.00001 7.05681L9.88535 5.17148L10.828 6.11415L8.94268 8.00015L10.828 9.88615Z"
                              fill={
                                location === "closeAccount"
                                  ? "#008080"
                                  : "#637381"
                              }
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_759_5782">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span> Deactivate Account</span>
                    </div>
                  </h4>
                </div>
                {/* <hr className="border-viewMore"></hr> */}
                <div className="mb-4">
                  <h4
                    className={
                      "text-primary px-9 py-2 cursor-pointer hover:text-teal-600 transition duration-200 rounded-b-lg"
                    }
                    onClick={(e) => handleLocation(e, "myStatistics")}>
                    <BarChartIcon
                      className="mr-2 md:mx-auto"
                      sx={{
                        color: location === "myStatistics" ? "" : "#637381",
                      }}
                    />{" "}
                    My Statistics
                  </h4>
                </div>
                </div>
              </div>
            ) : (
              <div className="mt-8">
              </div>
            )}
            <div className="border-none text-primary col-span-3  p-5 bg-card  rounded-lg shadow md:mt-6 ">
              {isMobile ? (
                <>
                  <div className="flex items-center justify-evenly">
                    <div className="px-3 py-3">
                      <h4
                        className={
                          location === "accountInfo"
                            ? "cursor-pointer bg-[#008080] px-4 py-1 rounded-lg text-white"
                            : ""
                        }
                        onClick={(e) => handleLocation(e, "accountInfo")}>
                        <ManageAccountsIcon
                          style={{ fontSize: "30px" }}
                          sx={{
                            color:
                              location === "accountInfo" ? "white" : "#637381",
                          }}
                        />
                      </h4>
                    </div>
                    <div className="px-3 py-3">
                      <h4
                        className={
                          location === "changeEmail"
                            ? "cursor-pointer bg-[#008080] px-4 py-1 rounded-lg text-white"
                            : ""
                        }
                        onClick={(e) => handleLocation(e, "changeEmail")}>
                        <MailLockIcon
                          style={{ fontSize: "30px" }}
                          sx={{
                            color:
                              location === "changeEmail" ? "white" : "#637381",
                          }}
                        />{" "}
                      </h4>
                    </div>
                    <div className="px-3 py-3">
                      <h4
                        className={
                          location === "feedback"
                            ? "cursor-pointer bg-[#008080] px-4 py-1 rounded-lg text-white"
                            : ""
                        }
                        onClick={(e) => handleLocation(e, "feedback")}>
                        <FeedbackIcon
                          className="mr-2 md:mx-auto"
                          sx={{
                            color:
                              location === "feedback" ? "white" : "#637381",
                          }}
                        />{" "}
                      </h4>
                    </div>
                    <div className="px-3 py-3">
                      <h4
                        className={
                          location === "changePassword"
                            ? "cursor-pointer bg-[#008080] px-4 py-1 rounded-lg text-white"
                            : ""
                        }
                        onClick={(e) => handleLocation(e, "changePassword")}>
                        <PasswordIcon
                          style={{ fontSize: "30px" }}
                          sx={{
                            color:
                              location === "changePassword"
                                ? "white"
                                : "#637381",
                          }}
                        />
                      </h4>
                    </div>
                    <div className="px-3 py-3">
                      <h4
                        className={
                          location === "closeAccount"
                            ? "cursor-pointer bg-[#008080] px-4 py-1 rounded-lg text-white"
                            : ""
                        }
                        onClick={(e) => handleLocation(e, "closeAccount")}>
                        <NoAccountsIcon
                          style={{ fontSize: "30px" }}
                          sx={{
                            color:
                              location === "closeAccount" ? "white" : "#637381",
                          }}
                        />
                      </h4>
                    </div>
                    <div className="px-3 py-3">
                      <h4
                        className={
                          location === "myStatistics"
                            ? "cursor-pointer bg-[#008080] px-4 py-1 rounded-lg text-white"
                            : ""
                        }
                        onClick={(e) => handleLocation(e, "myStatistics")}>
                        <BarChartIcon
                          style={{ fontSize: "30px" }}
                          sx={{
                            color:
                              location === "myStatistics" ? "white" : "#637381",
                          }}
                        />
                      </h4>
                    </div>
                  </div>
                  <hr className="border-viewMore"></hr>
                </>
              ) : (
                <></>
              )}
              {location === "accountInfo" ? (
                !UpdateOperation ? (
                  <div>
                    <form
                      onSubmit={(e) =>
                        handleSubmit(e, custom.firstName, custom.lastName)
                      }>
                      <div className="grid gap-2 mx-2 my-5 md:grid-cols-2">
                        <div>
                          {show ? (
                            <>
                              <div className="grid gap-2 md:grid-cols-2 ">
                                <input
                                  className="w-full p-2 rounded-lg outline-none bg-neutral text-primary border-viewMore border-1 focus:ring-2 ring-teal-700 "
                                  type="text"
                                  name="firstName"
                                  onChange={handleCustom}
                                  value={custom.firstName}
                                  placeholder="First Name*"
                                  required></input>
                                <input
                                  className="w-full p-2 rounded-lg outline-none bg-neutral text-primary border-viewMore border-1 focus:ring-2 ring-teal-700 "
                                  type="text"
                                  name="lastName"
                                  onChange={handleCustom}
                                  value={custom.lastName}
                                  placeholder="Last Name"></input>
                              </div>
                            </>
                          ) : (
                            <div className="relative">
                              <input
                                disabled
                                className="w-full p-2 rounded-lg outline-none text-primary border-viewMore border-1 focus:ring-2 ring-teal-700 bg-neutral"
                                type="text"
                                name="fullName"
                                onChange={handleName}
                                value={fullName}
                                data-testid="fullName"
                                placeholder="Full Name"></input>
                              <EditIcon
                                className="absolute cursor-pointer top-2 right-2"
                                sx={{ color: "#A3A2A6" }}
                                onClick={() => setShow(true)}
                              />
                            </div>
                          )}
                        </div>
                        <div className="relative">
                          <input
                            type="text"
                            className="w-full p-2 rounded-lg outline-none text-grayish border-viewMore border-1 focus:ring-2 ring-teal-700 bg-neutral"
                            disabled
                            value={userDetails.username}
                            data-testid="username"
                            placeholder="Username"></input>
                          <LockIcon
                            className="absolute cursor-pointer top-2 right-2"
                            sx={{ color: "#A3A2A6" }}
                          />
                        </div>
                      </div>
                      <div className="grid gap-2 mx-2 my-5 md:grid-cols-2">
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDateOfBirthChange}
                          // minDate={minDate}
                          maxDate={maxDate}
                          className="w-full p-2 rounded-lg outline-none text-primary bg-neutral border-viewMore border-1 focus:ring-2 ring-teal-700"
                          placeholderText="D.O.B"
                          dateFormat="yyyy-MM-dd"
                          showYearDropdown
                          scrollableYearDropdown
                          showTimeInput
                          timeInputLabel="Time"
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="Time"
                        />

                        {/* </div> */}
                        <div>
                          <select
                            name="country"
                            onChange={(e) => {
                              handleCountry(e);
                            }}
                            id="category"
                            placeholder=""
                            value={userDetails?.country}
                            className="w-full p-2 rounded-lg outline-none text-primary bg-neutral border-viewMore border-1 focus:ring-2 ring-teal-700 "
                            aria-label="Default select example">
                            {userDetails?.country === undefined
                              ? Countries2?.map((element, index) => {
                                  return (
                                    <option key={index} value={element.name}>
                                      {element.name}
                                    </option>
                                  );
                                })
                              : Countries2.slice(1)?.map((element, index) => {
                                  return (
                                    <option key={index} value={element.name}>
                                      {element.name}
                                    </option>
                                  );
                                })}
                          </select>
                        </div>


                      </div>
                      <div className="grid gap-2 gap-y-5 mx-2 my-5">
                        <div>
                          <input
                              className="w-full p-2 rounded-lg outline-none bg-neutral text-primary border-viewMore border-1 focus:ring-2 ring-teal-700 "
                              type="text"
                              name="address1"
                              onChange={handleAddress1}
                              value={userDetails?.addressLine1 || ""}
                              placeholder="Address Line 1*"
                              required>
                            </input>
                        </div>
                        <div> 
                          <input
                              className="w-full p-2 rounded-lg outline-none bg-neutral text-primary border-viewMore border-1 focus:ring-2 ring-teal-700 "
                              type="text"
                              name="address2"
                              onChange={handleAddress2}
                              value={userDetails?.addressLine2 || ""}
                              placeholder="Address Line 2"
                              required>
                            </input>
                        </div>
                      </div>
                      {/* This is extra */}
                      <div className="grid gap-2 mx-2 my-5 md:grid-cols-2">
                        <div>
                          <select
                            name="gender"
                            onChange={(e) => {
                              handleGender(e);
                            }}
                            id="gender"
                            value={userDetails?.gender}
                            className="w-full p-2 rounded-lg outline-none text-primary bg-neutral border-viewMore border-1 focus:ring-2 ring-teal-700"
                            aria-label="Select gender">
                            <option value="" disabled selected>
                              Select gender
                            </option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="others">Others</option>
                          </select>
                        </div>
                        <div>
                          <button
                            className="w-full text-start px-2 py-1.5 rounded-lg outline-none text-primary bg-neutral border-viewMore border-1 focus:ring-2 ring-teal-700"
                            type="button"
                            onClick={(event) => {
                              event.preventDefault();
                              setIsUpiModalVisible(true);
                            }}>
                            {user.paymentDetail ? user.paymentDetail : "UPI ID"}
                          </button>
                        </div>
                      </div>
                      <div className="grid gap-2 mx-2 my-5 md:grid-cols-2">
                        <div>
                            <div className="relative">
                              <input
                                disabled={!editPhoneNumber}
                                className="w-full p-2 rounded-lg outline-none text-primary border-viewMore border-1 focus:ring-2 ring-teal-700 bg-neutral"
                                type="text"
                                name="phone"
                                id="phone"
                                onChange={handlePhone}
                                value={userDetails?.phone}
                                data-testid="phoneNumber"
                                placeholder="Phone Number">
                                </input>

                              {!editPhoneNumber && 
                                <EditIcon
                                className="absolute cursor-pointer top-2 right-2"
                                sx={{ color: "#A3A2A6" }}
                                onClick={() => setEditPhoneNumber(true)}
                              />
                              }
                            </div>
                        </div>                        
                      </div>
                      <hr className="border-viewMore"></hr>
                      <input
                        style={{ border: "black", marginLeft: "0.5rem" }}
                        type="submit"
                        className="rounded-full field4 w-[75vw] sm:w-[86vw] h-[5vh] px-2 border-2 border-[#0E0E0E] bg-primary  my-[0.7rem] hover:bg-hover md:h-[6vh] transition duration-200 md:text-[1rem] md:w-[15vw] md:border-[#0E0E0E] shadow-2xl bg-teal-600 hover:bg-teal-700 md:hover:border-[#8C94AC] text-white cursor-pointer"
                        value="Update"
                      />
                    </form>
                  </div>
                ) : (
                  <div>
                    <Alert
                      msg={"Your changes has been done Successfully!!"}
                      type="success"
                    />
                  </div>
                )
              ) : location === "changeEmail" ? (
                <ChangeEmail
                  setVal={setVal}
                  getNotification={getNotification}
                />
              ) : location === "changePassword" ? (
                <ChangePassword
                  setVal={setVal}
                  getNotification={getNotification}
                />
              ) : location === "feedback" ? (
                <FeedbackForm />
              ) : location === "closeAccount" ? (
                <CloseAccount
                  setVal={setVal}
                  getNotification={getNotification}
                />
              ) : location === "myStatistics" ? (

                <div className="h-full flex items-center justify-center overflow-y-scroll scrollbar-hide">
                  <MyStatisticsDesktop />
                </div>
              ):(<></>)}
            </div>
          </div>
        </div>
      </div>
      {isMobile && <MobileFooter />}
    </>
  );
};

export default React.memo(AccountInfo);
