import React from 'react'
import CompAll from './CompAll'
import ContestList from './ContestList'

function CompetitionNew() {
    return (
        <>
            <div className='md:hidden'>
                <ContestList />
            </div>
            <div className='hidden md:block'>
                <CompAll />
            </div>
        </>
        
    )
}

export default CompetitionNew