import axios from "axios";
import { path } from "../../path";

function generateRandomUsername(firstName, lastName) {
  const randomNumber = Math.floor(Math.random() * 100); // You can adjust the range of the random number if needed
  const dotOrUnderscore = Math.random() < 0.5 ? "." : "_";

  let usernameOptions = [
    `${firstName}${dotOrUnderscore}${lastName}`,
    `${firstName}${randomNumber}}${dotOrUnderscore}`,
    `${firstName}_${lastName}${randomNumber}`,
    `${lastName}${dotOrUnderscore}${firstName}`,
    `${lastName}${randomNumber}}${dotOrUnderscore}`,
    `${lastName}_${firstName}${randomNumber}`,
    `${firstName}.${lastName}`,
    `${lastName}.${firstName}`,
  ];

  // Filter out usernames with special characters and adjust length
  usernameOptions = usernameOptions.filter(
    (username) =>
      username.length >= 5 &&
      username.length <= 30 &&
      !/[^a-zA-Z0-9_.]/.test(username)
  );

  const randomIndex = Math.floor(Math.random() * usernameOptions.length);
  return usernameOptions[randomIndex];
}

const searchUsername = async (val) => {
  try {
    const response = await axios.post(`${path}/searchusername`, {
      username: val,
    });
    console.log(response);
    return response.data.user;
  } catch (error) {
    console.log(error);
  }
};

export const getRandomUserName = async (firstname, lastname) => {
  if (firstname !== "" && lastname !== "") {
    // console.log(googleuser?.firstname);
    // console.log(googleuser?.lastname);
    let user = generateRandomUsername(firstname, lastname);
    let res = await searchUsername(user);

    while (res !== null) {
      user = generateRandomUsername(firstname, lastname);
      res = await searchUsername(user);
    }
    return user;
  } else {
    return null;
  }
};
