import * as React from "react";
import A from "./CompetitionUI/A";
import B from "./CompetitionUI/B";
import C from "./CompetitionUI/C";
import { useHistory } from "react-router-dom";

function ContestCard({ contest, contestId }) {

    const history = useHistory();
    // console.log(contest.sections[3].fields.slice(0, 2))

    return (
        <div onClick={() => history.push(`/competition/${contestId}`)} className="flex bg-compBgSec gap-2 flex-col mt-4 w-full font-medium bg-card rounded-lg">
            < A fields={contest.sections[1].fields} />
            < B fields={contest.sections[2].fields} />
            < C fields={contest.sections[3].fields.slice(0, 2)} />
        </div>
    );
}

export default ContestCard;