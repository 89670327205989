import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const LoginRouteLink = (props) => {
  const { user } = useSelector((state) => state.AuthReducer);
  if (user) {
    const hasProfessionalSkills = user?.professionalSkills?.length > 0;
    const hasUnprofessionalSkills = user?.unprofessionalSkills?.length > 0;

    const redirectPath =
      hasProfessionalSkills || hasUnprofessionalSkills ? "/home" : "/timeline";

    return <Redirect to={redirectPath} />;
  } else {
    return (
      <Route
        path={props.path}
        exact={props.exact}
        component={props.component}
      />
    );
  }
  // return user ? (
  //   // <Redirect to="/home" />
  //   <Redirect to="/timeline" />

  // ) : (
  //   <Route path={props.path} exact={props.exact} component={props.component} />
  // );
};

LoginRouteLink.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.func,
};

export default LoginRouteLink;
