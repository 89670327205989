//import {VideoMuteToggle} from "../types/VideoMuteToggle"

const initialState = true;
const VideoMuteToggleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "VideoMuteToggle": {
      const { muted } = action.payload;
      state = muted;
      return {
        state,
      };
    }
    default:
      return state;
  }
};
export default VideoMuteToggleReducer;
