 
 
import React, { useEffect, useRef, useState } from "react";
import {
  FetchPhotoPosts,
  updateVideoAction,
  getComments,
} from "../../store/asyncMethods/PostMethods";
import Cancel from "./img/cancel.svg";
import { Skeleton } from "@mui/material";
import Man from "../Header/img/Man.svg";
import Female from "../Header/img/Female.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REDIRECT_FALSE } from "../../store/types/postTypes";

import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import "./VideoPost.css";
import { Avatar } from "@mui/material";
import Appriciate from "../Appriciate/Appriciate";
import { Link} from "react-router-dom";
import Improve from "../Improve/Improve";
import SharePost from "../Share/SharePost";
import PostComment from "../Comments/PostComment";

import DeletePostModal from "../Modals/DeletePostModal";
import ReportModal from "../Modals/ReportModal";
import { Helmet } from "react-helmet";
import Alert from "../Alert/Alert";
import SingleComment from "./SingleComment";
import axios from "axios"
import { path } from "../../path";
import "pure-react-carousel/dist/react-carousel.es.css";

import Others from "../Header/img/Others.svg";
import logoAnt from "../Header/img/Ant.png";
import logoAntDark from "../Header/img/Ant_Dark.png";

const VideoPost = React.forwardRef(({ post }, ref) => {
  // console.log("video:" + post);
  const { redirect } = useSelector((state) => state.PostReducer);
  const [replymodel, setreplymodel] = useState(false);
  const [parentCmt, setparentCmt] = useState();
  const myInputRef = useRef(null);
  // console.log(" post in vdo component", post)
  const { user } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();
  const { photoposts } = useSelector((state) => state.FetchPosts);
  // useEffect(() => {
  //   console.log("This is a video ");
  // });

  // infinite comment code  ---->
  const { comments } = useSelector((state) => state.PostReducer);

  // console.log("photoposts : ", photoposts[0]);
  const [showIcon, setShowIcon] = useState(false);
  const [viewMoreCmt, setviewMoreCmt] = useState(false);

  const [text] = useState("nodownload");
  const isSuper = user.isSuperUser;
  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: user.theme === "dark" ? "colored" : "light",
  };
  // to update appreciate and improve
  const [update, setUpdate] = useState(post.appreciate.includes(user._id));
  const [improveUpdate, setImproveUpdate] = useState(
    post.improve.includes(user._id)
  );
  // const [allComments, setAllComments] = useState(post.comments);
  const [allComments, setAllComments] = useState([]);
  const page=1
  const _id = user._id;
  const createdAt = new Date(post.createdAt);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const setfocus = () => {
    myInputRef.current.focus();
  };
  const closeRef = useRef();
  const videoRef = useRef(null);
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const [deleteModal, setdeleteModal] = useState(false);
  const [reportModal, setreportModal] = useState(false);

  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    const strTime = `${hours}:${minutes} ${ampm}`;

    return strTime;
  };
  // useEffect(() => {
  //   console.log(window.innerHeight + document.documentElement.scrollTop);
  //       console.log(document.documentElement.offsetHeight);

  // }, [window.innerHeight,document.documentElement.offsetHeight])
  const [toggleReport, settoggleReport] = useState(false);
  // window.onscroll = function () {
  //   // console.log("not working");
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop >=
  //     document.documentElement.offsetHeight
  //   ) {
  //     scrollToEnd();
  //   } else {
  //     console.log("working");
  //   }
  // };

  //changing colors randomly for name image
  // useEffect(() => {
  //   let color = ["Orange", "Red", "Yellow", "Blue", "Green"];
  //   let i = Math.floor(Math.random() * 5);
  //   setProfilePictureColor(color[i]);
  // }, [profilePictureColor]);

  // console.log("Count on photo feed:",count);
  const handleViewMoreComments = () => {
    setviewMoreCmt(!viewMoreCmt);
  };
  useEffect(() => {
    if (redirect) {
      dispatch({ type: REDIRECT_FALSE });
    }
    dispatch(FetchPhotoPosts(_id));
    console.log(photoposts);
    // setPhotos([...photos, photoposts[0]]);
  }, [redirect, photoposts]);

  //SETS THE STATE TO STORE IMRPOVE COUNT
  const [improveLikeCount, setImprovelikeCount] = useState(
    (post?.improve && post?.improve.length) || 0
  );
  //SETS THE STATE TO STORE APPRICIATE COUNT
  const [appriciateLikeCount, setAppriciateLikeCount] = useState(
    (post?.appreciate && post?.appreciate.length) || 0
  );
  // Delete post using ID
  const DeletePost = async () => {
    try {
      let conf = window.confirm("Are you sure to delete the post?");
      if (conf) {
        dispatch({ type: "SET_POSTLOADING", payload: true });

   
     
     
    }} 


    
    catch (error) {
      toast.error("Already deleted!", toastOption);
      dispatch({ type: "SET_POSTLOADING", payload: false });
      console.log(error);
    }
  };

  // console.log("all comments",allComments)
  const commentDeleteHandler = async (commentId, postId) => {
    if (window.confirm("Do you want to delete this comment?") === true) {
      setAllComments(allComments.filter((comm) => comm.postId !== postId));
      setAllComments(allComments.filter((comm) => comm._id !== commentId));
      await axios.post(`${path}/deleteComment`, {
        postId: postId,
        commentId,
      });
    

      
    }
  };

  const nestcommentDeleteHandler = async (parentId, cmtID) => {
    const newCmt = allComments.map((comment) => ({
      ...comment,
      child: comment.child.filter((child) => child._id !== cmtID),
    }));

    setAllComments(newCmt);

    await axios.post(`${path}/deletenestedComment`, {
      parentId,
      cmtID,
    });

    // console.log(res);
  };
  // #################################
  const [state, setState] = useState({
    title: post.title,
    video: post.video,
    videoCaption: post.videoCaption,
    category: post.category,
    postType: post.postType,
    skillType: post.skillType,
  });

  const [editVideo, setEditVideo] = useState(false);
  const [postUploaded] = useState(false);
  const [imagePreview] = useState(post.video);
  const [controls, setControls] = useState(false);

  const [loading, setLoading] = useState(true);

  const handleVideoLoaded = () => {
    setLoading(false);
  };
  const handleInput = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const [playing, setPlaying] = useState(false);
  const [likeImproveRatio, setLikeImproveRatio] = useState([]);

  // const [progress, setProgress] = useState(0);

  //video mute state mngmnt
  const [videoMute, setVideoMute] = useState(true);
  const [muted, setmuted] = useState(true);

  useEffect(() => {
    setVideoMute(muted); //line 245
    //dispatch(VideoMuteToggle(muted));
  }, [muted]);

  /**
   *
   */
  // useEffect(() => {
  //   // setVideoMute(MuteReducer); // ?
  // }, [MuteReducer]);

  useEffect(() => {
    const progress = Math.round(
      (appriciateLikeCount / (appriciateLikeCount + improveLikeCount)) * 100
    );
    setLikeImproveRatio(progress);
  }, [appriciateLikeCount, improveLikeCount]);

  const videoHandler = (control) => {
    // const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
    if (control === "play") {
      // console.log("trial start")
      // console.log(isVideoPlaying(videoRef.current));
      // console.log("trial end")
      videoRef.current.play();
      setPlaying(true);
    } else if (control === "pause") {
      // console.log("trial start")
      // console.log(isVideoPlaying(videoRef.current));
      // console.log("trial end")
      videoRef.current.pause();
      setPlaying(false);
    }
  };
  const videoHandlers = () => {
    if (playing) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setPlaying(!playing);
    setShowIcon(true);

    // Hide the icon after a delay (e.g., 2 seconds)
    setTimeout(() => {
      setShowIcon(false);
    }, 1000);
  };

  // infinite comment logic ------->
  useEffect(() => {
    if (comments[post._id]) {
      setAllComments(comments[post._id]);
    }
  }, [comments]);

  useEffect(() => {
    // if(page != 1){
    dispatch(getComments(post?._id, page));
    // }
  }, [page]);

  // console.log("videoPost",post._id,page,comments)

  // Commented out to remove the auto play feature
  const videoElementRef = useRef(null);

  useEffect(() => {
    let options = {
      rootMargin: "0px",
      threshold: [0.5, 0.5],
    };

    let handlePlay = (entries) => {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          playing === false &&
          videoRef.current !== null
        ) {
          videoRef.current.play();

          // setcontrolsPause(true);
        } else {
          if (videoRef.current !== null) {
            videoRef.current.pause();
            videoHandler("pause");

          }
        }
      });
    };

    let observer = new IntersectionObserver(handlePlay, options);
    observer.observe(videoRef.current);
  }, []);

  const handleVideoEdit = () => {
    settoggleReport(!toggleReport);
    setEditVideo(true);
  };

  const handleVideoForm = (e) => {
    e.preventDefault();
    const formData = new FormData();
    // const { image, caption, category } = state;
    // formData.append("image", image);
    if (state.videoCaption.length > 200) {
      toast.error("Please limit the character to 200 letters", toastOption);
      return;
    }

    formData.append("videoCaption", state.videoCaption);
    // formData.append("caption", state.caption);
    formData.append("category", state.category);
    // formData.append("slug", slug);
    // formData.append("name", user.username);
    // formData.append('firstName', firstName);
    // formData.append('lastName', lastName);
    formData.append("id", post._id);
    formData.append("postType", state.postType);
    formData.append("skillType", state.skillType);
    dispatch(
      updateVideoAction({
        id: post._id,
        videoCaption: state.videoCaption,
        category: state.category,
      })
    );
    setTimeout(() => {
      setEditVideo(false);
      toast.success("Post has been Successfully updated", toastOption);
    }, 2000);
  };
  useEffect(() => {
    var closeHandler = (e) => {
      if (!closeRef?.current?.contains(e.target)) {
        setEditVideo(false);
        setState({
          title: post.title,
          video: post.video,
          videoCaption: post.videoCaption,
          category: post.category,
          postType: post.postType,
          skillType: post.skillType,
        });
      }
    };
    document.addEventListener("mousedown", closeHandler);

    return () => {
      document.removeEventListener("mousedown", closeHandler);
    };
  });

  // const handleCmtClick = (cmt) => {
  //   // check whether the url in the browser search bar has 'userDetails' or not
  //   // if not then push the url to the browser search bar

  //   if (viewMoreCmt) {
  //     if (!history.location.pathname.includes("userDetails")) {
  //       cmt?.userId !== null && cmt?.userId?._id === user?._id
  //         ? history.push("/profile")
  //         : history.push(`userDetails/${cmt?.userId?._id}`);
  //     } else {
  //       cmt?.userId !== null && cmt?.userId?._id === user?._id
  //         ? history.push("/profile")
  //         : history.push(`/${cmt?.userId?._id}`);
  //     }
  //   } else {
  //     cmt?.userId !== null && cmt?.userId?._id === user?._id
  //       ? history.push("/profile")
  //       : history.push(`userDetails/${cmt?.userId?._id}`);
  //   }
  // };

  return (
    <>
      {editVideo ? (
        <>
          <div
            className="text-primary  fixed top-0 left-0 w-[100vw] h-[100vh] bg-card opacity-80 z-[6]"
            onClick={() => setEditVideo(false)}></div>
          <div
            ref={closeRef}
            className="absolute text-primary top-20 left-[5%] w-[90%] sm:left-[25%] sm:right-[25%] sm:w-[50%] lg:left-[30%] lg:right-[30%] lg:w-[40%] xl:left-[35%] xl:right-[35%] xl:w-[30%]  mx-auto p-5 border-1 border-viewMore shadow-lg rounded-2xl bg-card z-[7]">
            <Helmet>
              <title>Create New </title>
              <meta name="description" content="Create a new Blog" />
            </Helmet>
            {!postUploaded ? (
              <form onSubmit={handleVideoForm}>
                <div className="flex items-center justify-between">
                  <h2 className="my-2 text-xl font-bold">Edit Post</h2>
                  <div>
                    <Avatar
                      src={Cancel}
                      className="cursor-pointer"
                      sx={{ width: 20, height: 20 }}
                      onClick={() => {
                        settoggleReport(false);
                        setEditVideo(false);
                      }}
                    />
                  </div>
                </div>
                <div>
                  {!isSuper && (
                    <>
                      <div className="flex flex-row justify-between">
                        <div className="w-[48%]">
                          <h2 className="font">
                            Select Type
                            <span className="font-bold text-red-600">*</span>
                          </h2>
                          <select
                            disabled="true"
                            required
                            name="skillType"
                            value={state.skillType}
                            onChange={(e) => handleInput(e)}
                            className="form-select 
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-message
                    bg-card bg-clip-padding bg-no-repeat
                    border-1 border-viewMore
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-message focus:ring-2 ring-teal-700 outline-none"
                            aria-label="Default select example">
                            <option>Select Skill type</option>
                            <option value="Polished">Polished Skill</option>
                            <option value="Novice">Novice Skill</option>
                          </select>
                        </div>
                        <div className="w-[50%]">
                          <h2 className="font">
                            Select Skills
                            <span className="font-bold text-red-600">*</span>
                          </h2>
                          <select
                            name="postType"
                            value={state.postType}
                            required
                            className="form-select
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-message
                    bg-neutral bg-clip-padding bg-no-repeat
                    border-1 border-viewMore
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-message focus:ring-2 ring-teal-700 outline-none"
                            aria-label="Default select example"
                            onChange={(e) => handleInput(e)}
                            id="setPostType">
                            <option>Select Post type</option>
                            <option value={"community"} key={"community"}>
                              Community
                            </option>
                            <option value={"friend"} key={"friend"}>
                              Peer
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="my-2">
                        <h2 className="font">
                          Select Skill{" "}
                          <span className="font-bold text-red-600">*</span>
                        </h2>
                        <select
                          required
                          name="category"
                          value={state.category}
                          onChange={(e) => handleInput(e)}
                          id="category"
                          className="form-select
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-message
                          bg-neutral bg-clip-padding bg-no-repeat
                          border-1 border-viewMore
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-message focus:ring-2 ring-teal-700 outline-none"
                          aria-label="Default select example">
                          <option>Select Skill</option>
                          {state.skillType === "Polished" &&
                            user.professionalSkills.map((s) => {
                              return (
                                <option value={s} key={s}>
                                  {s.includes("_")
                                    ? Capitalize(s.split("_").join(" "))
                                    : Capitalize(s)}
                                </option>
                              );
                            })}
                          {state.skillType === "Novice" &&
                            user.unprofessionalSkills.map((s) => {
                              return (
                                <option value={s} key={s}>
                                  {s.includes("_")
                                    ? Capitalize(s.split("_").join(" "))
                                    : Capitalize(s)}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </>
                  )}
                  <div className="my-3">
                    <input
                      type="text"
                      placeholder="Caption*"
                      required
                      maxLength={200}
                      onChange={(e) => handleInput(e)}
                      name="videoCaption"
                      value={state.videoCaption}
                      className="w-full overflow-wrap break-word px-3 py-1.5 text-base font-normal text-message bg-neutral bg-clip-padding bg-no-repeat border-1 border-viewMore rounded transition ease-in-out m-0 focus:text-message focus:ring-2 ring-teal-700"
                    />
                  </div>
                  <div className="flex flex-row justify-center rounded">
                    {imagePreview ? (
                      <div className="flex flex-row justify-center max-h-[40vh] rounded">
                        <>
                          <div className="relative mt-3 border border-gray-300 border-solid rounded">
                            {" "}
                            <video
                              ref={videoElementRef}
                              controlsList="nodownload"
                              className="w-[100%] h-[100%]"
                              src={imagePreview}
                              controls
                              id="videoElementID"
                              autoPlay={false}></video>
                          </div>
                        </>
                      </div>
                    ) : (
                      // <video
                      //   controlsList={text}
                      //   className="w-[40%]"
                      //   src={imagePreview}
                      //   // controls
                      //   muted></video>
                      ""
                    )}
                  </div>
                </div>
                <div className="flex justify-end">
                  <input
                    type="submit"
                    className="flex items-center justify-center p-2 m-1 text-white transition duration-200 bg-black border-solid cursor-pointer text-l rounded-3xl hover:bg-teal-600"
                    value="Update"
                  />
                </div>
              </form>
            ) : (
              <div>
                <Alert
                  msg={"Your video has been uploaded Successfully!!"}
                  type="success"
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      <div ref={ref} id="videoPost" className="mb-8">
        <div
          className={`bg-card w-[100%] p-3 sm:w-[100%] md:ml-[0%] md:w-[100%] md:rounded-lg md:border md:border-viewMore`}>
          {deleteModal && (
            <DeletePostModal
              id={post._id}
              type="video"
              setdeleteModal={setdeleteModal}
            />
          )}
          {reportModal && (
            <ReportModal
              id={post._id}
              type="blog"
              setreportModal={setreportModal}
            />
          )}
          <div className="flex flex-row justify-between">
            <div className="flex flex-row justify-start w-[78%]">
              <div className="flex-shrink mx-2">
                {loading && (
                  <Skeleton
                    variant="circular"
                    width={40}
                    height={40}
                    sx={{
                      bgcolor: `${
                        user.theme === "dark" ? "rgba(255, 255, 255, 0.2)" : ""
                      }`,
                    }}
                  />
                )}
                <div
                  className=""
                  style={{ display: loading ? "none" : "block" }}>
                  {/* {post?.userId?.online && (
                    <div className="w-3 h-3 rounded-full bg-[green] absolute bottom-0 right-0 border-[2px] border-white z-10"></div>
                    post.userId !== null &&
                  )} */}
                  {(post.userId !== null && post.userId?.profilePicture) ||
                  (post?.userId?._id === user._id && user.profilePicture) ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar
                        src={
                          post.userId?._id === user?._id
                            ? user.profilePicture
                            : post.userId.profilePicture
                        }
                      />
                    </Link>
                  ) : post?.userId?.gender === "female" ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar src={Female} sx={{ width: 40, height: 40 }} />
                    </Link>
                  ) : post?.userId?.gender === "male" ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar src={Man} sx={{ width: 40, height: 40 }} />
                    </Link>
                  ) : post?.userId?.gender === "others" ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar src={Others} sx={{ width: 40, height: 40 }} />
                    </Link>
                  ) : post?.userId?.theme === "dark" ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar
                        src={logoAntDark}
                        sx={{ width: 40, height: 40 }}
                      />
                    </Link>
                  ) : (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar src={logoAnt} sx={{ width: 40, height: 40 }} />
                    </Link>
                  )}
                </div>
              </div>
              <div className="">
                <div className="flex items-center justify-center">
                  <Link
                    to={
                      post.userId?._id === user._id
                        ? "/profile"
                        : `/userDetails/:${post.userId?._id}`
                    }>
                    <h2 className="flex flex-row font-gilroy-semibold text-lg  text-primary w-fit">
                      {post.userName}
                      &nbsp; &nbsp;
                      {/* {editState.postType === "friend" && (
                        <img
                          src={Peerss}
                          alt="Peer"
                          width={25}
                          title="Peer only"></img>
                      )} */}
                    </h2>
                  </Link>
                  <p className="bg-[#D9D9D9] rounded-full w-1 h-1 inline-block text-center"></p>
                  <h2
                    className={`text-xs font-gilroy-semibold ml-2
                      ${
                        post.skillType === "Polished"
                          ? "text-[#008080]"
                          : "text-[#FFC107]"
                      }
                      
                      `}>
                    {/* {createdAt.getDate() +
                    " " +
                    months[createdAt.getMonth()] +
                    " at " +
                    formatTime(createdAt)}{" "} */}
                    {post.uniqueId}
                  </h2>
                </div>

                {post.category !== "master" && (
                  <div className="flex flex-row">
                    <h2
                      // text-${
                      //     post.skillType === "Polished"
                      //       ? "[#008080]"
                      //       : "[#FFC107]"
                      //   }
                      className={`text-xs font-gilroy-semibold text-compPrimary `}>
                      {post.skillType} |{" "}
                      {post.category.includes("_")
                        ? Capitalize(post.category.split("_").join(" "))
                        : Capitalize(post.category)}{" "}
                    </h2>
                    {/* <h2 className="text-xs text-grayish"></h2> */}
                  </div>
                )}
                {/* <h2
                  className={`text-xs font-gilroy-regular
                      ${
                        post.skillType === "Polished"
                          ? "text-[#008080]"
                          : "text-[#FFC107]"
                      }
                      
                      `}>
                  {createdAt.getDate() +
                    " " +
                    months[createdAt.getMonth()] +
                    " at " +
                    formatTime(createdAt)}{" "}
                  {post.uniqueId}
                </h2> */}
              </div>
            </div>
            <div className="flex w-[20%] justify-end  ">
              <div className="flex justify-end item-center">
                <button
                  onClick={() => {
                    settoggleReport(!toggleReport);
                  }}
                  className=" h-[50%] rounded-3xl  flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 fill-svg"
                    viewBox="0 0 448 512">
                    <path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
                  </svg>
                </button>
                {toggleReport && (
                  <div className="fixed z-[6] top-0 left-0 flex justify-center h-[100vh] w-[100vw] border">
                    <div
                      className="fixed z-[6] top-0 left-0 h-[100%] w-[100%] bg-black opacity-50"
                      onClick={() => settoggleReport((prev) => !prev)}></div>
                    <div className=" z-[7] my-auto border-1 border-viewMore w-[60vw] sm:w-[20vw] h-auto shadow-sm bg-card text-primary rounded-lg">
                      {user._id === post.userId._id ? (
                        <ul className="rounded-lg bg-feedNeutral">
                          <li
                            onClick={() => handleVideoEdit()}
                            className="flex rounded justify-center items-center text-primary h-[7vh] bg-feedNeutral hover:bg-neutral rounded-t-md transition duration-200 px-3 py-1 font-semibold  cursor-pointer text-grayish">
                            Edit{" "}
                          </li>
                          <hr className="border-viewMore" />
                          <li
                            className="flex rounded justify-center items-center text-primary h-[7vh] bg-feedNeutral hover:bg-neutral rounded-t-md transition duration-200 px-3 py-1 font-semibold  cursor-pointer text-grayish"
                            onClick={() => {
                              settoggleReport(false);
                              // setdeleteModal(true);
                              DeletePost(post._id);
                            }}>
                            {" "}
                            Delete post{" "}
                          </li>
                        </ul>
                      ) : (
                        <ul>
                          <li
                            className="flex rounded justify-center items-center text-primary h-[7vh] bg-feedNeutral hover:bg-neutral rounded-t-md transition duration-200 px-3 py-1 font-semibold  cursor-pointer text-grayish"
                            onClick={() => {
                              setreportModal(true);

                              settoggleReport(false);
                            }}>
                            {" "}
                            Report{" "}
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <hr className="border-viewMore" /> */}

          <div
            style={{
              wordBreak: "break-word",
              display: loading ? "none" : "block",
            }}
            className="flex text-compPrimary flex-row mt-2 font-gilroy-medium  break-words">
            {post?.videoCaption}
            {/* {!longPost ? post?.videoCaption.slice(0, 500) + "...": post?.videoCaption}
            {(post?.videoCaption.length > 500 && !longPost ) ? (
              <p className="text-[#027575] cursor-pointer font-semibold hover:underline" onClick={()=>setLongPost(prev => !prev)}>read more</p>
            ) : ""} */}
          </div>
          {loading && <br></br>}
          {loading && (
            <Skeleton
              sx={{
                bgcolor: `${
                  user.theme === "dark" ? "rgba(255, 255, 255, 0.2)" : ""
                }`,
              }}
              variant="rounded"
              width={"60%"}
              height={10}
            />
          )}
          {loading && <br></br>}
          {loading && (
            <Skeleton
              sx={{
                bgcolor: `${
                  user.theme === "dark" ? "rgba(255, 255, 255, 0.2)" : ""
                }`,
              }}
              variant="rounded"
              width={"60%"}
              height={10}
            />
          )}
          {loading && <br></br>}
          <div
            className="flex flex-row justify-around mt-2 text-[#37474F]"
            style={{ display: loading ? "none" : "block" }}>
            {post.description}
          </div>
          <div className="flex flex-col sm:flex-row justify-evenly ">
            <div
              className="flex my-1 w-[100%] md:w-[100%] max-h-[80vh]"
              style={{ position: "relative" }}>
              {/* box issue solved for playing videos*/}
              {loading && (
                <Skeleton
                  sx={{
                    bgcolor: `${
                      user.theme === "dark" ? "rgba(255, 255, 255, 0.2)" : ""
                    }`,
                  }}
                  variant="rounded"
                  width={"100%"}
                  height={"60vh"}
                />
              )}
              <video
                onLoadedData={handleVideoLoaded}
                style={{ display: loading ? "none" : "block" }}
                onMouseEnter={() => {
                  setControls(true);
                }}
                onMouseLeave={() => {
                  setControls(false);
                }}
                controls={controls ? true : false}
                onClick={videoHandlers}
                /**
                 *
                 */
                onPointerMove={() => {
                  //sets muted state for usestate hook, this needs to be shifted to onclick event of mute button from pointerMove event
                  setmuted(document.getElementsByTagName("video")[0].muted);
                }}
                muted={videoMute}
                //muted={videoMute}
                controlsList={text}
                className="w-[100%] my-4 aspect-auto object-contain"
                ref={videoRef}>
                <source src={post.video} type="video/wmv" />
                <source src={post.video} type="video/mp4" />
                <source src={post.video} type="video/ogg" />
              </video>
              {showIcon && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "transparent",
                    animation: "fade-in-out 1s ease", // Adjust the duration as needed
                  }}>
                  {playing ? (
                    <PlayArrowRoundedIcon
                      style={{
                        fontSize: "3em", // Adjust the size as needed
                        color: "white", // Change the color to your preference
                      }}
                    />
                  ) : (
                    <PauseCircleIcon
                      style={{
                        fontSize: "3em", // Adjust the size as needed
                        color: "white", // Change the color to your preference
                      }}
                    />
                  )}
                </div>
              )}

              {/* <Progressbar progress={done} /> */}
            </div>
          </div>
          <h2
            // text-${post.skillType === "Polished" ? "[#008080]" : "[#FFC107]"}
            className={`text-xs text-compPrimary font-gilroy-regular `}
            style={{ display: loading ? "none" : "block" }}>
            {createdAt.getDate() +
              " " +
              months[createdAt.getMonth()] +
              " at " +
              formatTime(createdAt)}{" "}
          </h2>
      

         

         
          <div className="flex flex-row justify-between w-full gap-1">
  <div className="flex flex-row items-center w-1/3 md:w-5/12 py-1">
    <Appriciate
      post={post}
      appriciateLikeCount={appriciateLikeCount}
      update={update}
      setUpdate={(val) => setUpdate(val)}
      improveUpdate={improveUpdate}
      improveLikeCount={improveLikeCount}
      setImproveUpdate={(val) => setImproveUpdate(val)}
      setImprovelikeCount={(val) => setImprovelikeCount(val)}
      setAppriciateLikeCount={(val) => setAppriciateLikeCount(val)}
    />
  </div>
  
  <div className="flex flex-row items-center w-1/3 md:w-5/12 py-1">
    <Improve
      post={post}
      improveLikeCount={improveLikeCount}
      update={update}
      setUpdate={(val) => setUpdate(val)}
      improveUpdate={improveUpdate}
      setImproveUpdate={(val) => setImproveUpdate(val)}
      setImprovelikeCount={(val) => setImprovelikeCount(val)}
      setAppriciateLikeCount={(val) => setAppriciateLikeCount(val)}
      appriciateLikeCount={appriciateLikeCount}
    />
  </div>

  <div className="flex items-center justify-center w-1/3 md:w-1/6">
    <div className={`flex items-center hover:bg-neutral hover:cursor-pointer gap-1 px-4 ${user.theme === "dark" ? "bg-appreciateIcon" : "bg-primary"} border border-viewMore rounded-3xl`}>
      <SharePost post={post} />
      <span className={`${user.theme === "light" ? "text-black" : "text-white"}`}>Share</span>
    </div>
  </div>
</div>
          

          <div className="flex w-full h-[2px] mt-2 bg-neutral">
            <div
              className={`bg-green-400 h-[2px]`}
              style={{
                width: `${appriciateLikeCount !== 0 ? likeImproveRatio : 0}%`,
              }}></div>
            <div
              className={`bg-yellow-400 h-[2px]`}
              style={{
                width: `${
                  improveLikeCount !== 0 ? 100 - likeImproveRatio : 0
                }%`,
              }}></div>
          </div>
          {/* <hr className="border-viewMore" /> */}
          <PostComment
            post={post}
            allComments={allComments}
            setAllComments={setAllComments}
            parentCmt={parentCmt}
            myInputRef={myInputRef}
            replymodel={replymodel}
            setreply={setreplymodel}
          />
          <hr className="border-viewMore" />
          <div>
            <ul>
              {!viewMoreCmt &&
                allComments &&
                allComments
                  .slice(0)
                  ?.slice(0, 2)
                  .map((cmt, index) => {
                    if (cmt?.userId !== null) {
                      return (
                        <SingleComment
                        key={cmt._id}
                          cmt={cmt}
                          index={index}
                          setparentCmt={setparentCmt}
                          setfocus={setfocus}
                          setreplymodel={setreplymodel}
                          post={post}
                          commentDeleteHandler={commentDeleteHandler}
                          nestcommentDeleteHandler={nestcommentDeleteHandler}
                        />
                      );
                    }
                  })}
            </ul>
          </div>

          <div className="flex flex-col justify-center my-1">
            {viewMoreCmt && (
              //parent div for all comments
              <>
                <div className="h-[100%] ">
                  <div className="flex-1 w-[100%] flex flex-col justify-center">
                    <ul className=" w-[100%] scrollDiv self-center">
                      {allComments &&
                        allComments.slice(0).map((cmt, index) => {
                          if (cmt.userId === null) {
                            console.log("User id null");
                          } else {
                            return (
                              <SingleComment
                              key={cmt._id}
                                cmt={cmt}
                                index={index}
                                setparentCmt={setparentCmt}
                                setfocus={setfocus}
                                setreplymodel={setreplymodel}
                                post={post}
                                commentDeleteHandler={commentDeleteHandler}
                                nestcommentDeleteHandler={
                                  nestcommentDeleteHandler
                                }
                              />
                            );
                          }
                        })}
                    </ul>
                  </div>
                </div>
              </>
              // <Suspense>
              //   <MoreComments
              //     allComments={allComments}
              //     user={user}
              //     commentDeleteHandler={commentDeleteHandler}
              //     profilePictureColor={profilePictureColor}
              //     post={post}
              //     setparentCmt={setparentCmt}
              //     setfocus={setfocus}
              //     setreplymodel={setreplymodel}
              //     nestcommentDeleteHandler={nestcommentDeleteHandler}
              //   />
              // </Suspense>
            )}
            {!viewMoreCmt && allComments && allComments.length > 2 ? (
              <button
                className="p-2 px-3 rounded-3xl bg-viewMore hover:bg-neutral text-viewMore border-1 border-viewMore"
                onClick={handleViewMoreComments}>
                <h2 className="">
                  View {allComments?.length - 2}{" "}
                  {allComments?.length > 3 ? "More Comments" : "More Comment"}
                </h2>
              </button>
            ) : (
              ""
            )}
            {viewMoreCmt ? (
              <button
                className="p-2 px-3 rounded-3xl bg-viewMore hover:bg-neutral text-viewMore border-1 border-viewMore"
                onClick={handleViewMoreComments}>
                <h2 className="">Show less comments</h2>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div />
      </div>
    </>
  );
});

VideoPost.displayName=VideoPost;

export default React.memo(VideoPost);
