import LinearProgressBar from "./LinearProgressBar"
import React from "react"
import {PropTypes} from "prop-types"

const Statistics = ({ title, user, val, levelinfo }) => {
    console.log(user)
    console.log(levelinfo)

    return (

        <div className={`p-3 flex flex-col gap-[20px] ${user.theme === "dark" ? "bg-secondary" : "bg-[#F6F5FA]"} rounded-[9px]`}>
            <div className=" flex flex-col justify-around h-full">
                <div>
                    <h2 className={`font-gilroy-bold text-[13px] ${user.theme === "dark" ? "text-white" : "text-primary"}`}>{title}</h2>
                    <p className="font-gilroy-medium text-[17px] text-[#707C97]">{val}</p>
                </div>

                <div className="w-full bg-white rounded-full h-2.5 flex items-center ">
                    <LinearProgressBar percentage={`${(val * 100) / levelinfo}`}></LinearProgressBar>
                    {/* <div className="w-0.5 h-4 bg-gray-200 relative -left-10"></div> */}
                </div>
            </div>

        </div>

    )
}
Statistics.propTypes={
  title: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  val: PropTypes.number.isRequired,
  levelinfo: PropTypes.object.isRequired
}

export const MoreStatistics = ({ user,userLevel,levelinfo,engagementClick }) => {

    
        return (
            <div className="p-4">
                {!engagementClick?
                <div>
                    <div className={`flex items-center justify-between p-3 rounded-[9px]    ${user.theme === "dark" ? "bg-black" : "bg-[#F6F5FA]"} `}>
              <div  className="max-w-[200px]" >
                  <p className={`font-gilroy-medium text-[12px] ${user.theme === "dark" ? "text-white" : "text-black"}`}>How many competitions have
                      user participated in total
                  </p>
              </div>
              <p className="text-compPrimaryfont-gilroy-medium text-[16px]">{userLevel?.competitionParticipated}/{levelinfo?.competitionParticipated}</p>
          </div>
          <div className={`flex items-center justify-between p-3 rounded-[9px]    ${user.theme === "dark" ? "bg-black" : "bg-[#F6F5FA]"} `}>
              <div className="max-w-[200px]" >
                  <p className={`font-gilroy-medium text-[12px] ${user.theme === "dark" ? "text-white" : "text-black"}`}>How many competitions have
                      user won in total
                  </p>
              </div>
              <p className="text-compPrimaryfont-gilroy-medium text-[16px]">{userLevel?.competitionWon}/{levelinfo?.competitionWon}</p>
          </div>
                </div>
             :  <>  

            <h2 className="text-xl font-semibold mb-4">Engagement</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex items-center p-4 border rounded-lg">
                <img src="https://images.unsplash.com/photo-1512790182412-b19e6d62bc39?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Image" className="w-12 h-12 rounded-md mr-4"/>
                <div className="flex-1">
                  <div className="flex justify-between items-center mb-2">
                    <span>Image</span>
                    <span className="text-green-500">{userLevel?.photo} </span>
                  </div>
                  <div className="w-full bg-zinc-200 rounded-full h-2.5">
                    <div className="bg-teal-500 h-2.5 rounded-full" style={{ width: `${(userLevel?.photo/levelinfo?.photo)*100}%` }}></div>
                  </div>
                </div>
              </div>
              <div className="flex items-center p-4 border rounded-lg">
                <img src="https://images.unsplash.com/photo-1471107340929-a87cd0f5b5f3?q=80&w=1973&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Blog" className="w-12 h-12 rounded-md mr-4"/>
                <div className="flex-1">
                  <div className="flex justify-between items-center mb-2">
                    <span>Blog</span>
                    <span className="text-green-500">{userLevel?.blog} </span>
                  </div>
                  <div className="w-full bg-zinc-200 rounded-full h-2.5">
                    <div className="bg-teal-500 h-2.5 rounded-full" style={{ width: `${(userLevel?.blog/levelinfo?.blog)*100}%` }}></div>
                  </div>
                </div>
              </div>
              <div className="flex items-center p-4 border rounded-lg">
                <img src="https://images.unsplash.com/photo-1485846234645-a62644f84728?q=80&w=2059&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Video" className="w-12 h-12 rounded-md mr-4"/>
                <div className="flex-1">
                  <div className="flex justify-between items-center mb-2">
                    <span>Video</span>
                    <span className="text-green-500">{userLevel?.video}</span>
                  </div>
                  <div className="w-full bg-zinc-200 rounded-full h-2.5">
                    <div className="bg-teal-500 h-2.5 rounded-full" style={{ width: `${(userLevel?.video/levelinfo?.video)*100}%` }}></div>
                  </div>
                </div>
              </div>
            </div></>
            }
          </div>
          
        )
    
}
MoreStatistics.propTypes = {
  user: PropTypes.object.isRequired,
  userLevel: PropTypes.object.isRequired,
  levelinfo: PropTypes.object.isRequired,
  engagementClick: PropTypes.bool.isRequired
}


export default Statistics