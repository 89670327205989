export const SKILLS = [
  {
    id: 1,
    name: "Art and Culture",
    selected: false,
    path: "/skill/artCulture.jpg",
  },
  {
    id: 2,
    name: "Badminton",
    selected: false,
    path: "/skill/badminton.jpg",
  },
  {
    id: 3,
    name: "Beat Boxing",
    selected: false,
    path: "/skill/beat_boxing.jpg",
  },
  {
    id: 4,
    name: "Bowling",
    selected: false,
    path: "/skill/bowling.jpg",
  },
  {
    id: 5,
    name: "Chess",
    selected: false,
    path: "/skill/chess.jpg",
  },
  {
    id: 6,
    name: "Coding",
    selected: false,
    path: "/skill/coding.jpg",
  },
  {
    id: 7,
    name: "Communication Skills",
    selected: false,
    path: "/skill/communication.jpg",
  },
  {
    id: 8,
    name: "Cooking and Baking",
    selected: false,
    path: "/skill/cooking.jpg",
  },
  {
    id: 9,
    name: "Crafting",
    selected: false,
    path: "/skill/crafting.jpg",
  },
  {
    id: 10,
    name: "Cricket",
    selected: false,
    path: "/skill/cricket.jpg",
  },
  {
    id: 11,
    name: "Dancing",
    selected: false,
    path: "/skill/dancing.jpg",
  },
  {
    id: 12,
    name: "Editing",
    selected: false,
    path: "/skill/editing.jpg",
  },
  {
    id: 13,
    name: "Event Management",
    selected: false,
    path: "/skill/eventManagement.jpg",
  },
  {
    id: 14,
    name: "Fashion",
    selected: false,
    path: "/skill/fashion.jpg",
  },
  {
    id: 15,
    name: "Gaming",
    selected: false,
    path: "/skill/gaming.jpg",
  },
  {
    id: 16,
    name: "Graphics and Designing",
    selected: false,
    path: "/skill/graphicsDesigning.jpg",
  },
  {
    id: 17,
    name: "Kabaddi",
    selected: false,
    path: "/skill/kabaddi.jpg",
  },
  {
    id: 18,
    name: "Knowledge",
    selected: false,
    path: "/skill/knowledge.jpg",
  },
  {
    id: 19,
    name: "Ludo",
    selected: false,
    path: "/skill/ludo.jpg",
  },
  {
    id: 20,
    name: "Memes",
    selected: false,
    path: "/skill/memes.jpg",
  },
  {
    id: 21,
    name: "MS Office",
    selected: false,
    path: "/skill/msoffice.jpg",
  },
  {
    id: 22,
    name: "Musical Instruments",
    selected: false,
    path: "/skill/musicalInstrument.jpg",
  },
  {
    id: 23,
    name: "Photography",
    selected: false,
    path: "/skill/photography.jpg",
  },
  {
    id: 24,
    name: "Poetry",
    selected: false,
    path: "/skill/poetry.jpg",
  },
  {
    id: 25,
    name: "Politics",
    selected: false,
    path: "/skill/politics.jpg",
  },
  {
    id: 26,
    name: "Reasoning",
    selected: false,
    path: "/skill/reasoning.jpg",
  },
  {
    id: 27,
    name: "Research",
    selected: false,
    path: "/skill/research.jpg",
  },
  {
    id: 28,
    name: "Sales and Marketing",
    selected: false,
    path: "/skill/salesMarketing.jpg",
  },
  {
    id: 29,
    name: "Singing",
    selected: false,
    path: "/skill/singing.jpg",
  },
  {
    id: 30,
    name: "Snooker",
    selected: false,
    path: "/skill/snooker.jpg",
  },
  {
    id: 31,
    name: "Social Service",
    selected: false,
    path: "/skill/socialService.jpg",
  },
  {
    id: 32,
    name: "Stand Up",
    selected: false,
    path: "/skill/standup.jpg",
  },
  {
    id: 33,
    name: "Swimming",
    selected: false,
    path: "/skill/swimming.jpg",
  },
  {
    id: 34,
    name: "Table Tennis",
    selected: false,
    path: "/skill/tableTennis.jpg",
  },
  {
    id: 35,
    name: "volleyball",
    selected: false,
    path: "/skill/volleyball.jpg",
  },
  {
    id: 36,
    name: "Bloging",
    selected: false,
    path: "/skill/blogging.jpg",
  },
  {
    id: 37,
    name: "Fitness",
    selected: false,
    path: "/skill/fitness.jpg",
  },
];
export const SKILLS_NAME = [
  "Art and Craft",
  "Badminton",
  "Beat Boxing",
  "Bowling",
  "Chess",
  "Coding",
  "Communication Skills",
  "Cooking and Baking",
  "Crafting",
  "Cricket",
  "Culture",
  "Dancing",
  "Editing",
  "Event Management",
  "Fashion",
  "Gaming",
  "Graphics and Designing",
  "Kabaddi",
  "Knowledge",
  "Ludo",
  "Memes",
  "MS Office",
  "Musical Instruments",
  "Photography",
  "Poetry",
  "Politics",
  "Reasoning",
  "Research",
  "Sales and Marketing",
  "Singing",
  "Snooker",
  "Social Service",
  "Stand Up",
  "Swimming",
  "Table tennis",
  "volley ball",
  "Bloging",
];
