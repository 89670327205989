import CryptoJS from "crypto-js";

export const decodePayload = (ciphertext) => {
  try {
      const  privateKey =  "feedantsblackskull@2002"
    const bytes = CryptoJS.AES.decrypt(ciphertext, privateKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    if (!decryptedData) {
      throw new Error("Decryption failed, no valid data.");
    }
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Decryption error:", error);
    throw new Error("Failed to decode payload");
  }
};

export const encodePayload = (payload) => {
  const publicKey ="feedantsblackskull@2002";
  const jsonString = JSON.stringify(payload);
  const ciphertext = CryptoJS.AES.encrypt(jsonString, publicKey).toString();
  return ciphertext;
};

