import axios from "axios";
import {
  SET_LOADER,
  CLOSE_LOADER,
  REDIRECT_TRUE,
  SET_MESSAGE,
} from "../types/postTypes";
import { SET_PROFILE_ERRORS } from "../types/ProfileTypes";
import { SET_TOKEN, UPDATE_ACCOUNT } from "../types/AuthTypes";
import { path } from "./../../path";
import {
  SET_LIGHT_THEME,
  SET_THEME_ERROR,
  SET_DARK_THEME,
} from "../types/ThemeTypes";

// updating name of user
export const UpdateNameAction = (user) => {
  return async (dispatch, getState) => {
    // validating a user using token
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      const { data } = await axios.post(`${path}/updateName`, user, config);
      // console.log(data);
      localStorage.setItem("myToken", data.token);
      // dispatch({ type: SET_TOKEN, payload: data.token});
      dispatch({ type: "SET_USER", token: data.token, user: data.user });
      dispatch({ type: SET_MESSAGE, payload: data.msg });
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: REDIRECT_TRUE });
    } catch (error) {
      dispatch({ type: CLOSE_LOADER });
      console.log(error.response.data.errors);
      dispatch({
        type: SET_PROFILE_ERRORS,
        payload: error.response.data.errors,
      });
    }
  };
};

//updating social media urls
export const UpdateSocialMediaUrlAction = (user) => {
  return async (dispatch, getState) => {
    // validating a user using token
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      const { data } = await axios.post(
        `${path}/updateSocialMedia`,
        user,
        config
      );
      console.log(user);
      console.log(data);
      //   localStorage.setItem("myToken", data.token);
      // dispatch({ type: SET_TOKEN, payload: data.token});
      dispatch({ type: UPDATE_ACCOUNT, payload: data.user });
      dispatch({ type: SET_MESSAGE, payload: data.msg });
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: REDIRECT_TRUE });
    } catch (error) {
      dispatch({ type: CLOSE_LOADER });
      console.log(error.response.data.errors);
      dispatch({
        type: SET_PROFILE_ERRORS,
        payload: error.response.data.errors,
      });
    }
  };
};

// updating theme of user
export const UpdateThemeAction = (theme) => {
  return async (dispatch, getState) => {
    // validating a user using token
    const {
      AuthReducer: { token, user },
    } = getState();
    const config = {
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.post(
        `${path}/updateTheme`,
        { user: user, theme: theme },
        config
      );
      // const {ss} = await axios.post(`${path}/updateUser`)

      console.log(data);

      localStorage.setItem("myToken", data.token);
      // localStorage.setItem("theme", data.updatedUser.theme);
      if (user.theme === "light") {
        dispatch({ type: SET_DARK_THEME });
        dispatch({
          type: "SET_USER",
          token: data.token,
          user: data.updatedUser,
        });
      } else {
        dispatch({ type: SET_LIGHT_THEME });
        dispatch({
          type: "SET_USER",
          token: data.token,
          user: data.updatedUser,
        });
      }
    } catch (error) {
      console.log(error.response.data.errors);
      dispatch({
        type: SET_THEME_ERROR,
        payload: error.response.data.errors,
      });
    }
  };
};

// Uploading a Profile Picture
export const uploadDp = (formData) => {
  console.log(formData);
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      const { data } = await axios.post(
        `${path}/updatePicture`,
        formData,
        config
      );
      // dispatch({ type: CLOSE_LOADER });

      console.log(data);
      localStorage.setItem("myToken", data.token);
      dispatch({ type: SET_TOKEN, payload: data.token });
      dispatch({ type: SET_MESSAGE, payload: data.msg });
      dispatch({ type: REDIRECT_TRUE });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      console.log(error);
      dispatch({ type: CLOSE_LOADER });
      dispatch({
        type: SET_PROFILE_ERRORS,
        payload: false,
      });
    }
  };
};

export const removeDp = (userId) => {
  // console.log("userId:", userId)
  return async (dispatch) => {
    // const {
    //   AuthReducer: { token },
    // } = getState();
    // const config = {
    //   headers: {
    //     "Access-Control-Allow-Origin": `${path}`,
    //     Authorization: `Bearer ${token}`,
    //   },
    // };
    dispatch({ type: SET_LOADER });
    try {
      const { data } = await axios.post(`${path}/removeprofilepic/${userId}`);
      dispatch({ type: CLOSE_LOADER });
      // console.log(data);
      localStorage.setItem("myToken", data.token);
      dispatch({ type: SET_TOKEN, payload: data.token });
      dispatch({ type: SET_MESSAGE, payload: data.msg });
      dispatch({ type: REDIRECT_TRUE });
    } catch (error) {
      dispatch({ type: CLOSE_LOADER });
      dispatch({
        type: SET_PROFILE_ERRORS,
        payload: error.response.data.errors,
      });
    }
  };
};
