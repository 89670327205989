import React from 'react';
import Thumbnail from "../Thumbnail.png";
import PropTypes from 'prop-types';
function A({ fields }) {
    const imgSrc = fields[0]?.response || Thumbnail; // Use Thumbnail if fields[0].response is empty

    return (
        <div>
            <img 
                loading="lazy" 
                alt="cover img" 
                src={imgSrc} 
                className="w-full md:h-auto rounded-t-lg aspect-[2.27] object-cover" 
            />
        </div>
    );
}

A.propTypes = {
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            response: PropTypes.string,
        })
    ).isRequired,
};
export default A;
