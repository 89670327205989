/* eslint-disable no-warning-comments */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import { useState, useEffect } from 'react';
import MobileFooter from '../footer/MobileFooter';
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import axios from 'axios';
import {Helmet} from "react-helmet";
import {useHistory} from "react-router-dom"
import { path } from '../../path';
import useIsCurrentDateTimeGreater from "../../useIsCurrentDateTimeGreater"
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import logo from "../../Components/LandingPage/logo/Logo-design.png";
import logoDark from "../../Components/LandingPage/logo/Tranparent_dark_logo.png";
import { useRef } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
const Rewards = () => {
  const history = useHistory()
  const closeRef = useRef();
  const { user } = useSelector((state) => state.AuthReducer)
  const [results, setResults] = useState();
  const { contestId } = useParams();
  const allowed = useIsCurrentDateTimeGreater("result",contestId)
  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  };
  useEffect(()=>{
    if(allowed===false){
      toast.error("You are not allowed to view this page", toastOption)
      setTimeout(() => {
       history.goBack();
      }, 2000);
    }
    console.log(allowed)
  },[allowed])
  useEffect(() => {
    async function getResult() {
      try {
        const res = await axios.get(`${path}/contest/getResultbyId/${contestId}`);
        setResults(res?.data)
        console.log(user, "User hai ye")
        setMedia(res?.data[0]?.submission.mediaUrl)
      } catch (e) { console.log(e) }
    }
    getResult()
  }, [])

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [media, setMedia] = useState()
  const [rewards, setRewards] = useState()
  const [isPwdMatchOpen, setPwdMatchOpen] = useState(false);
  // const [isPasswordMatched, setIsPasswordMatched] = useState(false);
  const [password, setPassword] = useState('')
  const [showPwd, setshowPwd] = useState(false);
  useEffect(() => { console.log(results); }, [results])
  useEffect(() => { console.log(media) }, [media])
  useEffect(() => {
    async function getRewards() {
      try {
        const res = await axios.get(`${path}/contest/rewardsById/${contestId}`)
        console.log(res)
        let finaldata = res.data.map(item => ({
          fullname: `${item.submission.user.firstName} ${item.submission.user.firstName}`,
          email: item.submission.user.email,
          paymentdetails: item.submission.user.paymentDetail,
          reward: item.reward
        }));
        console.log(finaldata)
        setRewards(finaldata)
      } catch (e) { console.log(e); toast.error("Payment detail not provided for a user") }
    }
    getRewards()
  }, []
  )
  useEffect(() => { console.log(rewards) }, [rewards])


  const handlePassword = async (e) => {
    e.preventDefault()

    try {
      const res = await axios.post(`${path}/payment/resultPayout`, { username: user.username, password, rewards, contestId })
      console.log(res)
      if (res.statusText === "Created") {
        setPwdMatchOpen(false); toast.success("Payment initiated", toastOption)
      }
      else if (res.data.msg === "Already Paid") {
        setPwdMatchOpen(false); toast.error("Already Paid", toastOption)
      }

      else { toast.error(res.data.errors[0].msg, toastOption) }


    } catch (e) { console.log(e); toast.error("Some error", toastOption) }

  }
  const handlePlayIconClick = (video) => {
    setMedia(video)
    setIsOverlayVisible(!isOverlayVisible);
  };

  const handleToggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };
  return (

    <>
     <Helmet>
          <title>Results</title>
          <meta name="description" content="Compete in the most challenging contests" />
        </Helmet>
      <div className="flex md:hidden justify-center items-center h-screen bg-compBgSec relative">
        <div className=" xl:w-[50vw] md:w-[70vw] w-[98vw] h-[82vh] rounded-lg  p-4">
          <h3 className={`font-gilroy-semibold ml-1  ${user.theme==="dark" ? "text-white" : "" }`}>Results</h3>
          {user.isMarketingAdmin && <button onClick={() => setPwdMatchOpen(true)}>Payout</button>}
          {user.isMarketingAdmin && isPwdMatchOpen && <div className="flex flex-col justify-center items-center absolute top-0 left-0 h-[100vh] w-[100vw]"><div className="border-2 border-[#C7C7C7] shadow-lg mr-20 rounded-lg py-6 px-6 w-[90%] md:w-[60%] xl:w-[30%] flex flex-col justify-between gap-4 bg-primary text-card">
            <div className="w-full flex justify-between">
              <div></div>
              <img src={user.theme === "light" ? logo : logoDark} className="w-[50%]" />
              <HighlightOffRoundedIcon
                className="cursor-pointer"
                onClick={() => setPwdMatchOpen(false)}
              />
            </div>
            <h3 className="font-semibold text-lg text-center">
              Confirm your password here
            </h3>
            <form onSubmit={handlePassword}>


              <div className="w-full flex px-4 py-1 border rounded-lg outline-none bg-primary text-grayish border-viewMore">
                <input
                  type={showPwd ? "text" : "password"}
                  className="grow outline-none bg-transparent"
                  placeholder="Enter password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password} />
                {showPwd ? <VisibilityOffIcon className="cursor-pointer" onClick={() => setshowPwd(perv => !perv)} /> : <VisibilityIcon className="cursor-pointer" onClick={() => setshowPwd(perv => !perv)} />}

              </div>

              <br />
              <input
                ref={closeRef}
                type="submit"
                value="Submit"
                className="bg-[#008080] rounded-lg px-2 py-1 text-white mt-2 cursor-pointer w-full"
              />
            </form>
            <ToastContainer />
          </div></div>}
          {/* From the password dialog box I just want the password and username(username from auth reducer) */}
          {results?.length > 0 &&
            <div className="flex h-full">

              <div className="md:w-[60%] w-[100%] h-[98%] mr-2 overflow-y-none">
                <div className="h-[35%] flex flex-row justify-center items-center gap-2">

                  <div className="h-[90%] mx-1 rounded-lg w-[30%] border border-teal-500 flex flex-col justify-center items-center relative bg-white">
                    <div className="reward-icon absolute top-0 left-1/2 transform -translate-x-1/2">
                      <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_867_1802)">
                          <path d="M38.831 0.582275C38.7742 0.245953 38.4835 0 38.1425 0H26.9955C26.8883 0 26.7821 0.0242227 26.6862 0.0717363C26.6545 0.0875742 26.2697 0.279492 25.6912 0.691277L30.1491 8.32421C31.0341 6.6575 32.33 5.15756 34.0247 3.83929C36.2439 2.11296 38.3512 1.36579 38.3717 1.35833C38.6941 1.24654 38.8879 0.917666 38.831 0.582275Z" fill="#3CAF91" />
                          <path d="M30.1493 8.32385C29.5027 9.53778 29.0732 10.8411 28.8655 12.2274L19.6953 12.5908C19.563 12.4436 19.4987 12.2461 19.5202 12.0495C20.0102 7.45742 21.9136 4.45661 23.4228 2.74798C24.2687 1.79025 25.0858 1.12134 25.6914 0.690918C25.9476 0.941529 26.2093 1.21636 26.473 1.51449C27.7354 2.94269 29.2418 5.17025 30.1493 8.32385Z" fill="#218780" />
                          <path d="M28.9802 12.0497C28.4898 7.45825 26.5869 4.45734 25.0769 2.74843C23.4234 0.87714 21.8784 0.103878 21.8134 0.0719227C21.7174 0.0245953 21.6117 0 21.5047 0H10.3574C10.0166 0 9.72585 0.246046 9.66883 0.582089C9.61181 0.918039 9.80597 1.24635 10.1278 1.3587C10.1488 1.36606 12.2559 2.11287 14.4754 3.83901C17.4047 6.11706 19.1406 8.93956 19.6351 12.2279L28.8051 12.591C28.9376 12.4436 29.0012 12.2469 28.9802 12.0497Z" fill="#3CAF91" />
                          <path d="M26.9667 12.5175L26.3417 12.2051L25.7182 11.8896C25.6872 11.951 24.962 13.413 25.2569 15.3161L26.6379 15.1021C26.4143 13.6592 26.9612 12.5288 26.9667 12.5175Z" fill="#A9BBE0" />
                          <path d="M21.5334 12.5174C21.539 12.5287 22.0859 13.6591 21.8623 15.1021L23.2433 15.3161C23.5382 13.413 22.813 11.951 22.7819 11.8896L21.5334 12.5174Z" fill="#A9BBE0" />
                          <path d="M36.0204 19.2197V21.6615L36.0195 40.367V42.8079C33.0047 45.8283 28.8403 47.6999 24.2501 47.6999C23.9063 47.6999 23.5653 47.6897 23.2271 47.6692C19.0422 47.413 15.2691 45.6019 12.4797 42.807V19.2197C15.2691 16.4257 19.0422 14.6146 23.2271 14.3584C23.5653 14.3379 23.9063 14.3276 24.2501 14.3276C28.8412 14.3276 33.0056 16.1993 36.0204 19.2197Z" fill="#E5EAF9" />
                          <path d="M36.0194 40.3672V42.8081C33.0046 45.8285 28.8402 47.7001 24.25 47.7001C23.9062 47.7001 23.5652 47.6899 23.2271 47.6694C28.5504 47.3461 33.2003 44.527 36.0194 40.3672Z" fill="#CBD6EF" />
                          <path d="M36.0203 19.2197V21.6615C33.2003 17.5008 28.5504 14.6817 23.2271 14.3584C23.5652 14.3379 23.9062 14.3276 24.25 14.3276C28.8411 14.3276 33.0056 16.1993 36.0203 19.2197Z" fill="#CBD6EF" />
                          <path d="M40.9042 31.0134C40.9042 35.6147 39.0363 39.7866 36.0196 42.8079L34.9688 41.7552L33.0803 39.8621V22.1656L34.9697 20.2725L36.0206 19.2197C39.0363 22.242 40.9042 26.4129 40.9042 31.0134Z" fill="#CBD6EF" />
                          <path d="M15.4199 22.1656V39.8621L12.4796 42.807C9.46389 39.7857 7.59595 35.6138 7.59595 31.0134C7.59595 26.4129 9.46389 22.242 12.4796 19.2197L15.4199 22.1656Z" fill="#CBD6EF" />
                          <path d="M40.904 31.0134C40.904 35.6147 39.036 39.7866 36.0194 42.8079L34.9685 41.7552C37.4141 38.8531 38.8861 35.1061 38.8861 31.0143C38.8861 26.9225 37.4141 23.1745 34.9694 20.2725L36.0203 19.2197C39.036 22.242 40.904 26.4129 40.904 31.0134Z" fill="#A9BBE0" />
                          <path d="M37.168 31.0131C37.168 38.1616 31.3844 43.9573 24.2499 43.9573C23.9052 43.9573 23.5633 43.9433 23.2251 43.9163C16.5695 43.3937 11.3318 37.8159 11.3318 31.0131C11.3318 24.2103 16.5695 18.6335 23.2251 18.1108C23.5633 18.0838 23.9052 18.0698 24.2499 18.0698C31.3844 18.0698 37.168 23.8646 37.168 31.0131Z" fill="#A9BBE0" />
                          <path d="M37.168 31.0131C37.168 38.1616 31.3844 43.9573 24.2499 43.9573C23.9052 43.9573 23.5633 43.9433 23.2251 43.9163C29.8807 43.3937 35.1184 37.8159 35.1184 31.0131C35.1184 24.2103 29.8807 18.6335 23.2251 18.1108C23.5633 18.0838 23.9052 18.0698 24.2499 18.0698C31.3844 18.0698 37.168 23.8646 37.168 31.0131Z" fill="#8794C6" />
                          <path d="M32.8147 27.9487C33.2006 27.9487 33.5134 27.6359 33.5134 27.25C33.5134 26.8641 33.2006 26.5513 32.8147 26.5513C32.4288 26.5513 32.116 26.8641 32.116 27.25C32.116 27.6359 32.4288 27.9487 32.8147 27.9487Z" fill="#CBD6EF" />
                          <path d="M15.0332 31.7212C15.4191 31.7212 15.7319 31.4084 15.7319 31.0225C15.7319 30.6366 15.4191 30.3237 15.0332 30.3237C14.6473 30.3237 14.3345 30.6366 14.3345 31.0225C14.3345 31.4084 14.6473 31.7212 15.0332 31.7212Z" fill="#CBD6EF" />
                          <path d="M15.6853 35.4946C16.0712 35.4946 16.384 35.1818 16.384 34.7959C16.384 34.41 16.0712 34.0972 15.6853 34.0972C15.2994 34.0972 14.9866 34.41 14.9866 34.7959C14.9866 35.1818 15.2994 35.4946 15.6853 35.4946Z" fill="#CBD6EF" />
                          <path d="M15.6853 27.9487C16.0712 27.9487 16.384 27.6359 16.384 27.25C16.384 26.8641 16.0712 26.5513 15.6853 26.5513C15.2994 26.5513 14.9866 26.8641 14.9866 27.25C14.9866 27.6359 15.2994 27.9487 15.6853 27.9487Z" fill="#FFCB78" />
                          <path d="M32.8147 35.4946C33.2006 35.4946 33.5134 35.1818 33.5134 34.7959C33.5134 34.41 33.2006 34.0972 32.8147 34.0972C32.4288 34.0972 32.116 34.41 32.116 34.7959C32.116 35.1818 32.4288 35.4946 32.8147 35.4946Z" fill="#CBD6EF" />
                          <path d="M33.4668 31.7212C33.8527 31.7212 34.1655 31.4084 34.1655 31.0225C34.1655 30.6366 33.8527 30.3237 33.4668 30.3237C33.0809 30.3237 32.7681 30.6366 32.7681 31.0225C32.7681 31.4084 33.0809 31.7212 33.4668 31.7212Z" fill="#CBD6EF" />
                          <path d="M15.6853 27.9487C16.0712 27.9487 16.384 27.6359 16.384 27.25C16.384 26.8641 16.0712 26.5513 15.6853 26.5513C15.2994 26.5513 14.9866 26.8641 14.9866 27.25C14.9866 27.6359 15.2994 27.9487 15.6853 27.9487Z" fill="#CBD6EF" />
                          <path d="M32.1736 11.997C32.0211 11.9737 28.3913 11.4248 24.2501 11.4248C20.1089 11.4248 16.4791 11.9737 16.3266 11.997C15.9452 12.0554 15.6834 12.4121 15.7418 12.7935C15.8001 13.1749 16.1566 13.4368 16.5382 13.3784C16.5745 13.3728 20.2196 12.8223 24.2501 12.8223C28.2806 12.8223 31.9257 13.3729 31.962 13.3784C31.9979 13.3839 32.0335 13.3865 32.0686 13.3865C32.4078 13.3865 32.7056 13.139 32.7585 12.7934C32.8168 12.4121 32.555 12.0555 32.1736 11.997Z" fill="#E5EAF9" />
                          <path d="M27.4277 35.093C26.8723 35.2063 26.3123 35.2756 25.7678 35.313C26.4478 34.7432 27.0406 34.1038 27.5358 33.4021C28.7774 31.6425 29.3286 29.5482 29.0482 27.656C28.8594 26.3818 28.3153 25.2824 27.4747 24.4768C26.5589 23.5991 25.3591 23.1353 24.005 23.1353C22.1605 23.1353 21.0965 23.9285 20.5288 24.594C19.0508 26.3265 19.4039 28.8572 19.449 29.14C19.5705 29.9021 20.2871 30.4251 21.0497 30.3036C21.8119 30.182 22.3317 29.4692 22.2101 28.707C22.1515 28.3124 22.1084 27.0419 22.6595 26.403C22.7604 26.2861 23.0672 25.9303 24.005 25.9303C25.7759 25.9303 26.1884 27.4238 26.2837 28.0659C26.5789 30.0584 25.2613 33.5551 20.4211 35.0218C19.8153 35.2054 19.4084 35.7734 19.4297 36.4061C19.451 37.0389 19.895 37.5783 20.5119 37.7207C20.6178 37.7452 22.3859 38.145 24.6783 38.1449C25.7095 38.1449 26.8471 38.064 27.9864 37.8316C28.7426 37.6774 29.2306 36.9393 29.0764 36.1831C28.9222 35.4269 28.1841 34.9384 27.4277 35.093Z" fill="#E5EAF9" />
                        </g>
                        <defs>
                          <clipPath id="clip0_867_1802">
                            <rect width="47.7" height="47.7" fill="white" transform="translate(0.399902)" />
                          </clipPath>
                        </defs>
                      </svg>

                    </div>



                    <div className="user-details flex flex-col items-center px-2">
                      <img src={results[1].submission.user?.profilePicture} alt="user" className="mx-auto mt-8" />

                      <div className="text-center">
                        <p className="text-sm ">{results[1].submission.user.firstName} {results[1].submission.user.lastName}</p>
                        <p className="text-[0.6rem]  text-[#707C97]">@{results[1].submission.user.username}</p>

                      </div>
                    </div>

                    <div className="play-icon absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2" onClick={() => handlePlayIconClick(results[0].submission.mediaUrl)}>
                      <svg width="32" height="32" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.766016" y="1.36563" width="33.968" height="33.968" rx="16.984" fill={`${user.theme==="dark" ? "#0D0D0D" : "white"}`} stroke="#008080" strokeWidth="1.325" />
                        <g clipPath="url(#clip0_867_1851)">
                          <path d="M22.0206 16.3222L17.2241 12.8051C16.8494 12.5307 16.4061 12.3655 15.9433 12.3277C15.4806 12.2899 15.0163 12.3809 14.6021 12.5908C14.1879 12.8007 13.8399 13.1211 13.5967 13.5167C13.3535 13.9122 13.2245 14.3674 13.2241 14.8317V21.8695C13.2234 22.3343 13.3517 22.7902 13.5947 23.1864C13.8377 23.5826 14.1859 23.9036 14.6005 24.1137C15.0151 24.3238 15.4798 24.4147 15.943 24.3763C16.4062 24.3378 16.8497 24.1716 17.2241 23.8962L22.0206 20.379C22.3388 20.1454 22.5976 19.8402 22.776 19.488C22.9544 19.1359 23.0473 18.7466 23.0473 18.3519C23.0473 17.9571 22.9544 17.5679 22.776 17.2157C22.5976 16.8635 22.3388 16.5583 22.0206 16.3248V16.3222Z" fill="#008080" />
                        </g>
                        <defs>
                          <clipPath id="clip0_867_1851">
                            <rect width="12.0692" height="12.0692" fill="white" transform="translate(11.7153 12.3149)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                  </div>




                  <div className="h-[90%] mx-1 rounded-lg w-[30%] border border-teal-500 flex flex-col justify-center items-center relative bg-white">
                    <div className="reward-icon absolute top-0 left-1/2 transform -translate-x-1/2">
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_867_1737)">
                          <path d="M38.581 0.582275C38.5242 0.245953 38.2335 0 37.8925 0H26.7455C26.6383 0 26.5321 0.0242227 26.4362 0.0717363C26.4045 0.0875742 26.0197 0.279492 25.4412 0.691277L29.8991 8.32421C30.7841 6.6575 32.08 5.15756 33.7747 3.83929C35.9939 2.11296 38.1012 1.36579 38.1217 1.35833C38.4441 1.24654 38.6379 0.917666 38.581 0.582275Z" fill="#78C2EF" />
                          <path d="M29.8993 8.32385C29.2527 9.53778 28.8232 10.8411 28.6155 12.2274L19.4453 12.5908C19.313 12.4436 19.2487 12.2461 19.2702 12.0495C19.7602 7.45742 21.6636 4.45661 23.1728 2.74798C24.0187 1.79025 24.8358 1.12134 25.4414 0.690918C25.6976 0.941529 25.9593 1.21636 26.223 1.51449C27.4854 2.94269 28.9918 5.17025 29.8993 8.32385Z" fill="#74A1E2" />
                          <path d="M28.7302 12.0497C28.2398 7.45825 26.3369 4.45734 24.8269 2.74843C23.1734 0.87714 21.6284 0.103878 21.5634 0.0719227C21.4674 0.0245953 21.3617 0 21.2547 0H10.1074C9.76661 0 9.47585 0.246046 9.41883 0.582089C9.36181 0.918039 9.55597 1.24635 9.87776 1.3587C9.89881 1.36606 12.0059 2.11287 14.2254 3.83901C17.1547 6.11706 18.8906 8.93956 19.3851 12.2279L28.5551 12.591C28.6876 12.4436 28.7512 12.2469 28.7302 12.0497Z" fill="#78C2EF" />
                          <path d="M26.7167 12.5175L26.0917 12.2051L25.4682 11.8896C25.4372 11.951 24.712 13.413 25.0069 15.3161L26.3879 15.1021C26.1643 13.6592 26.7112 12.5288 26.7167 12.5175Z" fill="#FFA757" />
                          <path d="M21.2834 12.5174C21.289 12.5287 21.8359 13.6591 21.6123 15.1021L22.9933 15.3161C23.2882 13.413 22.563 11.951 22.5319 11.8896L21.2834 12.5174Z" fill="#FFA757" />
                          <path d="M35.7704 19.2197V21.6615L35.7695 40.367V42.8079C32.7547 45.8283 28.5903 47.6999 24.0001 47.6999C23.6563 47.6999 23.3153 47.6897 22.9771 47.6692C18.7922 47.413 15.0191 45.6019 12.2297 42.807V19.2197C15.0191 16.4257 18.7922 14.6146 22.9771 14.3584C23.3153 14.3379 23.6563 14.3276 24.0001 14.3276C28.5912 14.3276 32.7556 16.1993 35.7704 19.2197Z" fill="#FFE0A9" />
                          <path d="M35.7694 40.3672V42.8081C32.7546 45.8285 28.5902 47.7001 24 47.7001C23.6562 47.7001 23.3152 47.6899 22.9771 47.6694C28.3004 47.3461 32.9503 44.527 35.7694 40.3672Z" fill="#FFCB78" />
                          <path d="M35.7703 19.2197V21.6615C32.9503 17.5008 28.3004 14.6817 22.9771 14.3584C23.3152 14.3379 23.6562 14.3276 24 14.3276C28.5911 14.3276 32.7555 16.1993 35.7703 19.2197Z" fill="#FFCB78" />
                          <path d="M40.6542 31.0134C40.6542 35.6147 38.7863 39.7866 35.7696 42.8079L34.7188 41.7552L32.8303 39.8621V22.1656L34.7197 20.2725L35.7706 19.2197C38.7863 22.242 40.6542 26.4129 40.6542 31.0134Z" fill="#FFCB78" />
                          <path d="M15.1699 22.1656V39.8621L12.2296 42.807C9.21389 39.7857 7.34595 35.6138 7.34595 31.0134C7.34595 26.4129 9.21389 22.242 12.2296 19.2197L15.1699 22.1656Z" fill="#FFCB78" />
                          <path d="M40.654 31.0134C40.654 35.6147 38.786 39.7866 35.7694 42.8079L34.7185 41.7552C37.1641 38.8531 38.6361 35.1061 38.6361 31.0143C38.6361 26.9225 37.1641 23.1745 34.7194 20.2725L35.7703 19.2197C38.786 22.242 40.654 26.4129 40.654 31.0134Z" fill="#FFA757" />
                          <path d="M36.918 31.0131C36.918 38.1616 31.1344 43.9573 23.9999 43.9573C23.6552 43.9573 23.3133 43.9433 22.9751 43.9163C16.3195 43.3937 11.0818 37.8159 11.0818 31.0131C11.0818 24.2103 16.3195 18.6335 22.9751 18.1108C23.3133 18.0838 23.6552 18.0698 23.9999 18.0698C31.1344 18.0698 36.918 23.8646 36.918 31.0131Z" fill="#FFA757" />
                          <path d="M36.918 31.0131C36.918 38.1616 31.1344 43.9573 23.9999 43.9573C23.6552 43.9573 23.3133 43.9433 22.9751 43.9163C29.6307 43.3937 34.8684 37.8159 34.8684 31.0131C34.8684 24.2103 29.6307 18.6335 22.9751 18.1108C23.3133 18.0838 23.6552 18.0698 23.9999 18.0698C31.1344 18.0698 36.918 23.8646 36.918 31.0131Z" fill="#FF895A" />
                          <path d="M28.3059 36.0225C28.2502 36.015 27.2451 35.8807 25.8984 35.8132C26.149 29.6552 25.8328 23.8538 25.8293 23.7915C25.7946 23.1704 25.3552 22.6467 24.7505 22.5057C24.1458 22.3649 23.5208 22.6406 23.2163 23.1826C21.8765 25.5671 19.7733 26.4975 19.7172 26.5218C19.0077 26.8174 18.669 27.6321 18.9606 28.3454C19.2531 29.0612 20.0694 29.4037 20.7833 29.1106C20.8566 29.0805 21.924 28.6322 23.1651 27.5734C23.2129 29.7797 23.2291 32.754 23.1014 35.8068C21.689 35.8718 20.6196 36.0147 20.562 36.0224C19.7974 36.1259 19.2615 36.8308 19.3644 37.597C19.4674 38.3633 20.1706 38.9003 20.9356 38.7979C20.9521 38.7956 22.6062 38.5759 24.4293 38.5755H24.4353H24.4414C26.2634 38.5761 27.9159 38.7956 27.9318 38.7978C27.9953 38.8064 28.0582 38.8105 28.1206 38.8105C28.8093 38.8105 29.409 38.3003 29.5037 37.5976C29.6071 36.8312 29.0707 36.126 28.3059 36.0225Z" fill="#FFE0A9" />
                          <path d="M32.5647 27.9487C32.9506 27.9487 33.2634 27.6359 33.2634 27.25C33.2634 26.8641 32.9506 26.5513 32.5647 26.5513C32.1788 26.5513 31.866 26.8641 31.866 27.25C31.866 27.6359 32.1788 27.9487 32.5647 27.9487Z" fill="#FFCB78" />
                          <path d="M14.7832 31.7212C15.1691 31.7212 15.4819 31.4084 15.4819 31.0225C15.4819 30.6366 15.1691 30.3237 14.7832 30.3237C14.3973 30.3237 14.0845 30.6366 14.0845 31.0225C14.0845 31.4084 14.3973 31.7212 14.7832 31.7212Z" fill="#FFCB78" />
                          <path d="M15.4353 35.4946C15.8212 35.4946 16.134 35.1818 16.134 34.7959C16.134 34.41 15.8212 34.0972 15.4353 34.0972C15.0494 34.0972 14.7366 34.41 14.7366 34.7959C14.7366 35.1818 15.0494 35.4946 15.4353 35.4946Z" fill="#FFCB78" />
                          <path d="M15.4353 27.9487C15.8212 27.9487 16.134 27.6359 16.134 27.25C16.134 26.8641 15.8212 26.5513 15.4353 26.5513C15.0494 26.5513 14.7366 26.8641 14.7366 27.25C14.7366 27.6359 15.0494 27.9487 15.4353 27.9487Z" fill="#FFCB78" />
                          <path d="M32.5647 35.4946C32.9506 35.4946 33.2634 35.1818 33.2634 34.7959C33.2634 34.41 32.9506 34.0972 32.5647 34.0972C32.1788 34.0972 31.866 34.41 31.866 34.7959C31.866 35.1818 32.1788 35.4946 32.5647 35.4946Z" fill="#FFCB78" />
                          <path d="M33.2168 31.7212C33.6027 31.7212 33.9155 31.4084 33.9155 31.0225C33.9155 30.6366 33.6027 30.3237 33.2168 30.3237C32.8309 30.3237 32.5181 30.6366 32.5181 31.0225C32.5181 31.4084 32.8309 31.7212 33.2168 31.7212Z" fill="#FFCB78" />
                          <path d="M14.7832 31.7212C15.1691 31.7212 15.4819 31.4084 15.4819 31.0225C15.4819 30.6366 15.1691 30.3237 14.7832 30.3237C14.3973 30.3237 14.0845 30.6366 14.0845 31.0225C14.0845 31.4084 14.3973 31.7212 14.7832 31.7212Z" fill="#FFCB78" />
                          <path d="M15.4353 27.9487C15.8212 27.9487 16.134 27.6359 16.134 27.25C16.134 26.8641 15.8212 26.5513 15.4353 26.5513C15.0494 26.5513 14.7366 26.8641 14.7366 27.25C14.7366 27.6359 15.0494 27.9487 15.4353 27.9487Z" fill="#FFCB78" />
                          <path d="M15.4353 35.4946C15.8212 35.4946 16.134 35.1818 16.134 34.7959C16.134 34.41 15.8212 34.0972 15.4353 34.0972C15.0494 34.0972 14.7366 34.41 14.7366 34.7959C14.7366 35.1818 15.0494 35.4946 15.4353 35.4946Z" fill="#FFCB78" />
                          <path d="M33.2168 31.7212C33.6027 31.7212 33.9155 31.4084 33.9155 31.0225C33.9155 30.6366 33.6027 30.3237 33.2168 30.3237C32.8309 30.3237 32.5181 30.6366 32.5181 31.0225C32.5181 31.4084 32.8309 31.7212 33.2168 31.7212Z" fill="#FFCB78" />
                          <path d="M32.5647 27.9487C32.9506 27.9487 33.2634 27.6359 33.2634 27.25C33.2634 26.8641 32.9506 26.5513 32.5647 26.5513C32.1788 26.5513 31.866 26.8641 31.866 27.25C31.866 27.6359 32.1788 27.9487 32.5647 27.9487Z" fill="#FFCB78" />
                          <path d="M32.5647 35.4946C32.9506 35.4946 33.2634 35.1818 33.2634 34.7959C33.2634 34.41 32.9506 34.0972 32.5647 34.0972C32.1788 34.0972 31.866 34.41 31.866 34.7959C31.866 35.1818 32.1788 35.4946 32.5647 35.4946Z" fill="#FFCB78" />
                          <path d="M31.9236 11.997C31.7711 11.9737 28.1413 11.4248 24.0001 11.4248C19.8589 11.4248 16.2291 11.9737 16.0766 11.997C15.6952 12.0554 15.4334 12.4121 15.4918 12.7935C15.5501 13.1749 15.9066 13.4368 16.2882 13.3784C16.3245 13.3728 19.9696 12.8223 24.0001 12.8223C28.0306 12.8223 31.6757 13.3729 31.712 13.3784C31.7479 13.3839 31.7835 13.3865 31.8186 13.3865C32.1578 13.3865 32.4556 13.139 32.5085 12.7934C32.5668 12.4121 32.305 12.0555 31.9236 11.997Z" fill="#FFE0A9" />
                        </g>
                        <defs>
                          <clipPath id="clip0_867_1737">
                            <rect width="47.7" height="47.7" fill="white" transform="translate(0.149902)" />
                          </clipPath>
                        </defs>
                      </svg>


                    </div>


                    <div className="user-details flex flex-col items-center px-2">
                      <img src={results[0].submission.user?.profilePicture} alt="user" className="mx-auto mt-8" />

                      <div className="text-center">
                        <p className="text-sm ">{results[0].submission.user.firstName} {results[0].submission.user.lastName}</p>
                        <p className="text-[0.6rem]  text-[#707C97]">@{results[0].submission.user.username}</p>

                      </div>
                    </div>

                    <div className="play-icon absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2" onClick={() => handlePlayIconClick(results[1].submission.mediaUrl)}>
                      <svg width="32" height="32" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.766016" y="1.36563" width="33.968" height="33.968" rx="16.984" fill={`${user.theme==="dark" ? "#0D0D0D" : "white"}`} stroke="#008080" strokeWidth="1.325" />
                        <g clipPath="url(#clip0_867_1851)">
                          <path d="M22.0206 16.3222L17.2241 12.8051C16.8494 12.5307 16.4061 12.3655 15.9433 12.3277C15.4806 12.2899 15.0163 12.3809 14.6021 12.5908C14.1879 12.8007 13.8399 13.1211 13.5967 13.5167C13.3535 13.9122 13.2245 14.3674 13.2241 14.8317V21.8695C13.2234 22.3343 13.3517 22.7902 13.5947 23.1864C13.8377 23.5826 14.1859 23.9036 14.6005 24.1137C15.0151 24.3238 15.4798 24.4147 15.943 24.3763C16.4062 24.3378 16.8497 24.1716 17.2241 23.8962L22.0206 20.379C22.3388 20.1454 22.5976 19.8402 22.776 19.488C22.9544 19.1359 23.0473 18.7466 23.0473 18.3519C23.0473 17.9571 22.9544 17.5679 22.776 17.2157C22.5976 16.8635 22.3388 16.5583 22.0206 16.3248V16.3222Z" fill="#008080" />
                        </g>
                        <defs>
                          <clipPath id="clip0_867_1851">
                            <rect width="12.0692" height="12.0692" fill="white" transform="translate(11.7153 12.3149)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                  </div>
                  <div className="h-[90%] mx-1 rounded-lg w-[30%] border border-teal-500 flex flex-col justify-center items-center relative bg-white">
                    <div className="reward-icon absolute top-0 left-1/2 transform -translate-x-1/2">
                      <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_867_1771)">
                          <path d="M39.331 0.582275C39.2742 0.245953 38.9835 0 38.6425 0H27.4955C27.3883 0 27.2821 0.0242227 27.1862 0.0717363C27.1545 0.0875742 26.7697 0.279492 26.1912 0.691277L30.6491 8.32421C31.5341 6.6575 32.83 5.15756 34.5247 3.83929C36.7439 2.11296 38.8512 1.36579 38.8717 1.35833C39.1941 1.24654 39.3879 0.917666 39.331 0.582275Z" fill="#B67DFF" />
                          <path d="M30.6493 8.32385C30.0027 9.53778 29.5732 10.8411 29.3655 12.2274L20.1953 12.5908C20.063 12.4436 19.9987 12.2461 20.0202 12.0495C20.5102 7.45742 22.4136 4.45661 23.9228 2.74798C24.7687 1.79025 25.5858 1.12134 26.1914 0.690918C26.4476 0.941529 26.7093 1.21636 26.973 1.51449C28.2354 2.94269 29.7418 5.17025 30.6493 8.32385Z" fill="#7D5EDD" />
                          <path d="M29.4802 12.0497C28.9898 7.45825 27.0869 4.45734 25.5769 2.74843C23.9234 0.87714 22.3784 0.103878 22.3134 0.0719227C22.2174 0.0245953 22.1117 0 22.0047 0H10.8574C10.5166 0 10.2258 0.246046 10.1688 0.582089C10.1118 0.918039 10.306 1.24635 10.6278 1.3587C10.6488 1.36606 12.7559 2.11287 14.9754 3.83901C17.9047 6.11706 19.6406 8.93956 20.1351 12.2279L29.3051 12.591C29.4376 12.4436 29.5012 12.2469 29.4802 12.0497Z" fill="#B67DFF" />
                          <path d="M27.4667 12.5175L26.8417 12.2051L26.2182 11.8896C26.1872 11.951 25.462 13.413 25.7569 15.3161L27.1379 15.1021C26.9143 13.6592 27.4612 12.5288 27.4667 12.5175Z" fill="#DB5E52" />
                          <path d="M22.0334 12.5174C22.039 12.5287 22.5859 13.6591 22.3623 15.1021L23.7433 15.3161C24.0382 13.413 23.313 11.951 23.2819 11.8896L22.0334 12.5174Z" fill="#DB5E52" />
                          <path d="M36.5204 19.2197V21.6615L36.5195 40.367V42.8079C33.5047 45.8283 29.3403 47.6999 24.7501 47.6999C24.4063 47.6999 24.0653 47.6897 23.7271 47.6692C19.5422 47.413 15.7691 45.6019 12.9797 42.807V19.2197C15.7691 16.4257 19.5422 14.6146 23.7271 14.3584C24.0653 14.3379 24.4063 14.3276 24.7501 14.3276C29.3412 14.3276 33.5056 16.1993 36.5204 19.2197Z" fill="#FFB08D" />
                          <path d="M36.5194 40.3672V42.8081C33.5046 45.8285 29.3402 47.7001 24.75 47.7001C24.4062 47.7001 24.0652 47.6899 23.7271 47.6694C29.0504 47.3461 33.7003 44.527 36.5194 40.3672Z" fill="#FF8C69" />
                          <path d="M36.5203 19.2197V21.6615C33.7003 17.5008 29.0504 14.6817 23.7271 14.3584C24.0652 14.3379 24.4062 14.3276 24.75 14.3276C29.3411 14.3276 33.5056 16.1993 36.5203 19.2197Z" fill="#FF8C69" />
                          <path d="M41.4042 31.0134C41.4042 35.6147 39.5363 39.7866 36.5196 42.8079L35.4688 41.7552L33.5803 39.8621V22.1656L35.4697 20.2725L36.5206 19.2197C39.5363 22.242 41.4042 26.4129 41.4042 31.0134Z" fill="#FF8C69" />
                          <path d="M15.9199 22.1656V39.8621L12.9796 42.807C9.96389 39.7857 8.09595 35.6138 8.09595 31.0134C8.09595 26.4129 9.96389 22.242 12.9796 19.2197L15.9199 22.1656Z" fill="#FF8C69" />
                          <path d="M41.404 31.0134C41.404 35.6147 39.536 39.7866 36.5194 42.8079L35.4685 41.7552C37.9141 38.8531 39.3861 35.1061 39.3861 31.0143C39.3861 26.9225 37.9141 23.1745 35.4694 20.2725L36.5203 19.2197C39.536 22.242 41.404 26.4129 41.404 31.0134Z" fill="#DB5E52" />
                          <path d="M37.668 31.0131C37.668 38.1616 31.8844 43.9573 24.7499 43.9573C24.4052 43.9573 24.0633 43.9433 23.7251 43.9163C17.0695 43.3937 11.8318 37.8159 11.8318 31.0131C11.8318 24.2103 17.0695 18.6335 23.7251 18.1108C24.0633 18.0838 24.4052 18.0698 24.7499 18.0698C31.8844 18.0698 37.668 23.8646 37.668 31.0131Z" fill="#DB5E52" />
                          <path d="M37.668 31.0131C37.668 38.1616 31.8844 43.9573 24.7499 43.9573C24.4052 43.9573 24.0633 43.9433 23.7251 43.9163C30.3807 43.3937 35.6184 37.8159 35.6184 31.0131C35.6184 24.2103 30.3807 18.6335 23.7251 18.1108C24.0633 18.0838 24.4052 18.0698 24.7499 18.0698C31.8844 18.0698 37.668 23.8646 37.668 31.0131Z" fill="#C43D4E" />
                          <path d="M33.3147 27.9487C33.7006 27.9487 34.0134 27.6359 34.0134 27.25C34.0134 26.8641 33.7006 26.5513 33.3147 26.5513C32.9288 26.5513 32.616 26.8641 32.616 27.25C32.616 27.6359 32.9288 27.9487 33.3147 27.9487Z" fill="#FF8C69" />
                          <path d="M15.5332 31.7212C15.9191 31.7212 16.2319 31.4084 16.2319 31.0225C16.2319 30.6366 15.9191 30.3237 15.5332 30.3237C15.1473 30.3237 14.8345 30.6366 14.8345 31.0225C14.8345 31.4084 15.1473 31.7212 15.5332 31.7212Z" fill="#FF8C69" />
                          <path d="M16.1853 35.4946C16.5712 35.4946 16.884 35.1818 16.884 34.7959C16.884 34.41 16.5712 34.0972 16.1853 34.0972C15.7994 34.0972 15.4866 34.41 15.4866 34.7959C15.4866 35.1818 15.7994 35.4946 16.1853 35.4946Z" fill="#FF8C69" />
                          <path d="M16.1853 27.9487C16.5712 27.9487 16.884 27.6359 16.884 27.25C16.884 26.8641 16.5712 26.5513 16.1853 26.5513C15.7994 26.5513 15.4866 26.8641 15.4866 27.25C15.4866 27.6359 15.7994 27.9487 16.1853 27.9487Z" fill="#FFCB78" />
                          <path d="M33.3147 35.4946C33.7006 35.4946 34.0134 35.1818 34.0134 34.7959C34.0134 34.41 33.7006 34.0972 33.3147 34.0972C32.9288 34.0972 32.616 34.41 32.616 34.7959C32.616 35.1818 32.9288 35.4946 33.3147 35.4946Z" fill="#FF8C69" />
                          <path d="M33.9668 31.7212C34.3527 31.7212 34.6655 31.4084 34.6655 31.0225C34.6655 30.6366 34.3527 30.3237 33.9668 30.3237C33.5809 30.3237 33.2681 30.6366 33.2681 31.0225C33.2681 31.4084 33.5809 31.7212 33.9668 31.7212Z" fill="#FF8C69" />
                          <path d="M16.1853 27.9487C16.5712 27.9487 16.884 27.6359 16.884 27.25C16.884 26.8641 16.5712 26.5513 16.1853 26.5513C15.7994 26.5513 15.4866 26.8641 15.4866 27.25C15.4866 27.6359 15.7994 27.9487 16.1853 27.9487Z" fill="#FF8C69" />
                          <path d="M32.6736 11.997C32.5211 11.9737 28.8913 11.4248 24.7501 11.4248C20.6089 11.4248 16.9791 11.9737 16.8266 11.997C16.4452 12.0554 16.1834 12.4121 16.2418 12.7935C16.3001 13.1749 16.6566 13.4368 17.0382 13.3784C17.0745 13.3728 20.7196 12.8223 24.7501 12.8223C28.7806 12.8223 32.4257 13.3729 32.462 13.3784C32.4979 13.3839 32.5335 13.3865 32.5686 13.3865C32.9078 13.3865 33.2056 13.139 33.2585 12.7934C33.3168 12.4121 33.055 12.0555 32.6736 11.997Z" fill="#FFB08D" />
                          <path d="M28.1126 31.0133C29.1538 30.1408 29.7114 28.9128 29.7114 27.4381C29.7114 24.9467 28.0736 22.9784 25.6359 22.5404C23.0341 22.0727 20.5895 23.4373 19.6906 25.8583C19.4219 26.5818 19.7906 27.3861 20.5141 27.6548C21.2373 27.9235 22.0419 27.5547 22.3106 26.8312C22.8006 25.5118 24.1212 25.1081 25.1417 25.2913C25.6753 25.3872 26.9166 25.7883 26.9166 27.4381C26.9166 28.3205 26.536 28.8801 25.6825 29.2523C24.8786 29.603 24.0139 29.6159 24.0123 29.616H24.01C23.2393 29.6172 22.6114 30.2424 22.6114 31.0134C22.6114 31.7849 23.233 32.4103 24.0044 32.4109H24.0059C24.032 32.4113 24.8871 32.4275 25.6826 32.7745C26.5361 33.1468 26.9167 33.7063 26.9167 34.5888C26.9167 36.2385 25.6755 36.6396 25.1418 36.7355C24.1213 36.9189 22.8008 36.5149 22.3107 35.1956C22.042 34.472 21.2376 34.1033 20.5141 34.3721C19.7906 34.6408 19.4219 35.4451 19.6907 36.1687C20.4717 38.2718 22.4194 39.5776 24.623 39.5775C24.9558 39.5775 25.2947 39.5477 25.636 39.4864C28.0737 39.0484 29.7115 37.0802 29.7115 34.5888C29.7114 33.114 29.1538 31.886 28.1126 31.0133Z" fill="#FFB08D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_867_1771">
                            <rect width="47.7" height="47.7" fill="white" transform="translate(0.899902)" />
                          </clipPath>
                        </defs>
                      </svg>


                    </div>


                    <div className="user-details flex flex-col items-center px-2">
                      <img src={results[2].submission.user?.profilePicture} alt="user" className="mx-auto mt-8" />

                      <div className="text-center">
                        <p className="text-sm ">{results[2].submission.user.firstName} {results[2].submission.user.lastName}</p>
                        <p className="text-[0.6rem]  text-[#707C97]">@{results[2].submission.user.username}</p>

                      </div>
                    </div>
                    <div className="play-icon absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2" onClick={() => handlePlayIconClick(results[2].submission.mediaUrl)}>
                      <svg width="32" height="32" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.766016" y="1.36563" width="33.968" height="33.968" rx="16.984" fill={`${user.theme==="dark" ? "#0D0D0D" : "white"}`} stroke="#008080" strokeWidth="1.325" />
                        <g clipPath="url(#clip0_867_1851)">
                          <path d="M22.0206 16.3222L17.2241 12.8051C16.8494 12.5307 16.4061 12.3655 15.9433 12.3277C15.4806 12.2899 15.0163 12.3809 14.6021 12.5908C14.1879 12.8007 13.8399 13.1211 13.5967 13.5167C13.3535 13.9122 13.2245 14.3674 13.2241 14.8317V21.8695C13.2234 22.3343 13.3517 22.7902 13.5947 23.1864C13.8377 23.5826 14.1859 23.9036 14.6005 24.1137C15.0151 24.3238 15.4798 24.4147 15.943 24.3763C16.4062 24.3378 16.8497 24.1716 17.2241 23.8962L22.0206 20.379C22.3388 20.1454 22.5976 19.8402 22.776 19.488C22.9544 19.1359 23.0473 18.7466 23.0473 18.3519C23.0473 17.9571 22.9544 17.5679 22.776 17.2157C22.5976 16.8635 22.3388 16.5583 22.0206 16.3248V16.3222Z" fill="#008080" />
                        </g>
                        <defs>
                          <clipPath id="clip0_867_1851">
                            <rect width="12.0692" height="12.0692" fill="white" transform="translate(11.7153 12.3149)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                  </div>
                </div>


                <div className="h-[62%] mt-6 overflow-y-scroll flex flex-col items-center bg-white rounded-lg">
                  <div className="w-[95%]">

                    {results?.length > 3 && results?.slice(3).map((r, index) =>
                    (<div className="flex flex-row justify-between items-center border-b py-2" key={index}>
                      <div className="flex flex-row items-center">
                        <div className={`${user.theme==="dark" ? "bg-compBg" : "bg-gray-300"} h-10 w-10 flex justify-center items-center rounded-md`}>
                          <p className="text-gray-800 text-[13px]">{r.rank}</p>
                        </div>
                        <div className="ml-2">
                          <div className="font-gilroy-bold text-[13px]">{r.submission?.user?.firstName} {r.submission?.user?.lastName}</div>
                          <div className="font-gilroy-light text-[10px]">{r.submission?.user?.username}</div>
                        </div>
                      </div>
                      <div>
                        <div onClick={() => handlePlayIconClick(r.submission?.mediaUrl)}>
                          <svg width="32" height="32" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.766016" y="1.36563" width="33.968" height="33.968" rx="16.984" fill={`${user.theme==="dark" ? "#0D0D0D" : "white"}`} stroke="#008080" strokeWidth="1.325" />
                            <g clipPath="url(#clip0_867_1851)">
                              <path d="M22.0206 16.3222L17.2241 12.8051C16.8494 12.5307 16.4061 12.3655 15.9433 12.3277C15.4806 12.2899 15.0163 12.3809 14.6021 12.5908C14.1879 12.8007 13.8399 13.1211 13.5967 13.5167C13.3535 13.9122 13.2245 14.3674 13.2241 14.8317V21.8695C13.2234 22.3343 13.3517 22.7902 13.5947 23.1864C13.8377 23.5826 14.1859 23.9036 14.6005 24.1137C15.0151 24.3238 15.4798 24.4147 15.943 24.3763C16.4062 24.3378 16.8497 24.1716 17.2241 23.8962L22.0206 20.379C22.3388 20.1454 22.5976 19.8402 22.776 19.488C22.9544 19.1359 23.0473 18.7466 23.0473 18.3519C23.0473 17.9571 22.9544 17.5679 22.776 17.2157C22.5976 16.8635 22.3388 16.5583 22.0206 16.3248V16.3222Z" fill="#008080" />
                            </g>
                            <defs>
                              <clipPath id="clip0_867_1851">
                                <rect width="12.0692" height="12.0692" fill="white" transform="translate(11.7153 12.3149)" />
                              </clipPath>
                            </defs>
                          </svg></div>
                      </div>
                    </div>))}

                  </div>
                </div>
              </div>






              {/* Top Overlay rounded-t-lg*/}
              {isOverlayVisible && (
                <div className="fixed top-0 left-0 w-full h-[30vh] bg-black opacity-30 z-10" onClick={handleToggleOverlay}></div>
              )}
              <div className={`fixed bottom-0 left-0 w-full h-[70vh] bg-white  transition-transform duration-300 rounded-lg ${isOverlayVisible ? 'translate-y-0' : 'translate-y-full'} z-10`}>
                {
                  isOverlayVisible && (
                    <div className="absolute top-[-50px] left-1/2 transform -translate-x-1/2 cursor-pointer" onClick={handleToggleOverlay}>
                      <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_321_640)">
                          <path d="M27.7666 9.90318L20.7766 18.2336L12.4461 11.2436C11.6793 10.6001 10.5361 10.7002 9.89268 11.4669C9.24926 12.2337 9.34928 13.377 10.1161 14.0204L18.4465 21.0105L11.4564 29.3409C10.813 30.1077 10.913 31.2509 11.6798 31.8943C12.4466 32.5377 13.5898 32.4377 14.2333 31.6709L21.2233 23.3405L29.5538 30.3306C30.3206 30.974 31.4638 30.874 32.1072 30.1072C32.7506 29.3404 32.6506 28.1972 31.8838 27.5537L23.5534 20.5637L30.5435 12.2332C31.1869 11.4664 31.0868 10.3232 30.3201 9.67978C29.5533 9.03637 28.41 9.13639 27.7666 9.90318Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_321_640">
                            <rect width="28.999" height="28.999" fill="white" transform="translate(0.572571 22.5742) rotate(-50)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>)
                }
                {/* <div className="w-[80%] h-[90%] bg-gray-300 mt-10 mx-auto rounded-2xl"></div> */}
                <div className="relative w-[70%] h-[87%] mt-10 mx-auto rounded-2xl overflow-hidden">
                  <video className="w-full h-full object-cover" src={media} controls={true} loop></video>

                </div>
                <div className="left-1/2 transform  flex  justify-center items-center m-2 space-x-4" onClick={() => document.querySelector('video').play()}>
                  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.111 5.97897L5.75238 1.31631C5.25573 0.952629 4.66807 0.73358 4.05455 0.683441C3.44103 0.633301 2.82561 0.754029 2.2765 1.03224C1.72739 1.31045 1.26605 1.73529 0.943606 2.25964C0.621162 2.784 0.450214 3.38741 0.449711 4.00297V13.333C0.448753 13.9491 0.618807 14.5535 0.940944 15.0787C1.26308 15.604 1.72466 16.0296 2.27431 16.308C2.82395 16.5865 3.4401 16.707 4.05416 16.6561C4.66822 16.6052 5.25611 16.3849 5.75238 16.0196L12.111 11.357C12.533 11.0473 12.876 10.6427 13.1125 10.1758C13.349 9.70897 13.4722 9.19298 13.4722 8.66964C13.4722 8.1463 13.349 7.6303 13.1125 7.16343C12.876 6.69657 12.533 6.29194 12.111 5.98231V5.97897Z" fill="black" />
                  </svg>
                </div>

              </div>

            </div>}
        </div>
        <MobileFooter />
      </div>
      <div className={`md:flex justify-center items-center h-screen ${user.theme==="dark" ? "bg-primary" : "bg-gray-300"} hidden`}>
        <div className={`xl:w-[60vw] md:w-[70vw] w-[98vw] h-[82vh] rounded-lg ${user.theme==="dark" ? "bg-card" : "bg-white" } p-4`}>
          <h3 className={`font-gilroy-bold text-md ml-3 ${user.theme==="dark" ? "text-white" : "" }`}>Results</h3>

          {user.isMarketingAdmin && isPwdMatchOpen && <div className="flex flex-col justify-center items-center absolute top-0 left-0 z-30 bg-transparent h-[100vh] w-[100vw]"><div className="border-2 border-[#C7C7C7] shadow-lg mr-20 rounded-lg py-6 px-6 w-[90%] md:w-[60%] xl:w-[30%] flex flex-col justify-between gap-4 bg-primary text-card">
            <div className="w-full flex justify-between">
              <div></div>
              <img src={user.theme === "light" ? logo : logoDark} className="w-[50%]" />
              <HighlightOffRoundedIcon
                className="cursor-pointer"
                onClick={() => setPwdMatchOpen(false)}
              />
            </div>
            <h3 className="font-semibold text-lg text-center">
              Confirm your password here
            </h3>
            <form onSubmit={handlePassword}>


              <div className="w-full flex px-4 py-1 border rounded-lg outline-none bg-primary text-grayish border-viewMore">
                <input
                  type={showPwd ? "text" : "password"}
                  className="grow outline-none bg-transparent"
                  placeholder="Enter password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password} />
                {showPwd ? <VisibilityOffIcon className="cursor-pointer" onClick={() => setshowPwd(perv => !perv)} /> : <VisibilityIcon className="cursor-pointer" onClick={() => setshowPwd(perv => !perv)} />}

              </div>

              <br />
              <input
                ref={closeRef}
                type="submit"
                value="Submit"
                className="bg-[#008080] rounded-lg px-2 py-1 text-white mt-2 cursor-pointer w-full"
              />
            </form>
            <ToastContainer />
          </div></div>}
          {results?.length > 0 &&
            <div className="flex h-full">

              <div className="md:w-[60%] xl:w-[50%] w-[100%] h-[98%] mr-2 overflow-y-none">
                <div className="h-[35%] flex flex-row justify-center items-center">

                  <div className="h-[90%] mx-1 rounded-lg w-[30%] border border-teal-500 flex flex-col justify-center items-center relative">
                    <div className="reward-icon absolute top-0 left-1/2 transform -translate-x-1/2">
                      <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_867_1802)">
                          <path d="M38.831 0.582275C38.7742 0.245953 38.4835 0 38.1425 0H26.9955C26.8883 0 26.7821 0.0242227 26.6862 0.0717363C26.6545 0.0875742 26.2697 0.279492 25.6912 0.691277L30.1491 8.32421C31.0341 6.6575 32.33 5.15756 34.0247 3.83929C36.2439 2.11296 38.3512 1.36579 38.3717 1.35833C38.6941 1.24654 38.8879 0.917666 38.831 0.582275Z" fill="#3CAF91" />
                          <path d="M30.1493 8.32385C29.5027 9.53778 29.0732 10.8411 28.8655 12.2274L19.6953 12.5908C19.563 12.4436 19.4987 12.2461 19.5202 12.0495C20.0102 7.45742 21.9136 4.45661 23.4228 2.74798C24.2687 1.79025 25.0858 1.12134 25.6914 0.690918C25.9476 0.941529 26.2093 1.21636 26.473 1.51449C27.7354 2.94269 29.2418 5.17025 30.1493 8.32385Z" fill="#218780" />
                          <path d="M28.9802 12.0497C28.4898 7.45825 26.5869 4.45734 25.0769 2.74843C23.4234 0.87714 21.8784 0.103878 21.8134 0.0719227C21.7174 0.0245953 21.6117 0 21.5047 0H10.3574C10.0166 0 9.72585 0.246046 9.66883 0.582089C9.61181 0.918039 9.80597 1.24635 10.1278 1.3587C10.1488 1.36606 12.2559 2.11287 14.4754 3.83901C17.4047 6.11706 19.1406 8.93956 19.6351 12.2279L28.8051 12.591C28.9376 12.4436 29.0012 12.2469 28.9802 12.0497Z" fill="#3CAF91" />
                          <path d="M26.9667 12.5175L26.3417 12.2051L25.7182 11.8896C25.6872 11.951 24.962 13.413 25.2569 15.3161L26.6379 15.1021C26.4143 13.6592 26.9612 12.5288 26.9667 12.5175Z" fill="#A9BBE0" />
                          <path d="M21.5334 12.5174C21.539 12.5287 22.0859 13.6591 21.8623 15.1021L23.2433 15.3161C23.5382 13.413 22.813 11.951 22.7819 11.8896L21.5334 12.5174Z" fill="#A9BBE0" />
                          <path d="M36.0204 19.2197V21.6615L36.0195 40.367V42.8079C33.0047 45.8283 28.8403 47.6999 24.2501 47.6999C23.9063 47.6999 23.5653 47.6897 23.2271 47.6692C19.0422 47.413 15.2691 45.6019 12.4797 42.807V19.2197C15.2691 16.4257 19.0422 14.6146 23.2271 14.3584C23.5653 14.3379 23.9063 14.3276 24.2501 14.3276C28.8412 14.3276 33.0056 16.1993 36.0204 19.2197Z" fill="#E5EAF9" />
                          <path d="M36.0194 40.3672V42.8081C33.0046 45.8285 28.8402 47.7001 24.25 47.7001C23.9062 47.7001 23.5652 47.6899 23.2271 47.6694C28.5504 47.3461 33.2003 44.527 36.0194 40.3672Z" fill="#CBD6EF" />
                          <path d="M36.0203 19.2197V21.6615C33.2003 17.5008 28.5504 14.6817 23.2271 14.3584C23.5652 14.3379 23.9062 14.3276 24.25 14.3276C28.8411 14.3276 33.0056 16.1993 36.0203 19.2197Z" fill="#CBD6EF" />
                          <path d="M40.9042 31.0134C40.9042 35.6147 39.0363 39.7866 36.0196 42.8079L34.9688 41.7552L33.0803 39.8621V22.1656L34.9697 20.2725L36.0206 19.2197C39.0363 22.242 40.9042 26.4129 40.9042 31.0134Z" fill="#CBD6EF" />
                          <path d="M15.4199 22.1656V39.8621L12.4796 42.807C9.46389 39.7857 7.59595 35.6138 7.59595 31.0134C7.59595 26.4129 9.46389 22.242 12.4796 19.2197L15.4199 22.1656Z" fill="#CBD6EF" />
                          <path d="M40.904 31.0134C40.904 35.6147 39.036 39.7866 36.0194 42.8079L34.9685 41.7552C37.4141 38.8531 38.8861 35.1061 38.8861 31.0143C38.8861 26.9225 37.4141 23.1745 34.9694 20.2725L36.0203 19.2197C39.036 22.242 40.904 26.4129 40.904 31.0134Z" fill="#A9BBE0" />
                          <path d="M37.168 31.0131C37.168 38.1616 31.3844 43.9573 24.2499 43.9573C23.9052 43.9573 23.5633 43.9433 23.2251 43.9163C16.5695 43.3937 11.3318 37.8159 11.3318 31.0131C11.3318 24.2103 16.5695 18.6335 23.2251 18.1108C23.5633 18.0838 23.9052 18.0698 24.2499 18.0698C31.3844 18.0698 37.168 23.8646 37.168 31.0131Z" fill="#A9BBE0" />
                          <path d="M37.168 31.0131C37.168 38.1616 31.3844 43.9573 24.2499 43.9573C23.9052 43.9573 23.5633 43.9433 23.2251 43.9163C29.8807 43.3937 35.1184 37.8159 35.1184 31.0131C35.1184 24.2103 29.8807 18.6335 23.2251 18.1108C23.5633 18.0838 23.9052 18.0698 24.2499 18.0698C31.3844 18.0698 37.168 23.8646 37.168 31.0131Z" fill="#8794C6" />
                          <path d="M32.8147 27.9487C33.2006 27.9487 33.5134 27.6359 33.5134 27.25C33.5134 26.8641 33.2006 26.5513 32.8147 26.5513C32.4288 26.5513 32.116 26.8641 32.116 27.25C32.116 27.6359 32.4288 27.9487 32.8147 27.9487Z" fill="#CBD6EF" />
                          <path d="M15.0332 31.7212C15.4191 31.7212 15.7319 31.4084 15.7319 31.0225C15.7319 30.6366 15.4191 30.3237 15.0332 30.3237C14.6473 30.3237 14.3345 30.6366 14.3345 31.0225C14.3345 31.4084 14.6473 31.7212 15.0332 31.7212Z" fill="#CBD6EF" />
                          <path d="M15.6853 35.4946C16.0712 35.4946 16.384 35.1818 16.384 34.7959C16.384 34.41 16.0712 34.0972 15.6853 34.0972C15.2994 34.0972 14.9866 34.41 14.9866 34.7959C14.9866 35.1818 15.2994 35.4946 15.6853 35.4946Z" fill="#CBD6EF" />
                          <path d="M15.6853 27.9487C16.0712 27.9487 16.384 27.6359 16.384 27.25C16.384 26.8641 16.0712 26.5513 15.6853 26.5513C15.2994 26.5513 14.9866 26.8641 14.9866 27.25C14.9866 27.6359 15.2994 27.9487 15.6853 27.9487Z" fill="#FFCB78" />
                          <path d="M32.8147 35.4946C33.2006 35.4946 33.5134 35.1818 33.5134 34.7959C33.5134 34.41 33.2006 34.0972 32.8147 34.0972C32.4288 34.0972 32.116 34.41 32.116 34.7959C32.116 35.1818 32.4288 35.4946 32.8147 35.4946Z" fill="#CBD6EF" />
                          <path d="M33.4668 31.7212C33.8527 31.7212 34.1655 31.4084 34.1655 31.0225C34.1655 30.6366 33.8527 30.3237 33.4668 30.3237C33.0809 30.3237 32.7681 30.6366 32.7681 31.0225C32.7681 31.4084 33.0809 31.7212 33.4668 31.7212Z" fill="#CBD6EF" />
                          <path d="M15.6853 27.9487C16.0712 27.9487 16.384 27.6359 16.384 27.25C16.384 26.8641 16.0712 26.5513 15.6853 26.5513C15.2994 26.5513 14.9866 26.8641 14.9866 27.25C14.9866 27.6359 15.2994 27.9487 15.6853 27.9487Z" fill="#CBD6EF" />
                          <path d="M32.1736 11.997C32.0211 11.9737 28.3913 11.4248 24.2501 11.4248C20.1089 11.4248 16.4791 11.9737 16.3266 11.997C15.9452 12.0554 15.6834 12.4121 15.7418 12.7935C15.8001 13.1749 16.1566 13.4368 16.5382 13.3784C16.5745 13.3728 20.2196 12.8223 24.2501 12.8223C28.2806 12.8223 31.9257 13.3729 31.962 13.3784C31.9979 13.3839 32.0335 13.3865 32.0686 13.3865C32.4078 13.3865 32.7056 13.139 32.7585 12.7934C32.8168 12.4121 32.555 12.0555 32.1736 11.997Z" fill="#E5EAF9" />
                          <path d="M27.4277 35.093C26.8723 35.2063 26.3123 35.2756 25.7678 35.313C26.4478 34.7432 27.0406 34.1038 27.5358 33.4021C28.7774 31.6425 29.3286 29.5482 29.0482 27.656C28.8594 26.3818 28.3153 25.2824 27.4747 24.4768C26.5589 23.5991 25.3591 23.1353 24.005 23.1353C22.1605 23.1353 21.0965 23.9285 20.5288 24.594C19.0508 26.3265 19.4039 28.8572 19.449 29.14C19.5705 29.9021 20.2871 30.4251 21.0497 30.3036C21.8119 30.182 22.3317 29.4692 22.2101 28.707C22.1515 28.3124 22.1084 27.0419 22.6595 26.403C22.7604 26.2861 23.0672 25.9303 24.005 25.9303C25.7759 25.9303 26.1884 27.4238 26.2837 28.0659C26.5789 30.0584 25.2613 33.5551 20.4211 35.0218C19.8153 35.2054 19.4084 35.7734 19.4297 36.4061C19.451 37.0389 19.895 37.5783 20.5119 37.7207C20.6178 37.7452 22.3859 38.145 24.6783 38.1449C25.7095 38.1449 26.8471 38.064 27.9864 37.8316C28.7426 37.6774 29.2306 36.9393 29.0764 36.1831C28.9222 35.4269 28.1841 34.9384 27.4277 35.093Z" fill="#E5EAF9" />
                        </g>
                        <defs>
                          <clipPath id="clip0_867_1802">
                            <rect width="47.7" height="47.7" fill="white" transform="translate(0.399902)" />
                          </clipPath>
                        </defs>
                      </svg>

                    </div>



                    <div className="user-details flex flex-col items-center px-2">
                      <img src={results[1].submission.user?.profilePicture} alt="" className="mx-auto mt-8" style={{ width: '40px', height: '40px' }} />
                      <div className="text-center">
                        <p className={`text-sm ${user.theme==="dark" ? "text-white" : "" }`}>{results[1].submission.user.firstName} {results[1].submission.user.lastName}</p>
                        <p className="text-xs  text-[#707C97]">@{results[1].submission.user.username}</p>
                      </div>
                    </div>

                    <div className="play-icon absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2" onClick={() => setMedia(results[0].submission.mediaUrl)}>
                      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.766016" y="1.36563" width="33.968" height="33.968" rx="16.984" fill={`${user.theme==="dark" ? "#0D0D0D" : "white"}`} stroke="#008080" strokeWidth="1.325" />
                        <g clipPath="url(#clip0_867_1851)">
                          <path d="M22.0206 16.3222L17.2241 12.8051C16.8494 12.5307 16.4061 12.3655 15.9433 12.3277C15.4806 12.2899 15.0163 12.3809 14.6021 12.5908C14.1879 12.8007 13.8399 13.1211 13.5967 13.5167C13.3535 13.9122 13.2245 14.3674 13.2241 14.8317V21.8695C13.2234 22.3343 13.3517 22.7902 13.5947 23.1864C13.8377 23.5826 14.1859 23.9036 14.6005 24.1137C15.0151 24.3238 15.4798 24.4147 15.943 24.3763C16.4062 24.3378 16.8497 24.1716 17.2241 23.8962L22.0206 20.379C22.3388 20.1454 22.5976 19.8402 22.776 19.488C22.9544 19.1359 23.0473 18.7466 23.0473 18.3519C23.0473 17.9571 22.9544 17.5679 22.776 17.2157C22.5976 16.8635 22.3388 16.5583 22.0206 16.3248V16.3222Z" fill="#008080" />
                        </g>
                        <defs>
                          <clipPath id="clip0_867_1851">
                            <rect width="12.0692" height="12.0692" fill="white" transform="translate(11.7153 12.3149)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                  </div>




                  <div className="h-[90%] mx-1 rounded-lg w-[30%] border border-teal-500 flex flex-col justify-center items-center relative">
                    <div className="reward-icon absolute top-0 left-1/2 transform -translate-x-1/2">
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_867_1737)">
                          <path d="M38.581 0.582275C38.5242 0.245953 38.2335 0 37.8925 0H26.7455C26.6383 0 26.5321 0.0242227 26.4362 0.0717363C26.4045 0.0875742 26.0197 0.279492 25.4412 0.691277L29.8991 8.32421C30.7841 6.6575 32.08 5.15756 33.7747 3.83929C35.9939 2.11296 38.1012 1.36579 38.1217 1.35833C38.4441 1.24654 38.6379 0.917666 38.581 0.582275Z" fill="#78C2EF" />
                          <path d="M29.8993 8.32385C29.2527 9.53778 28.8232 10.8411 28.6155 12.2274L19.4453 12.5908C19.313 12.4436 19.2487 12.2461 19.2702 12.0495C19.7602 7.45742 21.6636 4.45661 23.1728 2.74798C24.0187 1.79025 24.8358 1.12134 25.4414 0.690918C25.6976 0.941529 25.9593 1.21636 26.223 1.51449C27.4854 2.94269 28.9918 5.17025 29.8993 8.32385Z" fill="#74A1E2" />
                          <path d="M28.7302 12.0497C28.2398 7.45825 26.3369 4.45734 24.8269 2.74843C23.1734 0.87714 21.6284 0.103878 21.5634 0.0719227C21.4674 0.0245953 21.3617 0 21.2547 0H10.1074C9.76661 0 9.47585 0.246046 9.41883 0.582089C9.36181 0.918039 9.55597 1.24635 9.87776 1.3587C9.89881 1.36606 12.0059 2.11287 14.2254 3.83901C17.1547 6.11706 18.8906 8.93956 19.3851 12.2279L28.5551 12.591C28.6876 12.4436 28.7512 12.2469 28.7302 12.0497Z" fill="#78C2EF" />
                          <path d="M26.7167 12.5175L26.0917 12.2051L25.4682 11.8896C25.4372 11.951 24.712 13.413 25.0069 15.3161L26.3879 15.1021C26.1643 13.6592 26.7112 12.5288 26.7167 12.5175Z" fill="#FFA757" />
                          <path d="M21.2834 12.5174C21.289 12.5287 21.8359 13.6591 21.6123 15.1021L22.9933 15.3161C23.2882 13.413 22.563 11.951 22.5319 11.8896L21.2834 12.5174Z" fill="#FFA757" />
                          <path d="M35.7704 19.2197V21.6615L35.7695 40.367V42.8079C32.7547 45.8283 28.5903 47.6999 24.0001 47.6999C23.6563 47.6999 23.3153 47.6897 22.9771 47.6692C18.7922 47.413 15.0191 45.6019 12.2297 42.807V19.2197C15.0191 16.4257 18.7922 14.6146 22.9771 14.3584C23.3153 14.3379 23.6563 14.3276 24.0001 14.3276C28.5912 14.3276 32.7556 16.1993 35.7704 19.2197Z" fill="#FFE0A9" />
                          <path d="M35.7694 40.3672V42.8081C32.7546 45.8285 28.5902 47.7001 24 47.7001C23.6562 47.7001 23.3152 47.6899 22.9771 47.6694C28.3004 47.3461 32.9503 44.527 35.7694 40.3672Z" fill="#FFCB78" />
                          <path d="M35.7703 19.2197V21.6615C32.9503 17.5008 28.3004 14.6817 22.9771 14.3584C23.3152 14.3379 23.6562 14.3276 24 14.3276C28.5911 14.3276 32.7555 16.1993 35.7703 19.2197Z" fill="#FFCB78" />
                          <path d="M40.6542 31.0134C40.6542 35.6147 38.7863 39.7866 35.7696 42.8079L34.7188 41.7552L32.8303 39.8621V22.1656L34.7197 20.2725L35.7706 19.2197C38.7863 22.242 40.6542 26.4129 40.6542 31.0134Z" fill="#FFCB78" />
                          <path d="M15.1699 22.1656V39.8621L12.2296 42.807C9.21389 39.7857 7.34595 35.6138 7.34595 31.0134C7.34595 26.4129 9.21389 22.242 12.2296 19.2197L15.1699 22.1656Z" fill="#FFCB78" />
                          <path d="M40.654 31.0134C40.654 35.6147 38.786 39.7866 35.7694 42.8079L34.7185 41.7552C37.1641 38.8531 38.6361 35.1061 38.6361 31.0143C38.6361 26.9225 37.1641 23.1745 34.7194 20.2725L35.7703 19.2197C38.786 22.242 40.654 26.4129 40.654 31.0134Z" fill="#FFA757" />
                          <path d="M36.918 31.0131C36.918 38.1616 31.1344 43.9573 23.9999 43.9573C23.6552 43.9573 23.3133 43.9433 22.9751 43.9163C16.3195 43.3937 11.0818 37.8159 11.0818 31.0131C11.0818 24.2103 16.3195 18.6335 22.9751 18.1108C23.3133 18.0838 23.6552 18.0698 23.9999 18.0698C31.1344 18.0698 36.918 23.8646 36.918 31.0131Z" fill="#FFA757" />
                          <path d="M36.918 31.0131C36.918 38.1616 31.1344 43.9573 23.9999 43.9573C23.6552 43.9573 23.3133 43.9433 22.9751 43.9163C29.6307 43.3937 34.8684 37.8159 34.8684 31.0131C34.8684 24.2103 29.6307 18.6335 22.9751 18.1108C23.3133 18.0838 23.6552 18.0698 23.9999 18.0698C31.1344 18.0698 36.918 23.8646 36.918 31.0131Z" fill="#FF895A" />
                          <path d="M28.3059 36.0225C28.2502 36.015 27.2451 35.8807 25.8984 35.8132C26.149 29.6552 25.8328 23.8538 25.8293 23.7915C25.7946 23.1704 25.3552 22.6467 24.7505 22.5057C24.1458 22.3649 23.5208 22.6406 23.2163 23.1826C21.8765 25.5671 19.7733 26.4975 19.7172 26.5218C19.0077 26.8174 18.669 27.6321 18.9606 28.3454C19.2531 29.0612 20.0694 29.4037 20.7833 29.1106C20.8566 29.0805 21.924 28.6322 23.1651 27.5734C23.2129 29.7797 23.2291 32.754 23.1014 35.8068C21.689 35.8718 20.6196 36.0147 20.562 36.0224C19.7974 36.1259 19.2615 36.8308 19.3644 37.597C19.4674 38.3633 20.1706 38.9003 20.9356 38.7979C20.9521 38.7956 22.6062 38.5759 24.4293 38.5755H24.4353H24.4414C26.2634 38.5761 27.9159 38.7956 27.9318 38.7978C27.9953 38.8064 28.0582 38.8105 28.1206 38.8105C28.8093 38.8105 29.409 38.3003 29.5037 37.5976C29.6071 36.8312 29.0707 36.126 28.3059 36.0225Z" fill="#FFE0A9" />
                          <path d="M32.5647 27.9487C32.9506 27.9487 33.2634 27.6359 33.2634 27.25C33.2634 26.8641 32.9506 26.5513 32.5647 26.5513C32.1788 26.5513 31.866 26.8641 31.866 27.25C31.866 27.6359 32.1788 27.9487 32.5647 27.9487Z" fill="#FFCB78" />
                          <path d="M14.7832 31.7212C15.1691 31.7212 15.4819 31.4084 15.4819 31.0225C15.4819 30.6366 15.1691 30.3237 14.7832 30.3237C14.3973 30.3237 14.0845 30.6366 14.0845 31.0225C14.0845 31.4084 14.3973 31.7212 14.7832 31.7212Z" fill="#FFCB78" />
                          <path d="M15.4353 35.4946C15.8212 35.4946 16.134 35.1818 16.134 34.7959C16.134 34.41 15.8212 34.0972 15.4353 34.0972C15.0494 34.0972 14.7366 34.41 14.7366 34.7959C14.7366 35.1818 15.0494 35.4946 15.4353 35.4946Z" fill="#FFCB78" />
                          <path d="M15.4353 27.9487C15.8212 27.9487 16.134 27.6359 16.134 27.25C16.134 26.8641 15.8212 26.5513 15.4353 26.5513C15.0494 26.5513 14.7366 26.8641 14.7366 27.25C14.7366 27.6359 15.0494 27.9487 15.4353 27.9487Z" fill="#FFCB78" />
                          <path d="M32.5647 35.4946C32.9506 35.4946 33.2634 35.1818 33.2634 34.7959C33.2634 34.41 32.9506 34.0972 32.5647 34.0972C32.1788 34.0972 31.866 34.41 31.866 34.7959C31.866 35.1818 32.1788 35.4946 32.5647 35.4946Z" fill="#FFCB78" />
                          <path d="M33.2168 31.7212C33.6027 31.7212 33.9155 31.4084 33.9155 31.0225C33.9155 30.6366 33.6027 30.3237 33.2168 30.3237C32.8309 30.3237 32.5181 30.6366 32.5181 31.0225C32.5181 31.4084 32.8309 31.7212 33.2168 31.7212Z" fill="#FFCB78" />
                          <path d="M14.7832 31.7212C15.1691 31.7212 15.4819 31.4084 15.4819 31.0225C15.4819 30.6366 15.1691 30.3237 14.7832 30.3237C14.3973 30.3237 14.0845 30.6366 14.0845 31.0225C14.0845 31.4084 14.3973 31.7212 14.7832 31.7212Z" fill="#FFCB78" />
                          <path d="M15.4353 27.9487C15.8212 27.9487 16.134 27.6359 16.134 27.25C16.134 26.8641 15.8212 26.5513 15.4353 26.5513C15.0494 26.5513 14.7366 26.8641 14.7366 27.25C14.7366 27.6359 15.0494 27.9487 15.4353 27.9487Z" fill="#FFCB78" />
                          <path d="M15.4353 35.4946C15.8212 35.4946 16.134 35.1818 16.134 34.7959C16.134 34.41 15.8212 34.0972 15.4353 34.0972C15.0494 34.0972 14.7366 34.41 14.7366 34.7959C14.7366 35.1818 15.0494 35.4946 15.4353 35.4946Z" fill="#FFCB78" />
                          <path d="M33.2168 31.7212C33.6027 31.7212 33.9155 31.4084 33.9155 31.0225C33.9155 30.6366 33.6027 30.3237 33.2168 30.3237C32.8309 30.3237 32.5181 30.6366 32.5181 31.0225C32.5181 31.4084 32.8309 31.7212 33.2168 31.7212Z" fill="#FFCB78" />
                          <path d="M32.5647 27.9487C32.9506 27.9487 33.2634 27.6359 33.2634 27.25C33.2634 26.8641 32.9506 26.5513 32.5647 26.5513C32.1788 26.5513 31.866 26.8641 31.866 27.25C31.866 27.6359 32.1788 27.9487 32.5647 27.9487Z" fill="#FFCB78" />
                          <path d="M32.5647 35.4946C32.9506 35.4946 33.2634 35.1818 33.2634 34.7959C33.2634 34.41 32.9506 34.0972 32.5647 34.0972C32.1788 34.0972 31.866 34.41 31.866 34.7959C31.866 35.1818 32.1788 35.4946 32.5647 35.4946Z" fill="#FFCB78" />
                          <path d="M31.9236 11.997C31.7711 11.9737 28.1413 11.4248 24.0001 11.4248C19.8589 11.4248 16.2291 11.9737 16.0766 11.997C15.6952 12.0554 15.4334 12.4121 15.4918 12.7935C15.5501 13.1749 15.9066 13.4368 16.2882 13.3784C16.3245 13.3728 19.9696 12.8223 24.0001 12.8223C28.0306 12.8223 31.6757 13.3729 31.712 13.3784C31.7479 13.3839 31.7835 13.3865 31.8186 13.3865C32.1578 13.3865 32.4556 13.139 32.5085 12.7934C32.5668 12.4121 32.305 12.0555 31.9236 11.997Z" fill="#FFE0A9" />
                        </g>
                        <defs>
                          <clipPath id="clip0_867_1737">
                            <rect width="47.7" height="47.7" fill="white" transform="translate(0.149902)" />
                          </clipPath>
                        </defs>
                      </svg>


                    </div>

                    <div className="user-details flex flex-col items-center px-2">
                      <img src={results[0].submission.user?.profilePicture} alt="" className="mx-auto  mt-8" style={{ width: '40px', height: '40px' }} />
                      <div className="text-center">
                        <p className={`text-sm ${user.theme==="dark" ? "text-white" : "" }`} >{results[0].submission.user.firstName} {results[0].submission.user.lastName}</p>
                        <p className="text-xs  text-[#707C97]">@{results[0].submission.user.username}</p>
                      </div>
                    </div>
                    <div className="play-icon absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2" onClick={() => setMedia(results[1].submission.mediaUrl)}>
                      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.766016" y="1.36563" width="33.968" height="33.968" rx="16.984" fill={`${user.theme==="dark" ? "#0D0D0D" : "white"}`} stroke="#008080" strokeWidth="1.325" />
                        <g clipPath="url(#clip0_867_1851)">
                          <path d="M22.0206 16.3222L17.2241 12.8051C16.8494 12.5307 16.4061 12.3655 15.9433 12.3277C15.4806 12.2899 15.0163 12.3809 14.6021 12.5908C14.1879 12.8007 13.8399 13.1211 13.5967 13.5167C13.3535 13.9122 13.2245 14.3674 13.2241 14.8317V21.8695C13.2234 22.3343 13.3517 22.7902 13.5947 23.1864C13.8377 23.5826 14.1859 23.9036 14.6005 24.1137C15.0151 24.3238 15.4798 24.4147 15.943 24.3763C16.4062 24.3378 16.8497 24.1716 17.2241 23.8962L22.0206 20.379C22.3388 20.1454 22.5976 19.8402 22.776 19.488C22.9544 19.1359 23.0473 18.7466 23.0473 18.3519C23.0473 17.9571 22.9544 17.5679 22.776 17.2157C22.5976 16.8635 22.3388 16.5583 22.0206 16.3248V16.3222Z" fill="#008080" />
                        </g>
                        <defs>
                          <clipPath id="clip0_867_1851">
                            <rect width="12.0692" height="12.0692" fill="white" transform="translate(11.7153 12.3149)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                  </div>
                  <div className="h-[90%] mx-1 rounded-lg w-[30%] border border-teal-500 flex flex-col justify-center items-center relative">
                    <div className="reward-icon absolute top-0 left-1/2 transform -translate-x-1/2">
                      <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_867_1771)">
                          <path d="M39.331 0.582275C39.2742 0.245953 38.9835 0 38.6425 0H27.4955C27.3883 0 27.2821 0.0242227 27.1862 0.0717363C27.1545 0.0875742 26.7697 0.279492 26.1912 0.691277L30.6491 8.32421C31.5341 6.6575 32.83 5.15756 34.5247 3.83929C36.7439 2.11296 38.8512 1.36579 38.8717 1.35833C39.1941 1.24654 39.3879 0.917666 39.331 0.582275Z" fill="#B67DFF" />
                          <path d="M30.6493 8.32385C30.0027 9.53778 29.5732 10.8411 29.3655 12.2274L20.1953 12.5908C20.063 12.4436 19.9987 12.2461 20.0202 12.0495C20.5102 7.45742 22.4136 4.45661 23.9228 2.74798C24.7687 1.79025 25.5858 1.12134 26.1914 0.690918C26.4476 0.941529 26.7093 1.21636 26.973 1.51449C28.2354 2.94269 29.7418 5.17025 30.6493 8.32385Z" fill="#7D5EDD" />
                          <path d="M29.4802 12.0497C28.9898 7.45825 27.0869 4.45734 25.5769 2.74843C23.9234 0.87714 22.3784 0.103878 22.3134 0.0719227C22.2174 0.0245953 22.1117 0 22.0047 0H10.8574C10.5166 0 10.2258 0.246046 10.1688 0.582089C10.1118 0.918039 10.306 1.24635 10.6278 1.3587C10.6488 1.36606 12.7559 2.11287 14.9754 3.83901C17.9047 6.11706 19.6406 8.93956 20.1351 12.2279L29.3051 12.591C29.4376 12.4436 29.5012 12.2469 29.4802 12.0497Z" fill="#B67DFF" />
                          <path d="M27.4667 12.5175L26.8417 12.2051L26.2182 11.8896C26.1872 11.951 25.462 13.413 25.7569 15.3161L27.1379 15.1021C26.9143 13.6592 27.4612 12.5288 27.4667 12.5175Z" fill="#DB5E52" />
                          <path d="M22.0334 12.5174C22.039 12.5287 22.5859 13.6591 22.3623 15.1021L23.7433 15.3161C24.0382 13.413 23.313 11.951 23.2819 11.8896L22.0334 12.5174Z" fill="#DB5E52" />
                          <path d="M36.5204 19.2197V21.6615L36.5195 40.367V42.8079C33.5047 45.8283 29.3403 47.6999 24.7501 47.6999C24.4063 47.6999 24.0653 47.6897 23.7271 47.6692C19.5422 47.413 15.7691 45.6019 12.9797 42.807V19.2197C15.7691 16.4257 19.5422 14.6146 23.7271 14.3584C24.0653 14.3379 24.4063 14.3276 24.7501 14.3276C29.3412 14.3276 33.5056 16.1993 36.5204 19.2197Z" fill="#FFB08D" />
                          <path d="M36.5194 40.3672V42.8081C33.5046 45.8285 29.3402 47.7001 24.75 47.7001C24.4062 47.7001 24.0652 47.6899 23.7271 47.6694C29.0504 47.3461 33.7003 44.527 36.5194 40.3672Z" fill="#FF8C69" />
                          <path d="M36.5203 19.2197V21.6615C33.7003 17.5008 29.0504 14.6817 23.7271 14.3584C24.0652 14.3379 24.4062 14.3276 24.75 14.3276C29.3411 14.3276 33.5056 16.1993 36.5203 19.2197Z" fill="#FF8C69" />
                          <path d="M41.4042 31.0134C41.4042 35.6147 39.5363 39.7866 36.5196 42.8079L35.4688 41.7552L33.5803 39.8621V22.1656L35.4697 20.2725L36.5206 19.2197C39.5363 22.242 41.4042 26.4129 41.4042 31.0134Z" fill="#FF8C69" />
                          <path d="M15.9199 22.1656V39.8621L12.9796 42.807C9.96389 39.7857 8.09595 35.6138 8.09595 31.0134C8.09595 26.4129 9.96389 22.242 12.9796 19.2197L15.9199 22.1656Z" fill="#FF8C69" />
                          <path d="M41.404 31.0134C41.404 35.6147 39.536 39.7866 36.5194 42.8079L35.4685 41.7552C37.9141 38.8531 39.3861 35.1061 39.3861 31.0143C39.3861 26.9225 37.9141 23.1745 35.4694 20.2725L36.5203 19.2197C39.536 22.242 41.404 26.4129 41.404 31.0134Z" fill="#DB5E52" />
                          <path d="M37.668 31.0131C37.668 38.1616 31.8844 43.9573 24.7499 43.9573C24.4052 43.9573 24.0633 43.9433 23.7251 43.9163C17.0695 43.3937 11.8318 37.8159 11.8318 31.0131C11.8318 24.2103 17.0695 18.6335 23.7251 18.1108C24.0633 18.0838 24.4052 18.0698 24.7499 18.0698C31.8844 18.0698 37.668 23.8646 37.668 31.0131Z" fill="#DB5E52" />
                          <path d="M37.668 31.0131C37.668 38.1616 31.8844 43.9573 24.7499 43.9573C24.4052 43.9573 24.0633 43.9433 23.7251 43.9163C30.3807 43.3937 35.6184 37.8159 35.6184 31.0131C35.6184 24.2103 30.3807 18.6335 23.7251 18.1108C24.0633 18.0838 24.4052 18.0698 24.7499 18.0698C31.8844 18.0698 37.668 23.8646 37.668 31.0131Z" fill="#C43D4E" />
                          <path d="M33.3147 27.9487C33.7006 27.9487 34.0134 27.6359 34.0134 27.25C34.0134 26.8641 33.7006 26.5513 33.3147 26.5513C32.9288 26.5513 32.616 26.8641 32.616 27.25C32.616 27.6359 32.9288 27.9487 33.3147 27.9487Z" fill="#FF8C69" />
                          <path d="M15.5332 31.7212C15.9191 31.7212 16.2319 31.4084 16.2319 31.0225C16.2319 30.6366 15.9191 30.3237 15.5332 30.3237C15.1473 30.3237 14.8345 30.6366 14.8345 31.0225C14.8345 31.4084 15.1473 31.7212 15.5332 31.7212Z" fill="#FF8C69" />
                          <path d="M16.1853 35.4946C16.5712 35.4946 16.884 35.1818 16.884 34.7959C16.884 34.41 16.5712 34.0972 16.1853 34.0972C15.7994 34.0972 15.4866 34.41 15.4866 34.7959C15.4866 35.1818 15.7994 35.4946 16.1853 35.4946Z" fill="#FF8C69" />
                          <path d="M16.1853 27.9487C16.5712 27.9487 16.884 27.6359 16.884 27.25C16.884 26.8641 16.5712 26.5513 16.1853 26.5513C15.7994 26.5513 15.4866 26.8641 15.4866 27.25C15.4866 27.6359 15.7994 27.9487 16.1853 27.9487Z" fill="#FFCB78" />
                          <path d="M33.3147 35.4946C33.7006 35.4946 34.0134 35.1818 34.0134 34.7959C34.0134 34.41 33.7006 34.0972 33.3147 34.0972C32.9288 34.0972 32.616 34.41 32.616 34.7959C32.616 35.1818 32.9288 35.4946 33.3147 35.4946Z" fill="#FF8C69" />
                          <path d="M33.9668 31.7212C34.3527 31.7212 34.6655 31.4084 34.6655 31.0225C34.6655 30.6366 34.3527 30.3237 33.9668 30.3237C33.5809 30.3237 33.2681 30.6366 33.2681 31.0225C33.2681 31.4084 33.5809 31.7212 33.9668 31.7212Z" fill="#FF8C69" />
                          <path d="M16.1853 27.9487C16.5712 27.9487 16.884 27.6359 16.884 27.25C16.884 26.8641 16.5712 26.5513 16.1853 26.5513C15.7994 26.5513 15.4866 26.8641 15.4866 27.25C15.4866 27.6359 15.7994 27.9487 16.1853 27.9487Z" fill="#FF8C69" />
                          <path d="M32.6736 11.997C32.5211 11.9737 28.8913 11.4248 24.7501 11.4248C20.6089 11.4248 16.9791 11.9737 16.8266 11.997C16.4452 12.0554 16.1834 12.4121 16.2418 12.7935C16.3001 13.1749 16.6566 13.4368 17.0382 13.3784C17.0745 13.3728 20.7196 12.8223 24.7501 12.8223C28.7806 12.8223 32.4257 13.3729 32.462 13.3784C32.4979 13.3839 32.5335 13.3865 32.5686 13.3865C32.9078 13.3865 33.2056 13.139 33.2585 12.7934C33.3168 12.4121 33.055 12.0555 32.6736 11.997Z" fill="#FFB08D" />
                          <path d="M28.1126 31.0133C29.1538 30.1408 29.7114 28.9128 29.7114 27.4381C29.7114 24.9467 28.0736 22.9784 25.6359 22.5404C23.0341 22.0727 20.5895 23.4373 19.6906 25.8583C19.4219 26.5818 19.7906 27.3861 20.5141 27.6548C21.2373 27.9235 22.0419 27.5547 22.3106 26.8312C22.8006 25.5118 24.1212 25.1081 25.1417 25.2913C25.6753 25.3872 26.9166 25.7883 26.9166 27.4381C26.9166 28.3205 26.536 28.8801 25.6825 29.2523C24.8786 29.603 24.0139 29.6159 24.0123 29.616H24.01C23.2393 29.6172 22.6114 30.2424 22.6114 31.0134C22.6114 31.7849 23.233 32.4103 24.0044 32.4109H24.0059C24.032 32.4113 24.8871 32.4275 25.6826 32.7745C26.5361 33.1468 26.9167 33.7063 26.9167 34.5888C26.9167 36.2385 25.6755 36.6396 25.1418 36.7355C24.1213 36.9189 22.8008 36.5149 22.3107 35.1956C22.042 34.472 21.2376 34.1033 20.5141 34.3721C19.7906 34.6408 19.4219 35.4451 19.6907 36.1687C20.4717 38.2718 22.4194 39.5776 24.623 39.5775C24.9558 39.5775 25.2947 39.5477 25.636 39.4864C28.0737 39.0484 29.7115 37.0802 29.7115 34.5888C29.7114 33.114 29.1538 31.886 28.1126 31.0133Z" fill="#FFB08D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_867_1771">
                            <rect width="47.7" height="47.7" fill="white" transform="translate(0.899902)" />
                          </clipPath>
                        </defs>
                      </svg>


                    </div>

                    <div className="user-details flex flex-col items-center px-2">
                      <img src={results[2].submission.user?.profilePicture} alt="" className="mx-auto  mt-8" style={{ width: '40px', height: '40px' }} />
                      <div className="text-center">
                        <p className={`text-sm ${user.theme==="dark" ? "text-white" : "" }` }>{results[2].submission.user.firstName} {results[2].submission.user.lastName}</p>
                        <p className="text-xs  text-[#707C97]">@{results[2].submission.user.username}</p>
                      </div>
                    </div>
                    <div className="play-icon absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2" onClick={() => setMedia(results[2].submission.mediaUrl)}>
                      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.766016" y="1.36563" width="33.968" height="33.968" rx="16.984" fill={`${user.theme==="dark" ? "#0D0D0D" : "white"}`} stroke="#008080" strokeWidth="1.325" />
                        <g clipPath="url(#clip0_867_1851)">
                          <path d="M22.0206 16.3222L17.2241 12.8051C16.8494 12.5307 16.4061 12.3655 15.9433 12.3277C15.4806 12.2899 15.0163 12.3809 14.6021 12.5908C14.1879 12.8007 13.8399 13.1211 13.5967 13.5167C13.3535 13.9122 13.2245 14.3674 13.2241 14.8317V21.8695C13.2234 22.3343 13.3517 22.7902 13.5947 23.1864C13.8377 23.5826 14.1859 23.9036 14.6005 24.1137C15.0151 24.3238 15.4798 24.4147 15.943 24.3763C16.4062 24.3378 16.8497 24.1716 17.2241 23.8962L22.0206 20.379C22.3388 20.1454 22.5976 19.8402 22.776 19.488C22.9544 19.1359 23.0473 18.7466 23.0473 18.3519C23.0473 17.9571 22.9544 17.5679 22.776 17.2157C22.5976 16.8635 22.3388 16.5583 22.0206 16.3248V16.3222Z" fill="#008080" />
                        </g>
                        <defs>
                          <clipPath id="clip0_867_1851">
                            <rect width="12.0692" height="12.0692" fill="white" transform="translate(11.7153 12.3149)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                  </div>
                </div>


                <div className="h-[62%]  mt-6  flex flex-col items-center">
                  <div className="w-[95%] h-[80%] overflow-y-scroll">
                    {results?.length > 3 && results?.slice(3).map((r, index) => (
                      <div key={index}>
                        <div className="flex flex-row justify-between items-center border-b py-2">
                          <div className="flex flex-row items-center">
                            <div className={`${user.theme==="dark" ? "bg-compBg" : "bg-gray-300"} h-10 w-10 flex justify-center items-center rounded-md`}>
                              <p className={`${user.theme==="dark"? "text-compPrimary":"text-gray-800" } text-[13px]`}>{r.rank}</p>
                            </div>
                            <div className="ml-2">
                              <div className={`${user.theme==="dark" ? "text-white":""} font-gilroy-bold text-[13px]`}>{r.submission?.user?.firstName} {r.submission?.user?.lastName}</div>
                              <div className={`${user.theme==="dark" ? "text-message":""} font-gilroy-light text-[10px]`}>{r.submission?.user?.username}</div>
                            </div>
                          </div>
                          <div onClick={() => setMedia(r.submission?.mediaUrl)}>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.766016" y="1.36563" width="33.968" height="33.968" rx="16.984" fill={`${user.theme==="dark" ? "#0D0D0D" : "white"}`} stroke="#008080" strokeWidth="1.325" />
                              <g clipPath="url(#clip0_867_1851)">
                                <path d="M22.0206 16.3222L17.2241 12.8051C16.8494 12.5307 16.4061 12.3655 15.9433 12.3277C15.4806 12.2899 15.0163 12.3809 14.6021 12.5908C14.1879 12.8007 13.8399 13.1211 13.5967 13.5167C13.3535 13.9122 13.2245 14.3674 13.2241 14.8317V21.8695C13.2234 22.3343 13.3517 22.7902 13.5947 23.1864C13.8377 23.5826 14.1859 23.9036 14.6005 24.1137C15.0151 24.3238 15.4798 24.4147 15.943 24.3763C16.4062 24.3378 16.8497 24.1716 17.2241 23.8962L22.0206 20.379C22.3388 20.1454 22.5976 19.8402 22.776 19.488C22.9544 19.1359 23.0473 18.7466 23.0473 18.3519C23.0473 17.9571 22.9544 17.5679 22.776 17.2157C22.5976 16.8635 22.3388 16.5583 22.0206 16.3248V16.3222Z" fill="#008080" />
                              </g>
                              <defs>
                                <clipPath id="clip0_867_1851">
                                  <rect width="12.0692" height="12.0692" fill="white" transform="translate(11.7153 12.3149)" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                        <hr  className={`${user.theme==="dark" ? " border-[#2B2B2B]" :""}`}/>
                      </div>
                    ))}
                  </div>
                  {user.isMarketingAdmin && <button onClick={() => setPwdMatchOpen(true)} className="bg-teal-600 text-white p-2 rounded-lg">Issue Payout</button>}
                </div>
              </div>
              <div className="w-[40%]  h-[75%] flex flex-row items-center rounded-2xl justify-center">
                <video
                  className="w-[66%] h-[90%] rounded-2xl aspect-auto object-cover"
                  controls src={media}>

                </video>
              </div>
            </div>}
        </div>
      </div>
    </>
  );
};

export default Rewards;
