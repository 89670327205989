 
 

import {
  SET_NOTIFICATIONLOADER,
  SET_CLOSENOTIFICATIONLOADER,
  SET_CHAT_NOTIFICATION,
  RESET_CHAT_NOTIFICATION,
} from "../types/NotificationTypes";

const initState = {
  notificationLoading: false,
  notification: [],
  chatNotification: [],
  postLoading: false,
  loadingType: "",
};

const NotificationReducer = (state = initState, action) => {
  switch (action.type) {

      case SET_NOTIFICATIONLOADER:
      return { ...state, notificationLoading: true };

      case "SET_NOTIFICATIONS":
      console.log(action.payload);
      return {...state, notification: action.payload}

      case SET_CLOSENOTIFICATIONLOADER:
      return { ...state, notificationLoading: false };

      case SET_CHAT_NOTIFICATION:
      state.chatNotification.push(action.payload);
      return state;

      case RESET_CHAT_NOTIFICATION:
      state.chatNotification = state.chatNotification.filter(
        (m) => m.text !== action.payload.text
      );
      return state;

      case "SET_POSTLOADING":
      return { ...state, postLoading: action.payload };

      case "SET_POSTLOADING_TYPE":
      return { ...state, loadingType: action.payload };

      case "UPDATE_NOTIFICATION":
      return { ...state, notification: action.payload };

      case "RESET_NOTIFICATION":                   
      return { ...state, notification: [] };

    default:
      return state;
  }
};

export default NotificationReducer;
