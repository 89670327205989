import React, { useState } from "react";

const EnterPasswordModal = ({
  setAccessPassword,
  accessPassword,
  handleAdminAccess,
  setModal,
  message,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="bg-[rgb(0,0,0,0.5)] z-10 w-screen h-screen absolute p-2 flex justify-center items-center">
      <div className="bg-white w-96 h-56 border border-transparent rounded-lg shadow-lg flex justify-center items-center">
        <div className="w-full p-2 m-2 h-36 space-y-3">
          <h1 className="font-gilroy-medium">Enter Password to {message}</h1>
          <div className="flex items-center flex-row justify-between pr-4 w-full border space-x-2">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password "
              className="w-full p-1 border border-transparent "
              value={accessPassword}
              onChange={(e) => setAccessPassword(e.target.value)}
            />
            <button
              type="button"
              className=" text-gray-500 text-xs"
              onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? "Hide" : "Show"} {/* Toggle button text */}
            </button>
          </div>
          <button
            onClick={() => handleAdminAccess(accessPassword)}
            className="bg-[#008080] p-2 self-center mx-auto w-full transition duration-300 ease-in-out hover:scale-105 hover:cursor-pointer border rounded-lg border-transparent text-white">
            Enter
          </button>
        </div>
        <h1
          className="text-right self-start m-2 cursor-pointer"
          onClick={() => setModal(false)}>
          X
        </h1>
      </div>
    </div>
  );
};

export default EnterPasswordModal;
