import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import deleteIcon from "../Alert/39-trash-solid.gif";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PropTypes from 'prop-types';

const Alert = ({ msg, type }) => {
  return (
    <div>
      <div className="flex flex-row items-center p-2 space-x-2">
        {type === "success" && (
          <div>
            <CheckCircleIcon
              style={{ width: 40, height: 60 }}
              className="text-green-500"
            />{" "}
          </div>
        )}
        {type === "delete" && (
          <div>
            {" "}
            <img src={deleteIcon} alt="df" className="w-20 h-20" />{" "}
          </div>
        )}
        {type === "error" && (
          <div>
            {" "}
            <ErrorOutlineIcon
              style={{ width: 40, height: 60 }}
              className="text-red-700"
            />{" "}
          </div>
        )}
        <div className="text-gray-500">
          {" "}
          <h2
            className={`text-${type === "success" ? "green-500" : "red-700"}`}>
            {msg}
          </h2>{" "}
        </div>
      </div>
    </div>
  );
};

Alert.propTypes = {
  msg: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'delete', 'error']).isRequired,
};

export default Alert;
