/* eslint-disable */

import { Avatar } from "@mui/material";
import axios from "axios";
import React from "react";
import { path } from "../../../../path";
import { useState } from "react";
import parse from "html-react-parser";

function PhotoPost({
  post,
  setShowContent,
  setContent,
  setShowDeleteDialogue,
  setPostIdToDelete,
  removeReportHandler,
}) {
  const { postId } = post;

  const handleReportRemove = async () => {};
  // const openVideo = () => {
  //   window.open(post.postId.video, "_blank");
  // };

  const handleShowContentClick = () => {
    setShowContent(true);
    setContent(Content());
  };

  /**
   * This function returns appropriate component to show which content actually is
   * like for image post, it will return image element
   * for text, it will return simple p element
   * and for video, video element
   * finally if it does not find any content in "post" variable then simply returns "error" text
   */
  const Content = () => {
    if (post?.postId) {
      if (post.postId.image[0])
        return <img src={post.postId.image[0]} alt="Post Image" />;
      if (post.postId.body)
        return (
          <div className="text-black bg-white p-8 h-[100%] overflow-y-scroll">
            {parse(post.postId.body)}
          </div>
        );
      if (post.postId.video)
        return (
          <video controls className="h-[100%]">
            <source src={post.postId.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      else
        return (
          <p className="text-white text-[1.5rem]">Something went wrong!</p>
        );
    } else
      return (
        <p className="text-white font-semibold text-[1.5rem]">
          No content to show!
        </p>
      );
  };

  return (
    <div
      className={`border-0.5 my-6 p-6 rounded-lg w-[50vw] bg-page`}
      style={{
        borderColor: post.report.length > 5 ? "red" : "gray",
        boxShadow:
          "inset 5px 5px 15px white,inset -5px -5px 10px #b3b3b3, 3px 3px 10px #dedede",
      }}>
      <div className="flex justify-between mb-4">
        <div className="flex space-x-2 items-center">
          {postId?.userId?.profilePicture ? (
            <Avatar src={postId?.userId?.profilePicture} />
          ) : (
            <Avatar>{postId?.userName.slice(0, 1).toUpperCase()}</Avatar>
          )}
          <div>
            <h2 className="font-semibold text-[1.1rem]">{postId?.userName}</h2>
            <p className="text-[0.7rem]">
              {"Created on " + postId?.createdAt.slice(0, 10)}
            </p>
          </div>
        </div>
        <div className="flex ml-auto">
          <button
            className="px-8 py-1 text-blue-600"
            onClick={() => removeReportHandler(post._id)}>
            Remove
          </button>
          <button
            className="px-8 py-1 text-white bg-[red] rounded-lg"
            onClick={() => {
              setShowDeleteDialogue(true);
              setPostIdToDelete(post._id);
            }}>
            Delete
          </button>
        </div>
      </div>
      <div className="flex flex-col space-x-4 my-2">
        <div className="overflow-x-scroll">
          <table className="border-collapse border w-full">
            <thead>
              <tr className="border-b">
                <th className="border-r px-4 py-2">S.No</th>
                <th className="border-r px-4 py-2">Username</th>
                <th className="border-r px-4 py-2">Report Type</th>
                <th className="border-r px-4 py-2">Description</th>
                <th className="border-r px-4 py-2">Caption</th>
              </tr>
            </thead>
            <tbody>
              {post?.userId.map((p, index) => (
                <tr key={index} className="border-b">
                  <td className="border-r px-4 py-2 text-[0.8rem] text-center">
                    {index + 1}
                  </td>
                  <td className="border-r px-4 py-2 text-[0.8rem] text-center">
                    {p}
                  </td>
                  <td className="border-r px-4 py-2 text-[0.8rem] text-center">
                    {post.report[index]}
                  </td>
                  <td className="border-r px-4 py-2 text-[0.8rem] text-center">
                    {post.description[index]}
                  </td>
                  <td className="border-r px-4 py-2 text-[0.8rem] text-center">
                    {post?.postId?.imageCaption != null
                      ? post?.postId?.imageCaption
                      : post?.postId?.videoCaption}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex flex-col justify-center mt-4">
          <button
            className="bg-[#008080] rounded-md p-2 text-white"
            onClick={() => {
              handleShowContentClick();
            }}>
            Show Content
          </button>
        </div>
      </div>
    </div>
  );
}

export default PhotoPost;
