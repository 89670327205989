import {
  SET_DARK_THEME,
  SET_LIGHT_THEME,
  SET_THEME_ERROR,
} from "../types/ThemeTypes";
// Profile Reducer
const initState = {
  theme: "light",
  updateErrors: [],
};
// Update NAme Reducer
export const updateTheme = (state = initState, action) => {
  const { type, payload } = action;
  // Checking erros
  //temprory dark theme disabled to enable replace SET_DARK_THEME with on line no. 16 SET_LIGHT_THEME
  if (type === SET_DARK_THEME) {
    return {
      theme: "dark",
      updateErrors: payload,
    };
  } else if (type === SET_LIGHT_THEME) {
    return {
      theme: "light",
      updateErrors: payload,
    };
  } else if (type === SET_THEME_ERROR) {
    return {
      ...state,
      updateErrors: payload,
    };
  } else {
    return {
      ...state,
      updateErrors: [],
    };
  }
};
