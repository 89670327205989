import React from "react";
import { Avatar } from "@mui/material";
import Man from "../Header/img/Man.svg";
import Female from "../Header/img/Female.svg";
import Others from "../Header/img/Others.svg";
import logoAnt from "../Header/img/Ant.png";
import logoAntDark from "../Header/img/Ant_Dark.png";

// let color = ["Orange", "Red", "Yellow", "Blue", "Green"];
// let i = Math.floor(Math.random() * 5);

// const ratio = { width: 32.5, height: 32.5 };
const ratio = { width: 38.5, height: 38.5 };

const ProfilePicture = React.memo(({ profilePic }) => {
  // const [design] = useState({ height: "32.5px", width: "32.5px" });
  const design = { height: "32.5px", width: "32.5px" };
  // const [profilePictureColor, setProfilePictureColor] = useState(color[i]);

  // console.log("theme console krwa rhe hai", profilePic);

  return (
    <>
      {profilePic?.profilePicture ? (
        <Avatar src={`${profilePic?.profilePicture}`} style={design} />
      ) : profilePic?.gender === "male" ? (
        <Avatar src={Man} sx={ratio} />
      ) : profilePic?.gender === "female" ? (
        <Avatar src={Female} sx={ratio} />
      ) : profilePic?.gender === "others" ? (
        <Avatar src={Others} sx={ratio} />
      ) : profilePic?.theme === "dark" ? (
        <Avatar src={logoAntDark} sx={ratio} />
      ) : (
        <Avatar src={logoAnt} sx={ratio} />
      )}
    </>
  );
});

ProfilePicture.displayName = ProfilePicture;

export default React.memo(ProfilePicture);
