
import React ,{useState,useEffect} from 'react';
import { useSelector } from 'react-redux';
import { path } from "../../path";
import { useHistory } from "react-router-dom";
import feedants_logo from "../competition/yellow.png";
import axios from 'axios'

const MyPeer = () => {

    const { user ,token} = useSelector((state) => state.AuthReducer);
    const [friends, setFriends] = useState([]);
    const history = useHistory();


 //get total posts of user
 const config = {
    host: `${path}`,
    headers: {
      "Access-Control-Allow-Origin": `${path}`,
      Authorization: `Bearer ${token}`,
    },
  };


  const frnd = user?.friends;
  const MutualFriends = (BioUser) => {
    if (BioUser === null) {
      return "";
    }
    let MutualFriends = frnd.filter((x) => BioUser.friends.includes(x));

    return MutualFriends.length > 0 && MutualFriends.length < frnd.length
      ? `${MutualFriends.length} Mutual Peers `
      : MutualFriends.length === 0 && `No Mutual Peers`;
  };


  console.log("hii",friends)

    useEffect(() => {
        const getFriends = async () => {
          const res = await axios.get(`${path}/friends/${user._id}`, config);
          const fr = res?.data.friends.filter((f) => f !== null);
          setFriends(fr);
          // setFriendLoad(false);
        };
        getFriends();
      }, []);
    return (
        <>
          

            <div className={`peer-section mt-12 ${user.theme==="dark" ? "bg-page" : "bg-[#f5f6f7]"}  min-h-[100vh] p-4`}>
  <div className="mx-auto md:mx-24 lg:24">
    <h1 className={`my-4 mx-8 md:mx-0 font-gilroy-bold text-lg md:text-xl ${user.theme==="dark" ? "text-white" : ""}`}>My Peers {friends.length}</h1>
    <div className="inner-wrapper">
      <div className="wrapper grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {friends?.map((x) => {
            console.log(x,"new")
          if (!x) {
    return null; // Add curly braces to wrap the code after the condition
  };
          return (
            <div
              key={x._id}
              onClick={() => {
                if (x?._id === user._id) {
                  history.push("/profile");
                } else {
                  history.push(`/userDetails/${x._id}`);
                }
              }}
              className={`inner-container max-w-[300px] mx-auto w-full p-4 shadow-md ${user.theme==="dark" ? "bg-card" : "bg-white"}  rounded-md cursor-pointer`}
            >
              <div className="inner-bottom-section my-4">
                <div className="image-container flex justify-center items-center my-2">
                  {x?.profilePicture ? (
                    <img
                      src={x?.profilePicture}
                      alt={`${x.firstName} ${x.lastName}`}
                      className="rounded-full w-[105px] h-[105px] object-cover"
                    />
                  ) : (
                    <img
                      src={feedants_logo}
                      alt="defaultProfilePic"
                      className="rounded-full w-[105px] h-[105px] object-cover"
                    />
                  )}
                </div>
                <div className="user-info flex flex-col justify-center items-center">
                  <div className="username">
                    <h3 className={`font-gilroy-bold ${user.theme==="dark" ? "text-white" : ""} `}>{x.firstName} {x.lastName}</h3>
                  </div>
                  <div className="content text-sm text-[#666666]">
                    {MutualFriends(x) && (
                      <p>{MutualFriends(x)}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
</div>

        </>
    );
};

export default MyPeer;
