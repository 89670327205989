/* eslint-disable no-warning-comments */
/* eslint-disable react/no-unescaped-entities */
import { useSelector } from "react-redux";
import { LevelCard } from "../Levels/levels-mobile";
import MobileFooter from "../footer/MobileFooter";
import { useEffect, useState } from "react";
import Statistics, { MoreStatistics } from "./statistics";
import axios from "axios";
import { path } from "../../path";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from "react";



const MyStatisticsDesktop = () => {
    const { user, token } = useSelector((state) => state.AuthReducer);
    const [userLevel, SetuserLevel] = useState();
    const [levelinfo, setLevelInfo] = useState({})
    const [engagementClick, setengagementClick] = useState(false)
    console.log(user)
    const [showLevles, setShowLevels] = useState(false)
    // const handleOpenLevels = () => {
    //     setShowLevels(!showLevles)
    // }
    let [count, setcount] = useState(0);
    const [openLevel, setopenLevel] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const config = {
                host: `${path}`,
                headers: {
                    "Access-Control-Allow-Origin": `${path}`,
                    Authorization: `Bearer ${token}`,
                },
            };
            const res = await axios.post(`${path}/getUserLevel`, { userId: user._id }, config)
            console.log(res);
            if (res?.data?.msg === "success") { SetuserLevel(res?.data?.userLevel) }
            setcount(res?.data?.userLevel.blog + res?.data?.userLevel.video + res?.data?.userLevel.photo)
        }
        fetchData()
    }, [user])
    useEffect(() => { console.log(userLevel) }, [userLevel])
    useEffect(() => {
        const fetchData = async () => {
            // const config = {
            //     host: `${path}`,
            //     headers: {
            //         "Access-Control-Allow-Origin": `${path}`,
            //         Authorization: `Bearer ${token}`,
            //     },
            // };
            const res = await axios.post(`${path}/getlevel`, { level: user.level + 1 })
            console.log(res)
            console.log(res.data.levelInformation);
            // if(res.levelInformation !=null){
            setLevelInfo(res.data.levelInformation)
            // }  
            console.log(levelinfo)

        }
        fetchData()
    }, [userLevel])
    useEffect(() => { console.log(levelinfo) }, [levelinfo])

    return (

        <div className={` ${user.theme === "dark" ? "bg-black" : "bg-[#F6F5FA]"} rounded-lg h-full flex items-center justify-center  p-3 `}>
            <div className="h-full overflow-y-scroll scrollbar-hide">
                {openLevel ?
                    <div className="flex xl:flex-row items-start h-full mt-28 max-w-6xl gap-[10px] ">
                        <div className="flex flex-col h-full">
                            <div className="flex items-center justify-between">
                                <h2 className={`font-gilroy-bold text-center  text-base ${user.theme === "dark" ? "text-white" : "text-black"}`}>Levels</h2>
                                <ArrowBackIcon className="cursor-pointer" onClick={() => setopenLevel(false)} />
                            </div>
                            <div className="flex  flex-col mt-[20px] gap-[13px]">
                                <LevelCard level={1} title={"Map Reading"} Fordesktop={true} />
                                <LevelCard level={2} title={"Path Finding"} Fordesktop={true} />
                                <LevelCard level={3} title={"Destination Reaching"} Fordesktop={true} />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="flex xl:flex-row max-w-6xl gap-[10px] ">
                        <div className="sm:flex  hidden flex-col  h-full">
                            <h2 className={`font-gilroy-bold mt-[10px] text-base ${user.theme === "dark" ? "text-white" : "text-black"}`}>Levels</h2>
                            <div className="flex  flex-col mt-[20px] gap-[13px]">
                                <LevelCard level={1} title={"Map Reading"} Fordesktop={true} />
                                <LevelCard level={2} title={"Path Finding"} Fordesktop={true} />
                                <LevelCard level={3} title={"Destination Reaching"} Fordesktop={true} />
                            </div>
                        </div>

                        <div className="flex flex-col gap-[10px] w-full">
                            <p className={` pl-[18px]  md:hidden font-gilroy-bold mt-[10px] text-[15px] ${user.theme === "dark" ? "text-white" : "text-black"}`}>My Statistics</p>
                            <div className={`flex items-center justify-between h-[282px]  p-[30px] w-full ${user.theme === "dark" ? "bg-[#1D1D1E]" : "bg-white"} mt-[15px]   md:mt-[50px] rounded-[9px]`}>
                                <div className="w-[300px] mr-[70px] mt-8 h-[200px] md:flex flex-col gap-3 hidden">
                                    <h1 className={`font-gilroy-bold text-[19px] ${user.theme === "dark" ? "text-white" : "text-black"}`}>My Statistics</h1>
                                    <p className="text-compPrimary font-gilroy-medium text-[10px]">Lorem Ipsumis simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it</p>
                                    <button onClick={() => setShowLevels(!showLevles)} className="w-[86px] h-[30px] text-white font-gilroy-medium text-[11px] bg-[#008080] rounded-full">Learn more</button>

                                </div>
                                <div className="h-full grid grid-cols-2 mt-[10px]  md:mt-[15px] w-full gap-[10px]">
                                    <div className={`h-full flex flex-col gap-[10px] ${user.theme === "dark" ? "bg-black" : "bg-[#F6F5FA]"}  p-3 rounded-[9px]`}>
                                        <div className="flex flex-col gap-3">
                                            <h2 className={`font-gilroy-bold text-[13px] ${user.theme === "dark" ? "text-white" : "text-black"}`}>Your level</h2>
                                            <div className="w-[38px] h-[38px]">
                                                <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.3281 31.4688H21.6719V37.4062H16.3281V31.4688Z" fill="#7A829C" />
                                                    <path d="M16.3281 31.4688H21.6719V32.6562H16.3281V31.4688Z" fill="#646D8B" />
                                                    <path d="M17.5156 31.4688H21.6719V37.4062H17.5156V31.4688Z" fill="#646D8B" />
                                                    <path d="M17.5156 31.4688H21.6719V32.6562H17.5156V31.4688Z" fill="#505A76" />
                                                    <path d="M30.875 0.59375H7.125C5.15749 0.59375 3.5625 2.18874 3.5625 4.15625V27.9062C3.5625 29.8738 5.15749 31.4688 7.125 31.4688H30.875C32.8425 31.4688 34.4375 29.8738 34.4375 27.9062V4.15625C34.4375 2.18874 32.8425 0.59375 30.875 0.59375Z" fill="#FAFDFF" />
                                                    <path d="M7.125 1.78125C6.49511 1.78125 5.89102 2.03147 5.44562 2.47687C5.00022 2.92227 4.75 3.52636 4.75 4.15625V29.0938C4.75015 29.7475 4.93126 30.3884 5.27327 30.9455C5.83038 31.2875 6.47129 31.4686 7.125 31.4688H30.875C31.8198 31.4688 32.726 31.0934 33.3941 30.4253C34.0622 29.7572 34.4375 28.8511 34.4375 27.9062V4.15625C34.4374 3.50254 34.2562 2.86163 33.9142 2.30452C33.3571 1.96251 32.7162 1.7814 32.0625 1.78125H7.125Z" fill="#EEF6FF" />
                                                    <path d="M32.0625 27.9062V4.15625C32.0625 3.50041 31.5308 2.96875 30.875 2.96875H7.125C6.46916 2.96875 5.9375 3.50041 5.9375 4.15625V27.9062C5.9375 28.5621 6.46916 29.0938 7.125 29.0938H30.875C31.5308 29.0938 32.0625 28.5621 32.0625 27.9062Z" fill="#001E41" />
                                                    <path d="M32.0625 27.9062V4.15625H8.3125C7.99756 4.15625 7.69551 4.28136 7.47281 4.50406C7.25011 4.72676 7.125 5.02881 7.125 5.34375V29.0938H30.875C31.1899 29.0938 31.492 28.9686 31.7147 28.7459C31.9374 28.5232 32.0625 28.2212 32.0625 27.9062Z" fill="#003B80" />
                                                    <path d="M11.5781 26.125H9.79688V21.9688C9.79688 21.8113 9.73432 21.6603 9.62297 21.5489C9.51162 21.4376 9.3606 21.375 9.20312 21.375C9.04565 21.375 8.89463 21.4376 8.78328 21.5489C8.67193 21.6603 8.60938 21.8113 8.60938 21.9688V26.7188C8.60938 26.8762 8.67193 27.0272 8.78328 27.1386C8.89463 27.2499 9.04565 27.3125 9.20312 27.3125H11.5781C11.7356 27.3125 11.8866 27.2499 11.998 27.1386C12.1093 27.0272 12.1719 26.8762 12.1719 26.7188C12.1719 26.5613 12.1093 26.4103 11.998 26.2989C11.8866 26.1876 11.7356 26.125 11.5781 26.125Z" fill="#FAFDFF" />
                                                    <path d="M28.7969 26.125H27.0156V21.9688C27.0156 21.8113 26.9531 21.6603 26.8417 21.5489C26.7304 21.4376 26.5793 21.375 26.4219 21.375C26.2644 21.375 26.1134 21.4376 26.002 21.5489C25.8907 21.6603 25.8281 21.8113 25.8281 21.9688V26.7188C25.8281 26.8762 25.8907 27.0272 26.002 27.1386C26.1134 27.2499 26.2644 27.3125 26.4219 27.3125H28.7969C28.9543 27.3125 29.1054 27.2499 29.2167 27.1386C29.3281 27.0272 29.3906 26.8762 29.3906 26.7188C29.3906 26.5613 29.3281 26.4103 29.2167 26.2989C29.1054 26.1876 28.9543 26.125 28.7969 26.125Z" fill="#FAFDFF" />
                                                    <path d="M15.7344 22.5625C15.8918 22.5625 16.0429 22.4999 16.1542 22.3886C16.2656 22.2772 16.3281 22.1262 16.3281 21.9688C16.3281 21.8113 16.2656 21.6603 16.1542 21.5489C16.0429 21.4376 15.8918 21.375 15.7344 21.375H13.3594C13.2019 21.375 13.0509 21.4376 12.9395 21.5489C12.8282 21.6603 12.7656 21.8113 12.7656 21.9688V26.7188C12.7656 26.8762 12.8282 27.0272 12.9395 27.1386C13.0509 27.2499 13.2019 27.3125 13.3594 27.3125H15.7344C15.8918 27.3125 16.0429 27.2499 16.1542 27.1386C16.2656 27.0272 16.3281 26.8762 16.3281 26.7188C16.3281 26.5613 16.2656 26.4103 16.1542 26.2989C16.0429 26.1876 15.8918 26.125 15.7344 26.125H13.9531V24.9375H15.1406C15.2981 24.9375 15.4491 24.8749 15.5605 24.7636C15.6718 24.6522 15.7344 24.5012 15.7344 24.3438C15.7344 24.1863 15.6718 24.0353 15.5605 23.9239C15.4491 23.8126 15.2981 23.75 15.1406 23.75H13.9531V22.5625H15.7344Z" fill="#FAFDFF" />
                                                    <path d="M24.6406 22.5625C24.7981 22.5625 24.9491 22.4999 25.0605 22.3886C25.1718 22.2772 25.2344 22.1262 25.2344 21.9688C25.2344 21.8113 25.1718 21.6603 25.0605 21.5489C24.9491 21.4376 24.7981 21.375 24.6406 21.375H22.2656C22.1082 21.375 21.9571 21.4376 21.8458 21.5489C21.7344 21.6603 21.6719 21.8113 21.6719 21.9688V26.7188C21.6719 26.8762 21.7344 27.0272 21.8458 27.1386C21.9571 27.2499 22.1082 27.3125 22.2656 27.3125H24.6406C24.7981 27.3125 24.9491 27.2499 25.0605 27.1386C25.1718 27.0272 25.2344 26.8762 25.2344 26.7188C25.2344 26.5613 25.1718 26.4103 25.0605 26.2989C24.9491 26.1876 24.7981 26.125 24.6406 26.125H22.8594V24.9375H24.0469C24.2043 24.9375 24.3554 24.8749 24.4667 24.7636C24.5781 24.6522 24.6406 24.5012 24.6406 24.3438C24.6406 24.1863 24.5781 24.0353 24.4667 23.9239C24.3554 23.8126 24.2043 23.75 24.0469 23.75H22.8594V22.5625H24.6406Z" fill="#FAFDFF" />
                                                    <path d="M20.6616 21.4015C20.5112 21.3547 20.3485 21.3694 20.209 21.4425C20.0695 21.5156 19.9647 21.641 19.9177 21.7912L19.0001 24.7278L18.0825 21.7912C18.0355 21.6409 17.9308 21.5154 17.7912 21.4423C17.6517 21.3692 17.4889 21.3546 17.3386 21.4015C17.1883 21.4485 17.0628 21.5533 16.9897 21.6928C16.9166 21.8323 16.9019 21.9951 16.9489 22.1455L18.4333 26.8955C18.471 27.0163 18.5463 27.1219 18.6483 27.1969C18.7502 27.2719 18.8735 27.3123 19.0001 27.3123C19.1266 27.3123 19.2499 27.2719 19.3519 27.1969C19.4538 27.1219 19.5292 27.0163 19.5669 26.8955L21.0512 22.1455C21.0981 21.9951 21.0834 21.8323 21.0104 21.6928C20.9373 21.5534 20.8118 21.4486 20.6616 21.4015Z" fill="#FAFDFF" />
                                                    <path d="M19 19.5936C18.685 19.5936 18.383 19.4684 18.1603 19.2457C17.9376 19.023 17.8125 18.721 17.8125 18.4061V9.39804L16.8708 10.3394C16.6471 10.557 16.3467 10.6777 16.0347 10.6756C15.7226 10.6734 15.424 10.5484 15.2033 10.3278C14.9826 10.1071 14.8577 9.80847 14.8555 9.49641C14.8533 9.18436 14.974 8.88398 15.1916 8.66024L18.1603 5.69149C18.3264 5.52545 18.538 5.41237 18.7683 5.36656C18.9986 5.32075 19.2374 5.34426 19.4544 5.43412C19.6713 5.52399 19.8568 5.67616 19.9873 5.87141C20.1178 6.06666 20.1874 6.29622 20.1875 6.53106V18.4061C20.1875 18.721 20.0624 19.023 19.8397 19.2457C19.617 19.4684 19.3149 19.5936 19 19.5936Z" fill="#FAFDFF" />
                                                </svg>

                                            </div>
                                        </div>
                                    </div>
                                    {userLevel && <>
                                        <div onClick={() => setengagementClick(false)}>

                                            <Statistics user={user} val={userLevel?.competitionWon} levelinfo={levelinfo?.competitionWon} title={"Competetions won"} />
                                        </div>
                                        <div onClick={() => setengagementClick(false)}>

                                            <Statistics user={user} val={userLevel?.competitionParticipated} levelinfo={levelinfo?.competitionParticipated} title={"Participated"} />
                                        </div>
                                        <div onClick={() => setengagementClick(true)}>

                                            <Statistics user={user} val={count} levelinfo={levelinfo?.video + levelinfo?.photo + levelinfo?.blog} title={"Engagement with platform"} />
                                        </div>
                                    </>}

                            </div>
                        </div>
                        <div className={` p-[15px]  md:p-[33px] rounded-[9px] ${user.theme === "dark" ? "bg-card" : "bg-white"} `}>

                                <div className="flex flex-col gap-2">
                                    <MoreStatistics user={user} userLevel={userLevel} levelinfo={levelinfo} engagementClick={engagementClick} />
                                    {/* <MoreStatistics user={user} /> */}
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="flex flex-col items-center mb-5 md:hidden">
                                <p className={`font-gilroy-bold text-[15px] ${user.theme === "dark" ? "text-white" : "text-black"}`}>Want to know more about Levels?</p>
                                <p onClick={() => setopenLevel(true)} className="text-[#008080] font-gilroy-bold text-[15px] underline cursor-pointer"> "Open Levels"</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <MobileFooter />
        </div>
    );
}

export default MyStatisticsDesktop;