import React from "react";
import { ThreeDots } from "react-loader-spinner";

const Loading = () => {
  return (
    <div className="flex justify-center items-start md:h-[60px] h-[120px]">
      <div></div>
      <ThreeDots
        height="60"
        width="80"
        radius="9"
        color="#4fa94d"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
      <div></div>
    </div>
  );
};

export default Loading;
