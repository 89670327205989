import {
  CREATE_ERRORS,
  UPDATE_IMAGE_ERRORS,
  SET_LOADER,
  CLOSE_LOADER,
  REDIRECT_FALSE,
  REDIRECT_TRUE,
  SET_MESSAGE,
  REMOVE_MESSAGE,
  REMOVE_ERRORS,
  SET_POSTS,
  SET_PHOTO_POSTS,
  SET_VIDEO_POSTS,
  SET_BLOG_POSTS,
  POST_REQUEST,
  SET_POST,
  POST_RESET,
  SET_UPDATE_ERRORS,
  RESET_UPDATE_ERRORS,
  RESET_UPDATE_IMAGE_ERRORS,
  REPORTED_POSTS,
} from "../types/postTypes";
// local state for posts
const initState = {
  loading: false,
  postErrors: [],
  redirect: false,
  message: "",
  photoposts: [],
  blogposts: [],
  videoposts: [],
  post: [],
  posts: [],
  editErrors: [],
  postStatus: false,
  updateImageErrors: [],
  skill: "",
  skillPosts: "",
  likeCount: 0,
  homePageData: [],
  postComment: [],
  skillUsers: [],
  newPost: {},
  commentErrors: [],
  imageErrors: [],
  blogErrors: [],
  videoErrors: [],
  updatePost: "",
  ReportedPosts: "",
  newTempPosts: {},
  comments:{}

  
};
export const PostReducer = (state = initState, action) => {
  if (action.type === SET_LOADER) {
    // loader for async calls
    return { ...state, loading: true };
  } else if (action.type === "UPDATE_POST") {
    return { ...state, updatePost: action.payload };
  } else if (action.type === "SET_POST_SKILL") {
    // setting post skill
    return { ...state, skill: action.payload };
  } else if (action.type === "SET_POST_DATA") {
    // post data setting
    return {
      ...state,
      skillPosts: action.payload.posts,
      skillUsers: action.payload.users,
    };
  } else if (action.type === REPORTED_POSTS) {
    return {
      ...state,
      ReportedPosts: action.payload,
    };
  } else if (action.type === CLOSE_LOADER) {
    // close loader after response
    return { ...state, loading: false };
  } else if (action.type === CREATE_ERRORS) {
    // checking post errors
    return { ...state, postErrors: action.payload };
  } else if (action.type === "CREATE_COMMENT_ERRORS") {
    // checking post errors
    return { ...state, commentErrors: action.payload };
  } else if (action.type === "CREATE_VIDEO_ERRORS") {
    // checking post errors
    return { ...state, videoErrors: action.payload };
  } else if (action.type === "CREATE_IMAGE_ERRORS") {
    // checking post errors
    return { ...state, imageErrors: action.payload };
  } else if (action.type === "CREATE_BLOG_ERRORS") {
    // checking post errors
    return { ...state, blogErrors: action.payload };
  } else if (action.type === REDIRECT_TRUE) {
    // redirecting the page after post creation
    return { ...state, redirect: true };
  } else if (action.type === SET_MESSAGE) {
    // setting msg for notification
    state.homePageData = state.homePageData.map((pst) =>
      pst?._id === action.payload?._id ? action.payload : pst
    );
    return { ...state };
  } else if (action.type === "DELETE_COMMENT") {
    state.homePageData = state.homePageData.map((pst) =>
      pst?._id === action.payload.post?._id ? action.payload.post : pst
    );
    return { ...state };
  } else if (action.type === REMOVE_ERRORS) {
    // removing errors
    return { ...state, postErrors: [] };
  } else if (action.type === REDIRECT_FALSE) {
    return { ...state, redirect: false };
  } else if (action.type === REMOVE_MESSAGE) {
    return { ...state, message: "" };
  } else if (action.typr === "SET_LIKE_COUNT") {
    // like counts not in use now
    return { ...state, likeCount: action.payload };
  } else if (action.type === POST_REQUEST) {
    // requesting for post not in use now
    return { ...state, postStatus: false };
  } else if (action.type === "SET_POST_COMMENT") {
    // Post comment not in use  now
   

    // return {
    //   ...state,
    //   comments:{...state.comments , [action.payload.id] : [...(state.comments[action.payload.id] || [])  , ...action.payload.data] }
    // }

    return {
      ...state,
      comments: {
        ...state.comments,[action.payload.id]: action.payload.page === 1 ? action.payload.data :
            [...(state.comments[action.payload.id] || []),
         ...action.payload.data]
      }
    }
    

  } else if (action.type === "CLOSE_COMMENT") {
    // closing post comment
    return { ...state, comment: "" };
  } else if (action.type === "NEW_POST") {
    return { ...state, newPost: action.payload };
  } else if (action.type === "UNSET_NEW_POST") {
    return { ...state, newPost: "" };
  } else if (action.type === "ADD_NEW_TEMP_POSTS") {
    return { ...state, newTempPosts: action.payload };
  } else if (action.type === "CLEAR_NEW_TEMP_POSTS") {
    return { ...state, newTempPosts: {} };
  } else if (action.type === "LOGOUT") {
    // clearing local storage after logout
    return initState;
  }

  // else if(action.type==="LOGOUT"){
  //     return initState
  // }
  else {
    return state;
  }
};
// Fetching Post reducer
export const FetchPosts = (state = initState, action) => {
  // const {type,payload} = action;
  // setting post for temprary use
  if (action.type === SET_POSTS) {
    return { ...state, posts: action.payload };
  } else if (action.type === SET_PHOTO_POSTS) {
    state.photoposts.splice(0, state.photoposts.length);
    state.photoposts.push(action.payload);
    return state;
  } else if (action.type === SET_VIDEO_POSTS) {
    state.videoposts.splice(0, state.videoposts.length);
    state.videoposts.push(action.payload);
    return state;
  } else if (action.type === SET_BLOG_POSTS) {
    state.blogposts.splice(0, state.blogposts.length);
    state.blogposts.push(action.payload);
    return state;
  } else if (action.type === "RESET_POSTS") {
    // resetting post
    state.posts.splice(0, state.posts.length);
    return state;
    // return {...state.loading,postErrors:[],posts:[],message:''}
  } else if (action.type === SET_LOADER) {
    // loader for async use
    return { ...state, loading: true };
  } else if (action.type === CLOSE_LOADER) {
    // closing loader
    return { ...state, loading: false };
  } else if (action.type === POST_REQUEST) {
    // requeting Post
    return { ...state };
  } else if (action.type === "SET_LIKE_COUNT") {
    // Like count not in use now
    return { ...state, likeCount: action.payload };
  } else if (action.type === "SET_HOME_PAGE_DATA") {
    // setting data for home page
    return { ...state, homePageData: action.payload };
  } else if (action.type === SET_MESSAGE) {
    // notification message
    return { ...state, message: action.payload };
  }
  // else if(action.type==="LOGOUT"){
  //     return initState
  // }
  else {
    return state;
  }
};
export const FetchPost = (state = initState, action) => {
  // const {type,payload} = action;
  if (action.type === SET_POST) {
    // setting Post
    state.post.pop();
    state.post.push(action.payload);
    return state;
  } else if (action.type === SET_LOADER) {
    // setting Loader
    return { ...state, loading: true };
  } else if (action.type === "SET_LIKE_COUNT") {
    // like count
    return { ...state, likeCount: action.payload };
  } else if (action.type === POST_REQUEST) {
    //doing Post Request
    return { ...state, postStatus: true };
  } else if (action.type === POST_RESET) {
    // resetting Post
    return { ...state, postStatus: false };
  } else if (action.type === SET_MESSAGE) {
    // notification
    return { ...state, message: action.payload };
  }
  // else if(action.type==="LOGOUT"){
  //     return initState
  // }
  else {
    return state;
  }
};
export const updatePost = (state = initState, action) => {
  const { type, payload } = action;
  if (type === SET_UPDATE_ERRORS) {
    // update errors notification array
    return { ...state, editErrors: payload };
  } else if (type === RESET_UPDATE_ERRORS) {
    // restting erros
    return { ...state, editErrors: [] };
  } else if (action.type === SET_MESSAGE) {
    // notification message
    return { ...state, message: action.payload };
  } else if (action.type === CLOSE_LOADER) {
    // closing loader after response
    return { ...state, loading: false };
  } else if (action.type === REDIRECT_FALSE) {
    // redirecting false
    return { ...state, redirect: false };
  } else if (type === SET_LOADER) {
    // setting loader for async calls
    return { ...state, loader: true };
  } else if (action.typr === "SET_LIKE_COUNT") {
    // likes count no more in use
    return { ...state, likeCount: action.payload };
  }
  // else if(action.type==="LOGOUT"){
  //     return initState
  // }
  else {
    return state;
  }
};
// Update Image validations
export const UpdateImage = (state = initState, action) => {
  const { type, payload } = action;
  if (type === UPDATE_IMAGE_ERRORS) {
    return { ...state, updateImageErrors: payload };
  } else if (type === RESET_UPDATE_IMAGE_ERRORS) {
    return { ...state, updateImageErrors: [] };
  } else if (type === SET_LOADER) {
    return { ...state, loading: true };
  } else if (action.typr === "SET_LIKE_COUNT") {
    return { ...state, likeCount: action.payload };
  } else if (type === SET_MESSAGE) {
    return { ...state, message: payload };
  }
  // else if(action.type==="LOGOUT"){
  //     return initState
  // }
  else {
    return state;
  }
};

