import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';

import appStoreBadge from "./img/appStoreBadge.svg";
import playStoreBadge from "./img/playStoreBadge.png";
import { toast } from "react-toastify";

const DeepLinkBanner = () => {
    const { id: contestId } = useParams();
    const { user } = useSelector((state) => state.AuthReducer);
    const isLoggedIn = localStorage.getItem('myToken') !== null;

    const [redirecting, setRedirecting] = useState(false);

    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         setRedirecting(false);
    //     }, 3000);

    //     if (window.navigator.userAgent.includes("Android")) {
    //         window.location.replace("https://play.google.com/store/apps/details?id=com.feedants&pcampaignid=web_share")
    //     } else if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
    //         window.location.replace("https://apps.apple.com/in/app/feedants/id6477408585");
    //     } else {
    //         setRedirecting(false);
    //     }

    //     return () => clearTimeout(timeout);
    // }, [])

    const handleRedirect = () => {
        setRedirecting(true);
        const iframe = document.createElement('iframe');
        try {
            iframe.style.display = 'none';
            iframe.src = `feedants://competitionLink/${contestId}`;
            document.body.appendChild(iframe);
        } catch (err) {
            console.log(err);

            toast.error("Please install the app first!");
        }

        const downloadRedirectTimer = setTimeout(() => {
            if (window.navigator.userAgent.includes("Android")) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.feedants&pcampaignid=web_share"
            } else if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
                window.location.replace("https://apps.apple.com/in/app/feedants/id6477408585");
            } else {
                setRedirecting(false);
            }
        }, 3000);
        const unsetRedirecting = setTimeout(() => {
            setRedirecting(false);
            document.body.removeChild(iframe);
        }, 5000);
        return () => {
            clearTimeout(downloadRedirectTimer)
            clearTimeout(unsetRedirecting);
            document.body.removeChild(iframe);
        };
    }

    if (redirecting) {
        return (
            <div className={`h-[100vh] ${isLoggedIn ? 'pt-[90px]' : 'pt-[40px]'}`}>
                <div className={`flex justify-center items-center`}>
                    <span className="border-2 border-teal-700 border-t-transparent h-4 w-4 rounded-full animate-spin" ></span>
                    <h1 className="ml-2" style={{ color: user?.theme === "dark" ? "white" : "#333333" }}>Redirecting...</h1>
                </div>
            </div>
        );
    }

    return (
        <main className="relative h-[100vh] text-center">
            <div className={`py-[30px] ${isLoggedIn && 'mt-[50px]'} px-5`}>
                <h1 className="text-[30px] font-semibold" style={{ color: user?.theme === "dark" ? "white" : "#333333" }}>Get the Feedants app:</h1>
            </div>
            <div className="flex flex-row max-[350px]:flex-col justify-center items-center gap-1">
                <a href="https://apps.apple.com/in/app/feedants/id6477408585" className="w-[175px] h-[57px]">
                    <img className="w-full h-full" src={appStoreBadge} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.feedants&pcampaignid=web_share" className="w-[175px] h-[57px]" >
                    <img className="w-full h-full" src={playStoreBadge} />
                </a>
            </div>
            <div className="my-[30px] px-5">
                <h1 className="text-[30px] font-semibold" style={{ color: user?.theme === "dark" ? "white" : "#333333" }}>OR:</h1>
            </div>
            <div className="flex justify-center max-w-[350px] mx-auto px-5">
                <Button
                    onClick={handleRedirect}
                    style={{ backgroundColor: '#008080', color: 'white', width: '100%' }}
                >
                    Go To App
                </Button>
            </div>
            <p className="text-xs text-red-600">*Make sure you have the app installed</p>
            {/* <div className="my-[30px] px-5">
                <Link to={`/competition/${contestId}`} style={{ color: "#999" }}>or continue on Feedants website</Link>
            </div> */}
        </main>
    )
}

export default DeepLinkBanner;