import React from "react";

const VideoPreview = ({ video, username, setVideo }) => {
  if (!video) {
    return <div className="w-1/2 p-4">Select a video to preview</div>;
  }
  const handleModal = () => {
    setVideo(null);
  };
  return (
    <div className="flex-1 bg-white md:bg-none w-1/2 my-3 items-center h-screen md:h-[36rem] sm:mt-10 sm:mr-2 border rounded-lg py-3">
      <div className="flex justify-between">
        <h2 className="my-1 font-bold ml-3">Video Preview for {username}</h2>
        <h2
          className="mr-3 cursor-pointer hover:text-red-800"
          onClick={handleModal}>
          X
        </h2>
      </div>
      <div className="relative flex flex-col p-6 justify-center bg-white border border-transparent rounded-xl flex-1">
        {" "}
        {/* Aspect ratio 16:9 */}
        <video
          className="self-center top-0 border border-transparent rounded-xl  md:w-1/2 h-[35rem]  w-auto md:h-full"
          controls
          src={video}>
          Your browser does not support the video tag.
        </video>
        <button
          className="sm:hidden mt-10 self-center items-center mb-6 w-24 border rounded-lg p-2 transition duration-300 ease-in-out hover:scale-110 hover:cursor-pointer border-red-700 text-red-700"
          onClick={handleModal}>
          Close
        </button>
      </div>
    </div>
  );
};

export default VideoPreview;
