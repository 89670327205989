import React from "react";
import CommentIcon from '@mui/icons-material/Comment';
import RecommendIcon from '@mui/icons-material/Recommend';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PasswordIcon from '@mui/icons-material/Password';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { PropTypes } from 'prop-types'
const NotificationIcon = ({ notification }) => {
  return (
    <>
      {(() => {
        if (notification.type === "Profile") {
          return <div className={`${notification.seen ? "" : "text-teal-600"}`}><AccountCircleIcon /></div>

          // return <img src="assets/NotificationIcon/3p.png"></img>;
        } else if (
          notification.type === "Alternate Email" ||
          notification.type === "Email"
        ) {
          return <div className={`${notification.seen ? "" : "text-teal-600"}`}><AlternateEmailIcon /></div>
          // return <img src="assets/NotificationIcon/alternate_email.png"></img>;

          // }else if(notification.type === "Password"){
        } else if (notification.type === "comment_positive" || notification.type === "comment_negative") {
          return <div className={`${notification.seen ? "" : "text-teal-600"}`}><CommentIcon /></div>
        } else if (notification.type === "Improve" || notification.type === "Appreciate") {
          return <div className={`${notification.seen ? "" : "text-teal-600"}`}><RecommendIcon /></div>
        } else if (notification.type === "post") {
          return <div className={`${notification.seen ? "" : "text-teal-600"}`}><PostAddIcon /></div>
        } else if (notification.type === "password") {
          return <div className={`${notification.seen ? "" : "text-teal-600"}`}><PasswordIcon /></div>
        } else if (notification.type === "name_update" || notification.type === "profile_pic_update" || notification.type === "skill_update" || notification.type === "social_media_update") {
          return <div className={`${notification.seen ? "" : "text-teal-600"}`}><ManageAccountsIcon /></div>
        } else if (notification.type === "peerInterest" || notification.type === "peerAccepted") {
          return <div className={`${notification.seen ? "" : "text-teal-600"}`}><PersonAddIcon /></div>
        } else {
          return <div className={`${notification.seen ? "" : "text-teal-600"}`}>
            <img src="assets/NotificationIcon/lock.png" className=" w-[20px]" />
            {/* <PersonAddIcon /> */}
          </div>
          // return <img src="assets/NotificationIcon/lock.png"></img>;
        }
      })()}
    </>
  );
};
NotificationIcon.propTypes = {
  notification: PropTypes.object.isRequired,

}
export default NotificationIcon;