import { jwtDecode } from "jwt-decode";
import {
  SET_EMAIL,
  SET_LOADER,
  SET_TOKEN,
  LOGIN_ERRORS,
  SET_MESSAGE,
  UNSET_MESSAGE,
  LOGOUT,
  CLOSE_LOADER,
  REGISTER_ERRORS,
  CLOSE_LOGIN_ERRORS,
  SET_SKILLS,
  SET_USER,
  SET_FOLLOW,
  UPDATE_ACCOUNT,
  UPDATE_EMAIL,
  UPDATE_ALTERNATE_EMAIL,
  SET_CONTEST_SUBMISSION_UPLOADING,
} from "../types/AuthTypes";

// Initial State for auth reducers
const initState = {
  loading: false,
  notification: false,
  msgNotification: false,
  registerErrors: [],
  loginErrors: [],
  errorEmail: "",
  email: "",
  token: "",
  user: "",
  tempUser: "",
  tempSkills: {},
  tempNSkills: {},
  tour: true,
  nTour: true,
  header: false,
  skills: [],
  otpRecieved: false,
  otpHash: "",
  userDetailsAlternateEmail: "",
  otpVerified: false,
  sid: null,
  otpEmail:"",
  phone: "",
  type: "",
  followers: "",
  following: "",
  message: "",
  likeCount: 0,
  passwordChanged: false,
  searchUser: [],
  search: false,
  logTime: "",
  feedFilled: false,
  alternateEmail: "",
  mutualFriends: [],
  progress: 0,
  contributedContests: [],
  contestSubmissionUploading: false,
};

/*
// verifying if the user is already there in system or not
const verifyToken = (token) => {
  const decodedToken = jwtDecode(token);
  const expiresIn = new Date(decodedToken.exp * 1000);
  // checking expiry
  if (new Date() > expiresIn) {
    localStorage.removeItem("myToken");
    return null;
  } else {
    return decodedToken;
  }
};
// when a user is unauthorized, he/she will be logged out
// getting token
const token = localStorage.getItem("myToken");
if (token) {
  const decoded = verifyToken(token);
  initState.token = token;
  const { user } = decoded;
  initState.user = user;
}

*/

const verifyToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const expiresIn = new Date(decodedToken.exp * 1000);
    // checking expiry
    if (new Date() > expiresIn) {
      localStorage.removeItem("myToken");
      return null;
    } else {
      return decodedToken;
    }
  } catch (error) {
    console.error("Error verifying token:", error);
    // Log out the user if token is invalid
    localStorage.removeItem("myToken");
    return null;
  }
};

// when a user is unauthorized, he/she will be logged out
// getting token
const token = localStorage.getItem("myToken");
if (token) {
  const decoded = verifyToken(token);
  if (decoded) {
    initState.token = token;
    const { user } = decoded;
    initState.user = user;
  } else {
    // Token verification failed, log out the user
    initState.token = null;
    initState.user = null;
  }
} else {
  // No token found, log out the user
  initState.token = null;
  initState.user = null;
}

// Auth Reducer
const AuthReducer = (state = initState, action) => {
  // setting loading true for async requests
  if (action.type === SET_LOADER) {
    return { ...state, loading: true };
  } else if (action.type === "OTP_RECIEVED") {
    //    otp Recieved
    return { ...state, otpRecieved: true };
  } else if (action.type === "SET_PHONE") {
    //    setting phone number to sent otp
    return { ...state, phone: action.payload };
  } else if (action.type === "UNSET_PHONE") {
    //    unset otp
    return { ...state, phone: "" };
  } else if (action.type === "SET_FR") {
    return { ...state, user: { ...state.user, friends: action.payload } };
    // return state;
  } else if (action.type === "SET_TYPE") {
    //    setting type for phone or email differentiation
    return { ...state, type: action.payload };
  } else if (action.type === "UNSET_TYPE") {
    //    unsetting type email or phone
    return { ...state, type: "" };
  } else if (action.type === "UNSET_OTP") {
    //    unsetting otp(not in use now)
    return { ...state, otpRecieved: false };
  } else if (action.type === "SET_OTPHASH") {
    //    otp hash
    return { ...state, otpHash: action.payload };
  }
   else if (action.type === "SET_SID") {
    //    otp access token 
    return { ...state, sid: action.payload };
  }
   else if (action.type === "SET_EMAILS") {
    //    otp email
    return { ...state,  otpEmail: action.payload };
  }
  //    userdetails on email alternate
  else if (action.type === "USER_DETAILS_EMAIL") {
    return { ...state, userDetailsAlternateEmail: action.payload };
  } else if (action.type === "OTP_VERIFIED") {
    //    checking otp verification
    return { ...state, otpVerified: true };
  } else if (action.type === "RESET_OTP_VERIFIED") {
    //    resetting otp verification
    return { ...state, otpVerified: false, otpHash: "" };
  } else if (action.type === CLOSE_LOADER) {
    //   closing loader after the reponse recieved
    return { ...state, loading: false };
  } else if (action.type === "UNSET_N_TOUR") {
    //Closing novice skill tour guide
    return { ...state, nTour: false };
  } else if (action.type === REGISTER_ERRORS) {
    //    checing register errors
    return { ...state, registerErrors: action.payload };
  } else if (action.type === SET_EMAIL) {
    //    setting email
    return { ...state, email: action.payload };
  } else if (action.type === SET_TOKEN) {
    //    setting token for future use and validating
    const decoded = verifyToken(action.payload);
    const { user } = decoded;
    return {
      ...state,
      user: user,
      token: action.payload,
      loginErrors: [],
      registerErrors: [],
    };
  } else if (action.type === SET_MESSAGE) {
    // message for notification
    return { ...state, message: action.payload };
  } else if (action.type === UNSET_MESSAGE) {
    // useting message after dispalying it
    return { ...state, message: "" };
  } else if (action.type === LOGIN_ERRORS) {
    return {
      ...state,
      loginErrors: action.payload.errors,
      errorEmail: action.payload.email,
    };
  } else if (action.type === CLOSE_LOGIN_ERRORS) {
    // Closeing login errors
    return {
      ...state,
      loginErrors: [],
    };
  } else if (action.type === SET_SKILLS) {
    state.skills.push(action.payload);
    return { ...state };
  } else if (action.type === SET_USER) {
    return {
      ...state,

      token: action.token,
      loginErrors: [],
      registerErrors: [],
      user: action.user,
      logTime: action.loginTime,
    };
  } 
  else if (action.type === LOGOUT) {
    // clearing local storage after logout
    localStorage.clear();
    return {
      loading: false,
      notification: false,
      msgNotification: false,
      registerErrors: [],
      loginErrors: [],
      errorEmail: "",
      email: "",
      token: "",
      user: "",
      tempUser: "",
      tempSkills: {},
      tempNSkills: {},
      tour: true,
      nTour: true,
      header: false,
      skills: [],
      otpRecieved: false,
      otpHash: "",
      sid:"",
      otpEmail:"",
      userDetailsAlternateEmail: "",
      otpVerified: false,
      phone: "",
      type: "",
      followers: "",
      following: "",
      message: "",
      likeCount: 0,
      passwordChanged: false,
      searchUser: [],
      search: false,
      logTime: "",
      feedFilled: false,
    };
  } else if (action.type === "UNSHOW_FEEDBACK") {
    return { ...state, feedFilled: true };
  } else if (action.type === "SET_LIKE_COUNT") {
    return { ...state, likeCount: action.payload };
  } else if (action.type === "PASSWORD_CHANGED") {
    return { ...state, passwordChanged: action.payload };
  } else if (action.type === "UNSET_PASSWORD_CHANGED") {
    return { ...state, passwordChanged: false };
  } else if (action.type === SET_CONTEST_SUBMISSION_UPLOADING) {
    return { ...state, contestSubmissionUploading: action.payload };
  } else if (action.type === "SET_UPLOAD_PROGRESS") {
    return { ...state, progress: action.payload };
  } else if (action.type === "CONTRIBUTION") {
    console.log(state.contributedContests, action.payload);
    return {
      ...state,
      // contributedContests: [...state.contributedContests, action.payload],
    };

    //  return {
    //     ...state,
    //     contributedContests: Array.isArray(state.contributedContests)
    //       ? [...state.contributedContests, action.payload]
    //       : [action.payload],
    //   };
  } else if (action.type === "UNSET_CONTRIBUTION") {
    console.log(state.contributedContests);

    return {
      ...state,
      contributedContests: state?.contributedContests?.filter(
        (id) => id !== action.payload
      ),
    };
  }

  // else if(action.type===SET_SEARCH){
  //     state.searchUser.push(action.payload)
  //     return {...state}
  // }
  else if (action.type === "UNSET_SEARCH") {
    return { ...state, searchUser: "" };
  } else if (action.type === "SET_SEARCH_LOADER") {
    return { ...state, search: true };
  } else if (action.type === "CLOSE_SEARCH_LOADER") {
    return { ...state, search: false };
  } else if (action.type === "SET_HEADER") {
    return { ...state, header: true };
  } else if (action.type === "UNSET_HEADER") {
    return { ...state, header: false };
  } else if (action.type === SET_FOLLOW) {
    return {
      ...state,
      following: action.following,
      followers: action.followers,
    };
  } else if (
    action.type === UPDATE_ACCOUNT ||
    action.type === UPDATE_EMAIL ||
    action.type === UPDATE_ALTERNATE_EMAIL ||
    action.type === "CHANGE_SOCIAL_MEDIA_URL"
  ) {
    // console.log("RED: " + action.payload)
    // console.log("Reducer:" + action.payload.firstName);
    return { ...state, user: { ...action.payload } };
  } else if (action.type === "CREATE_NEW_USER") {
    return { ...state, tempUser: action.payload };
  } else if (action.type === "SET_USER_LEVEL") {
    return { ...state, user: { ...state.user, level: action.payload } };
  } else if (action.type === "ALTERNATE_EMAIL") {
    return { ...state, alternateEmail: action.payload };
  } else if (action.type === "SET_TEMP_SKILLS") {
    console.log("Reducer Temp:", action.payload);
    return { ...state, tempSkills: action.payload };
  } else if (action.type === "SET_TEMP_N_SKILLS") {
    return { ...state, tempNSkills: action.payload };
  } else if (action.type === "SET_TOUR") {
    return { ...state, tour: false };
  } else if (action.type === "UNSET_NEW_USER") {
    return { ...state, tempUser: "" };
  } else if (action.type === "UNSET_NEW_SKILLS") {
    return { ...state, tempSkills: {} };
  } else if (action.type === "UNSET_TEMP_N_SKILLS") {
    return { ...state, tempNSkills: {} };
  } else if (action.type === "SET_NOTIFICATION") {
    return { ...state, notification: true };
  } else if (action.type === "UNSET_NOTIFICATION") {
    return { ...state, notification: false };
  } else if (action.type === "UNSET_MSG_NOTIFICATION") {
    return { ...state, msgNotification: false };
  } else if (action.type === "SET_MSG_NOTIFICATION") {
    return { ...state, msgNotification: true };
  } else if (action.type === "SET_USER_PAYMENT_DETAIL") {
    return { ...state, user: { ...state.user, paymentDetail: action.payload } };
  } else {
    return state;
  }
};

export default AuthReducer;
