import React from "react";
import { ThreeDots } from "react-loader-spinner";

function FallbackUI() {
  return (
    <div className="flex bg-card justify-center items-start h-[100vh]">
      <div></div>
      <ThreeDots
        height="700"
        width="80"
        radius="9"
        color="#4fa94d"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
      <div></div>
    </div>
  );
}

export default FallbackUI;
