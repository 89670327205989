import { createContext } from "react";
import io from "socket.io-client";
import { path } from "./path";

export const socket = io(path, {
  transports: ["websocket"],
  jsonp: false,
  forceNew: false,
});

export const WebSocketContext = createContext();
