import React, { useState, useEffect } from "react";
import axios from "axios";
import { path } from "../../path";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import Man from "../Header/img/Man.svg";
import Female from "../Header/img/Female.svg";
import Others from "../Header/img/Others.svg";
import logoAnt from "../Header/img/Ant.png";
import logoAntDark from "../Header/img/Ant_Dark.png"
import PropTypes from 'prop-types'; 
import { to_Encrypt } from "../CryptoJS/CryptoJS";
import { Circles } from "react-loader-spinner";

function DmUsers({ close, msg }) {
  const { user, token } = useSelector((state) => state.AuthReducer);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [friends, setFriends] = useState([]);
  const [msgLoader, setMsgLoader] = useState("");
  const [sentIndicator, setSentIndicator] = useState([]);
  //get all friends
  const config = {
    host: `${path}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${path}`,
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    const getAllFriends = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${path}/friends/` + user._id, config);
        // console.log("RESPONSE", res);
        setFriends(res.data.friends);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };
    getAllFriends();
  }, []);

  const handleSend = async (receiverId) => {
    setMsgLoader(receiverId);
    try {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
        token,
      };

      const encryptedText = to_Encrypt(msg);

      // for conversation Id
      const res = await axios.get(
        `${path}/findConversation/${receiverId}/${user._id}`
      );

      // console.log("Res:", res);
      // for sending DM
      if (res.data !== null) {
        const message = {
          sender: user._id,
          content: encryptedText,
          chatId: res.data._id,
          readBy: [user._id],
        };
        const mes = await axios.post(`${path}/messages`, message, config);
        if (Object.keys(mes.data).length === 2) {
          setSentIndicator([...sentIndicator, receiverId]);
        }
      } else {
        const response = await axios.post(`${path}/chat`, {
          friendId: receiverId,
          userId: user._id,
        });
        const message = {
          sender: user._id,
          content: encryptedText,
          chatId: response.data._id,
          readBy: [user._id],
        };
        const mes = await axios.post(`${path}/messages`, message, config);
        if (Object.keys(mes.data).length === 2) {
          setSentIndicator([...sentIndicator, receiverId]);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setMsgLoader("");
  };
  return (
    <div className="AllFriends">
      {loading ? (
        <Circles
          height="60"
          width="60"
          color="#008080"
          ariaLabel="circles-loading"
          // wrapperStyle={{
          //   position: "absolute",
          //   top: "2.2rem",
          //   left: "1.8rem",
          // }}
          wrapperClass=""
          visible={true}
        />
      ) : (
        <div className="w-[92%] max-w-[600px] bg-card  text-primary  h-[80vh] rounded-[12px] overflow-x-hidden overflow-y-auto md:w-[100vw]">
          <div
            className="flex justify-between py-2 px-4"
            style={{ boxShadow: "4px 1px 2px 0px #8080801f" }}>
            <p className="text-lg">Chats</p>
            <button
              className="border border-red-500 rounded-lg text-red-500 px-2 hover:text-white hover:bg-red-500"
              onClick={() => {
                close(false);
              }}>
              Close
            </button>
          </div>
          <div className="px-4 py-2">
            {friends.length > 0 ? (
              friends.map((x,index) => {
                if (x === null) {return;}
                else
                  {return (
                    <div key={x._id || index} className="flex justify-between p-2 border-b">
                      <div
                        className=" flex w-[100%] items-center"
                        onClick={() => {
                          {
                            if (x._id === user._id) {
                              history.push("/profile");
                            } else {
                              history.push(`/userDetails/${x._id}`);
                            }
                            close(false);
                          }
                        }}>
                        <div>
                          {x?.profilePicture ? (
                            <div className="">
                              <Avatar
                                src={x?.profilePicture}
                                className="w-8 h-8 rounded-full"
                              />
                            </div>
                          ) : x.gender === "male" ? (
                            <Avatar src={Man} sx={{ width: 40, height: 40 }} />
                          ) : x.gender === "female" ? (
                            <Avatar src={Female} sx={{ width: 40, height: 40 }} />
                          ) : x.gender === "others" ? (
                            <Avatar src={Others} sx={{ width: 40, height: 40 }} />
                          ) : x.theme === "dark" ? (
                            <Avatar src={logoAntDark} sx={{ width: 40, height: 40 }} />
                          ) : (
                            <Avatar
                              src={logoAnt}
                              sx={{ width: 40, height: 40 }}
                            />
                          )}
                        </div>
                        <div className="flex flex-col mx-2 h-min">
                          <h2 className="font-semibold">
                            {x?.firstName + " " + x?.lastName}
                          </h2>
                        </div>
                      </div>
                      {sentIndicator.includes(x?._id) ? (
                        <button disabled className="text-grey-500">
                          Sent
                        </button>
                      ) : (
                        <button
                          className="cursor-pointer text-[#008080]"
                          onClick={() => handleSend(x._id)}>
                          {msgLoader === x?._id ? "Sending" : "Send"}
                        </button>
                      )}
                    </div>
                  );}
              })
            ) : (
              <p className="text-slate-300">You have no peers.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

 // PropTypes definition
 DmUsers.propTypes = {
  close: PropTypes.func.isRequired,
  msg: PropTypes.string.isRequired,
};

export default DmUsers;
