/* eslint-disable no-warning-comments */
/* eslint-disable react/no-array-index-key */

import "./AllFriends.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import React from "react";
import feedants_logo from "../competition/yellow.png";

function AllFriends(users) {
  

  const history = useHistory();
  const { user } = useSelector((state) => state.AuthReducer);
  const frnd = user?.friends;
  const MutualFriends = (BioUser) => {
    if (BioUser === null) {
      return "";
    }
    let MutualFriends = frnd.filter((x) => BioUser.friends.includes(x));

    return MutualFriends.length > 0 && MutualFriends.length < frnd.length
      ? `${MutualFriends.length} Mutual Peers `
      : MutualFriends.length === 0 && `No Mutual Peers`;
  };

  return (
    <div className="rounded-t-lg AllFriends bg-primary">
      <div className="AllFriendsContainer w-[80%] sm:w-[30%] border border-viewMore">
        <div className="top rounded-t-[12px]">
          <p className="ml-[1vw] mt-[1vh] font-gilroy-semibold">
            {users.type === "wirings" ? "Wirings" : "All Peers"}
          </p>
          <button
            onClick={() => {
              users.close(false);
            }}>
            ❌
          </button>
        </div>
        {users?.user.length > 0 ? (
          <div
            className="allfriends flex flex-col py-2 gap-4"
            data-testid="allFriends-div">
            {users?.user?.map((x, index) => {
              if (x === null) {return;}
              else
                {return (
                  <>
                    <div
                      key={index}
                      onClick={() => {
                        {
                          if (x?._id === user._id) {
                            history.push("/profile");
                          } else {
                            history.push(`/userDetails/${x._id}`);
                          }
                          users.close(false);
                        }
                      }}
                      className="flex cursor-pointer flex-1 items-center gap-2 p-1.5 md:py-0.5 rounded-md  max-h-[53px]">
                      {x?.profilePicture !== "" && x?.profilePicture ? (
                        <img
                          loading="lazy"
                          src={x?.profilePicture}
                          alt="profilePic"
                          className="shrink-0 aspect-square rounded-md w-[39px] h-[39px]"
                        />
                      ) : (
                        <img
                          loading="lazy"
                          src={feedants_logo}
                          alt="profilePic"
                          className="shrink-0 aspect-square ml-1 rounded-md w-[29px] h-[29px]"
                        />
                      )}

                      <div className="flex flex-col items-start justify-center">
                        <h2 className="flex-auto my-auto text-compSec font-gilroy-semibold text-xs">
                          {" "}
                          {x.firstName} {x.lastName}{" "}
                        </h2>
                        {MutualFriends(x) === null ? (
                          ""
                        ) : (
                          <h3 className="text-[0.6rem] mt-0 text-[#8D8D8D] mx-0">
                            {MutualFriends(x)}
                          </h3>
                        )}
                      </div>
                    </div>
                    {/* <div
                      className=" flex border bg-feedNeutral hover:cursor-pointer hover:bg-neutral transition duration-200 border-x-0 border-viewMore border-t-0 w-[100%] p-[10px] items-center content-center"
                      data-testid="allFriends"
                      onClick={() => {
                        {
                          if (x?._id === user._id) {
                            history.push("/profile");
                          } else {
                            history.push(`/userDetails/${x._id}`);
                          }
                          users.close(false);
                        }
                      }}>
                      <div>
                        {x?.profilePicture ? (
                          <div className="">
                            <Avatar
                              src={x?.profilePicture}
                              className="w-8 h-8 rounded-full"
                            />
                          </div>
                        ) : x.gender === "male" ? (
                          <Avatar src={Man} sx={{ width: 40, height: 40 }} />
                        ) : x.gender === "female" ? (
                          <Avatar src={Female} sx={{ width: 40, height: 40 }} />
                        ) : x.gender === "others" ? (
                          <Avatar src={Others} sx={{ width: 40, height: 40 }} />
                        ) : x.theme === "dark" ? (
                          <Avatar
                            src={logoAntDark}
                            sx={{ width: 40, height: 40 }}
                          />
                        ) : (
                          <Avatar
                            src={logoAnt}
                            sx={{ width: 40, height: 40 }}
                          />
                        )}
                      </div>
                      <div className="flex flex-col mx-2 cursor-pointer h-min">
                        <h2 className="font-semibold">{x?.username}</h2>
                        <p className="text-[0.6rem] text-[#8D8D8D] mx-0">
                          {
                            //x?.username
                          }
                        </p>
                        {MutualFriends(x) == null ? (
                          ""
                        ) : (
                          <h3 className="text-[0.6rem] mt-0 text-[#8D8D8D] mx-0">
                            {MutualFriends(x)}
                          </h3>
                        )}
                      </div>
                    </div> */}
                  </>
                );}
            })}
          </div>
        ) : (
          <p className="text-primary">No peer(s) available</p>
        )}
      </div>
    </div>
  );
}

export default React.memo(AllFriends);
