import React from 'react'

function B({ fields ,skill}) {
    return (
        <div className="flex text-primary justify-between items-start p-3 pb-0 overflow-x-none">
            <div>
                <h1 className="font-gilroy-semibold text-compSec text-base">{fields[0]?.response}</h1>
                <h3 className="font-gilroy-medium flex justify-start gap-2 items-center dark:text-[#9C9C9D] text-compPrimary text-xs">{skill} <p className='bg-[#D9D9D9] rounded-full w-1 h-1 inline-block text-center'></p> {fields[2]?.response} <p className='bg-[#D9D9D9] rounded-full w-1 h-1 inline-block text-center'></p> {fields[3]?.response}</h3>
            </div>
            <h1 className="font-gilroy-semibold whitespace-nowrap text-[#008080] mlg:text-base text-xs mlg:mt-0 lg:mt-1">&#8377; {fields[1]?.response}</h1>
        </div>
    )
}

export default B