import React, { useRef } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from "@mui/material";
import axios from "axios";
import { path } from "../../path";
import Man from "./img/Man.svg";
import Female from "./img/Female.svg";
import Others from "./img/Others.svg";
import logoAnt from "./img/Ant.png";
import logoAntDark from "./img/Ant_Dark.png";
import ProfilePicture from "../ProfilePicture/ProfilePicture";

function SearchPopup({ searchResultDisplay, text, setSearchResultDisplay, searchdata, setText }) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.AuthReducer);
    const searchResultsRefs = useRef(null);

    const recentSearchFun = async (searchUser) => {
        let searchedUserId = searchUser?._id;
        try {
            await axios.post(`${path}/recentSearches/`, {
                searchedUserId,
                userId: user?._id,
            });
            //response.data.flag ? fetchRecentUser() : alert(response.data.msg)
            //response.data.flag ? console.log("fetchRecentUser()") : alert('response.data.msg')
        } catch (error) {
            console.log("recent catch error", error.response.data);
        }
    };
    return (
        <div
            className={`flex flex-col relative bottom-[4.37rem] w-full sm:top-15 bg-primary overflow-x-hidden ${(searchResultDisplay && text.length > 0) ? "border border-viewMore rounded-b-2xl border-t-primary" : ""} `}>


            <div className={` text-primary ${text.length && searchResultDisplay > 0 ? "block" : "hidden"} mx-3 font-bold my-1`}> Search results</div>
            {searchResultDisplay && searchdata[0]?.length > 0 ? (
                <div
                    className="w-[100%] rounded-md  srch lg:grid lg:grid-cols-2 overflow-x-hidden whitespace-nowrap lg:gap-2 px-2"
                    ref={searchResultsRefs}>
                    {text.length > 0 &&
                        searchdata[0].map((u) => {
                            if (u._id !== user._id) {
                                return (
                                    <Link
                                        to={`/userDetails/${u._id}`}
                                        key={u._id}
                                        onClick={() => {
                                            //setSearchResultDisplay(false);
                                            //setText("");
                                            setSearchResultDisplay((prev) => !prev);
                                            dispatch({ type: "LOGOUT_SR" });
                                            recentSearchFun(u);
                                        }}
                                        className="flex mobil items-center overflow-none px-2 py-1 mt-2 space-x-1 transition duration-200 hover:bg-neutral ">
                                        {u.profilePicture ? (
                                            <Avatar src={u.profilePicture} />
                                        ) : u.gender === "male" ? (
                                            <Avatar src={Man} />
                                        ) : u.gender === "female" ? (
                                            <Avatar src={Female} />
                                        ) : u.gender === "others" ? (
                                            <Avatar src={Others} />
                                        ) : u.theme === "dark" ? (
                                            <Avatar src={logoAntDark} />
                                        ) : (
                                            <Avatar src={logoAnt} />
                                        )}
                                        <div className="flex flex-col w-[70%]">
                                            <span className="text-[#637381]  whitespace-nowrap overflow-hidden text-ellipsis font-semibold dark:text-primary ">
                                                {" "}
                                                {u.firstName} {u.lastName}
                                            </span>
                                            <p className=" text-[#637381] text-[0.5rem] dark:text-primary whitespace-nowrap overflow-hidden text-ellipsis">
                                                {u.username}
                                            </p>
                                        </div>
                                    </Link>
                                );
                            } else {
                                return (
                                    <Link
                                        to={`/profile`}
                                        key={u._id}
                                        onClick={() => {
                                            // setsearchdata(false);
                                            setSearchResultDisplay((prev) => !prev);
                                            setText("");
                                        }}
                                        className="flex items-center px-2 py-1 space-x-1 transition duration-200  bg-feedNeutral hover:bg-neutral border-viewMore">
                                        <ProfilePicture profilePic={u} />
                                        <div className="flex flex-col w-[70%]">
                                            <span className="text-[#637381] font-gilroy-semibold dark:text-primary overflow-hidden text-ellipsis">
                                                {" "}
                                                {u._id === user._id
                                                    ? u.username + " (You)"
                                                    : u.username}
                                            </span>
                                            <p className="text-[0.5rem] text-[#637381] dark:text-primary ">
                                                {u.firstName} {u.lastName} (You)
                                            </p>
                                        </div>
                                    </Link>
                                );
                            }
                        })}
                </div>
            ) : (
                text.length > 0 &&
                searchResultDisplay === true && (
                    <h2 className="p-2 pl-4 text-[#637381] font-semibold">
                        No User Found!!
                    </h2>
                )
            )}
        </div>
    )
}

export default SearchPopup