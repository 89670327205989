/* eslint-disable no-warning-comments */
 
/* eslint-disable react/no-array-index-key */

import React, { useState, useEffect, useContext } from "react";
import MobileFooter from "../footer/MobileFooter";
// import "./competition.css";
import { path } from "../../path";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import ParticipateUserList from "./ParticipateUserList";
import { WebSocketContext } from "../../WebSocket";

import { useParams } from "react-router-dom";
import B from "./CompetitionUI/B";
import C from "./CompetitionUI/C";
import D from "./CompetitionUI/D";
import E from "./CompetitionUI/E";
import F from "./CompetitionUI/F";
import G from "./CompetitionUI/G";
import A from "./CompetitionUI/A";
import { toast } from "react-toastify";

import Winner from "./CompetitionUI/Winner";
import {
    unsetContribution,
} from "../../store/asyncMethods/AuthMethods";
const CompetitionMobile = () => {


    const socket = useContext(WebSocketContext);
    const { user, token
    } = useSelector((state) => state.AuthReducer);
    const [contestData, setContestData] = useState(null);
    const [userRegister, setuserRegister] = useState(false);
    const [userSubmission, setuserSubmission] = useState(true);
    const [currentDate, setcurrentDate] = useState(new Date());
    const [RegistrationEnd, setRegistrationEnd] = useState();
    const [SubmissionStart, setSubmissionStart] = useState();
    const [SubmissionEnd, setSubmissionEnd] = useState();
    const [ParticipatedUser, setParticipatedUser] = useState(true);
    const [isMediaUrl, setIsMediaUrl] = useState(false);
    const [isPaid, setIsPaid] = useState();
    const paymentInitializing= false
    const [isRegistered, setIsRegistered] = useState(false);
    const [resultDate, setResultDate] = useState();

    const history = useHistory();
    const { id } = useParams();
    const fee = contestData?.responses?.sections[2].fields[1].response;

    const dispatch = useDispatch();
    const eventId = id;

    const config = {
        host: `${path}`,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${path}`,
            Authorization: `Bearer ${token}`,
        },
    };
    const getcontestdetails = async () => {
        try {
            console.log(id)
            const res = await axios.get(`${path}/contest/getContestsbyId/${id}`)
            setContestData(res?.data?.data)
            console.log(res?.data?.data?.responses?.sections[3].fields[0].response)
            // RegistrationEnd.current.value = res?.data?.data?.responses?.sections[3].fields[0].response;
            setRegistrationEnd(ConvertToDate(res?.data?.data?.responses?.sections[3].fields[0].response));
            setSubmissionEnd(ConvertToDate(res?.data?.data?.responses?.sections[3].fields[3].response))
            setSubmissionStart(ConvertToDate(res?.data?.data?.responses?.sections[3].fields[2].response))
            setResultDate(ConvertToDate(res?.data?.data?.responses.sections[3]?.fields[1]?.response))

            // console.log(RegistrationEnd.current.toUTCString())
        } catch (err) { console.log(err) }
    }

    const ConvertToDate = (stringDate) => {
        let dateString = stringDate;
        // Extract date and time components
        let [datePart, timePart] = dateString.split(" at ");

        // Extract year, month, and day
        let [year, month, day] = datePart.split("-");

        // Extract hour and minute
        let [hour, minute] = timePart.split(":");

        // Create a new Date object
        let dateObject = new Date(year, month - 1, day, hour, minute);

        return dateObject
    }

    // const Register = async () => {

    // }




    const createSumbission = async () => {
        if (userSubmission) { return; }
        history.push(`/submission/${id}`);
    }

    const checkRegistrationStatus = async () => {
        const res = await axios.get(`${path}/contest/hasUserRegister/${id}/${user._id}`, config)
        if (res?.data?.userRegister) { setuserRegister(true); checkSubmissionStatus() }
    }



    const checkSubmissionStatus = async () => {
        const response = await axios.get(`${path}/contest/hasUserContributed/${id}/${user._id}`, config);
        if (response?.data?.length === 0 || response?.data === null) { setuserSubmission(false); }

        if (response.data) {
            setIsPaid(response.data.paid);
            console.log("prevUploadingStatus: ");

            setIsMediaUrl(response.data.mediaUrl ? true : false);
            // setIsProcessing(response.data.processing);
            console.log("mediaUrl has user: ", response.data.mediaUrl);
            // dispatch(setContribution(eventId));
        } else {
            dispatch(unsetContribution(eventId));
        }
    }

    useEffect(() => {
        getcontestdetails();
        checkRegistrationStatus();
        // console.log(contestData.responses)
    }, [])

    const register = async () => {
        const config = {
            host: `${path}`,
            headers: {
                "Access-Control-Allow-Origin": `${path}`,
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.post(
            `${path}/contest/joinnow`,
            {
                user: user,
                contestId: contestData?._id,
            },
            config
        );

        setIsRegistered(true);

    }

    useEffect(() => {
        const changeDate = () => {
            setcurrentDate(new Date());
        }
        socket.on("submission-start", changeDate);
        socket.on("submission-stop", changeDate);
        socket.on("registration-end", changeDate);
        socket.on("submission-stop", changeDate);

        socket.on("on_contest_submission", (data) => {
            if (data && data?.contestId === contestData?._id) {
                console.log("on_contest_submission: ", data);
                setIsMediaUrl(data.mediaUrl);
                // setIsProcessing(data.processing);
            }
        });


        return () => {
            socket.off("submission-start", changeDate);
            socket.off("submission-stop", changeDate);
            socket.off("registration-end", changeDate);
            socket.off("submission-stop", changeDate);
        }

    }, [socket])

    const razorPay = async () => {
        /** Payment Method using razor pay */
        const orderOptions = {
            amount: parseInt(fee) * 100,
            currency: "INR",
            receipt: user.username + Date.now(),
        };

        const config = {
            host: `${path}`,
            headers: {
                "Access-Control-Allow-Origin": `${path}`,
                Authorizatison: `Bearer ${token}`,
            },
        };

        const res = await axios.post(`${path}/payment/createOrder`, { orderOptions, config }) //create a if else statement here to check the failure or create order api
        var options = {
            "key": "rzp_test_0afNUeK57l5gZi", // Enter the Key ID generated from the Dashboard
            "amount": orderOptions.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "Feedants",
            "description": "Contest Registration",
            "image": "https://example.com/your_logo",
            "order_id": res.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            // "callback_url": "http://localhost:4000/payment/validatePayment",
            "handler": async function (response) {
                const options = { ...response };
                const registration = {
                    user: user,
                    contestId: contestData?._id,
                }
                const validatePayment = await axios.post(`${path}/payment/validatePayment`, { options, registration })
                console.log(validatePayment, "validPayment");
                if (validatePayment.status === 201) {
                    setIsPaid(true);

                    alert("Registered Successfully")
                }
                else {
                    alert("failed")

                }
            },
            "prefill": {
                "name": "Aryan",
                "email": "aryan@example.com",
                "contact": "9000090000"
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            //proper handlling is required
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    }


    const componentMap = {
        A, B, C, D, E, F, G, Winner
    };

    const DynamicComponent = ({ componentName, ...props }) => {
        const Component = componentMap[componentName];
        if (!Component) {
            return <div>Component not found</div>;
        }
        return <Component {...props} />;
    };

    useEffect(() => {

        if (contestData && contestData?.participatedUser.some((par) => par?._id === user?._id)) {
            setIsRegistered(true);
        }
      
    }, [contestData, user])


    // console.log(contest.responses)
    // console.log(contest.responses.sections[1])
    return (
        <>
            {contestData !== null && <div className="flex pb-14 flex-col mx-auto overflow-auto rounded-3xl bg-compBgSec w-full h-[100vh]">

                {ParticipatedUser ?
                    <>
                        <div className="flex gap-5 justify-between self-center px-5 mt-12 w-full font-semibold text-primary max-w-[392px]">
                            <h1 onClick={() => history.push("/competition")} className="text-base cursor-pointer font-gilroy-semibold text-compSec">Competitions</h1>
                            <h1 onClick={() => setParticipatedUser(false)} className="text-sm text-right  font-gilroy-semibold cursor-pointer text-compSec ">Participants ({contestData?.participatedUser?.length})</h1>
                        </div>
                        <div className="flex flex-col gap-2 self-center pt-px pb-4 mt-4 w-full bg-compBg rounded-lg max-w-[362px]">
                            {/* Section A*/}
                            {contestData.responses.sections.map((section, index) => {
                                if (index === 0) { return <></> }
                                let SectionComponent = section.title.split("_")[1];
                                if (SectionComponent === "B"){
                                    <DynamicComponent key={index} componentName={SectionComponent} fields={section.fields} skill={
                                        contestData?.responses?.sections[0].description
                                    }
                                    />
                                }
                                if (SectionComponent === "H") { return ""; }
                                // Check for contestData.result and use Winner component if applicable
                                if (SectionComponent === "F" && contestData.result && contestData.result.length > 0) {
                                    return <DynamicComponent key={index} componentName="Winner" contestId={contestData._id} />;
                                }
                                return <DynamicComponent key={index} componentName={SectionComponent} fields={section.fields} />
                            })}
                            {/* <div className="p-2">
                                {userRegister === true ? (
                                    <>
                                        {currentDate < SubmissionStart ? <button className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4">
                                            Registrated
                                        </button> : (
                                            <>
                                                {currentDate < SubmissionEnd ? <button onClick={createSumbission} className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4">
                                                    {userSubmission ? "Already Submitted" : "Create Submission"}
                                                </button>
                                                    :
                                                    <button className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4">
                                                        Sumission Ended
                                                    </button>}
                                            </>)}
                                    </>
                                ) : (
                                    <>
                                        {currentDate < RegistrationEnd ? (
                                            <>
                                                <button onClick={register} className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4">
                                                    Registration Started
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4">
                                                    Registation Closed
                                                </button>
                                            </>
                                        )}
                                    </>
                                )}
                            </div> */}

                            <div className="p-2">
                                {

                                    user.judge.includes(eventId) ? (
                                        resultDate < currentDate ? (
                                            <button
                                                className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center rounded-full w-auto whitespace-nowrap"

                                                onClick={() => {
                                                    history.push(`/rewards/${eventId}`);
                                                }}
                                            >
                                                <p className="text-white">View Result</p>
                                            </button>
                                        ) : (
                                            <button
                                                className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center rounded-full w-auto whitespace-nowrap"

                                                onClick={() => {
                                                    if (SubmissionEnd > currentDate) {

                                                        toast.error("Cannot check submission before submission end date!")
                                                    } else {
                                                        history.push(`/submission/${eventId}`)
                                                    }
                                                }}
                                            >
                                                <p className="text-white">Check Submissions</p>
                                            </button>
                                        )
                                    ) : resultDate < currentDate ? (
                                        <button
                                            className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center rounded-full w-auto whitespace-nowrap"

                                            onClick={() => {
                                                history.push(`/rewards/${eventId}`);
                                            }}
                                        >
                                            <p className="text-white">View Result</p>
                                        </button>
                                    ) : SubmissionEnd < currentDate ? (
                                        <button
                                            className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center rounded-full w-auto whitespace-nowrap"

                                            disabled={true}>
                                            <p className="text-white">Contest Ended!</p>
                                        </button>
                                    ) : userRegister === true ? (
                                        <>
                                            {currentDate < SubmissionStart ? (
                                                <button
                                                    className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4"
                                                >
                                                    Submission has not started yet!
                                                </button>
                                            ) : userSubmission ? (
                                                isMediaUrl ? (
                                                    <button
                                                        className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4"
                                                        onClick={() => {
                                                            if (SubmissionEnd > Date.now()) {
                                                                razorPay();
                                                            } else {
                                                                toast.error("Submission time ended.");
                                                            }
                                                        }} // payment function
                                                        disabled={isPaid || paymentInitializing}
                                                    >
                                                        {isPaid ? (
                                                            <p className="">Under Evaluation</p> //isMediaUrl && !isProcessing && isPaid -> under evaluation
                                                        ) : (
                                                            <p className="">Pay for Evaluation</p> //isMediaUrl && !isProcessing && !isPaid -> payment enabled
                                                        )}
                                                    </button>
                                                ) : <button
                                                    className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center rounded-full w-auto whitespace-nowrap"
                                                    disabled={true}
                                                >
                                                    <p>Pay for Evaluation</p>
                                                </button>
                                            ) : (
                                                <>
                                                    {currentDate < SubmissionEnd ? (
                                                        <button onClick={createSumbission} className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4">
                                                            Create Submission
                                                        </button>
                                                    ) : (
                                                        <button className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4">
                                                            Submission Ended
                                                        </button>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {currentDate < RegistrationEnd ? (
                                                <>
                                                    {
                                                        isRegistered ? <button className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center rounded-full w-auto whitespace-nowrap" disabled>
                                                            Participated
                                                        </button> : <button onClick={register} className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center rounded-full w-auto whitespace-nowrap">
                                                            Registration Now
                                                        </button>
                                                    }
                                                </>

                                            ) : (
                                                <button className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4" disabled>
                                                    Registration Closed
                                                </button>
                                            )}
                                        </>
                                    )}
                            </div>

                        </div>
                    </>
                    :
                    <>
                        <ParticipateUserList userList={contestData?.participatedUser} closeList={setParticipatedUser} />
                    </>}
                <MobileFooter />
            </div>}
        </>
    );
}


export default CompetitionMobile;