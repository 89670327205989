import React, { useEffect, useState } from "react";
import axios from "axios";
import { path } from "../../../path";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

function AddSkill() {
  const dispatch = useDispatch();
  const [allSkills, setAllSkills] = useState([]);
  const [showAddDialogue, setShowAddDialogue] = useState(false);

  const getSkills = async () => {
    axios({
      method: "get",
      url: `${path}/getTheSkills`,
    }).then(function (response) {
      setAllSkills(response.data.response);
      console.log(response.data.response);
    });
  };
  useEffect(() => {
    getSkills();
  }, []);
  const DeleteSkill = async (id) => {
    try {
      let conf = window.confirm("Are you sure you want to delete the skill?");
      if (conf) {
        const news = allSkills.filter((skill) => skill._id !== id);
        setAllSkills(news);
      }
    } catch (error) {
      alert("Some error", error);
    }
  };

  useEffect(() => {
    dispatch({ type: "UNSET_HEADER" });
  }, []);

  const { token } = useSelector((state) => state.AuthReducer);

  const [state, setState] = useState({
    newSkill: "",
    image: "",
  });
  const toastOption = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 2000,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  };
  const fileHandler = (e) => {
    setState({ ...state, image: e.target.files[0] });
  };

  const skillSubmitHandler = async (e) => {
    e.preventDefault();
    const { newSkill, image } = state;

    if (!newSkill) {
      alert("Skill name is empty.");
      return;
    }

    if (!image) {
      alert("Upload an image.");
      return;
    }
    const characterCount = newSkill.replace(/\s/g, "").length;
    if (characterCount < 3) {
      toast.error("Minimum 3 characters needed without space", toastOption);
    }
    const formData = new FormData();
    formData.append("name", newSkill);
    formData.append("image", image);

    try {
      const res = await axios.post(`${path}/addNewSkill`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res);
      setState({
        newSkill: "",
        path: "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const submitHandler = () => {
    skillSubmitHandler();
    setShowAddDialogue(false);
    setState({
      newSkill: "",
      image: "",
    });
    getSkills();
  };

  return (
    <div className="text-primary bg-page min-h-[100vh] px-2  py-[3rem] overflow-x-none overflow-y-auto">
      {/* THIS SHOWS ADD NEW SKILL DIALOGUE BOX */}
      {showAddDialogue ? (
        <div className="fixed top-0 left-0 flex justify-center items-center bg-[#000000b3] w-[100%] h-[100%] z-30">
          <div className="flex flex-col p-4 w-[25%] bg-white rounded-lg">
            <p className="text-[1.05rem]">
              Are you sure want to add new skill?
            </p>
            <div className="ml-auto mt-6">
              <button
                className="px-6 py-1 rounded-lg text-white bg-[#008080]"
                onClick={() => submitHandler()}>
                Yes
              </button>
              <button
                className="px-6 py-1 ml-2 rounded-lg"
                onClick={() => setShowAddDialogue(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <form className="ml-4" onSubmit={skillSubmitHandler}>
        <div className="flex space-x-2 my-4">
          <label htmlFor="newSkill" className="w-40 font-bold">
            Enter skill name:{" "}
          </label>
          <input
            type="text"
            name="newSkill"
            className="border-2 px-2 w-[38vw]"
            onChange={(e) => {
              setState({ ...state, newSkill: e.target.value });
            }}
            value={state.newSkill}
          />
        </div>
        <div className="flex space-x-2 ">
          <label htmlFor="image" className="w-40 font-bold text-primary">
            Skill photo:{" "}
          </label>
          <input
            type="file"
            accept=".png, .jpeg, .jpg"
            name="image"
            onChange={fileHandler}
          />
        </div>
        <input
          type="submit"
          value="Submit"
          className="cursor-pointer border-2 border-[#008080] bg-[#008080] text-white rounded-lg mt-4 px-6 py-2"
          onClick={(e) => {
            e.preventDefault();
            setShowAddDialogue(true);
          }}
        />
      </form>
      <table className="border-collapse border-2 m-4">
        <thead className="border-b-2">
          <tr>
            <th className="border-r-2 px-2">S.No</th>
            <th className="border-r-2 px-2">Skill</th>
            <th className="border-r-2 px-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {allSkills.map((u, index) => (
            <tr key={u?._id}>
              <td className="border-r-2 px-2 text-[0.8rem] text-center">
                {index + 1}
              </td>
              <td className="border-r-2 px-2 text-[0.8rem] text-center">
                {u.name}
              </td>
              <td className="border-r-2 px-2 text-[0.8rem] text-center">
                <button
                  className="text-red-500"
                  onClick={() => DeleteSkill(u?._id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AddSkill;
