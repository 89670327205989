import React from "react";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import FormWrapper from "./FormWrapper.js";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PropTypes from "prop-types";

const FormInput = React.forwardRef(
  (
    {
      name,
      label,
      placeholder,
      type,
      max,
      value,
      onChange,
      onBlur,
      onFocus,
      error,
      touched,
      inputProps,
      children,
      helperText,
      wrapperProps = {},
      onButtonClick,
      passwordView,
      iconClassname,
      checked,
      width,
      height,
      fontSize,
      autoComplete,
    },
    ref
  ) => {
    return (
      <FormWrapper
        isInvalid={Boolean(error && touched)}
        wrapperProps={wrapperProps}
        helperText={helperText}
        label={label}
        touched={touched}
        error={error}>
        <InputGroup size="md">
          <Input
            autoComplete={autoComplete}
            name={name}
            placeholder={placeholder}
            className="text-primary bg-secondary focus:outline-none focus:ring-2 ring-[#0080806e] mt-1 accent-[#006969]"
            value={value}
            checked={checked}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus && onFocus}
            // styles
            type={type ? type : "text"}
            max={max}
            width={width ? width : "100%"}
            maxHeight="none !important"
            height={height ? height : "30px"}
            fontSize={fontSize ? fontSize : "0.6rem"}
            fontWeight="500"
            px="20px"
            // border="1px solid #c0bcd7"
            bg="inputBg"
            borderRadius="10px"
            focusBorderColor="primary"
            errorBorderColor="errorRed"
            _placeholder={{
              color: "text.placeholder",
            }}
            ref={ref}
            {...inputProps}
          />
          {iconClassname && (
            <InputRightElement width={"3.5rem"} height={"100%"}>
              {name === "password" &&
                (passwordView ? (
                  <VisibilityIcon
                    className={iconClassname}
                    onClick={onButtonClick}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <VisibilityOffIcon
                    className={iconClassname}
                    onClick={onButtonClick}
                    style={{ cursor: "pointer" }}
                  />
                ))}
              {name === "username" && (
                <CheckCircleIcon className={iconClassname} />
              )}
            </InputRightElement>
          )}
        </InputGroup>

        {children}
      </FormWrapper>
    );
  }
);


FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.string,
  touched: PropTypes.bool,
  inputProps: PropTypes.object,
  children: PropTypes.node,
  helperText: PropTypes.string,
  wrapperProps: PropTypes.object,
  onButtonClick: PropTypes.func,
  passwordView: PropTypes.bool,
  iconClassname: PropTypes.string,
  checked: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.string,
};
FormInput.displayName = "FormInput";

export default FormInput;
