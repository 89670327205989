import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
const RouteLinks = (props) => {
  const { user } = useSelector((state) => state.AuthReducer);
  return user ? (
    <Redirect to="/skills" />
  ) : (
    <Route path={props.path} exact={props.exact} component={props.component} />
  );
};

RouteLinks.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
};
export default RouteLinks;
