import { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
// import LogoLight from "../logo/logoLight.svg";
import LogoDark from "../logo/dark logo.png";
import Logo from "../logo/Logo-design.png";
import illustration from "../logo/Illustration.svg";
import signup_bg_dark from "../logo/signup_bg.svg";
import "./signUp.css";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { path } from "../../../path";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AlertInvalidFormat from "../../Alert/AlertInvalidFormat";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "../../formComponents/FormInput";
import React from "react";
import { createRef } from "react";
import { useCookies } from "react-cookie";
import { SendHGoogleEvent } from "../../../analytics/ga";

export default function GoogleDetails() {
  const isSubmit =false;

  const [usernameIconColour, setusernameIconColour] =
    useState("text-slate-300");
  const [passwordView, setpasswordView] = useState(true);
  const [pwdType, setPwdType] = useState("password");
  //accessing store values
  const history = useHistory();
  const { state } = useLocation();
  // console.log(state);

  const [UsernameExistAlert, setUsernameExistAlert] = useState(false);
  const dispatch = useDispatch();

  const [InvalidFormatAlert, setInvalidFormatAlert] = useState("");
  const [accLoader, setAccLoader] = useState(false);
  const [theme, ] = useCookies(["Theme"]);
  const validUnmount = false;
  const [googleuser, Setgoogleuser] = useState(state);
  const [randomUsername, SetrandomUsername] = useState([]);

  function generateRandomUsername(firstName, lastName) {
    const randomNumber = Math.floor(Math.random() * 100); // You can adjust the range of the random number if needed
    const dotOrUnderscore = Math.random() < 0.5 ? "." : "_";

    let usernameOptions = [
      `${firstName}${dotOrUnderscore}${lastName}`,
      `${firstName}${randomNumber}}${dotOrUnderscore}`,
      `${firstName}_${lastName}${randomNumber}`,
      `${lastName}${dotOrUnderscore}${firstName}`,
      `${lastName}${randomNumber}}${dotOrUnderscore}`,
      `${lastName}_${firstName}${randomNumber}`,
      `${firstName}.${lastName}`,
      `${lastName}.${firstName}`,
    ];

    // Filter out usernames with special characters and adjust length
    usernameOptions = usernameOptions.filter(
      (username) =>
        username.length >= 5 &&
        username.length <= 30 &&
        !/[^a-zA-Z0-9_.]/.test(username)
    );

    const randomIndex = Math.floor(Math.random() * usernameOptions.length);
    return usernameOptions[randomIndex];
  }

  const setUserField = (e) => {
    // console.log(e.target.innerText)
    setFieldValue("username", e.target.innerText);
  };

  const searchUsername = async (val) => {
    try {
      const response = await axios.post(`${path}/searchusername`, {
        username: val,
      });
      return response.data.user;
    } catch (error) {
      console.log(error);
    }
  };

  const toastOption = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 3000,
    pauseOnHover: false,
    draggable: true,
    theme: "colored",
  };

  const passwordRef = createRef();
  const googleSignup = async () => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
      },
    };

    try {
      let datee = undefined;
      if (
        googleuser?.birthday?.year !== undefined &&
        googleuser?.birthday?.month !== undefined &&
        googleuser?.birthday?.day !== undefined
      ) {
        datee = new Date();
        datee.setFullYear(googleuser?.birthday?.year);
        datee.setMonth(googleuser?.birthday?.month);
        datee.setDate(googleuser?.birthday?.day);
      }
      console.log(datee);
      const response = await axios.post(
        `${path}/googlesignup`,
        {
          firstName: googleuser?.firstname,
          lastName: googleuser?.lastname,
          password: googleuser?.password,
          gender: googleuser?.gender,
          username: googleuser?.username,
          email: googleuser?.email,
          birthdate: datee,
        },
        config
      );
      if (response?.data?.success === true) {
        dispatch({ type: "SET_HEADER" });
        localStorage.setItem("myToken", response.data.token);
        const date = new Date();
        console.log("Date:", date.getMinutes());
        dispatch({
          type: "SET_USER",
          token: response.data.token,
          user: response.data.user,
          loginTime: date.getMinutes(),
        });
        SendHGoogleEvent("google signup", "User signup Successfull");
        history.push("/timeline");
      } else if (response?.data?.success === false) {
        toast.error(response?.data?.msg, toastOption);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // console.log(googleuser);
  }, [googleuser]);

  useEffect(() => {
    return () => {
      if (!validUnmount) {
        window.gtag("event", "signup_incomplete", {
          event_category: "User Interaction",
          event_label: "User started signup but left incomplete on signup page",
        });
        // console.log("unmounting log of signup");
      }
    };
  }, []);

  const getUsername = async () => {
    try {
      const response = await axios.post(`${path}/searchusername`, {
        username: values.username,
      });
      return response.data.user;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: {
      username: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .required("Username is required")
        .test("username", "Invalid Username", (val) => {
          const user_regex =
            /^(?=.*[a-zA-Z0-9])(?=.*[._])(?!.*[`!@#$%^&*()|<>?~ ]).{5,30}$/;
          if (user_regex.test(val)) {
            setusernameIconColour("text-teal-600");
            return true;
          }
          setusernameIconColour("text-slate-300");
          return false;
        })
        .test("username", "Username is already taken", (val) => {
          console.log("duplicate test running");

          async function getUsernameIner() {
            const res1 = await getUsername();
            const user_regex =
              /^(?=.*[a-zA-Z0-9])(?=.*[._])(?!.*[`!@#$%^&*()|<>?~ ]).{5,30}$/;
            if (user_regex.test(val)) {
              if (res1 !== null) {
                setusernameIconColour("text-slate-300");
                return false;
              }
              setusernameIconColour("text-teal-600");
              return true;
            }
          }

          return getUsernameIner();
        }),

      password: Yup.string()
        .required("Password is required")
        .matches(/^[a-zA-Z0-9!@#$%^&*()_+|<>?~].{6,15}$/gm, {
          message: "Password must be 7-16 characters long",
        }),

      confirmPassword: Yup.string()
        .required("Passwords don't match.")
        .oneOf([Yup.ref("password"), null], "Passwords don't match."),
    }),
    onSubmit: async () => {
      // e.preventDefault();
      // handleValidation() === true &&
      setAccLoader(true);
      const res1 = await getUsername();
      // console.log(googleuser);
      // console.log(res);
      if (res1 !== null) {
        setAccLoader(false);
        setUsernameExistAlert(true);

        setTimeout(() => {
          setUsernameExistAlert(false);
        }, 1500);
      } else {
        googleSignup();
      }

      // history.push("/timeline");
    },
  });

  useEffect(() => {
    Setgoogleuser((perv) => ({ ...perv, ...values }));
  }, [values]);

  useEffect(() => {
    if (
      googleuser?.firstname !== "" &&
      googleuser?.lastname !== "" &&
      googleuser !== null
    ) {
      let data = [];

      for (let i = 0; i < 3; i++) {
        // console.log(googleuser?.firstname);
        // console.log(googleuser?.lastname);
        let user = generateRandomUsername(
          googleuser?.firstname,
          googleuser?.lastname
        );
        let res = searchUsername(user);
        while (res === null && !data.includes(user)) {
          user = generateRandomUsername(
            googleuser?.firstname,
            googleuser?.lastname
          );
          res = searchUsername(user);
        }
        data.push(user);
      }

      // const data = [generateRandomUsername(values?.firstName, values?.lastName), generateRandomUsername(values?.firstName, values?.lastName), generateRandomUsername(values?.firstName, values?.lastName)];

      SetrandomUsername(data);
    }
  }, []);

  //Password
  const [usernamePolicy, setusernamePolicy] = useState(false);

  // const dispatch = useDispatch();
  // Getting loading and errors from Global state(AuthReducer)
  const { registerErrors, user} = useSelector(
    (state) => state.AuthReducer
  );

  // password view toggle
  const togglePasswordView = (t) => {
    t.preventDefault();
    let e = passwordRef.current;
    if (e.type === "text") {
      // e.type = "password";
      setPwdType("password");
      setpasswordView(true);
    } else {
      setPwdType("text");
      setpasswordView(false);
    }
  };

  function useKey(key, cb) {
    const callbackRef = useRef(cb);

    useEffect(() => {
      callbackRef.current = cb;
      // window.history.pushState(null, document.title, window.location.href);
      // const event = () => {
      //   window.history.pushState(null, document.title, window.location.href);
      // };
      // window.addEventListener("popstate", event);

      // return () => {
      //   window.removeEventListener("popstate", event);
      // };
    }, []);

    useEffect(() => {
      function handle(event) {
        if (event.code === key) {
          callbackRef.current(event);
        }
      }
      document.addEventListener("keypress", handle);
      return () => {
        document.removeEventListener("keypress", handle);
      };
    }, [key]);
  }
  useKey("Enter", handleSubmit);

  useEffect(() => {
    if (user && isSubmit) {
      // props.history.push("/polished");
       history.push("/timeline");
    }
  }, [registerErrors, user]);

  return (
    // <div className="bg-page text-primary ">
    <div className="bg-page text-primary h-screen md:h-auto md:min-h-screen md:pt-0 flex md:flex-col justify-center">
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ style: { marginTop: "50px" } }}
      />
      <Helmet>
        <title>FeedAnts Sign Up</title>
        <meta name="description" content="Learn skills while surfing" />
      </Helmet>
      <div>
        {UsernameExistAlert && (
          <AlertInvalidFormat msg="Oops!! This username already exists. Try another one." />
        )}

        {InvalidFormatAlert.length > 0 && (
          <AlertInvalidFormat
            msg={InvalidFormatAlert}
            toggle={setInvalidFormatAlert}
          />
        )}
      </div>

      {/* DESKTOP VIEW */}
      <div
        className="hidden md:flex md:flex-row md:justify-evenly pt-[50px] pb-[20px]"
        id="home-content "
        data-testid="sign-up">
        <div className="items-center justify-center hidden md:w-4/12 md:flex md:h-[100vh]">
          <img
            src={theme.Theme === "dark" ? signup_bg_dark : illustration}
            alt="vector"
          />
        </div>
        <div className="my-2  md:w-4/12 w-5/12 flex items-center">
          <div className="border-[1px] w-[70%] border-solid border-viewMore bg-page text-primary drop-shadow-md  rounded-2xl p-4 pt-0 my-0 sm:mr-5">
            <div className="flex justify-center bg-page">
              <img
                className={
                  theme.Theme === "dark"
                    ? " scale-150 h-[16vh]"
                    : "h-[13vh] mt-1 mb-1"
                }
                src={theme.Theme === "dark" ? LogoDark : Logo}
                alt="FeedAnts"
                // style={{ height: "9vh"}}
              />
            </div>
            <h2 className="text-2xl font-bold bg-page">
              Create New Username and Password
            </h2>
            {/* <p className="text-base antialiased text-blackGray">
              Fill up this form to complete your account details
            </p> */}
            <div onMouseDown={(e) => e.preventDefault()}>
              {state?.firstName !== "" && state?.lastName !== "" && (
                <h1 className="message text-xs font-semibold">
                  Suggested Usernames{" "}
                  <span className="text-green-600">
                    <span
                      onClick={setUserField}
                      className="cursor-pointer hover:underline">
                      {randomUsername[0]}
                    </span>{" "}
                    ,{" "}
                    <span
                      onClick={setUserField}
                      className="cursor-pointer hover:underline">
                      {randomUsername[1]}
                    </span>{" "}
                    ,{" "}
                    <span
                      onClick={setUserField}
                      className="cursor-pointer hover:underline">
                      {randomUsername[2]}
                    </span>
                  </span>
                </h1>
              )}
            </div>
            <form>
              <div className="relative mt-4">
                <FormInput
                  autoComplete="off"
                  height="5vh"
                  fontSize="0.875rem"
                  type="text"
                  name="username"
                  onFocus={() => {
                    setusernamePolicy(true);
                  }}
                  onChange={handleChange}
                  onBlur={(e) => {
                    setusernamePolicy(false);
                    handleBlur(e);
                  }}
                  placeholder="Username"
                  value={values?.username}
                  error={errors?.username}
                  touched={touched?.username}
                  iconClassname={usernameIconColour}
                />

                {usernamePolicy === true && (
                  <div className="w-[34vw] absolute top-[1rem] z-10 xl:w-[23vw]">
                    <div className="flex flex-col items-center justify-center">
                      <ArrowDropUpIcon
                        fontSize="large"
                        className="text-teal-600 w-[100%] -mb-5"
                        style={{ height: 50, width: 50 }}
                      />

                      <div className="p-2 text-xs border-2 rounded-lg shadow-lg bg-card text-primary border-viewMore">
                        <ul className="text-teal-600">
                          <li>1) Username must be 5-30 characters long.</li>
                          <li>2) Must included . or _ in the username.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="relative mt-4">
                <FormInput
                  autoComplete="off"
                  height="5vh"
                  fontSize="0.875rem"
                  type={pwdType}
                  name="password"
                  onChange={handleChange}
                
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  placeholder="Password"
                  value={values?.password}
                  error={errors?.password}
                  touched={touched?.password}
                  onButtonClick={togglePasswordView}
                  passwordView={passwordView}
                  iconClassname={"text-[#008080]"}
                  ref={passwordRef}
                />
                {/* {passwordPolicy == true && (
                  <div className="w-[23vw] absolute top-[1.5rem] z-10">
                    <div className="flex flex-row items-center justify-center">
                      <ArrowDropUpIcon
                        fontSize="large"
                        className="text-teal-600 w-[100%] -mb-5"
                        style={{ height: 50, width: 50 }}
                      />
                    </div>
                    <div className=" bg-card  rounded-lg p-2 shadow-lg border-2 border-viewMore text-xs">
                      <ul className="text-teal-600">
                        <li>
                          1) Password must be minimum 7 & maximum 16 characters
                          long.
                        </li>
                        <li>
                          2) Password must contain atleast 1 uppercase and 1
                          lowercase letter.
                        </li>
                        <li>
                          3) Password must contain atleast 1 numeric and 1
                          special character .
                        </li>
                      </ul>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="mt-4 ">
                <FormInput
                  height="5vh"
                  fontSize="0.875rem"
                  type="password"
                  name="confirmPassword"
                  onChange={handleChange}
                  autoComplete="off"
                  onBlur={handleBlur}
                  placeholder="Confirm Password"
                  value={values?.confirmPassword}
                  error={errors?.confirmPassword}
                  touched={touched?.confirmPassword}
                />
              </div>

              <div className="">
                <button
                  type="button"
                  className="border-solid bg-[black] text-lg w-[100%] h-[2.5rem] rounded-xl  md:h-[3rem] text-white mt-3  lg:p-1 lg:text-xl lg:mt-4 transition duration-200 hover:bg-gray-800"
                  onClick={handleSubmit}>
                  {accLoader === false ? "Continue" : <>...</>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      {/* <div className="hidden md:w-full md:flex md:justify-center">
        <Link to="/privacy-policy" className="text-sm text-center text-slate-600">
          Privacy Policy || FeedAnts 2023
        </Link>
      </div> */}

      {/* MOBILE VIEW */}
      <div className="flex flex-col items-center justify-center px-2 overflow-hidden md:hidden">
        <div className="w-[90%] my-4">
          <div className="border-[1px] border-solid border-viewMore  bg-page text-primarydrop-shadow-md rounded-2xl p-4">
            <div className="flex justify-center  bg-page">
              <img
                src={theme.Theme === "dark" ? LogoDark : Logo}
                alt="FeedAnts"
                className={
                  theme.Theme === "dark"
                    ? "scale-[2] h-[12vh] sm:h-[13vh]"
                    : "scale-110 h-[12vh] sm:h-[13vh]"
                }
              />
            </div>
            <h2 className="mt-8 font-bold bg-page">
              Create New Username and Password
            </h2>

            <form>
              <div className="relative">
                <div className="flex justify-center mt-4 ">
                  <FormInput
                    type="text"
                    name="username"
                    autoComplete="off"
                    onFocus={() => {
                      setusernamePolicy(true);
                    }}
                    onChange={handleChange}
                    onBlur={() => {
                      setusernamePolicy(false);
                    }}
                    placeholder="Username"
                    value={values?.username}
                    error={errors?.username}
                    touched={touched?.username}
                    iconClassname={usernameIconColour}
                  />
                </div>
                {usernamePolicy === true && (
                  <div className="w-[82vw] absolute top-[0.9rem] z-10 xl:w-[17.5vw]">
                    <div className="flex flex-col items-center justify-center">
                      <div className="hidden xl:block">
                        <ArrowLeftIcon
                          fontSize="large"
                          className="text-teal-600 w-[100%] -mr-5"
                          style={{ height: 120, width: 50 }}
                        />
                      </div>
                      <div className="xl:hidden">
                        <ArrowDropUpIcon
                          fontSize="large"
                          className="text-teal-600 w-[100%] -mb-5"
                          style={{ height: 50, width: 50 }}
                        />
                      </div>
                      <div className="p-2 text-xs border-2 rounded-lg shadow-lg bg-card text-primary border-viewMore">
                        <ul className="text-teal-600">
                          <li>1) Username must be 5-30 characters long.</li>
                          <li>2) Must included . or _ in the username.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                <div className="relative">
                  <div className="flex justify-center mt-4 ">
                    <FormInput
                      type={pwdType}
                      name="password"
                      autoComplete="off"
                      onChange={handleChange}
                  
                 
                      placeholder="Password"
                      value={values?.password}
                      error={errors?.password}
                      touched={touched?.password}
                      onButtonClick={togglePasswordView}
                      passwordView={passwordView}
                      iconClassname={"text-[#008080]"}
                      ref={passwordRef}
                    />
                  </div>
                  {/* {passwordPolicy == true && (
                    <div className="w-[82vw] absolute top-[1.6rem] z-10 xl:w-[17.5vw]">
                      <div className="flex flex-col items-center justify-center xl:flex-row">
                        <div className="hidden xl:block">
                          <ArrowLeftIcon
                            fontSize="large"
                            className="text-teal-600 w-[100%] -mr-5"
                            style={{ height: 120, width: 50 }}
                          />
                        </div>
                        <div className="xl:hidden">
                          <ArrowDropUpIcon
                            fontSize="large"
                            className="text-teal-600 w-[100%] -mb-5"
                            style={{ height: 50, width: 50 }}
                          />
                        </div>
                        <div className="bg-card text-primary rounded-lg p-2 shadow-lg border-2 border-viewMore  text-xs">
                          <ul className="text-teal-600">
                            <li>
                              1) Password must be minimum 7 & maximum 16
                              characters long.
                            </li>
                            <li>
                              2) Password must contain atleast 1 uppercase and 1
                              lowercase letter.
                            </li>
                            <li>
                              3) Password must contain atleast 1 numeric and 1
                              special character .
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
              <div className="flex justify-center mt-4 ">
                <FormInput
                  type="password"
                  name="confirmPassword"
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Confirm Password"
                  value={values?.confirmPassword}
                  error={errors?.confirmPassword}
                  touched={touched?.confirmPassword}
                />
              </div>

              <div className="flex flex-col">
                <button
                  type="button"
                  data-testid="btn"
                  className="flex items-center justify-center p-2 my-3 text-sm text-white bg-black border-solid rounded-lg cursor-pointer hover:bg-teal-600 "
                  onClick={handleSubmit}>
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
