import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, WhatsappIcon } from "react-share";
import ShareIcon from "@mui/icons-material/Share";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import DmUsers from "./DmUsers";
import { gtag } from "ga-gtag";
import { sharePostUrlPrefix } from "../../path";

const SharePost = ({ post }) => {
  const [shareOptionsDisplay, setShareOptionsDisplay] = useState("none");
  const [dmDisplay, setDmDispaly] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");
  let ko = 3000;

  const sharebuttonHandler = () => {
    if (shareOptionsDisplay === "none") {
      setShareOptionsDisplay("flex");
    } else {
      setShareOptionsDisplay("none");
    }
  };
  const { user } = useSelector((state) => state.AuthReducer);
  // console.log("USER", user._id);
  // const userId = user._id;

  // console.log("post", post)
  // console.log("friends", friends)
  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    pauseOnHover: false,
    draggable: true,
    theme: user.theme === "dark" ? "colored" : "light",
  };
  const [toastFlag, setToastFlag] = useState(0);
  //handle DM
  // const handleDM = (e) => {
  //   setDmDispaly(true);
  // };
  //handle sending msg

  // const handleChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };
  // console.log("searchTerm", searchTerm)
  //search friends

  const closeRef = useRef();
  useEffect(() => {
    var closeHandler = (e) => {
      if (!closeRef?.current?.contains(e.target)) {
        setToastFlag(0);
        ko = 3000;

        setShareOptionsDisplay("none");
      }
    };
    document.addEventListener("mousedown", closeHandler);
    return () => {
      document.removeEventListener("mousedown", closeHandler);
    };
  });

  const handleLinkCopy = (postId) => {
    let p = `${sharePostUrlPrefix}post/${postId}`;
    navigator.clipboard.writeText(p);
    if (toastFlag === 0) {
      toast.success("Link copied Successfully", toastOption);
      setToastFlag(1);
      setTimeout(() => {
        // console.log("toastFlag", toastFlag);

        setToastFlag(0);
      }, ko);
    }
  };

  useEffect(() => {
    const handler = (event) => {
      if (event.target.classList.contains("share-post-event")) {
        gtag("event", "share-post", {});
      }
    };

    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  });

  return (
    <div
      className="  py-[0.6rem] "
      onClick={sharebuttonHandler}
      data-testid="share-icon">
      <div
        ref={closeRef}
        className="relative flex flex-row items-center justify-end ">
        <ShareIcon
          className={`${
            user.theme === "dark" ? "text-white opacity-60" : "text-black"
          } tecursor-pointer share-post-event `}
        />
        <div
          className=" flex  bg-card rounded-md shadow-lg justify-center space-y-2 items-center absolute top-[-3rem] right-0 z-10  text-white border-[1px] border-viewMore "
          style={{
            display: shareOptionsDisplay,
            paddingLeft: "4px",
            paddingRight: "2px",
          }}>
          <FacebookShareButton
            style={{ margin: "6px 4px 6px 4px" }}
            url={`${sharePostUrlPrefix}post/${post?.uniqueId}`}
            quote="Post from Feedants"
            data-testid="facebook-share-button"
            hashtag="#blaccksckull">
            {/* <FacebookOutlinedIcon sx={{color:"blue"}}/> */}
            <FacebookIcon
              style={{ borderRadius: "50%" }}
              size={30}
              logofillcolor="white"
              rounded></FacebookIcon>
          </FacebookShareButton>
          <WhatsappShareButton
            style={{ margin: "6px 4px 6px 4px" }}
            title="Post From Feedants"
            data-testid="whatsapp-share-button"
            url={`${sharePostUrlPrefix}post/${post?.uniqueId}`}>
            <WhatsappIcon
              style={{ borderRadius: "50%" }}
              size={30}
              logofillcolor="white"
              rounded></WhatsappIcon>
          </WhatsappShareButton>
          <ContentCopyIcon
            style={{ margin: "6px 4px 6px 4px" }}
            sx={{ color: "#008080", fontSize: "1.75rem", cursor: "pointer" }}
            onClick={() => handleLinkCopy(post?.uniqueId)}
          />
          {/* <SendIcon
            style={{ margin: "6px 4px 6px 4px" }}
            sx={{
              color: "#008080",
              fontSize: "1.75rem",
              cursor: "pointer",
              margin: "4px",
            }}
            onClick={handleDM}
          /> */}
        </div>
      </div>
      {dmDisplay === true ? (
        <DmUsers close={setDmDispaly} msg={post?.uniqueId} />
      ) : (
        ""
      )}
    </div>
  );
};

SharePost.propTypes = {
  post: PropTypes.shape({
    uniqueId: PropTypes.string.isRequired, // Example: Assuming uniqueId is a string and is required
    // Define other expected props inside the shape object
  }),
};

export default React.memo(SharePost);
