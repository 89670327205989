// Post Types
export const CREATE_ERRORS = "CREATE_ERRORS";
export const REMOVE_ERRORS = "REMOVE_ERRORS";
export const SET_LOADER = "SET_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";
export const REDIRECT_TRUE = "REDIRECT_TRUE";
export const REDIRECT_FALSE = "REDIRECT_FALSE";
export const SET_MESSAGE = "SET_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const SET_POSTS = "SET_POSTS";
export const SET_PHOTO_POSTS = "SET_PHOTO_POSTS";
export const SET_VIDEO_POSTS = "SET_VIDEO_POSTS";
export const SET_BLOG_POSTS = "SET_BLOG_POSTS";
export const SET_POST = "SET_POST";
export const POST_REQUEST = "POST_REQUEST";
export const POST_RESET = "POST_RESET";
export const RESET_UPDATE_ERRORS = "RESET_UPDATE_ERRORS";
export const SET_UPDATE_ERRORS = "SET_UPDATE_ERRORS";
export const UPDATE_IMAGE_ERRORS = "UPDATE_IMAGE_ERRORS";
export const RESET_UPDATE_IMAGE_ERRORS = "RESET_UPDATE_IMAGE_ERRORS";
export const REPORTED_POSTS = "REPORTED_POSTS";
