import { Link } from "react-router-dom";
import React from "react";

const Footer = () => {
  // const [text, setText] = useState("Privacy Policy |");
  const text = "Privacy Policy |";
  // useEffect(() => {
  //   if (window.location.href.includes("/forgotPassword") || window.location.href.includes("/forgotPassword") ) {
  //     setText("");
  //   }
  //   console.log(window.location.href);
  // }, []);

  const year = new Date().getFullYear();
  return (
    <div className="bg-comgBg flex w-[100%] justify-center py-2">
      <div
        className="md:flex text-center flex-row justify-center text-grayish text-xs font-semibold w-[100%]"
        data-testid="footer">
        <div>
          {" "}
          <Link to={"/privacy-policy"} target={"_blank"}>
            {text}
          </Link>{" "}
          FeedAnts {year}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Footer);
