import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { path } from "../../../path";

function Feedback() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.AuthReducer);
  const [allUsers, setAllUsers] = useState([]);
  // const [newlyAddedUsers, setNewlyAddedUsers] = useState([]);

  const getAllUsers = async () => {
    try {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(`${path}/allfeedbacks`, config);
      console.log(res.data);

      setAllUsers(res.data.feebacks);
    } catch (error) {
      alert("Some error in getting users.", error);
    }
  };
  useEffect(() => {
    dispatch({ type: "UNSET_HEADER" });
    getAllUsers();
  }, []);

  const removeParticularAccount = async (userId) => {
    try {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
        },
      };
      const res = await axios.delete(
        `${path}/deletefeedback/${userId}`,
        config
      );
      console.log(res.data);
      getAllUsers();
    } catch (error) {
      alert("Some error in deleting user.", error);
    }
  };

  return (
    <div className="text-primary bg-page min-h-[100vh] px-2  py-[3rem] overflow-x-auto overflow-y-auto">
      <table className="border-collapse border-2 m-4">
        <thead className="border-b-2">
          <tr>
            <th className="border-r-2 px-2">S.No</th>
            <th className="border-r-2 px-2" style={{ maxWidth: "300px" }}>
              Comment1
            </th>
            <th className="border-r-2 px-2" style={{ maxWidth: "300px" }}>
              Comment2
            </th>
          </tr>
        </thead>
        <tbody>
          {allUsers?.map((u, index) => (
            <>
              <tr className="border-b-2">
                <td className="border-r-2 px-2 text-[0.8rem] text-center">
                  {index + 1}
                </td>
                <td
                  className="border-r-2 px-2 text-[0.8rem]"
                  style={{
                    maxWidth: "300px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}>
                  {u.comment1.trim()}
                </td>

                <td
                  className="border-r-2 px-2 text-[0.5rem] "
                  style={{
                    maxWidth: "300px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}>
                  {u.comment2}
                </td>
                <td className="text-[0.8rem] text-center">
                  <button
                    className="text-red-500"
                    onClick={() => removeParticularAccount(u._id)}>
                    Remove
                  </button>
                </td>
              </tr>
              <hr className="border-viewMore"></hr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Feedback;
