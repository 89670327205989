import {
  SET_POST,
  POST_LOADER,
  CLOSE_POST_LOADER,
  SET_SHARE_POST,
  UNSET_POST,
  SET_SHARE_POST_ID,
} from "../types/SearchPostTypes";

const initState = {
  postLoader: false,
  posts: [],
  sharePost: [],
  sharepostId: "",
};

const SearchPostReducer = (state = initState, action) => {
  switch (action.type) {
    case POST_LOADER:
      return { ...state, postLoader: true };
    case SET_POST:
      state.posts.splice(0, state.posts.length);
      state.posts.push(action.payload);
      return state;
    case UNSET_POST:
      // state.posts.splice(0,state.posts.length);
      state.posts.pop();
      return { ...state, postLoader: true };
    case SET_SHARE_POST:
      state.sharePost.pop();
      state.sharePost.push(action.payload);
      return state;
    case CLOSE_POST_LOADER:
      return { ...state, postLoader: false };
    case SET_SHARE_POST_ID:
      return { ...state, sharepostId: action.payload };
    case "UNSET_SHARE_POST_ID":
      return { ...state, sharepostId: "" };
    case "LOGOUT_SPR":
      return initState;
    default:
      return state;
  }
};

export default SearchPostReducer;
