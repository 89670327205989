/* eslint-disable no-warning-comments */
// eslint-disable-next-line no-undef
var Crypto = require("crypto-js");

const to_Encrypt = (data) => {
  var ciphertext = Crypto.AES.encrypt(data, "my-secret-key@123").toString();
  return ciphertext;
};

const to_Decrypt = (data) => {
  var bytes = Crypto.AES.decrypt(data, "my-secret-key@123");
  var decryptedData = bytes.toString(Crypto.enc.Utf8);
  return decryptedData;
};

export { to_Decrypt, to_Encrypt };
