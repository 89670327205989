import React, { useContext, useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { path } from "../../path";
import { Link } from "react-router-dom";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";

import MobileFooter from "../footer/MobileFooter";
import Man from "../Header/img/Man.svg";
import Others from "../Header/img/Others.svg";
import Female from "../Header/img/Female.svg";
import logoAnt from "../Header/img/Ant.png";
import logoAntDark from "../Header/img/Ant_Dark.png";

import { WebSocketContext } from "../../WebSocket";
import { UPDATE_ACCOUNT } from "../../store/types/AuthTypes";
import { toast } from "react-toastify";
import "./Friend.css";

function FriendRequest() {
  const dispatch = useDispatch();

  const { user, token } = useSelector((state) => state.AuthReducer);

  const [friendRequests, setFriendRequests] = useState([]);
  const [acceptedReq] = useState([]);
  const [reqRejectLoader, setRejectLoader] = useState("");
  const [msg, setMsg] = useState(""); // request acceptance msg
  const [total,setTotal] = useState(2)
  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000,
    pauseOnHover: true,
    draggable: true,
    theme: user.theme === "dark" ? "colored" : "light",
  };
  const socket = useContext(WebSocketContext);
  const config = {
    host: `${path}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${path}`,
      Authorization: `Bearer ${token}`,
    },
  };
  const getAllFriendRequests = async () => {
    const res = await axios.get(`${path}/friendRequests/${user._id}`, config);
    setFriendRequests(res.data.friendrequests);
  };

  useEffect(() => {
    getAllFriendRequests();
  }, []);

  const [reqAcceptLoader, setAcceptedReqLoader] = useState("");

  //accept request
  useEffect(() => {
    socket.on("friend received", (data) => {
      setFriendRequests((prev) => [data, ...prev]);
    });
    socket.on("pull cancel", () => {
      getAllFriendRequests();
    });
  }, [socket]);

  const friendRequestAcceptHandler = async (senderId, index) => {
    // acceptedReq.push(senderId);
    try {
      setAcceptedReqLoader(index);
      const res = await axios.post(
        `${path}/acceptFriendRequest/${senderId}`,
        {
          receiverId: user._id,
        },
        config
      );
      if (res.data.msg === "Request accepted successfully.") {
        setMsg(res.data.msg);
        getAllFriendRequests();
        dispatch({
          type: UPDATE_ACCOUNT,
          payload: res.data.user,
        });
        socket.emit("accept friend", senderId, user._id);
        toast.success("Peer Added", toastOption);
      } else {
        alert(res.data.msg);
      }
    } catch (error) {
      console.log("err in accepting fr", error);
    }
    // const responseOFnewFriend = await axios.get(
    //   `${path}/getUserData/${user._id}`,
    //   config
    // );
    // console.log("data we get : ", responseOFnewFriend);
    // const frnds = responseOFnewFriend.data.friends;
    // if (frnds) {
    //   dispatch({
    //     type: "SET_FR",
    //     payload: frnds,
    //   });
    // }
    // Show the "Friend Request Accepted" message
  };
  //delete request
  const deleteRequestAcceptHandler = async (senderId, index) => {
    setRejectLoader(index);
    const res = await axios.post(
      `${path}/deleteFriendRequest/${senderId}`,
      {
        receiverId: user._id,
      },
      config
    );
    if (Object.keys(res.data).length === 2) {
      getAllFriendRequests();
      socket.emit("reject friend", res);
      toast.success("Peer Rejected", toastOption);
    } else {
      alert("Error in deleting request.");
    }
    setRejectLoader("");
  };

  //mutual friends;
  const MutualFriends = (BioUser) => {
    let LoginFriends = user?.friends?.map((fr) => {
      return fr?._id;
    });

    let MutualFriends = BioUser?.friends?.filter((fr) => {
      return LoginFriends?.includes(fr);
    });

    if (MutualFriends?.length > 0) {
      return (
        <>
          {" "}
          <PeopleOutlineOutlinedIcon
            sx={{ color: "#7A7A7A", fontSize: "15px" }}
          />{" "}
          `${MutualFriends?.length} Mutual Peers`
        </>
      );
    } else {return;}
  };

  return (
    <div className="ml-1 md:ml-0">
      {/* <div className="flex items-center justify-center w-full bg-page md:hidden">
        <div className="w-full p-3 border-2 rounded-lg bg-card border-viewMore">
          <p className="font-semibold text-primary text-sm border-b-2 border-b-[#DCDCDC] pb-2">
            Peer Interest ({friendRequests.length})
          </p>
          <div className="h-[20%]">
            <ul>
              {friendRequests.length > 0 ? (
                friendRequests.map((fr, index) => {
                  return (
                    <li className="p-2 my-1 rounded-xl text-primary">
                      <div className="flex">
                        <div className="flex items-center justify-center ">

                          {fr.senderId?.profilePicture != null ? (
                            <Avatar
                              src={fr.senderId?.profilePicture}
                              sx={{ width: 40, height: 40 }}
                            />
                          ) : fr.senderId?.gender === "male" ? (
                            <Avatar src={Man} sx={{ width: 40, height: 40 }} />
                          ) : fr.senderId?.gender === "female" ? (
                            <Avatar
                              src={Female}
                              sx={{ width: 40, height: 40 }}
                            />
                          ) : (
                            <Avatar
                              src={Others}
                              sx={{ width: 40, height: 40 }}
                            />
                          )}

                        </div>
                        <div className="mx-3">
                          <Link to={`/userDetails/:${fr.senderId?._id}`}>
                            <h2 className="font-semibold ml-2">
                              {fr.senderId?.username}
                            </h2>
                            <h2 className="text-sm my-0.5">
                              {" "}
                              {MutualFriends(fr) ? MutualFriends(fr) : ""}
                            </h2>
                          </Link>

                          {msg && acceptedReq.includes(fr.senderId?._id) ? (
                            <p className="text-[12px] text-[teal] ">{msg}</p>
                          ) : (
                            <div className="flex flex-row space-x-3">
                              <button
                                data-testid="accept"
                                className="rounded-3xl bg-[#282828] p-1 px-3 text-white hover:bg-[#070707]"
                                onClick={() =>
                                  friendRequestAcceptHandler(
                                    fr.senderId._id,
                                    index
                                  )
                                }>
                                {reqAcceptLoader === index
                                  ? "Adding"
                                  : "Accept"}
                              </button>
                              <button
                                className="rounded-3xl bg-page p-1 px-3 mx-1 text-[#D15D5D] hover:bg-[#ffa5a5]"
                                data-testid="reject"
                                onClick={() =>
                                  deleteRequestAcceptHandler(fr.senderId?._id)
                                }>
                                {reqRejectLoader === index
                                  ? "Removing"
                                  : "Reject"}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <h2 className="w-56 px-1 py-2 ml-2 text-primary">
                  No Peer Interests
                </h2>
              )}
              <hr className="border-viewMore" />
            </ul>
          </div>
          <div className="flex justify-center mt-1">
            {friendRequests.length > 4 ? (
              <button className="p-2 px-3 rounded-3xl bg-slate-200">
                <h2 className="text-slate-600">See all Peer Interest</h2>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div> */}
      <div className=" border bg-card md:flex md:flex-col md:justify-center md:p-4 p-1 rounded-lg md:ml-1 md:bg-card border-viewMore">
        <div className="px-3 py-1 text-sm font-semibold text-primary lg:text-lg">
          Peer Interest ({friendRequests.length})
        </div>
        <hr className="border-viewMore" />

        <div>
          <ul>
            {friendRequests.length > 0 ? (
              friendRequests.slice(0,total).map((fr, index) => {
                return (
                  <li key={fr._id} className="p-2 my-1 text-primary rounded-xl">
                    <div className="flex">
                      <div className="flex items-center justify-center ">
                        {fr.senderId?.profilePicture !== null ? (
                          <Avatar
                            src={fr.senderId?.profilePicture}
                            sx={{ width: 40, height: 40 }}
                          />
                        ) : fr.senderId?.gender === "male" ? (
                          <Avatar src={Man} sx={{ width: 40, height: 40 }} />
                        ) : fr.senderId?.gender === "female" ? (
                          <Avatar src={Female} sx={{ width: 40, height: 40 }} />
                        ) : fr.senderId?.gender === "others" ? (
                          <Avatar src={Others} sx={{ width: 40, height: 40 }} />
                        ) : fr.senderId?.theme === "dark" ? (
                          <Avatar
                            src={logoAntDark}
                            sx={{ width: 40, height: 40 }}
                          />
                        ) : (
                          <Avatar
                            src={logoAnt}
                            sx={{ width: 40, height: 40 }}
                          />
                        )}
                      </div>
                      <div className="mx-3">
                        <Link to={`/userDetails/:${fr.senderId?._id}`}>
                          <h2 className="font-semibold ml-2">
                            {fr.senderName}
                          </h2>
                          <h2 className="text-sm my-0.5">
                            {" "}
                            {MutualFriends(fr) ? MutualFriends(fr) : ""}
                          </h2>
                        </Link>

                        {msg && acceptedReq.includes(fr.senderId._id) ? (
                          <p className="text-[12px] text-[teal] ">{msg}</p>
                        ) : (
                          <div className="flex flex-col lg:flex-row">
                            <button
                              className="rounded-full h-[3vh] flex justify-center items-center text-sm bg-[#282828]  p-3 px-3 text-white hover:bg-[#070707]"
                              onClick={() =>
                                friendRequestAcceptHandler(fr.senderId._id)
                              }>
                              {reqAcceptLoader === index ? "Adding" : "Accept"}
                            </button>
                            <button
                              className="rounded-full h-[3vh] flex justify-center items-center text-sm bg-red-500 p-3 px-3 ml-0 lg:ml-4 text-white hover:bg-[#ffa5a5]"
                              onClick={() =>
                                deleteRequestAcceptHandler(fr.senderId._id)
                              }>
                              {reqRejectLoader === index
                                ? "Removing"
                                : "Reject"}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <h2 className="w-56 px-1 pt-2 ml-2 text-primary dark:text-viewMore">
                No Peer Interests
              </h2>
            )}
          </ul>
        </div>
        <div className="flex justify-center mt-1">
          {friendRequests.length > 2 ? (
            <button className="p-2 px-3 rounded-3xl bg-slate-200" onClick={() => setTotal(friendRequests.length)}>
              <h2 className="text-slate-600">See all Peer Interest</h2>
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <MobileFooter />
    </div>
  );
}

export default FriendRequest;
