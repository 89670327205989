import React from "react";

import {Link} from 'react-router-dom'
const Subcompetition = ({ data, setEditable, setEditableProp }) => {

  function EditClick() {
    setEditableProp(data);
    setEditable(true);
  }
  
  return (
    <div className="">
      <div>
        {data && (
          <>
            <div className="sub-container flex flex-wrap justify-center h-[100vh]">
              <div className="bg-card rounded-lg flex flex-col shadow-md p-4 mb-4">
                <div className="flex justify-center">
                  {data.coverPhoto ? (
                    <img
                      src={data.coverPhoto}
                      alt="contest-image"
                      className="w-full object-cover max-h-48 rounded-md"
                    />
                  ) : (
                    <div className="w-full h-[12rem] bg-gray-100 rounded-md"></div>
                  )}
                </div>
                <div className="text-left ml-4 mt-4">
                  <h1 className="text-primary text-xl font-semibold">
                    {data.title}
                  </h1>
                  <div className="mt-2 ">
                    <div className="info-container p-2 rounded-md border-2 flex flex-col md:flex-row md:flex-wrap md:justify-between">
                     {data.venue && 
                      <div className="flex items-start space-x-2 md:w-1/2 ">
                        <label className="font-semibold">Venue:</label>
                        <p className="text-primary text-sm">{data.venue}</p>
                      </div>}
                      {/* <div className="flex items-start space-x-2 md:w-1/2 ">
                        <label className="font-semibold">Status:</label>
                        <p className="text-primary text-sm">{data.status}</p>
                      </div> */}
                      {/* <div className="flex items-start space-x-2 mb-2 md:w-1/2">
                        <p className="text-primary text-sm font-semibold">
                          Registraion Start Date:
                        </p>
                        <p className="text-primary text-sm">
                          {data.registrationStartDate?.split("T")[0]}
                        </p>
                      </div> */}
                        <div className="flex items-start space-x-2 mb-2 md:w-1/2">
                        <p className="text-primary text-sm font-semibold">
                          Video length(in minute(s)):
                        </p>
                        <p className="text-primary text-sm">
  {data.videolen && data.videolen !== 0 ? data.videolen: <span></span>}
</p>
                      </div>
                      <div className="flex items-start space-x-2 mb-2 md:w-1/2">
                        <p className="text-primary text-sm font-semibold">
                          Recording length(in minute(s)):
                        </p>
                        <p className="text-primary text-sm">
  {data.recordinglen && data.recordinglen !== 0 ? data.recordinglen : <span></span>}
</p>
                      </div>
                      <div className="flex items-start space-x-2 mb-2 md:w-1/2">
                        <p className="text-primary text-sm font-semibold">
                          Registration Closing Date:
                        </p>
                        <p className="text-primary text-sm">
                          {data.registrationCloseDate?.split("T")[0]} {data.registrationCloseDate?.split("T")[1]}
                        </p>
                      </div>
                      <div className="flex items-start space-x-2 mb-2 md:w-1/2">
                        <p className="text-primary text-sm font-semibold">
                          Mode
                        </p>
                        <p className="text-primary text-sm">
                          {
                            data?.mode
                          }
                        </p>
                      </div>
                      <div className="flex items-start space-x-2 mb-2 md:w-1/2">
                        <p className="text-primary text-sm font-semibold">
                          Submission Closing Date:
                        </p>
                        <p className="text-primary text-sm">
                          {data.submissionClosingDate?.split("T")[0]} {data.submissionClosingDate?.split("T")[1]}
                        </p>
                      </div>
                      <div className="flex items-start space-x-2 mb-2 md:w-1/2">
                        <p className="text-primary text-sm font-semibold">
                          Submission Starting Date:
                        </p>
                        <p className="text-primary text-sm">
                          {data.submissionStartDate?.split("T")[0]} {data.submissionStartDate?.split("T")[1]}
                        </p>
                      </div>
                      <div className="flex items-start space-x-2 mb-2 md:w-1/2">
                        <p className="text-primary text-sm font-semibold">
                          Slotsize:
                        </p>
                        <p className="text-primary text-sm">
                          {
                            data?.slotsize
                          }
                        </p>
                      </div>
                      <div className="flex items-start space-x-2 mb-2 md:w-1/2">
                        <p className="text-primary text-sm font-semibold">
                          Result Date:
                        </p>
                        <p className="text-primary text-sm">
                          {data.resultTime?.split("T")[0]} {data.resultTime?.split("T")[1]}
                        </p>
                      </div>
                      <div className="flex items-start space-x-2 mb-2 md:w-1/2">
                        <p className="text-primary text-sm font-semibold">
                          Format:                        </p>
                        <p className="text-primary text-sm">
                          {data?.format}
                        </p>
                      </div>
                      {data?.venue!=="" && (
                        <div className="flex items-start space-x-2 mb-2 md:w-1/2">
                          <p className="text-primary text-sm font-semibold">
                            Venue:{" "}
                          </p>
                          <p className="text-primary text-sm">{data?.venue}</p>
                        </div>
                      )}
                    </div>
                    <div className="desc text-sm text-primary my-2">
                      <h1 className="font-bold">
                        <strong>Description:</strong>
                      </h1>
                      <div>{data.description}</div>
                    </div>
                    <div>
                      <div className="desc text-sm text-primary my-2 font-bold">
                        Participated User
                      </div>
                      <div className="px-2 bg-card border-1 border-viewMore h-[10rem] w-full overflow-y-auto rounded-md">
                        {data.participatedUser.map((partUser) => (
                          <div key={partUser._id} className="px-2 py-1 bg-secondary text-sm rounded-md my-1">
                            {partUser.username}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="desc text-sm text-primary my-2">
                      <h1 className="font-bold">
                        <strong>Judges: </strong>
                      </h1>
                      <div>
                        {data.judges.map((judge) => (
                          <div
                            key={judge._id}
                            style={{ wordWrap: "break-word" }}
                            className="px-1 my-1 rounded-md ">
                            {judge.username}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="desc text-sm text-primary my-2">
                      <h1 className="font-bold">
                        <strong>Organizers: </strong>
                      </h1>
                      <div>
                        {data.organizers.map((organizer) => (
                          <div
                            key={organizer._id}
                            style={{ wordWrap: "break-word" }}
                            className="px-1 y my-1 rounded-md ">
                            {organizer.username}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="desc text-sm text-primary my-2">
                      <h1 className="font-bold">
                        <strong>Creator: </strong>
                      </h1>
                      <div
                        style={{ wordWrap: "break-word" }}
                        className="px-1 my-1 rounded-md ">
                        {data.creator.username}
                      </div>
                    </div>
                    <div className="desc text-sm text-primary my-2">
                      <h1 className="font-bold">
                        <strong>Rules and Regulations:</strong>
                      </h1>
                      <div>
                        {data.ruleAndRegulations.map((rule, key) => (
                          <div
                            key={rule._id || key}
                            style={{ wordWrap: "break-word" }}
                            className="px-1 bg-secondary my-1 rounded-md ">
                            {rule}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="desc text-sm text-primary my-2">
                      <h1 className="font-bold">
                        <strong>Winning Prizes:</strong>
                      </h1>
                      <div>
                        {data.winningPrize.map((rule, key) => (
                          <div
                            key={rule._id ||key}
                            style={{ wordWrap: "break-word" }}
                            className="px-1 bg-secondary my-1 rounded-md ">
                            {rule}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="register flex justify-between">
                      <button
                        onClick={EditClick}
                        className="bg-teal-500 hover:bg-teal-600 text-sm text-white py-1 px-3 rounded-md">
                        {/* {editable ? "Cancel" : "Edit"} */}
                        Edit
                      </button>
                      <Link to={`/submissions/${data._id}`}
                        // onClick={getAllSubmissions}
                        className="bg-teal-500 hover:bg-teal-600 text-sm text-white py-1 px-3 rounded-md">
                        {/* {editable ? "Cancel" : "Edit"} */}
                        Submissions
                      </Link>
                      {/* <button className="bg-red-500 text-sm text-white"onClick={deleteContest}>Delete</button> */}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Subcompetition;
