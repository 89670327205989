 
 
import React from 'react';



const LinearProgressBar = ({ percentage }) => {
  console.log(percentage)
  return (
    <div className="h-2 w-full bg-gray-200 rounded-full">
      <div
        className="h-full bg-teal-500 rounded-full"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};
export default LinearProgressBar;

