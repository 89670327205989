import axios from "axios";
import React, { useEffect, useState } from "react";
import { path } from "../../../path";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../LandingPage/logo/Logo-design.png";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { ToastContainer, toast } from "react-toastify";
function NewSkills() {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState(false);
  const [newSkills, setNewSkills] = useState([]);
 
  const [pathModel, setPathModel] = useState(false);
  const [password, setPassword] = useState("");
  const [skill, setSkill] = useState({});
  const [pathValue, setPathValue] = useState("");
  const [skillValue, setSkillValue] = useState("");

  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  };

  const getAllNewSkills = async () => {
    try {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      const res = await axios.get(`${path}/admin/fetchallnewskills`, config);
      let ns = [];
      ns = res.data.response.filter((n) => n.username !== null);
      setNewSkills(ns);
      setLoading(false);
    } catch (error) {
      console.log("Some error in fetching new skills.",error);
    }
  };

  useEffect(() => {
    dispatch({ type: "UNSET_HEADER" });
    getAllNewSkills();
  }, [token]);

  const addSkillHandler = async (skillName, userId, newSkillId, type) => {
    setPathModel(true);
    setSkillValue(skillName);
    setSkill({ name: skillName, userId, newSkillId, type });
  };

  const deleteSkillHandler = async (newSkillId) => {
    try {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete(`${path}/admin/deletenewskill/${newSkillId}`, config);
      alert("Skill deleted successfully.");
      setNewSkills(newSkills.filter((ns) => ns._id !== newSkillId));
    } catch (error) {
      alert("Some error in deleting skill.",error);
    }
  };

 

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      if (password && pathValue) {
        const config = {
          headers: {
            "Access-Control-Allow-Origin": `${path}`,
            Authorization: `Bearer ${token}`,
          },
        };
        let res = await axios.post(
          `${path}/matchpassword`,
          {
            userId: user._id,
            password: password,
          },
          config
        );
        console.log(res);
        if (Object.keys(res.data).length === 2) {
          res = await axios.post(
            `${path}/admin/addnewskiilswithmain`,
            { ...skill, path: pathValue },
            config
          );
          console.log(res.data);
          res.data.success === true
            ? toast.success("Skill added successfully.", toastOption)
            : toast.error("Some error in adding skills", toastOption);
          setNewSkills(newSkills.filter((ns) => ns.name !== skill.skillName));

          setPathModel(false);
        } else {
          toast.error(res.data.msg, toastOption);
        }
      } else {
        toast.error("Enter Path and Password.", toastOption);
      }
    } catch (error) {
      console.log(error);
      toast.error("Check Console!", toastOption);
    }
    setPassword("");
    setPathValue("");
  };

  return (
    <div className="text-primary bg-page min-h-[100vh] px-2  py-[3rem] overflow-x-none overflow-y-auto">
      {/* Path Input Model */}
      {pathModel && (
        <div className="sticky top-0 left-0 w-full h-[100vh] flex justify-center items-center">
          <div className="border-2 border-[#C7C7C7] shadow-lg rounded-lg py-6 px-6 w-[90%] md:w-[60%] xl:w-[30%] flex flex-col justify-between gap-4 bg-primary text-card">
            <div className="w-full flex justify-between">
              <div></div>
              <img src={Logo} className="w-[50%]" />
              <HighlightOffRoundedIcon
                className="cursor-pointer"
                onClick={() => setPathModel(false)}
              />
            </div>
            <form
              onSubmit={handlePasswordSubmit}
              className="flex flex-col justify-between gap-3">
              <input
                type="text"
                className="bg-primary text-grayish border border-viewMore w-full rounded-lg py-1 px-4 outline-none"
                placeholder="Enter Skill Name"
                onChange={(e) => setSkillValue(e.target.value)}
                value={skillValue}
              />
              <input
                type="text"
                className="bg-primary text-grayish border border-viewMore w-full rounded-lg py-1 px-4 outline-none"
                placeholder="Enter Path"
                onChange={(e) => setPathValue(e.target.value)}
                value={pathValue}
              />
              <input
                type="password"
                className="bg-primary text-grayish border border-viewMore w-full rounded-lg py-1 px-4 outline-none"
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <input
                type="submit"
                value="Submit"
                className="bg-[#008080] rounded-lg px-2 py-1 text-white mt-2 cursor-pointer w-full"
              />
            </form>
            <ToastContainer />
          </div>
        </div>
      )}

      <h1 className="text-center font-bold text-[2rem] my-2">
        Recommended New Skills
      </h1>
      {loading === true ? (
        <p className="text-primary">Loading...</p>
      ) : newSkills?.length > 0 ? (
        <table className="m-auto border">
          <thead>
            <tr className="border-b">
              <th className="px-2 border-r">S.No</th>
              <th className="px-2 border-r">Username</th>
              <th className="px-2 border-r">Skills Suggested</th>
              <th className="px-2 border-r">Skill Type</th>
              <th className="px-2 border-r">Action</th>
            </tr>
          </thead>
          <tbody>
            {newSkills.map(
              (ns, index) =>
                ns && (
                  <tr className="border-b" key={ns.userId}>
                    <td className="px-2 border-r">{index + 1}</td>
                    <td className="px-2 border-r">{ns.userId?.username}</td>
                    <td className="px-2 border-r">{ns.name}</td>
                    <td className="px-2 border-r">{ns.type}</td>
                    <td className="flex px-2 space-x-2">
                      <CheckCircleRoundedIcon
                        onClick={() =>
                          addSkillHandler(
                            ns.name,
                            ns.userId._id,
                            ns._id,
                            ns.type
                          )
                        }
                        sx={{ cursor: "pointer", color: "#008080" }}
                      />
                      <CancelRoundedIcon
                        onClick={() => deleteSkillHandler(ns._id)}
                        sx={{ cursor: "pointer", color: "red" }}
                      />
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      ) : (
        <p>No new data</p>
      )}
    </div>
  );
}

export default NewSkills;
