import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
function F({ fields }) {
    const { id } = useParams();
    return (
        <div className="flex flex-col text-primary p-3 gap-2 md:bg-compBg ">
            <Link to={`/rewards/${id}`} className="no-underline">
                <h1 className="font-gilroy-medium text-compPrimary text-base">
                    {fields[0].title}
                </h1>
            </Link>
            {/* {fields[0].response.map((reward) => {
                return <div className="flex flex-1 justify-between gap-2 p-1 py-1.5 rounded-md bg-compBgSec">
                    <div className="flex justify-center items-center gap-2">
                        <img
                            loading="lazy"
                            src="https://blaccsckullplatformsprivatelim.box.com/shared/static/8mlhyxt98p6ro1i52jruleq69dkfm9dg"
                            alt="judge"
                            className="shrink-0 aspect-square rounded-md  w-[26px]"
                        />
                        <h2 className="flex-auto my-auto font-gilroy-semibold text-sm">Ayu</h2>
                    </div>
                    <h1 className="font-gilroy-semibold text-[#008080] text-base">INR {reward} /-</h1>
                </div>
            })} */}
            <div className="flex flex-1 items-center justify-between gap-2 p-1 py-1.5 rounded-md bg-compBgSec md:bg-compBgSec">
                <div className="flex justify-center items-center gap-2">
                    {/* <img
                        loading="lazy"
                        src="https://blaccsckullplatformsprivatelim.box.com/shared/static/8mlhyxt98p6ro1i52jruleq69dkfm9dg"
                        alt="judge"
                        className="shrink-0 aspect-square rounded-md  w-[26px]"
                    /> */}
                    <div className='w-[26px] bg-compBg flex items-center justify-center p-1 rounded-md'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_61_1698)">
                                <path d="M5.49241 8.97084H5.49216C2.46378 8.97084 0 6.50643 0 3.47721V1.4729C0 1.21403 0.209875 1.00415 0.46875 1.00415H3.48041C3.73928 1.00415 3.94916 1.21403 3.94916 1.4729C3.94916 1.73178 3.73928 1.94165 3.48041 1.94165H0.9375V3.47721C0.9375 5.98946 2.98072 8.03331 5.49219 8.03331C5.75106 8.03331 5.96106 8.24318 5.96106 8.50206C5.96106 8.76093 5.75131 8.97084 5.49241 8.97084Z" fill="#FFAA3F" />
                                <path d="M10.5081 8.97084C10.2492 8.97084 10.0391 8.76096 10.0391 8.50209C10.0391 8.24321 10.2487 8.03334 10.5076 8.03334H10.5081C13.0194 8.03334 15.0625 5.98949 15.0625 3.47724V1.94165H12.5196C12.2607 1.94165 12.0508 1.73178 12.0508 1.4729C12.0508 1.21403 12.2607 1.00415 12.5196 1.00415H15.5312C15.7901 1.00415 16 1.21403 16 1.4729V3.47721C16 6.50643 13.5363 8.97084 10.5081 8.97084Z" fill="#FFAA3F" />
                                <path d="M12.5152 0H3.48035C3.22147 0 3.0116 0.209875 3.0116 0.46875V4.9875C3.0116 5.98109 3.26841 6.81466 3.79666 7.53578C4.84538 8.96741 6.54022 9.39403 6.52522 10.5041C6.52522 11.6228 4.01547 11.7096 4.01547 13.5229C4.01547 13.7818 4.22535 13.9917 4.48422 13.9917H11.5113C11.7702 13.9917 11.98 13.7818 11.98 13.5229C11.98 11.7096 9.47028 11.6228 9.47028 10.5041C9.45532 9.39403 11.1501 8.96741 12.1988 7.53578C12.7271 6.81466 12.9839 5.98109 12.9839 4.9875V0.46875C12.9839 0.209875 12.7741 0 12.5152 0Z" fill="#FFD316" />
                                <path d="M11.9801 13.5229C11.9801 11.7096 9.47039 11.6228 9.47039 10.5041C9.45542 9.39403 11.1502 8.96741 12.199 7.53578C12.7272 6.81466 12.984 5.98109 12.984 4.9875V0.46875C12.984 0.209875 12.7741 0 12.5153 0H7.99792V13.9917H11.5114C11.7703 13.9917 11.9801 13.7818 11.9801 13.5229Z" fill="#FFAA3F" />
                                <path d="M10.8313 4.06686C10.6997 3.66189 10.34 3.38418 9.91483 3.35939L9.21055 3.31839L8.95395 2.6613C8.79902 2.26464 8.42377 2.0083 7.99792 2.0083C7.57208 2.0083 7.19683 2.26461 7.04189 2.66127L6.7853 3.31839L6.08102 3.35939C5.65589 3.38418 5.29617 3.66186 5.16458 4.06686C5.03298 4.47189 5.1608 4.90802 5.4902 5.17789L6.03583 5.62502L5.8572 6.30746C5.74939 6.71939 5.9023 7.14733 6.24686 7.39771C6.59139 7.64796 7.04564 7.66118 7.40411 7.43133L7.99795 7.05055L8.5918 7.43133C8.95027 7.66118 9.40452 7.64799 9.74905 7.39771C10.0936 7.14733 10.2465 6.71939 10.1387 6.30746L9.96008 5.62502L10.5057 5.17789C10.835 4.90802 10.9629 4.47193 10.8313 4.06686Z" fill="#FFAA3F" />
                                <path d="M9.74899 7.39771C10.0935 7.14733 10.2465 6.71939 10.1386 6.30746L9.96002 5.62502L10.5056 5.17789C10.835 4.90802 10.9629 4.47193 10.8313 4.06686C10.6997 3.66189 10.34 3.38418 9.91483 3.35939L9.21055 3.31839L8.95396 2.6613C8.79902 2.26464 8.42377 2.0083 7.99792 2.0083V7.05055L8.59177 7.43133C8.95024 7.66114 9.40446 7.64796 9.74899 7.39771Z" fill="#FF8A53" />
                                <path d="M13.0216 15.5313H2.97388C2.715 15.5313 2.50513 15.3214 2.50513 15.0625V13.5229C2.50513 13.2641 2.715 13.0542 2.97388 13.0542H13.0216C13.2805 13.0542 13.4904 13.2641 13.4904 13.5229V15.0625C13.4904 15.3214 13.2805 15.5313 13.0216 15.5313Z" fill="#FFAA3F" />
                                <path d="M13.4905 15.0625V13.5229C13.4905 13.2641 13.2806 13.0542 13.0217 13.0542H7.99792V15.5313H13.0218C13.2806 15.5313 13.4905 15.3214 13.4905 15.0625Z" fill="#FF8A53" />
                                <path d="M14.0212 16H1.97461C1.71573 16 1.50586 15.7901 1.50586 15.5312C1.50586 15.2724 1.71573 15.0625 1.97461 15.0625H14.0212C14.2801 15.0625 14.49 15.2724 14.49 15.5312C14.49 15.7901 14.28 16 14.0212 16Z" fill="#344779" />
                                <path d="M14.49 15.5312C14.49 15.2724 14.2801 15.0625 14.0212 15.0625H7.99792V16H14.0212C14.2801 16 14.49 15.7901 14.49 15.5312Z" fill="#0C1638" />
                            </g>
                            <defs>
                                <clipPath id="clip0_61_1698">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <h2 className="flex-auto my-auto text-compSec font-gilroy-semibold text-sm md:text-xs">Winner</h2>
                </div>
                <p className="font-gilroy-semibold text-[#008080] text-sm md:text-xs md:mt-0.5 lg:whitespace-nowrap">&#8377; {fields[0].response[0]}</p>

            </div>
            <div className="flex flex-1 items-center justify-between gap-2 p-1 py-1.5 rounded-md bg-compBgSec ">
                <div className="flex justify-center items-center gap-2">
                    {/* <img
                        loading="lazy"
                        src="https://blaccsckullplatformsprivatelim.box.com/shared/static/8mlhyxt98p6ro1i52jruleq69dkfm9dg"
                        alt="judge"
                        className="shrink-0 aspect-square rounded-md  w-[26px]"
                    /> */}
                    <div className='w-[26px] bg-compBg flex items-center justify-center p-1 rounded-md'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.03125 0.5V4.25C3.03125 4.38906 3.09313 4.52094 3.2 4.61L5.95 6.90156L7.7 8.36C7.78687 8.4325 7.89344 8.46875 8 8.46875C8.10656 8.46875 8.21313 8.4325 8.3 8.36L10.05 6.90156L12.8 4.61C12.9069 4.52094 12.9688 4.38906 12.9688 4.25V0.5H3.03125Z" fill="#48BBF0" />
                            <path d="M12.9688 0.5V4.25C12.9688 4.38906 12.9069 4.52094 12.8 4.61L10.05 6.90156L8.3 8.36C8.21313 8.4325 8.10656 8.46875 8 8.46875V0.54625C8.31094 0.53875 8.6375 0.524062 8.98 0.5H12.9688Z" fill="#1293DA" />
                            <path d="M5.78125 0.5V6.54156C5.78125 6.68062 5.84312 6.8125 5.95 6.90156L7.7 8.36C7.78687 8.4325 7.89344 8.46875 8 8.46875C8.10656 8.46875 8.21313 8.4325 8.3 8.36L10.05 6.90156C10.1569 6.8125 10.2188 6.68062 10.2188 6.54156V0.5H5.78125Z" fill="#84FFAB" />
                            <path d="M10.2188 0.5V6.54156C10.2188 6.68062 10.1569 6.8125 10.05 6.90156L8.3 8.36C8.21313 8.4325 8.10656 8.46875 8 8.46875V0.5H10.2188Z" fill="#28DD7A" />
                            <path d="M13.9688 0.46875C13.9688 0.7275 13.7587 0.9375 13.5 0.9375H2.5C2.24125 0.9375 2.03125 0.7275 2.03125 0.46875C2.03125 0.21 2.24125 0 2.5 0H13.5C13.7587 0 13.9688 0.21 13.9688 0.46875Z" fill="#596C76" />
                            <path d="M13.9688 0.46875C13.9688 0.7275 13.7587 0.9375 13.5 0.9375H8V0H13.5C13.7587 0 13.9688 0.21 13.9688 0.46875Z" fill="#3E4F55" />
                            <path d="M8 4.5C4.82938 4.5 2.25 7.07938 2.25 10.25C2.25 13.4206 4.82938 16 8 16C11.1706 16 13.75 13.4206 13.75 10.25C13.75 7.07938 11.1706 4.5 8 4.5Z" fill="#CBBDB8" />
                            <path d="M13.75 10.25C13.75 13.4206 11.1706 16 8 16V4.5C11.1706 4.5 13.75 7.07938 13.75 10.25Z" fill="#BFB5B1" />
                            <path d="M8 6.28125C5.81156 6.28125 4.03125 8.06156 4.03125 10.25C4.03125 12.4384 5.81156 14.2188 8 14.2188C10.1884 14.2188 11.9688 12.4384 11.9688 10.25C11.9688 8.06156 10.1884 6.28125 8 6.28125Z" fill="#FFF4F4" />
                            <path d="M11.9688 10.25C11.9688 12.4384 10.1884 14.2188 8 14.2188V6.28125C10.1884 6.28125 11.9688 8.06156 11.9688 10.25Z" fill="#F1E1DB" />
                            <path d="M9.5938 11.9973C9.59536 12.256 9.38692 12.4673 9.12786 12.4688C8.78849 12.471 8.50255 12.472 8.26161 12.472C8.16786 12.472 8.08067 12.472 8.00005 12.4713C7.03099 12.4682 6.96474 12.4457 6.89942 12.4241C6.75224 12.3751 6.63817 12.257 6.59442 12.1079C6.52036 11.8563 6.65005 11.667 6.88536 11.3232C7.01567 11.1332 7.1988 10.8779 7.42911 10.5645C7.63317 10.287 7.84442 10.0054 8.00005 9.79945C8.13692 9.61789 8.23067 9.49508 8.23849 9.4857C8.31005 9.39633 8.33099 9.27977 8.29411 9.1732C8.27474 9.11727 8.22255 9.0182 8.08942 8.98695C8.06036 8.98008 8.03036 8.97539 8.00005 8.9732C7.98255 8.97133 7.96474 8.9707 7.94692 8.9707C7.6513 8.9707 7.39536 9.1807 7.33849 9.46977C7.2888 9.72383 7.04224 9.88945 6.78817 9.83945C6.53411 9.78945 6.3688 9.5432 6.41849 9.28914C6.56161 8.56133 7.20442 8.0332 7.94692 8.0332C7.96474 8.0332 7.98255 8.03352 8.00005 8.03414C8.10255 8.03758 8.20411 8.05102 8.30349 8.07414C8.71442 8.1707 9.04224 8.46727 9.18036 8.86758C9.32224 9.27852 9.24442 9.72758 8.97224 10.0691C8.78692 10.3085 8.35349 10.8854 8.00005 11.3729C7.96036 11.4276 7.92192 11.481 7.88474 11.5326C7.92224 11.5329 7.96099 11.5329 8.00005 11.5329C8.34317 11.5345 8.74349 11.5335 9.12224 11.5313H9.12505C9.38255 11.5313 9.59224 11.7391 9.5938 11.9973Z" fill="#CBBDB8" />
                            <path d="M8.97219 10.0692C8.78687 10.3086 8.35344 10.8854 8 11.3729V9.79949C8.13688 9.61793 8.23063 9.49512 8.23844 9.48574C8.31 9.39637 8.33094 9.27981 8.29406 9.17324C8.27469 9.11731 8.2225 9.01824 8.08937 8.98699C8.06031 8.98012 8.03031 8.97543 8 8.97324V8.03418C8.1025 8.03762 8.20406 8.05105 8.30344 8.07418C8.71437 8.17074 9.04219 8.46731 9.18031 8.86762C9.32219 9.27856 9.24437 9.72762 8.97219 10.0692Z" fill="#BFB5B1" />
                            <path d="M9.59375 11.9972C9.59531 12.2559 9.38687 12.4672 9.12781 12.4688C8.78844 12.4709 8.5025 12.4719 8.26156 12.4719C8.16781 12.4719 8.08062 12.4719 8 12.4712V11.5328C8.34312 11.5344 8.74344 11.5334 9.12219 11.5312H9.125C9.3825 11.5312 9.59219 11.7391 9.59375 11.9972Z" fill="#BFB5B1" />
                        </svg>
                    </div>
                    <h2 className="flex-auto my-auto text-compSec font-gilroy-semibold text-sm md:text-xs">2nd </h2>
                </div>
                <h1 className="font-gilroy-semibold text-[#008080] text-sm md:text-xs lg:whitespace-nowrap">&#8377; {fields[0].response[1]}</h1>
            </div>
            <div className="flex flex-1 items-center justify-between gap-2 p-1 py-1.5 rounded-md bg-compBgSec ">
                <div className="flex justify-center items-center gap-2">
                    {/* <img
                        loading="lazy"
                        src="https://blaccsckullplatformsprivatelim.box.com/shared/static/8mlhyxt98p6ro1i52jruleq69dkfm9dg"
                        alt="judge"
                        className="shrink-0 aspect-square rounded-md  w-[26px]"
                    /> */}
                    <div className='w-[26px] bg-compBg flex items-center justify-center p-1 rounded-md'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.03125 0.5V4.25C3.03125 4.38906 3.09313 4.52094 3.2 4.61L5.95 6.90156L7.7 8.36C7.78687 8.4325 7.89344 8.46875 8 8.46875C8.10656 8.46875 8.21313 8.4325 8.3 8.36L10.05 6.90156L12.8 4.61C12.9069 4.52094 12.9688 4.38906 12.9688 4.25V0.5H3.03125Z" fill="#48BBF0" />
                            <path d="M12.9688 0.5V4.25C12.9688 4.38906 12.9069 4.52094 12.8 4.61L10.05 6.90156L8.3 8.36C8.21313 8.4325 8.10656 8.46875 8 8.46875V0.5H12.9688Z" fill="#1293DA" />
                            <path d="M5.78125 0.5V6.54156C5.78125 6.68062 5.84312 6.8125 5.95 6.90156L7.7 8.36C7.78687 8.4325 7.89344 8.46875 8 8.46875C8.10656 8.46875 8.21313 8.4325 8.3 8.36L10.05 6.90156C10.1569 6.8125 10.2188 6.68062 10.2188 6.54156V0.5H5.78125Z" fill="#84FFAB" />
                            <path d="M10.2188 0.5V6.54156C10.2188 6.68062 10.1569 6.8125 10.05 6.90156L8.3 8.36C8.21313 8.4325 8.10656 8.46875 8 8.46875V0.5H10.2188Z" fill="#28DD7A" />
                            <path d="M13.9688 0.46875C13.9688 0.7275 13.7587 0.9375 13.5 0.9375H2.5C2.24125 0.9375 2.03125 0.7275 2.03125 0.46875C2.03125 0.21 2.24125 0 2.5 0H13.5C13.7587 0 13.9688 0.21 13.9688 0.46875Z" fill="#596C76" />
                            <path d="M13.9688 0.46875C13.9688 0.7275 13.7587 0.9375 13.5 0.9375H8V0H13.5C13.7587 0 13.9688 0.21 13.9688 0.46875Z" fill="#3E4F55" />
                            <path d="M8 4.5C4.82938 4.5 2.25 7.07938 2.25 10.25C2.25 13.4206 4.82938 16 8 16C11.1706 16 13.75 13.4206 13.75 10.25C13.75 7.07938 11.1706 4.5 8 4.5Z" fill="#F67E00" />
                            <path d="M13.75 10.25C13.75 13.4206 11.1706 16 8 16V4.5C11.1706 4.5 13.75 7.07938 13.75 10.25Z" fill="#D06A00" />
                            <path d="M8 6.28125C5.81156 6.28125 4.03125 8.06156 4.03125 10.25C4.03125 12.4384 5.81156 14.2188 8 14.2188C10.1884 14.2188 11.9688 12.4384 11.9688 10.25C11.9688 8.06156 10.1884 6.28125 8 6.28125Z" fill="#FF9E00" />
                            <path d="M11.9688 10.25C11.9688 12.4384 10.1884 14.2188 8 14.2188V6.28125C10.1884 6.28125 11.9688 8.06156 11.9688 10.25Z" fill="#F67E00" />
                            <path d="M9.14406 10.25C9.34625 10.4853 9.46875 10.7912 9.46875 11.125C9.46875 11.8659 8.86594 12.4688 8.125 12.4688H7.75C7.07812 12.4688 6.53125 11.9219 6.53125 11.25C6.53125 10.9913 6.74125 10.7812 7 10.7812C7.25875 10.7812 7.46875 10.9913 7.46875 11.25C7.46875 11.405 7.595 11.5312 7.75 11.5312H8.125C8.34906 11.5312 8.53125 11.3491 8.53125 11.125C8.53125 10.9009 8.34906 10.7188 8.125 10.7188H8C7.74125 10.7188 7.53125 10.5087 7.53125 10.25C7.53125 9.99125 7.74125 9.78125 8 9.78125H8.125C8.34906 9.78125 8.53125 9.59906 8.53125 9.375C8.53125 9.15094 8.34906 8.96875 8.125 8.96875H7.75C7.595 8.96875 7.46875 9.095 7.46875 9.25C7.46875 9.50875 7.25875 9.71875 7 9.71875C6.74125 9.71875 6.53125 9.50875 6.53125 9.25C6.53125 8.57812 7.07812 8.03125 7.75 8.03125H8.125C8.86594 8.03125 9.46875 8.63406 9.46875 9.375C9.46875 9.70875 9.34625 10.0147 9.14406 10.25Z" fill="#F67E00" />
                            <path d="M9.14406 10.25C9.34625 10.4853 9.46875 10.7912 9.46875 11.125C9.46875 11.8659 8.86594 12.4688 8.125 12.4688H8V11.5312H8.125C8.34906 11.5312 8.53125 11.3491 8.53125 11.125C8.53125 10.9009 8.34906 10.7188 8.125 10.7188H8V9.78125H8.125C8.34906 9.78125 8.53125 9.59906 8.53125 9.375C8.53125 9.15094 8.34906 8.96875 8.125 8.96875H8V8.03125H8.125C8.86594 8.03125 9.46875 8.63406 9.46875 9.375C9.46875 9.70875 9.34625 10.0147 9.14406 10.25Z" fill="#D06A00" />
                        </svg>

                    </div>
                    <h2 className="flex-auto my-auto text-compSec font-gilroy-semibold text-sm md:text-xs">3rd </h2>
                </div>
                <h1 className="font-gilroy-semibold text-[#008080] text-sm md:text-xs lg:whitespace-nowrap">&#8377; {fields[0].response[2]}</h1>
            </div>

        </div>
    )
}

export default F