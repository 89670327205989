import React, {
  useEffect,
  useState,
  lazy,
  useRef,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import FriendRequest from "../../Components/FriendRequest/FriendRequest";
import { Helmet } from "react-helmet";
import {
  REDIRECT_FALSE,
  REMOVE_MESSAGE,
  REPORTED_POSTS,
} from "../../store/types/postTypes";
import { path } from "../../path";
import Skillmodel from "../../Components/SelectSkills/Skillmodel";
import { useHistory } from "react-router-dom";
import PhotoPosts from "../../Components/Feed/PhotoPosts";
import VideoPost from "../../Components/Feed/VideoPost";
import BlogPosts from "../../Components/Feed/BlogPosts";
import UserComps from "../../Components/SimillarUsers/UserComps";

import SimilarUser from "../../Components/SimillarUsers/SimilarUser";
import MobileFooter from "../../Components/footer/MobileFooter";
import ProffesionalSkillFilter from "../../Components/PostsFilter/ProffesionalSkillFilter";
import "../HomePage/HomePage.css";
import Admin from "../../Components/Admin/Admin";

import FeedbackModal from "../../Components/FeedbackForm/FeedbackModal";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import useInfinitePostScroll from "../../Components/Infinite Scrolling/useInfinitePostScroll";
import { getPostId } from "../../store/asyncMethods/SearchPostMethod";
import { UPDATE_ACCOUNT } from "./../../store/types/AuthTypes";

import Skeleton from "@mui/material/Skeleton";
import Footer from "../../Components/footer/Footer";

const UploadBlog = lazy(() => import("../../Components/uploads/UploadBlog"));
const UploadImage = lazy(() => import("../../Components/uploads/UploadImage"));
const UploadVideos = lazy(() => import("../../Components/uploads/UploadVideos"));
const HomePage = () => {
  const history = useHistory();
  const { user, token, feedFilled } = useSelector(
    (state) => state.AuthReducer
  );
  const { loading, redirect, message, newPost, newTempPosts, ReportedPosts } =
    useSelector((state) => state.PostReducer);


  // Applied Use Memo on ALL SKILLS (before : 21 , After : 2)
  const initialSkills = () => {
    return user?.professionalSkills
      ?.map((skill) => {
        return { name: skill, type: "Polished" };
      })
      .concat(
        user?.unprofessionalSkills?.map((skill) => {
          return { name: skill, type: "Novice" };
        })
      );
  };

  const { allSkills } = useState(initialSkills);
  const postContentWrapperRef = useRef();
  const postImageWrapperRef = useRef();

  const { postLoading } = useSelector((state) => state.NotificationReducer);
  const dispatch = useDispatch();
  const { homePageData } = useSelector((state) => state.FetchPosts);
  const { sharepostId } = useSelector((state) => state.SearchPostReducer);
  const [filterSidebar, setfilterSidebar] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [skillQueryArray, setSkillQueryArray] = useState(initialSkills);
  const [postType, setPostType] = useState(new Array(3).fill(true));
  const [noPosts, setNoPosts] = useState(false);
  const [redirectModal, setRedirectModal] = useState(false);
  const [options, setOptions] = useState(false);
  const [togglePostContent, settogglePostContent] = useState(false);
  const [togglePostImageContent, settogglePostImageContent] = useState(false);
  const [togglePostVideoContent, settogglePostVideoContent] = useState(false);
  const showOptions = () => {
    setOptions(prev => !prev);
  }

  useEffect(() => {
    let currentSkills = user?.professionalSkills
      ?.map((skill) => {
        return { name: skill, type: "Polished" };
      })
      .concat(
        user?.unprofessionalSkills?.map((skill) => {
          return { name: skill, type: "Novice" };
        })
      );
    setSkillQueryArray([...currentSkills]);
  }, [user?.skills]);

  useEffect(() => {
    if (!loading && user?.skills.length === 0) {
      setRedirectModal(true);
      console.log(loading);
    }
  }, [loading, user]);

  // Infinite scrolling logic starts
  const [page, setPage] = useState(1);
  const observer = useRef();

  const skillQueryArrayRef = useRef(skillQueryArray);
  skillQueryArrayRef.current = skillQueryArray;

  const { posts, hasMore, postLoader, setPosts } = useInfinitePostScroll(
    skillQueryArrayRef.current,
    postType,
    page,
    token,
    user._id,
    noPosts
  );

  const lastPostRef = useCallback(
    (node) => {
      if (postLoader) { return; }
      if (observer.current) { observer.current.disconnect(); }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prev) => prev + 1);
        }
      });
      setTimeout(() => {
        if (node) { observer.current.observe(node); }
      }, 3000);
    },
    [postLoader, hasMore]
  );

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (ReportedPosts) {
      setPosts((prev) => prev.filter((post) => post._id !== ReportedPosts));
      dispatch({ type: REPORTED_POSTS, payload: null });
    }
  }, [ReportedPosts]);

  const handlePostTypeChange = useCallback(
    (value) => {
      setPostType(value);
      setPosts([]);
      if (page === 1) { setNoPosts((prev) => !prev); }
      else { setPage(1); }
    },
    [page]
  );

  useEffect(() => {
    if (user?.isMarketingAdmin) {
      history.push("/marketingadmin");
    }
  }, []);
  const handleSkillQuerryChange = useCallback(
    (value) => {
      if (value.length === 0) { setSkillQueryArray([...allSkills]); }
      else { setSkillQueryArray(value); }
      setPosts([]);
      if (page === 1) { setNoPosts((prev) => !prev); }
      else { setPage(1); }
    },
    [page]
  );

  // ends


  //Handle upload post toggle
  const handletogglePostContent = useCallback(() => {

    settogglePostContent((curr) => !curr);
    settogglePostVideoContent(false);
    settogglePostImageContent(false);
    setOptions(false)


  }, [togglePostContent]);

  const handletogglePostImageContent = useCallback(() => {
    settogglePostImageContent(!togglePostImageContent);
    settogglePostContent(false);
    settogglePostVideoContent(false);
    setOptions(false)


  }, [togglePostImageContent]);
  const handletogglePostVideoContent = useCallback(() => {

    settogglePostContent(false);
    settogglePostVideoContent(!togglePostVideoContent);
    settogglePostImageContent(false);
    setOptions(false)

  }, [togglePostVideoContent]);

  useEffect(() => {
    if (sharepostId !== "") {
      dispatch(getPostId(""));
      history.push(`post/${sharepostId}`);
    }
  }, [sharepostId]);

  useEffect(() => {
    if (typeof localStorage.getItem("currPost") !== "undefined") {
      localStorage.removeItem("currPost");
    }
    if (redirect) {
      dispatch({ type: REDIRECT_FALSE });
    }
    if (message) {
      alert(message);
      dispatch({ type: REMOVE_MESSAGE });
    }
    if (newPost) {
      homePageData.unshift(newPost);
      dispatch({ type: "UNSET_NEW_POST" });
    }
  }, [message, redirect, loading, newPost]);

  //handling left sidebar
  //finding all the users having similar skillset
  const config = {
    host: `${path}`,
    headers: {
      "Access-Control-Allow-Origin": `${path}`,
      Authorization: `Bearer ${token}`,
    },
    token,
  };
  useEffect(() => {
    console.log("HOME");
    const updateUser = async () => {
      try {
        const res = await axios.get(`${path}/getUserData/${user._id}`, config);
        dispatch({ type: UPDATE_ACCOUNT, payload: res.data });
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    updateUser();

    dispatch({ type: "SET_HEADER" });

    const findSimilarSkillsetUsers = async () => {
    };
    user.isAdmin === false &&
      !user.isMarketingAdmin &&
      findSimilarSkillsetUsers();
    return () => {
      dispatch({ type: "CLEAR_NEW_TEMP_POSTS" });
    };
  }, []);

  const ref = useRef(null);

  const filterHamburgerMenu = (e) => {
    e.preventDefault();
    if (filterSidebar) {
      setfilterSidebar(false);
    } else {
      setfilterSidebar(true);
    }
  };

  function closeSidebarHandler(e) {
    if (
      filterSidebar &&
      document.getElementById("filterSidebar")?.contains(e.target) === false
    ) {
      setfilterSidebar(false);
    }
  }

  const [currTime, setCurrTime] = useState(new Date().getMinutes());

  setInterval(() => {
    setCurrTime(new Date().getMinutes());
  }, 60000);

  const logTimeRef = useRef(new Date().getMinutes());
  useEffect(() => {
    // console.log(currTime - logTimeRef.current)
    if (
      user.feedbackFilled === false &&
      feedFilled === false &&
      currTime - logTimeRef.current >= 30
    ) {
      console.log("Feeding time");
      setFeedbackModalOpen(true);
      logTimeRef.current = currTime;
    }
  }, [currTime, user.feedbackFilled, feedFilled]);

  useEffect(() => {
    if (newTempPosts?.length > 0) { setPosts((prev) => [...newTempPosts, ...prev]); }
  }, [newTempPosts]);

  // for model background color
  const [close, setClose] = useState(false);
  const modalBackgroundClass =
    user?.theme === "light" ? "bg-[#0000004f]" : "bg-[#ffffff20]";

  return (
    <div className="relative h-[100vh] pt-[37px] sm:pt-[61px]">
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Upload Video" />
      </Helmet>
      {redirectModal && (
        <div
          className={`z-50 fixed flex flex-col justify-center w-[100vw] h-[100vh] items-center ${close ? "" : modalBackgroundClass
            }`}>
          <Skillmodel setClose={setClose}></Skillmodel>
        </div>
      )}
      {user.isAdmin ? (
        <Admin />
      ) : (
        <div
          onClick={closeSidebarHandler}
          ref={ref}
          id="home-Page"
          className={`scrollbar h-full overflow-auto bg-page  opacity-${feedbackModalOpen === true ? 10 : null
            }`}>
          <MobileFooter />
          {/* {redirectModal && (
                <div className="z-50 absolute flex flex-col justify-center  w-[100vw] h-[100vh] bg-transparent">
                  <Skillmodel></Skillmodel>
                </div>
              )} */}
          {/* filter */}
          <div>
            <div
              id="filterSidebar"
              className={`w-72 p-2 shadow-xl  bg-card text-primary sm:hidden transition-all fixed h-[100vh] top-0 ${filterSidebar ? "left-0" : "-left-72"
                } z-20`}>
              <div className="flex justify-between p-2 border-b mt-7 sm:hidden">
                <div className="flex divide-y">
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2">
                    <path
                      d="M7.97044 17.75V12.25H9.34029V14.25H16.6462V15.75H9.34029V17.75H7.97044ZM0.664551 15.75V14.25H5.68735V15.75H0.664551ZM4.31749 11.75V9.75H0.664551V8.25H4.31749V6.25H5.68735V11.75H4.31749ZM7.97044 9.75V8.25H16.6462V9.75H7.97044ZM11.6234 5.75V0.25H12.9932V2.25H16.6462V3.75H12.9932V5.75H11.6234ZM0.664551 3.75V2.25H9.34029V3.75H0.664551Z"
                      fill="#637381"
                    />
                  </svg>
                  <p
                    className="text-[#637381] text-sm"
                    style={{ borderTopWidth: 0 }}>
                    FILTERS
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={(e) => {
                    filterHamburgerMenu(e);
                  }}>
                  <svg
                    width="21"
                    height="16"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.45801 11.9754L12.8687 8.56468L16.2794 11.9754L17.0832 11.2032L13.6409 7.79251L17.0515 4.38183L16.2794 3.60966L12.8687 7.02034L9.45801 3.60966L8.68584 4.38183L12.0965 7.79251L8.68584 11.2032L9.45801 11.9754ZM0.610474 7.79251L5.17941 1.32558C5.4368 0.939494 5.7745 0.633971 6.1925 0.409012C6.61102 0.183538 7.05605 0.0708008 7.52758 0.0708008H19.0136C19.5284 0.0708008 19.9683 0.258438 20.3333 0.633714C20.6977 1.00899 20.88 1.44346 20.88 1.93714V13.6479C20.88 14.1416 20.6977 14.576 20.3333 14.9513C19.9683 15.3266 19.5284 15.5142 19.0136 15.5142H7.52758C7.03442 15.5142 6.5894 15.3963 6.1925 15.1606C5.7956 14.9243 5.45791 14.6026 5.17941 14.1954L0.610474 7.79251ZM1.92934 7.79251L6.17628 13.7768C6.3266 13.9699 6.50909 14.1253 6.72375 14.2432C6.9379 14.3611 7.17367 14.4201 7.43106 14.4201H19.0136C19.2067 14.4201 19.3892 14.3398 19.5611 14.1791C19.7325 14.018 19.8182 13.8409 19.8182 13.6479V1.93714C19.8182 1.7441 19.7325 1.56701 19.5611 1.40589C19.3892 1.24527 19.2067 1.16497 19.0136 1.16497H7.43106C7.17367 1.16497 6.9325 1.22391 6.70754 1.34179C6.48206 1.45968 6.30498 1.61514 6.17628 1.80819L1.92934 7.79251Z"
                      fill="#637381"
                    />
                  </svg>
                </div>
              </div>
              <ProffesionalSkillFilter
                postLoader={postLoader}
                skillQueryArray={skillQueryArray}
                setSkillQueryArray={handleSkillQuerryChange}
                postType={postType}
                setPostType={handlePostTypeChange}
              />
            </div>
            <div
              className={`fixed w-[100vw] h-[100vh] bg-[black] opacity-30  ${filterSidebar ? "z-10" : "-z-10"
                }`}></div>
          </div>

          <div
            id="home-content"
            className="flex h-full bg-page overflow-auto scrollbar flex-row justify-center w-[100%] lg:mx-auto  overflow-x-hidden">
            <div className="hidden fixed trial w-[27%] sm:mt-1 left-0 xl:left-[0.4%] lg:justify-start md:flex-2 scrollbar-leftsec sm:flex lg:flex-1 flex-row-reverse home-side-left heightOfLeftAndRightSec overflow-y-scroll">
              <div className="w-[80%] flex-col mb-0 md:flex pop">
                <FriendRequest />
                <ProffesionalSkillFilter
                  postLoader={postLoader}
                  skillQueryArray={skillQueryArray}
                  setSkillQueryArray={handleSkillQuerryChange}
                  postType={postType}
                  setPostType={handlePostTypeChange}
                />
              </div>
            </div>

            <div className="spacer"></div>

            <div className="flex test sm:mt-1 md:mx-auto marginCorrector flex-col w-[100%] sm:w-[65%] sm:ml-[25%] md:w-[42%] xl:w-[42%] posts">
              {Object.keys(newTempPosts).length > 0 &&
                (newTempPosts.type === "photo" ? (
                  <PhotoPosts key={newTempPosts._id} post={newTempPosts} />
                ) : newTempPosts.type === "video" ? (
                  <VideoPost key={newTempPosts._id} post={newTempPosts} />
                ) : (
                  <BlogPosts key={newTempPosts._id} post={newTempPosts} />
                ))}

              {posts?.length > 0
                ? posts?.map((p, index) => {
                  if (posts.length === index + 1 && p) {
                    if (p.type === "video" && p.userId !== null) {
                      return (
                        <VideoPost ref={lastPostRef} key={p._id} post={p} />
                      );
                    } else if (p.type === "photo" && p.userId !== null) {
                      return (
                        <PhotoPosts
                          className=""
                          ref={lastPostRef}
                          key={p._id}
                          post={p}
                        />
                      );
                    } else if (p.type === "blog" && p.userId !== null) {
                      return (
                        <BlogPosts ref={lastPostRef} key={p._id} post={p} />
                      );
                    }
                  } else if (p) {
                    if (p.type === "video" && p.userId !== null) {
                      return <VideoPost key={p._id} post={p} />;
                    } else if (p.type === "photo" && p.userId !== null) {
                      return <PhotoPosts className="" key={p._id} post={p} />;
                    } else if (p.type === "blog" && p.userId !== null) {
                      return <BlogPosts key={p._id} post={p} />;
                    }
                  }
                })
                : !postLoader &&
                !postLoading && (
                  <div className="flex items-center justify-center mt-4">
                    <div>
                      <div className="max-w-sm overflow-hidden border rounded-lg shadow-lg bg-card border-viewMore">
                        <div className="px-6 py-4">
                          <div className="mb-2 text-xl font-bold text-primary">
                            No post found
                          </div>
                          <p className="text-base text-message">
                            The skill you entered have not featured in any of
                            the posts.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {(postLoader || postLoading) && (
                <div className="w-[100%] flex flex-col gap-5">
                  <div className="w-[100%] h-full flex flex-col gap-3 items-center">
                    <div className="flex items-center justify-start w-full gap-3">
                      <Skeleton
                        sx={{
                          bgcolor: `${user.theme === "dark"
                            ? "rgba(255, 255, 255, 0.2)"
                            : ""
                            }`,
                        }}
                        variant="circular"
                        width={40}
                        height={40}
                      />
                      <div className="w-[80%] flex flex-col gap-1">
                        <Skeleton
                          sx={{
                            bgcolor: `${user.theme === "dark"
                              ? "rgba(255, 255, 255, 0.2)"
                              : ""
                              }`,
                          }}
                          variant="rounded"
                          width={"60%"}
                          height={10}
                        />
                        <Skeleton
                          sx={{
                            bgcolor: `${user.theme === "dark"
                              ? "rgba(255, 255, 255, 0.2)"
                              : ""
                              }`,
                          }}
                          variant="rounded"
                          width={"60%"}
                          height={10}
                        />
                      </div>
                    </div>
                    <Skeleton
                      sx={{
                        bgcolor: `${user.theme === "dark"
                          ? "rgba(255, 255, 255, 0.2)"
                          : ""
                          }`,
                      }}
                      variant="rounded"
                      width={"100%"}
                      height={"60vh"}
                    />
                  </div>
                  <div className="w-[100%] h-full flex flex-col gap-3 items-center">
                    <div className="flex items-center justify-start w-full gap-3">
                      <Skeleton
                        sx={{
                          bgcolor: `${user.theme === "dark"
                            ? "rgba(255, 255, 255, 0.2)"
                            : ""
                            }`,
                        }}
                        variant="circular"
                        width={40}
                        height={40}
                      />
                      <div className="w-[80%] flex flex-col gap-1">
                        <Skeleton
                          sx={{
                            bgcolor: `${user.theme === "dark"
                              ? "rgba(255, 255, 255, 0.2)"
                              : ""
                              }`,
                          }}
                          variant="rounded"
                          width={"60%"}
                          height={10}
                        />
                        <Skeleton
                          sx={{
                            bgcolor: `${user.theme === "dark"
                              ? "rgba(255, 255, 255, 0.2)"
                              : ""
                              }`,
                          }}
                          variant="rounded"
                          width={"60%"}
                          height={10}
                        />
                      </div>
                    </div>
                    <Skeleton
                      sx={{
                        bgcolor: `${user.theme === "dark"
                          ? "rgba(255, 255, 255, 0.2)"
                          : ""
                          }`,
                      }}
                      variant="rounded"
                      width={"100%"}
                      height={"60vh"}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* <div className="flex border h-[88vh] md:flex-1 flex-col lg:flex-[1.5] w-[100%] md:w-[45%] lg:w-[45%] xl:w-[45%] posts"></div> */}
            <div className="overflow-y-scroll h-[88vh] sm:mt-1 scrollbar-leftsec hidden fixed right-[7%] w-[21%] lg:justify-start items-start md:flex-2 md:flex lg:flex-1 flex-col  RightSideHome ">
              <div className="w-[100%] md:flex flex-col">
                <SimilarUser />
                <div className="">
                  <h1 className="text-xl text-primary font-gilroy-semibold  my-2">Competitions</h1>
                </div>
                <UserComps />


                <div className="mt-2">
                  <Footer />
                </div>

              </div>
            </div>

            {/* <Suspense fallback={<FallbackUI />}> */}
            {togglePostContent && (
              <div ref={postContentWrapperRef}>
                <UploadBlog toggle={handletogglePostContent} />
              </div>
            )}

            {togglePostImageContent && (
              <div ref={postImageWrapperRef}>
                <UploadImage
                  toggle={handletogglePostImageContent}
                  redirect={redirectModal}
                />
              </div>
            )}

            {togglePostVideoContent && (
              <div>
                <UploadVideos
                  settogglePostVideoContent={settogglePostVideoContent}
                  toggle={handletogglePostVideoContent}
                  // settogglePostVideoContent={settogglePostVideoContent}
                  redirect={redirectModal}
                />
              </div>
            )}
            {/* </Suspense> */}

            <div className="icon absolute bottom-0 right-0 mr-4 mb-4">
              {
                options ? (
                  <div className="menu-items flex flex-col mr-1 text-[#008080]">
                    <div className="menu-item flex items-center justify-end w-full p-2 gap-1" onClick={handletogglePostContent}>
                      <p className="flex-1 text-right text-sm ">
                        Content
                      </p>
                      <svg className="cursor-pointer" width="38" height="38" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="14.9824" cy="14.3479" r="14" fill="#008080" />
                        <g clipPath="url(#clip0_4115_104)">
                          <path d="M15.607 14.3481C15.607 14.6931 15.327 14.9731 14.982 14.9731C14.637 14.9731 14.357 14.6931 14.357 14.3481C14.357 14.0031 14.637 13.7231 14.982 13.7231C15.327 13.7231 15.607 14.0031 15.607 14.3481ZM17.0654 13.7231C16.7204 13.7231 16.4404 14.0031 16.4404 14.3481C16.4404 14.6931 16.7204 14.9731 17.0654 14.9731C17.4104 14.9731 17.6904 14.6931 17.6904 14.3481C17.6904 14.0031 17.4104 13.7231 17.0654 13.7231ZM12.8987 13.7231C12.5537 13.7231 12.2737 14.0031 12.2737 14.3481C12.2737 14.6931 12.5537 14.9731 12.8987 14.9731C13.2437 14.9731 13.5237 14.6931 13.5237 14.3481C13.5237 14.0031 13.2437 13.7231 12.8987 13.7231ZM19.982 14.4897V17.2647C19.982 18.4135 19.0474 19.3481 17.8987 19.3481H15.4333C12.5162 19.3481 10.1779 17.3514 9.99452 14.7039C9.8941 13.2556 10.4224 11.8381 11.4441 10.8151C12.4658 9.79223 13.8833 9.26223 15.3304 9.36015C17.9387 9.53723 19.982 11.7901 19.982 14.4897ZM19.1487 14.4897C19.1487 12.2268 17.447 10.3389 15.2741 10.1914C15.1787 10.1847 15.0833 10.1818 14.9883 10.1818C13.8816 10.1818 12.8179 10.6185 12.0337 11.4039C11.182 12.2564 10.7416 13.4385 10.8258 14.6464C10.9812 16.8881 12.9191 18.5152 15.4337 18.5152H17.8991C18.5883 18.5152 19.1491 17.9543 19.1491 17.2651V14.4901L19.1487 14.4897Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_4115_104">
                            <rect width="10" height="10" fill="white" transform="translate(9.98242 9.3479)" />
                          </clipPath>
                        </defs>
                      </svg>

                    </div>
                    <div className="menu-itemm flex items-center w-full p-2 gap-1 mr" onClick={handletogglePostImageContent}>
                      <p className="flex-1 text-right text-sm">
                        Image
                      </p>
                      <svg className="cursor-pointer" width="38" height="38" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="14.7793" cy="14.0266" r="14" fill="#008080" />
                        <g clipPath="url(#clip0_3892_3575)">
                          <path d="M17.696 10.6933H17.4843L16.5743 9.51328C16.457 9.36236 16.3069 9.24012 16.1354 9.1558C15.9639 9.07148 15.7754 9.0273 15.5843 9.02661H13.9743C13.7832 9.0273 13.5947 9.07148 13.4232 9.1558C13.2517 9.24012 13.1016 9.36236 12.9843 9.51328L12.0743 10.6933H11.8626C11.3103 10.6939 10.7808 10.9136 10.3902 11.3042C9.99966 11.6948 9.77996 12.2243 9.7793 12.7766V16.9433C9.77996 17.4956 9.99966 18.0251 10.3902 18.4157C10.7808 18.8062 11.3103 19.0259 11.8626 19.0266H17.696C18.2483 19.0259 18.7778 18.8062 19.1684 18.4157C19.5589 18.0251 19.7786 17.4956 19.7793 16.9433V12.7766C19.7786 12.2243 19.5589 11.6948 19.1684 11.3042C18.7778 10.9136 18.2483 10.6939 17.696 10.6933ZM13.6443 10.0224C13.6833 9.97205 13.7333 9.93122 13.7905 9.90306C13.8477 9.8749 13.9106 9.86016 13.9743 9.85994H15.5843C15.648 9.86022 15.7109 9.87499 15.768 9.90315C15.8252 9.9313 15.8752 9.9721 15.9143 10.0224L16.4318 10.6933H13.1268L13.6443 10.0224ZM18.946 16.9433C18.946 17.2748 18.8143 17.5927 18.5798 17.8272C18.3454 18.0616 18.0275 18.1933 17.696 18.1933H11.8626C11.5311 18.1933 11.2132 18.0616 10.9787 17.8272C10.7443 17.5927 10.6126 17.2748 10.6126 16.9433V12.7766C10.6126 12.4451 10.7443 12.1271 10.9787 11.8927C11.2132 11.6583 11.5311 11.5266 11.8626 11.5266H17.696C18.0275 11.5266 18.3454 11.6583 18.5798 11.8927C18.8143 12.1271 18.946 12.4451 18.946 12.7766V16.9433Z" fill="white" />
                          <path d="M14.7793 12.36C14.2848 12.36 13.8015 12.5066 13.3904 12.7813C12.9792 13.056 12.6588 13.4465 12.4696 13.9033C12.2804 14.3601 12.2309 14.8628 12.3273 15.3477C12.4238 15.8327 12.6619 16.2781 13.0115 16.6278C13.3612 16.9774 13.8066 17.2155 14.2916 17.3119C14.7765 17.4084 15.2792 17.3589 15.736 17.1697C16.1928 16.9805 16.5833 16.66 16.858 16.2489C17.1327 15.8378 17.2793 15.3544 17.2793 14.86C17.2786 14.1971 17.015 13.5616 16.5463 13.0929C16.0776 12.6243 15.4421 12.3606 14.7793 12.36ZM14.7793 16.5267C14.4497 16.5267 14.1274 16.4289 13.8533 16.2458C13.5793 16.0626 13.3656 15.8023 13.2395 15.4978C13.1134 15.1932 13.0803 14.8581 13.1447 14.5348C13.209 14.2115 13.3677 13.9146 13.6008 13.6815C13.8339 13.4484 14.1308 13.2897 14.4541 13.2253C14.7774 13.161 15.1126 13.194 15.4171 13.3202C15.7216 13.4463 15.9819 13.66 16.1651 13.934C16.3482 14.2081 16.446 14.5303 16.446 14.86C16.446 15.302 16.2704 15.7259 15.9578 16.0385C15.6452 16.3511 15.2213 16.5267 14.7793 16.5267Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_3892_3575">
                            <rect width="10" height="10" fill="white" transform="translate(9.7793 9.02661)" />
                          </clipPath>
                        </defs>
                      </svg>

                    </div>
                    <div className="menu-item flex items-center  justify-end w-full p-2 gap-1" onClick={handletogglePostVideoContent}>
                      <p className="flex-1 text-right text-sm">
                        Video
                      </p>
                      <svg className="cursor-pointer" width="38" height="38" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="14.9824" cy="14.3479" r="14" fill="#008080" />
                        <g clipPath="url(#clip0_4115_103)">
                          <path d="M19.5253 12.072C19.2437 11.9311 18.9116 11.9607 18.6599 12.1503C18.6437 12.1624 18.6283 12.1761 18.6141 12.1903L17.882 12.9291C17.7941 11.8603 16.9066 11.0149 15.8158 11.0149H12.0658C10.917 11.0145 9.98242 11.9491 9.98242 13.0978V15.5978C9.98242 16.7466 10.917 17.6811 12.0658 17.6811H15.8158C16.9112 17.6811 17.8024 16.8282 17.8833 15.7532L18.6149 16.4841C18.6291 16.4978 18.6437 16.5103 18.6595 16.5224C18.8062 16.6324 18.9795 16.6882 19.1545 16.6882C19.2808 16.6882 19.407 16.6595 19.5253 16.6003C19.8074 16.4595 19.9824 16.1757 19.9824 15.8611V12.812C19.9824 12.4966 19.8074 12.2128 19.5253 12.072ZM15.8158 16.8478H12.0658C11.3766 16.8478 10.8158 16.287 10.8158 15.5978V13.0978C10.8158 12.4086 11.3766 11.8478 12.0658 11.8478H15.8158C16.5049 11.8478 17.0658 12.4086 17.0658 13.0978V15.5978C17.0658 16.287 16.5049 16.8478 15.8158 16.8478ZM17.8991 14.5907V14.0953L19.1491 12.8336L19.1595 15.8495L17.8991 14.5903V14.5907Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_4115_103">
                            <rect width="10" height="10" fill="white" transform="translate(9.98242 9.3479)" />
                          </clipPath>
                        </defs>
                      </svg>

                    </div>
                  </div>
                ) : ""
              }

              {
                options ? (
                  <div className="hidden  toggle  sm:flex items-center justify-end cursor-pointer" onClick={showOptions}>
                    <svg width="62" height="63" viewBox="0 0 62 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="30.7794" cy="31.5809" r="30.6798"   fill={`${user.theme==="dark"? "black" :"white"}`}  />
                      <g clipPath="url(#clip0_3892_3522)">
                        <path d="M36.3868 24.1052L30.7799 29.7121L25.173 24.1052C24.6569 23.5891 
                        23.8201 23.5891 23.304 24.1052C22.7879 24.6213 22.7879 25.4581 23.304 25.9742L28.9109 
                        31.5811L23.304 37.188C22.7879 37.7041 22.7879 38.5408 23.304 39.0569C23.8201 39.573
                         24.6569 39.573 25.173 39.0569L30.7799 33.45L36.3868 39.0569C36.9029 39.573 37.7396 
                         39.573 38.2557 39.0569C38.7718 38.5408 38.7718 37.7041 38.2557 37.188L32.6488 31.5811L38.2557 
                         25.9742C38.7718 25.4581 38.7718 24.6213 38.2557 24.1052C37.7396 23.5891 36.9029 23.5891 36.3868 24.1052Z"
                          fill={`${user.theme==="dark"? "white" :"black"}`}  />
                      </g>
                      <defs>
                        <clipPath id="clip0_3892_3522">
                          <rect width="21.145" height="21.145" fill="white" transform="translate(15.8281 31.5811) rotate(-45)" />
                        </clipPath>
                      </defs>
                    </svg>

                  </div>
                ) : (<div className="hidden toggle sm:flex items-center justify-end cursor-pointer" onClick={showOptions}>
                  <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="31.4825" cy="31.5809" r="30.6798" fill="#008080" />
                    <g clipPath="url(#clip0_3892_2847)">
                      <path d="M40.7336 30.2594H32.8042V22.33C32.8042 21.6001 32.2125 21.0085 31.4826 21.0085C30.7528 21.0085 30.1611 21.6001 30.1611 22.33V30.2594H22.2317C21.5018
                       30.2594 20.9102 30.8511 20.9102 31.5809C20.9102 32.3108 21.5018 32.9025 22.2317
                        32.9025H30.1611V40.8319C30.1611 41.5617 30.7528 42.1534 31.4826 42.1534C32.2125
                         42.1534 32.8042 41.5617 32.8042 40.8319V32.9025H40.7336C41.4634 32.9025 42.0551
                          32.3108 42.0551 31.5809C42.0551 30.8511 41.4634 30.2594 40.7336 30.2594Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_3892_2847">
                        <rect width="21.145" height="21.145" fill="white" transform="translate(20.9102 21.0085)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>)
              }






            </div>








          </div>


        </div>
      )}
      <div className="sm:hidden fixed bottom-[10%] right-[1.6rem] z-20 cursor-pointer scale-150">
        <div className="relative">
          <TuneRoundedIcon
            className="bg-[#008080] text-white rounded-full p-1 tune"
            sx={{ fontWeight: "bold", width: "20px" }}
            onClick={(e) => {
              filterHamburgerMenu(e);
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-center">
        {feedbackModalOpen && (
          <FeedbackModal setFeedbackModalOpen={setFeedbackModalOpen} />
        )}
      </div>
      {/* {redirectModal && <RedirectToSkills />} */}
    </div>
  );
};
export default React.memo(HomePage);
