import { useSelector } from "react-redux";
import MobileFooter from "../footer/MobileFooter";
import Header from "../Header/Header";
import PropTypes from 'prop-types';
import React from 'react';
const Levels = () => {
    const { user } = useSelector((state) => state.AuthReducer);

    return (
        <div className={`p-5 flex flex-col items-center ${user.theme === "dark" ? "bg-black" : "bg-[#F6F5FA]"} min-h-screen`}>
            <Header />
            <div className="flex flex-col">
                <h1 className={`mt-40px text-15px ${user.theme === "dark" ? "text-white" : "text-black"} font-gilroy-bold h-10px w-43px`}>Levels</h1>
                <div className="flex flex-col mt-32px gap-14px">
                    <LevelCard level={1} title={"Map Reading"} />
                    <LevelCard level={2} title={"Path Finding"} />
                    <LevelCard level={3} title={"Destination Reaching"} />
                </div>
            </div>
            <MobileFooter />
        </div>
    );
}




export const LevelCard = ({ level, title, Fordesktop }) => {
    const { user } = useSelector((state) => state.AuthReducer);
    return (
        <div className={` bg-card  max-w-[600px]  p-3 flex flex-col items-center ${Fordesktop ? "" : "gap-1"}  rounded-[9px]`}>
            <div className={`flex items-center gap-3 ${Fordesktop}`}>

                <div className={` ${Fordesktop ? "h-[50px] w-[50px]" : "h-[62px] w-[62px]"}  `}>
                    {
                        level === 1 ? (
                            <svg width={`${Fordesktop ? "50" : "66"}`} height={`${Fordesktop ? "50" : "66"}`} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="62" height="62" rx="10" fill="#002147" />
                                <path d="M28.4688 42.8125H33.5312V48.4375H28.4688V42.8125Z" fill="#7A829C" />
                                <path d="M28.4688 42.8125H33.5312V43.9375H28.4688V42.8125Z" fill="#646D8B" />
                                <path d="M29.5938 42.8125H33.5312V48.4375H29.5938V42.8125Z" fill="#646D8B" />
                                <path d="M29.5938 42.8125H33.5312V43.9375H29.5938V42.8125Z" fill="#505A76" />
                                <path d="M42.25 13.5625H19.75C17.886 13.5625 16.375 15.0735 16.375 16.9375V39.4375C16.375 41.3015 17.886 42.8125 19.75 42.8125H42.25C44.114 42.8125 45.625 41.3015 45.625 39.4375V16.9375C45.625 15.0735 44.114 13.5625 42.25 13.5625Z" fill="#FAFDFF" />
                                <path d="M19.75 14.6875C19.1533 14.6875 18.581 14.9246 18.159 15.3465C17.7371 15.7685 17.5 16.3408 17.5 16.9375V40.5625C17.5001 41.1818 17.6717 41.789 17.9957 42.3168C18.5235 42.6408 19.1307 42.8124 19.75 42.8125H42.25C43.1451 42.8125 44.0036 42.4569 44.6365 41.824C45.2694 41.1911 45.625 40.3326 45.625 39.4375V16.9375C45.6249 16.3182 45.4533 15.711 45.1293 15.1832C44.6015 14.8592 43.9943 14.6876 43.375 14.6875H19.75Z" fill="#EEF6FF" />
                                <path d="M43.375 39.4375V16.9375C43.375 16.3162 42.8713 15.8125 42.25 15.8125H19.75C19.1287 15.8125 18.625 16.3162 18.625 16.9375V39.4375C18.625 40.0588 19.1287 40.5625 19.75 40.5625H42.25C42.8713 40.5625 43.375 40.0588 43.375 39.4375Z" fill="#001E41" />
                                <path d="M43.375 39.4375V16.9375H20.875C20.5766 16.9375 20.2905 17.056 20.0795 17.267C19.8685 17.478 19.75 17.7641 19.75 18.0625V40.5625H42.25C42.5484 40.5625 42.8345 40.444 43.0455 40.233C43.2565 40.022 43.375 39.7359 43.375 39.4375Z" fill="#003B80" />
                                <path d="M23.9688 37.75H22.2812V33.8125C22.2812 33.6633 22.222 33.5202 22.1165 33.4148C22.011 33.3093 21.8679 33.25 21.7188 33.25C21.5696 33.25 21.4265 33.3093 21.321 33.4148C21.2155 33.5202 21.1562 33.6633 21.1562 33.8125V38.3125C21.1562 38.4617 21.2155 38.6048 21.321 38.7102C21.4265 38.8157 21.5696 38.875 21.7188 38.875H23.9688C24.1179 38.875 24.261 38.8157 24.3665 38.7102C24.472 38.6048 24.5312 38.4617 24.5312 38.3125C24.5312 38.1633 24.472 38.0202 24.3665 37.9148C24.261 37.8093 24.1179 37.75 23.9688 37.75Z" fill="#FAFDFF" />
                                <path d="M40.2812 37.75H38.5938V33.8125C38.5938 33.6633 38.5345 33.5202 38.429 33.4148C38.3235 33.3093 38.1804 33.25 38.0312 33.25C37.8821 33.25 37.739 33.3093 37.6335 33.4148C37.528 33.5202 37.4688 33.6633 37.4688 33.8125V38.3125C37.4688 38.4617 37.528 38.6048 37.6335 38.7102C37.739 38.8157 37.8821 38.875 38.0312 38.875H40.2812C40.4304 38.875 40.5735 38.8157 40.679 38.7102C40.7845 38.6048 40.8438 38.4617 40.8438 38.3125C40.8438 38.1633 40.7845 38.0202 40.679 37.9148C40.5735 37.8093 40.4304 37.75 40.2812 37.75Z" fill="#FAFDFF" />
                                <path d="M27.9062 34.375C28.0554 34.375 28.1985 34.3157 28.304 34.2102C28.4095 34.1048 28.4688 33.9617 28.4688 33.8125C28.4688 33.6633 28.4095 33.5202 28.304 33.4148C28.1985 33.3093 28.0554 33.25 27.9062 33.25H25.6562C25.5071 33.25 25.364 33.3093 25.2585 33.4148C25.153 33.5202 25.0938 33.6633 25.0938 33.8125V38.3125C25.0938 38.4617 25.153 38.6048 25.2585 38.7102C25.364 38.8157 25.5071 38.875 25.6562 38.875H27.9062C28.0554 38.875 28.1985 38.8157 28.304 38.7102C28.4095 38.6048 28.4688 38.4617 28.4688 38.3125C28.4688 38.1633 28.4095 38.0202 28.304 37.9148C28.1985 37.8093 28.0554 37.75 27.9062 37.75H26.2188V36.625H27.3438C27.4929 36.625 27.636 36.5657 27.7415 36.4602C27.847 36.3548 27.9062 36.2117 27.9062 36.0625C27.9062 35.9133 27.847 35.7702 27.7415 35.6648C27.636 35.5593 27.4929 35.5 27.3438 35.5H26.2188V34.375H27.9062Z" fill="#FAFDFF" />
                                <path d="M36.3438 34.375C36.4929 34.375 36.636 34.3157 36.7415 34.2102C36.847 34.1048 36.9062 33.9617 36.9062 33.8125C36.9062 33.6633 36.847 33.5202 36.7415 33.4148C36.636 33.3093 36.4929 33.25 36.3438 33.25H34.0938C33.9446 33.25 33.8015 33.3093 33.696 33.4148C33.5905 33.5202 33.5312 33.6633 33.5312 33.8125V38.3125C33.5312 38.4617 33.5905 38.6048 33.696 38.7102C33.8015 38.8157 33.9446 38.875 34.0938 38.875H36.3438C36.4929 38.875 36.636 38.8157 36.7415 38.7102C36.847 38.6048 36.9062 38.4617 36.9062 38.3125C36.9062 38.1633 36.847 38.0202 36.7415 37.9148C36.636 37.8093 36.4929 37.75 36.3438 37.75H34.6562V36.625H35.7812C35.9304 36.625 36.0735 36.5657 36.179 36.4602C36.2845 36.3548 36.3438 36.2117 36.3438 36.0625C36.3438 35.9133 36.2845 35.7702 36.179 35.6648C36.0735 35.5593 35.9304 35.5 35.7812 35.5H34.6562V34.375H36.3438Z" fill="#FAFDFF" />
                                <path d="M32.574 33.2751C32.4316 33.2308 32.2774 33.2447 32.1453 33.3139C32.0131 33.3831 31.9138 33.502 31.8692 33.6443L30.9999 36.4263L30.1307 33.6443C30.0862 33.5019 29.9869 33.383 29.8547 33.3137C29.7226 33.2445 29.5683 33.2306 29.4259 33.2751C29.2835 33.3196 29.1646 33.4189 29.0954 33.551C29.0261 33.6832 29.0122 33.8375 29.0567 33.9799L30.463 38.4799C30.4987 38.5943 30.5701 38.6944 30.6667 38.7654C30.7633 38.8365 30.88 38.8748 30.9999 38.8748C31.1199 38.8748 31.2366 38.8365 31.3332 38.7654C31.4298 38.6944 31.5012 38.5943 31.5369 38.4799L32.9432 33.9799C32.9876 33.8375 32.9737 33.6832 32.9044 33.5511C32.8352 33.4189 32.7164 33.3197 32.574 33.2751Z" fill="#FAFDFF" />
                                <path d="M31 31.5623C30.7016 31.5623 30.4155 31.4438 30.2045 31.2328C29.9935 31.0218 29.875 30.7357 29.875 30.4373V21.9034L28.9829 22.7952C28.771 23.0014 28.4864 23.1157 28.1908 23.1137C27.8951 23.1116 27.6122 22.9933 27.4031 22.7842C27.1941 22.5752 27.0757 22.2922 27.0736 21.9966C27.0716 21.701 27.1859 21.4164 27.392 21.2044L30.2045 18.3919C30.3619 18.2346 30.5623 18.1275 30.7805 18.0841C30.9987 18.0407 31.2249 18.063 31.4304 18.1481C31.636 18.2332 31.8117 18.3774 31.9353 18.5624C32.0589 18.7474 32.1249 18.9648 32.125 19.1873V30.4373C32.125 30.7357 32.0064 31.0218 31.7955 31.2328C31.5845 31.4438 31.2983 31.5623 31 31.5623Z" fill="#FAFDFF" />
                            </svg>
                        )
                            :
                            level === 2 ? (
                                <svg width={`${Fordesktop ? "50" : "66"}`} height={`${Fordesktop ? "50" : "66"}`} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="62" height="62" rx="10" fill="#003A47" />
                                    <path d="M28.4688 42.8125H33.5312V48.4375H28.4688V42.8125Z" fill="#7A829C" />
                                    <path d="M28.4688 42.8125H33.5312V43.9375H28.4688V42.8125Z" fill="#646D8B" />
                                    <path d="M29.5938 42.8125H33.5312V48.4375H29.5938V42.8125Z" fill="#646D8B" />
                                    <path d="M29.5938 42.8125H33.5312V43.9375H29.5938V42.8125Z" fill="#505A76" />
                                    <path d="M42.25 13.5625H19.75C17.886 13.5625 16.375 15.0735 16.375 16.9375V39.4375C16.375 41.3015 17.886 42.8125 19.75 42.8125H42.25C44.114 42.8125 45.625 41.3015 45.625 39.4375V16.9375C45.625 15.0735 44.114 13.5625 42.25 13.5625Z" fill="#FAFDFF" />
                                    <path d="M19.75 14.6875C19.1533 14.6875 18.581 14.9246 18.159 15.3465C17.7371 15.7685 17.5 16.3408 17.5 16.9375V40.5625C17.5001 41.1818 17.6717 41.789 17.9957 42.3168C18.5235 42.6408 19.1307 42.8124 19.75 42.8125H42.25C43.1451 42.8125 44.0036 42.4569 44.6365 41.824C45.2694 41.1911 45.625 40.3326 45.625 39.4375V16.9375C45.6249 16.3182 45.4533 15.711 45.1293 15.1832C44.6015 14.8592 43.9943 14.6876 43.375 14.6875H19.75Z" fill="#EEF6FF" />
                                    <path d="M43.375 39.4375V16.9375C43.375 16.3162 42.8713 15.8125 42.25 15.8125H19.75C19.1287 15.8125 18.625 16.3162 18.625 16.9375V39.4375C18.625 40.0588 19.1287 40.5625 19.75 40.5625H42.25C42.8713 40.5625 43.375 40.0588 43.375 39.4375Z" fill="#1297B5" />
                                    <path d="M43.375 39.4375V16.9375H20.875C20.5766 16.9375 20.2905 17.056 20.0795 17.267C19.8685 17.478 19.75 17.7641 19.75 18.0625V40.5625H42.25C42.5484 40.5625 42.8345 40.444 43.0455 40.233C43.2565 40.022 43.375 39.7359 43.375 39.4375Z" fill="#0D687C" />
                                    <path d="M34.375 31.5625H27.625C27.3266 31.5625 27.0405 31.444 26.8295 31.233C26.6185 31.022 26.5 30.7359 26.5 30.4375V29.3125C26.5 28.4212 26.7805 27.5525 27.3016 26.8295C27.8228 26.1065 28.5582 25.5657 29.4037 25.2838L31.8844 24.4569C32.282 24.3244 32.6279 24.0702 32.873 23.7302C33.1181 23.3902 33.25 22.9816 33.25 22.5625C33.25 21.9658 33.0129 21.3935 32.591 20.9715C32.169 20.5496 31.5967 20.3125 31 20.3125C30.4033 20.3125 29.831 20.5496 29.409 20.9715C28.9871 21.3935 28.75 21.9658 28.75 22.5625C28.75 22.8609 28.6315 23.147 28.4205 23.358C28.2095 23.569 27.9234 23.6875 27.625 23.6875C27.3266 23.6875 27.0405 23.569 26.8295 23.358C26.6185 23.147 26.5 22.8609 26.5 22.5625C26.5 21.369 26.9741 20.2244 27.818 19.3805C28.6619 18.5366 29.8065 18.0625 31 18.0625C32.1935 18.0625 33.3381 18.5366 34.182 19.3805C35.0259 20.2244 35.5 21.369 35.5 22.5625C35.5 23.4538 35.2195 24.3225 34.6984 25.0455C34.1772 25.7685 33.4418 26.3093 32.5963 26.5912L30.1154 27.4181C29.7178 27.5506 29.3719 27.8049 29.1269 28.1449C28.8818 28.4849 28.75 28.8934 28.75 29.3125H34.375C34.6734 29.3125 34.9595 29.431 35.1705 29.642C35.3815 29.853 35.5 30.1391 35.5 30.4375C35.5 30.7359 35.3815 31.022 35.1705 31.233C34.9595 31.444 34.6734 31.5625 34.375 31.5625Z" fill="#FAFDFF" />
                                    <path d="M23.9688 37.75H22.2812V33.8125C22.2812 33.6633 22.222 33.5202 22.1165 33.4148C22.011 33.3093 21.8679 33.25 21.7188 33.25C21.5696 33.25 21.4265 33.3093 21.321 33.4148C21.2155 33.5202 21.1562 33.6633 21.1562 33.8125V38.3125C21.1562 38.4617 21.2155 38.6048 21.321 38.7102C21.4265 38.8157 21.5696 38.875 21.7188 38.875H23.9688C24.1179 38.875 24.261 38.8157 24.3665 38.7102C24.472 38.6048 24.5312 38.4617 24.5312 38.3125C24.5312 38.1633 24.472 38.0202 24.3665 37.9148C24.261 37.8093 24.1179 37.75 23.9688 37.75Z" fill="#FAFDFF" />
                                    <path d="M40.2812 37.75H38.5938V33.8125C38.5938 33.6633 38.5345 33.5202 38.429 33.4148C38.3235 33.3093 38.1804 33.25 38.0312 33.25C37.8821 33.25 37.739 33.3093 37.6335 33.4148C37.528 33.5202 37.4688 33.6633 37.4688 33.8125V38.3125C37.4688 38.4617 37.528 38.6048 37.6335 38.7102C37.739 38.8157 37.8821 38.875 38.0312 38.875H40.2812C40.4304 38.875 40.5735 38.8157 40.679 38.7102C40.7845 38.6048 40.8438 38.4617 40.8438 38.3125C40.8438 38.1633 40.7845 38.0202 40.679 37.9148C40.5735 37.8093 40.4304 37.75 40.2812 37.75Z" fill="#FAFDFF" />
                                    <path d="M27.9062 34.375C28.0554 34.375 28.1985 34.3157 28.304 34.2102C28.4095 34.1048 28.4688 33.9617 28.4688 33.8125C28.4688 33.6633 28.4095 33.5202 28.304 33.4148C28.1985 33.3093 28.0554 33.25 27.9062 33.25H25.6562C25.5071 33.25 25.364 33.3093 25.2585 33.4148C25.153 33.5202 25.0938 33.6633 25.0938 33.8125V38.3125C25.0938 38.4617 25.153 38.6048 25.2585 38.7102C25.364 38.8157 25.5071 38.875 25.6562 38.875H27.9062C28.0554 38.875 28.1985 38.8157 28.304 38.7102C28.4095 38.6048 28.4688 38.4617 28.4688 38.3125C28.4688 38.1633 28.4095 38.0202 28.304 37.9148C28.1985 37.8093 28.0554 37.75 27.9062 37.75H26.2188V36.625H27.3438C27.4929 36.625 27.636 36.5657 27.7415 36.4602C27.847 36.3548 27.9062 36.2117 27.9062 36.0625C27.9062 35.9133 27.847 35.7702 27.7415 35.6648C27.636 35.5593 27.4929 35.5 27.3438 35.5H26.2188V34.375H27.9062Z" fill="#FAFDFF" />
                                    <path d="M36.3438 34.375C36.4929 34.375 36.636 34.3157 36.7415 34.2102C36.847 34.1048 36.9062 33.9617 36.9062 33.8125C36.9062 33.6633 36.847 33.5202 36.7415 33.4148C36.636 33.3093 36.4929 33.25 36.3438 33.25H34.0938C33.9446 33.25 33.8015 33.3093 33.696 33.4148C33.5905 33.5202 33.5312 33.6633 33.5312 33.8125V38.3125C33.5312 38.4617 33.5905 38.6048 33.696 38.7102C33.8015 38.8157 33.9446 38.875 34.0938 38.875H36.3438C36.4929 38.875 36.636 38.8157 36.7415 38.7102C36.847 38.6048 36.9062 38.4617 36.9062 38.3125C36.9062 38.1633 36.847 38.0202 36.7415 37.9148C36.636 37.8093 36.4929 37.75 36.3438 37.75H34.6562V36.625H35.7812C35.9304 36.625 36.0735 36.5657 36.179 36.4602C36.2845 36.3548 36.3438 36.2117 36.3438 36.0625C36.3438 35.9133 36.2845 35.7702 36.179 35.6648C36.0735 35.5593 35.9304 35.5 35.7812 35.5H34.6562V34.375H36.3438Z" fill="#FAFDFF" />
                                    <path d="M32.574 33.2756C32.4316 33.2312 32.2774 33.2452 32.1453 33.3144C32.0131 33.3836 31.9138 33.5024 31.8692 33.6448L30.9999 36.4268L30.1307 33.6448C30.0862 33.5024 29.9869 33.3835 29.8547 33.3142C29.7226 33.245 29.5683 33.2311 29.4259 33.2756C29.2835 33.3201 29.1646 33.4194 29.0954 33.5515C29.0261 33.6837 29.0122 33.838 29.0567 33.9804L30.463 38.4804C30.4987 38.5948 30.5701 38.6949 30.6667 38.7659C30.7633 38.837 30.88 38.8753 30.9999 38.8753C31.1199 38.8753 31.2366 38.837 31.3332 38.7659C31.4298 38.6949 31.5012 38.5948 31.5369 38.4804L32.9432 33.9804C32.9876 33.838 32.9737 33.6837 32.9044 33.5516C32.8352 33.4194 32.7164 33.3202 32.574 33.2756Z" fill="#FAFDFF" />
                                </svg>

                            )
                                :
                                (
                                    <svg width={`${Fordesktop ? "50" : "66"}`} height={`${Fordesktop ? "50" : "66"}`} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="62" height="62" rx="10" fill="#471100" />
                                        <path d="M28.4688 42.8125H33.5312V48.4375H28.4688V42.8125Z" fill="#7A829C" />
                                        <path d="M28.4688 42.8125H33.5312V43.9375H28.4688V42.8125Z" fill="#646D8B" />
                                        <path d="M29.5938 42.8125H33.5312V48.4375H29.5938V42.8125Z" fill="#646D8B" />
                                        <path d="M29.5938 42.8125H33.5312V43.9375H29.5938V42.8125Z" fill="#505A76" />
                                        <path d="M42.25 13.5625H19.75C17.886 13.5625 16.375 15.0735 16.375 16.9375V39.4375C16.375 41.3015 17.886 42.8125 19.75 42.8125H42.25C44.114 42.8125 45.625 41.3015 45.625 39.4375V16.9375C45.625 15.0735 44.114 13.5625 42.25 13.5625Z" fill="#FAFDFF" />
                                        <path d="M19.75 14.6875C19.1533 14.6875 18.581 14.9246 18.159 15.3465C17.7371 15.7685 17.5 16.3408 17.5 16.9375V40.5625C17.5001 41.1818 17.6717 41.789 17.9957 42.3168C18.5235 42.6408 19.1307 42.8124 19.75 42.8125H42.25C43.1451 42.8125 44.0036 42.4569 44.6365 41.824C45.2694 41.1911 45.625 40.3326 45.625 39.4375V16.9375C45.6249 16.3182 45.4533 15.711 45.1293 15.1832C44.6015 14.8592 43.9943 14.6876 43.375 14.6875H19.75Z" fill="#EEF6FF" />
                                        <path d="M43.375 39.4375V16.9375C43.375 16.3162 42.8713 15.8125 42.25 15.8125H19.75C19.1287 15.8125 18.625 16.3162 18.625 16.9375V39.4375C18.625 40.0588 19.1287 40.5625 19.75 40.5625H42.25C42.8713 40.5625 43.375 40.0588 43.375 39.4375Z" fill="#9F3311" />
                                        <path d="M43.375 39.4375V16.9375H20.875C20.5766 16.9375 20.2905 17.056 20.0795 17.267C19.8685 17.478 19.75 17.7641 19.75 18.0625V40.5625H42.25C42.5484 40.5625 42.8345 40.444 43.0455 40.233C43.2565 40.022 43.375 39.7359 43.375 39.4375Z" fill="#76240A" />
                                        <path d="M33.6236 23.4084C34.2164 22.877 34.69 22.2262 35.0134 21.4988C35.3368 20.7713 35.5026 19.9836 35.5 19.1875C35.5 18.8891 35.3815 18.603 35.1705 18.392C34.9595 18.181 34.6734 18.0625 34.375 18.0625H28.1875C27.8891 18.0625 27.603 18.181 27.392 18.392C27.181 18.603 27.0625 18.8891 27.0625 19.1875C27.0625 19.4859 27.181 19.772 27.392 19.983C27.603 20.194 27.8891 20.3125 28.1875 20.3125H33.0627C32.7705 21.1577 32.1577 21.854 31.3565 22.2513L30.4968 22.6811C30.2701 22.7944 30.0882 22.981 29.9808 23.2106C29.8733 23.4401 29.8465 23.6993 29.9048 23.946C29.963 24.1927 30.1029 24.4125 30.3017 24.5698C30.5004 24.727 30.7465 24.8126 31 24.8125C31.445 24.8125 31.88 24.9445 32.25 25.1917C32.62 25.4389 32.9084 25.7903 33.0787 26.2015C33.249 26.6126 33.2936 27.065 33.2068 27.5015C33.12 27.9379 32.9057 28.3388 32.591 28.6535C32.2763 28.9682 31.8754 29.1825 31.439 29.2693C31.0025 29.3561 30.5501 29.3115 30.139 29.1412C29.7278 28.9709 29.3764 28.6825 29.1292 28.3125C28.882 27.9425 28.75 27.5075 28.75 27.0625C28.75 26.7641 28.6315 26.478 28.4205 26.267C28.2095 26.056 27.9234 25.9375 27.625 25.9375C27.3266 25.9375 27.0405 26.056 26.8295 26.267C26.6185 26.478 26.5 26.7641 26.5 27.0625C26.4997 27.8337 26.6975 28.592 27.0745 29.2647C27.4516 29.9374 27.9951 30.5019 28.6531 30.9041C29.3111 31.3063 30.0614 31.5326 30.832 31.5614C31.6026 31.5902 32.3677 31.4205 33.0538 31.0685C33.74 30.7165 34.3242 30.1941 34.7504 29.5514C35.1765 28.9088 35.4304 28.1673 35.4876 27.3983C35.5448 26.6293 35.4034 25.8584 35.077 25.1598C34.7506 24.4611 34.2501 23.858 33.6236 23.4084Z" fill="#FAFDFF" />
                                        <path d="M23.9688 37.75H22.2812V33.8125C22.2812 33.6633 22.222 33.5202 22.1165 33.4148C22.011 33.3093 21.8679 33.25 21.7188 33.25C21.5696 33.25 21.4265 33.3093 21.321 33.4148C21.2155 33.5202 21.1562 33.6633 21.1562 33.8125V38.3125C21.1562 38.4617 21.2155 38.6048 21.321 38.7102C21.4265 38.8157 21.5696 38.875 21.7188 38.875H23.9688C24.1179 38.875 24.261 38.8157 24.3665 38.7102C24.472 38.6048 24.5312 38.4617 24.5312 38.3125C24.5312 38.1633 24.472 38.0202 24.3665 37.9148C24.261 37.8093 24.1179 37.75 23.9688 37.75Z" fill="#FAFDFF" />
                                        <path d="M40.2812 37.75H38.5938V33.8125C38.5938 33.6633 38.5345 33.5202 38.429 33.4148C38.3235 33.3093 38.1804 33.25 38.0312 33.25C37.8821 33.25 37.739 33.3093 37.6335 33.4148C37.528 33.5202 37.4688 33.6633 37.4688 33.8125V38.3125C37.4688 38.4617 37.528 38.6048 37.6335 38.7102C37.739 38.8157 37.8821 38.875 38.0312 38.875H40.2812C40.4304 38.875 40.5735 38.8157 40.679 38.7102C40.7845 38.6048 40.8438 38.4617 40.8438 38.3125C40.8438 38.1633 40.7845 38.0202 40.679 37.9148C40.5735 37.8093 40.4304 37.75 40.2812 37.75Z" fill="#FAFDFF" />
                                        <path d="M27.9062 34.375C28.0554 34.375 28.1985 34.3157 28.304 34.2102C28.4095 34.1048 28.4688 33.9617 28.4688 33.8125C28.4688 33.6633 28.4095 33.5202 28.304 33.4148C28.1985 33.3093 28.0554 33.25 27.9062 33.25H25.6562C25.5071 33.25 25.364 33.3093 25.2585 33.4148C25.153 33.5202 25.0938 33.6633 25.0938 33.8125V38.3125C25.0938 38.4617 25.153 38.6048 25.2585 38.7102C25.364 38.8157 25.5071 38.875 25.6562 38.875H27.9062C28.0554 38.875 28.1985 38.8157 28.304 38.7102C28.4095 38.6048 28.4688 38.4617 28.4688 38.3125C28.4688 38.1633 28.4095 38.0202 28.304 37.9148C28.1985 37.8093 28.0554 37.75 27.9062 37.75H26.2188V36.625H27.3438C27.4929 36.625 27.636 36.5657 27.7415 36.4602C27.847 36.3548 27.9062 36.2117 27.9062 36.0625C27.9062 35.9133 27.847 35.7702 27.7415 35.6648C27.636 35.5593 27.4929 35.5 27.3438 35.5H26.2188V34.375H27.9062Z" fill="#FAFDFF" />
                                        <path d="M36.3438 34.375C36.4929 34.375 36.636 34.3157 36.7415 34.2102C36.847 34.1048 36.9062 33.9617 36.9062 33.8125C36.9062 33.6633 36.847 33.5202 36.7415 33.4148C36.636 33.3093 36.4929 33.25 36.3438 33.25H34.0938C33.9446 33.25 33.8015 33.3093 33.696 33.4148C33.5905 33.5202 33.5312 33.6633 33.5312 33.8125V38.3125C33.5312 38.4617 33.5905 38.6048 33.696 38.7102C33.8015 38.8157 33.9446 38.875 34.0938 38.875H36.3438C36.4929 38.875 36.636 38.8157 36.7415 38.7102C36.847 38.6048 36.9062 38.4617 36.9062 38.3125C36.9062 38.1633 36.847 38.0202 36.7415 37.9148C36.636 37.8093 36.4929 37.75 36.3438 37.75H34.6562V36.625H35.7812C35.9304 36.625 36.0735 36.5657 36.179 36.4602C36.2845 36.3548 36.3438 36.2117 36.3438 36.0625C36.3438 35.9133 36.2845 35.7702 36.179 35.6648C36.0735 35.5593 35.9304 35.5 35.7812 35.5H34.6562V34.375H36.3438Z" fill="#FAFDFF" />
                                        <path d="M32.574 33.2756C32.4316 33.2312 32.2774 33.2452 32.1453 33.3144C32.0131 33.3836 31.9138 33.5024 31.8692 33.6448L30.9999 36.4268L30.1307 33.6448C30.0862 33.5024 29.9869 33.3835 29.8547 33.3142C29.7226 33.245 29.5683 33.2311 29.4259 33.2756C29.2835 33.3201 29.1646 33.4194 29.0954 33.5515C29.0261 33.6837 29.0122 33.838 29.0567 33.9804L30.463 38.4804C30.4987 38.5948 30.5701 38.6949 30.6667 38.7659C30.7633 38.837 30.88 38.8753 30.9999 38.8753C31.1199 38.8753 31.2366 38.837 31.3332 38.7659C31.4298 38.6949 31.5012 38.5948 31.5369 38.4804L32.9432 33.9804C32.9876 33.838 32.9737 33.6837 32.9044 33.5516C32.8352 33.4194 32.7164 33.3202 32.574 33.2756Z" fill="#FAFDFF" />
                                    </svg>

                                )
                    }
                </div>

                <div className="flex flex-col ">
                    <h2 className={`${Fordesktop ? " text-[12px]" : "text-[14px]"} font-gilroy-bold ${user.theme === "dark" ? "text-white" : "text-black"}`}>{title}</h2>
                    <div className={`h-[26px] ${Fordesktop ? "mt-[2px]" : "mt-[5px]"}`}>
                        <p className="font-gilroy-regularl text-compPrimary text-[10px]"> Industry standard dummy text ever since the 1500s, when an unknown printer took...</p>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-start">
                <div className="flex items-center gap-2 mt-2 ">
                    <p className="text-[#008080] font-gilroy-bold text-[12px]">Know more</p>
                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.99996 4.00021C9.99672 3.64947 9.8554 3.31414 9.60663 3.06688L6.74663 0.20021C6.62172 0.0760423 6.45275 0.00634766 6.27663 0.00634766C6.1005 0.00634766 5.93153 0.0760423 5.80663 0.20021C5.74414 0.262185 5.69454 0.335919 5.6607 0.417158C5.62685 0.498398 5.60943 0.585535 5.60943 0.673543C5.60943 0.761551 5.62685 0.848688 5.6607 0.929927C5.69454 1.01117 5.74414 1.0849 5.80663 1.14688L7.99996 3.33354H1.33329C1.15648 3.33354 0.986913 3.40378 0.861888 3.52881C0.736864 3.65383 0.666626 3.8234 0.666626 4.00021C0.666626 4.17702 0.736864 4.34659 0.861888 4.47161C0.986913 4.59664 1.15648 4.66688 1.33329 4.66688H7.99996L5.80663 6.86021C5.68109 6.98486 5.61021 7.15428 5.60959 7.33119C5.60896 7.5081 5.67864 7.67801 5.80329 7.80354C5.92794 7.92908 6.09736 7.99996 6.27427 8.00058C6.45118 8.00121 6.62109 7.93153 6.74663 7.80688L9.60663 4.94021C9.85702 4.69131 9.99847 4.35326 9.99996 4.00021Z" fill="#008080" />
                    </svg>

                </div>
            </div>
        </div>
    )
}

LevelCard.propTypes = {
    level: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    Fordesktop: PropTypes.bool,
};

export default Levels;