import {
  SET_SEARCH,
  SET_SEARCH_LOADER,
  CLOSE_SEARCH_LOADER,
} from "../types/SearchTypes";

const initState = {
  searchLoading: false,
  userData: [],
};

const SearchReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_SEARCH_LOADER:
      return { ...state, searchLoading: true };
    case CLOSE_SEARCH_LOADER:
      return { ...state, searchLoading: false };
    case SET_SEARCH:
      state.userData.pop();
      state.userData.push(action.payload);
      return state;

    // not woking
    case "LOGOUT_SR":
      console.log("sear R");
      return initState;
    default:
      return state;
  }
};

export default SearchReducer;
