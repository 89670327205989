import { useState, useEffect } from 'react';
import axios from 'axios';

import { path } from './path';

// Utility function to compare current date time with given date time
function compareDateTimes(dateTimeString) {
  let [datePart, timePart] = dateTimeString.split(' at ');
  let [year, month, day] = datePart.split('-').map(Number);
  let [hours, minutes] = timePart.split(':').map(Number);

  // Create a Date object for the given date and time
  let givenDateTime = new Date(year, month - 1, day, hours, minutes);

  // Get the current date and time
  let currentDateTime = new Date();

  // Compare the two dates
  return currentDateTime >= givenDateTime;
}

function useIsCurrentDateTimeGreater(action, contestId) {
  const [isGreater, setIsGreater] = useState();
  const [dateTimeString, setDateTimeString] = useState("");
console.log("Hi")
  useEffect(() => {
    // Fetch data if contestId is provided
    if (contestId) {
      axios.get(`${path}/api/response/getContestId/${contestId}`)
        .then(res => {
          let response = res.data.data.responses.sections[3]?.fields;

          if (response) {
            if (action.toLowerCase() === "register before") {
              setDateTimeString(response[0]?.response);
            } else if (action.toLowerCase() === "result") {
              setDateTimeString(response[1]?.response);
            } else if (action.toLowerCase() === "submission start") {
              setDateTimeString(response[2]?.response);
            } else if (action.toLowerCase() === "submission end") {
              setDateTimeString(response[3]?.response);
             
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [contestId, action]);

  useEffect(() => {
    if (dateTimeString) {
      console.log(dateTimeString)
      setIsGreater(compareDateTimes(dateTimeString));
    }
  }, [dateTimeString]);

  return isGreater;
}

export default useIsCurrentDateTimeGreater;
