import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { path } from "../../../path";

function Users() {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [allUsers, setAllUsers] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [Userfallback, setuserfallback] = useState([]);
  const getAllUsers = async () => {
    try {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(`${path}/admin/allUsers`, config);
      console.log(res);
      setAllUsers(
        res.data.filter(
          (us) => us.isAdmin === false && us.username !== "Deleted User"
        )
      );
      setuserfallback(
        res.data.filter(
          (us) => us.isAdmin === false && us.username !== "Deleted User"
        )
      );
    } catch (error) {
      console.log(error);
      alert("Some error in getting users.");
    }
  };
  useEffect(() => {
    dispatch({ type: "UNSET_HEADER" });
    getAllUsers();
  }, []);

  const removeParticularAccount = async (userId, userEmail) => {
    try {
      const password = window.prompt("Enter password ");
      console.log(password);
      if (password) {
        const config = {
          headers: {
            "Access-Control-Allow-Origin": `${path}`,
            Authorization: `Bearer ${token}`,
          },
        };
        let res = await axios.post(
          `${path}/matchpassword`,
          {
            userId: user._id,
            password: password,
          },
          config
        );
        if (Object.keys(res.data).length === 2) {
          alert("Password matched successfully.");
          const res = await axios.post(
            `${path}/admin/deleteaccount`,
            {
              userId,
              userEmail,
            },
            config
          );
          setAllUsers(allUsers.filter((us) => us._id !== userId));
          res.data.success === true
            ? alert("Account deleted successully.")
            : alert("Error in deleting account.");
        } else {
          alert(res.data.msg);
        }
      } else {
        alert("Please enter a password first.");
        return;
      }
      // eslint-disable-next-line no-warning-comments
    } catch (error) {
      console.log(error);
      alert("Error in deleting account.");
    }
  };

  const searchUser = (e) => {
    if (e.trim() === "") {
      setAllUsers(Userfallback);
    } else {
      const filterdUsers = allUsers.filter((user) => {
        return user.username.includes(e);
      });
      setAllUsers(filterdUsers);
    }
  };

  useEffect(() => {
    searchUser(searchtext);
  }, [searchtext]);

  return (
    <div className="text-primary bg-page min-h-[100vh] px-2  py-[3rem] overflow-x-none overflow-y-auto">
      <div className="grid grid-cols-3 items-center">
        <div className="border-2 shadow-xl m-2 rounded-full flex flex-col justify-center items-center py-4">
          <h1 className="font-bold">Total Users</h1>
          <p>{allUsers.length}</p>
        </div>
        <div>
          <input
            type="text"
            value={searchtext}
            onChange={(e) => setsearchtext(e.target.value)}
            placeholder="Search By Username"
            className="text-grayish search-tab-event p-4 rounded-3xl border m-1 border-viewMore w-[31vw] h-8 mb-2 bg-primary outline-none
              focus:ring-2 ring-[#008080] Searchbox"
          />
        </div>
      </div>
      <table className="border-collapse border-2 m-4">
        <thead className="border-b-2">
          <tr>
            <th className="border-r-2 px-2">S.No</th>
            <th className="border-r-2 px-2">User Id</th>
            <th className="border-r-2 px-2">Name</th>
            <th className="border-r-2 px-2">Polished Count</th>
            <th className="border-r-2 px-2">Novice Count</th>
            <th className="border-r-2 px-2">Username</th>
            <th className="border-r-2 px-2">Email Id</th>
            <th className="border-r-2 px-2">Gender</th>
            <th className="border-r-2 px-2">DOB</th>
            <th className="border-r-2 px-2">Country</th>
            <th className="border-r-2 px-2">Joined on</th>
            <th>Remove Account</th>
          </tr>
        </thead>
        <tbody>
          {allUsers.map((u, index) => (
            <>
              <tr className="">
                <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">
                  {index + 1}
                </td>
                <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">
                  {u._id}
                </td>
                <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">
                  {u.firstName} {u.lastName}
                </td>

                <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">
                  {u.professionalSkills.length}
                </td>
                <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">
                  {u.unprofessionalSkills.length}
                </td>
                <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">
                  {u.username}
                </td>
                <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">
                  {u.email}
                </td>

                <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">
                  {u.gender}
                </td>
                <td className="border-r-2 border-b-2 px-2 text-[0.4rem] text-center">
                  {u?.birthdate?.slice(0, 10)}
                </td>
                <td className="border-r-2 border-b-2 px-2 text-[0.8rem] text-center">
                  {u.country ? u.country : "NULL"}
                </td>
                <td className="border-r-2 border-b-2 px-2 text-[0.5rem] text-center">
                  {u?.createdAt?.slice(0, 10)}
                </td>
                <td className="text-[0.8rem] border-b-2 text-center">
                  <button
                    className="text-red-500"
                    onClick={() => removeParticularAccount(u._id, u.email)}>
                    Remove
                  </button>
                </td>
              </tr>
              <div />
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Users;
