/* eslint-disable no-warning-comments */
/* eslint-disable react/no-array-index-key */

import Button from "@mui/material/Button";
import { Avatar } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { path } from "../../path.js";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom"

import Widget from "./Widget.jsx"
import {Helmet} from "react-helmet"

export default function CompAll() {
    const history = useHistory();
    const { user 
      // token
    } = useSelector((state) => state.AuthReducer); 
//   const [contestData, setContestData] = useState({});
  const [allcontest, setAllcontest] = useState([]);
  const [clickedcontest,setClickedContest] = useState([])
  const [toggle2,setToggle2] = useState("teal-700")
  const [toggle,setToggle] = useState("compPrimary")
  const [toggles,setToggles] = useState(true)
  // const [reg,setReg] = useState("")
  const filterData = async(clicked)=>{
    if(clicked==="Registered"){
    
      setClickedContest(allcontest && user ? allcontest.filter((contest)=>contest.participatedUser.some((p)=>p===user._id)):[])
      setToggle("primary");
      setToggle2("teal-700")
      setToggles(true)
    }else{
      setClickedContest(allcontest && user ? allcontest.filter((contest)=>!contest.participatedUser.some((p)=>p===user._id)):[])
      setToggle2("primary");
      setToggle("teal-700")
      setToggles(false)
    }
    
  }
  

  const fetchcontestdetails = async (cid) => {
    try {
        history.push(`/competition/${cid}`);
      
      
      
    } catch (err) {
      console.log(err);
    }
  }
  
 
//   useEffect(()=>{
// console.log(contestData)
//   },[contestData])
  useEffect(() => {
    const getContests = async () => {
      try {
        const res = await axios.get(`${path}/api/response/getAllresponse`);
        console.log(res);
        console.log(res.data.data);
        setAllcontest(res?.data.data);
        // setContestData(res?.data.data[0])
       

      } catch (error) {
        console.log(error);
      }
    };
    getContests();
  }, []);

  useEffect(() => {
    setClickedContest(allcontest && user ? allcontest.filter((contest)=>contest.participatedUser.some((p)=>p===user._id)):[])
    console.log(allcontest);
  }, [allcontest]);

  useEffect(()=>{console.log(clickedcontest)},[clickedcontest])

 

  return (
 
    <div className="bg-compBgSec  mx-auto w-[100vw] overflow-x-hidden ">
      {/* Ignore the header code */}
      <Helmet>
          <title>Competitions</title>
          <meta name="description" content="Compete in the most challenging contests" />
        </Helmet>
      <header className="flex items-center justify-between p-4 border-b invisible">
        <div className="flex items-center space-x-4">
          <img alt="Black Skull Logo" className="h-8" src="/placeholder.svg" />
          <div className="relative">
            <input
              className="border rounded-full pl-10 pr-4 py-2"
              placeholder="Find frinds and more"
              type="text"
            />
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Button className="bg-[#bd1e59] text-white">Competition</Button>
        
          <Avatar></Avatar>
        </div>
      </header>

      <main className="flex items-center flex-row bg-compBgSec">

        <section className="xl:w-[82vw] w-[90vw] xl:mx-[20%] bg-compBgSec flex items-center overflow-x-none  p-2">
  <div className="flex flex-col h-[100vh] w-full  p-2">
        <h2 className="text-xl text-primary font-semibold mb-1 ml-5">Competitions</h2>
          <div className="mlg:flex flex-row p-3 py-0 hidden ">

<div className={`text-${toggle2} text-xs mx-2 ${toggles  ? "border hover:cursor-pointer border-teal-700 rounded-full p-2 bg-primary" : "mt-2"}`} onClick={()=>filterData("Registered")}>Registered Contests</div>
<div className={`text-${toggle} text-xs mx-2 ${!toggles  ? "border hover:cursor-pointer border-teal-700 rounded-full p-2 bg-primary" : "mt-2"}`} onClick={()=>filterData("Unregister")}>Unregistered Contests</div>  
</div>
<div className="flex flex-row p-3 py-0 mlg:hidden">

<div className={`text-${toggle2} text-xs mx-2 ${toggles ? "border hover:cursor-pointer border-teal-700 rounded-full p-2 bg-primary" : "mt-2"}`} onClick={()=>filterData("Registered")}>Registered</div>
<div className={`text-${toggle} text-xs mx-2 ${!toggles  ? "border hover:cursor-pointer border-teal-700 rounded-full p-2 bg-primary" : "mt-2"}`} onClick={()=>filterData("Unregister")}>Unregistered</div>  
</div>
    <div className="bg-compBgSec  overflow-y-auto mt-2 scrollbar-thumb-gray-500 scrollbar-thumb-rounded-full scrollbar-track-gray-200 scrollbar-track-rounded-full" style={{ scrollbarWidth: "none" }} >
    {clickedcontest.length > 0 && clickedcontest.map((item, index) => (
      <>
      <div key={index} onClick={()=>fetchcontestdetails(item?._id)} className="rounded-xl ml-2 px-4 py-1 overflow-hidden">
        <Widget contest={item}></Widget>
      </div>
      
      </>
    ))}
    <br></br>
    <div className="mt-2 invisible h-[30px]"><h1>Feeednats</h1></div>
    </div>
    
  </div>


          
        </section>
      </main>
    </div>

  );
}

